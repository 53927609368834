//global
import React, { useEffect } from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import {
  formatDateForGTM,
  notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'src/helpers/Constants';
import { useOcSelector } from 'src/redux/ocStore';
import { useBreakpoints } from 'src/utils/breakpoints';
import { ctaBannerTailwindVariant } from 'tailwindVariants/components/ctaBannerTailwindVariant';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
//type
export type CtaBannerVariantProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.CtaBannerVariant.Fields.CtabannerVariant;
//component variants

//main component
const CtaBannerVariant: React.FC<CtaBannerVariantProps> = ({ fields, params }) => {
  const { base, ctaBannerWrapper, contentWrapper, title, description, link, icon, img } =
    ctaBannerTailwindVariant({
      size: { initial: 'mobile', lg: 'desktop' },
    });
  const isEE = useExperienceEditor();
  //Getting current Store ID
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const { deviceName }: { deviceName: string } = useBreakpoints();
  // Promotion GTM data Object
  const gtmPromotion = [
    {
      promotion_id: notAvailableIfNullOrEmpty(fields?.promotionId?.value),
      promotion_name: notAvailableIfNullOrEmpty(fields?.promotionName?.value),
      creative_name: notAvailableIfNullOrEmpty(fields?.componentName?.value),
      creative_slot: Number(fields?.creativeSlotNumber?.value),
      promotion_device: deviceName,
      promotion_copy: notAvailableIfNullOrEmpty(fields?.promotionCopy?.value),
      promotion_dates: notAvailableIfNullOrEmpty(
        formatDateForGTM(`${fields?.promotionDateFROM?.value} - ${fields?.promotionDateTO?.value}`)
      ),
      promotion_cta: notAvailableIfNullOrEmpty(fields?.cTAButtonCopy?.value),
      promotion_url: notAvailableIfNullOrEmpty(fields?.promotionURL?.value?.href),
    },
  ];
  //Promotion GTM data push on load
  useEffect(() => {
    if (myStoreId && fields?.componentName?.value !== '') {
      trackObjectForPromotion(GTM_EVENT?.viewPromotion, myStoreId, gtmPromotion);
    }
  }, [myStoreId]);
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div
      id={'cta-banner-variant'}
      className={base({ className: `${params?.Styles}` })}
      data-component={'components/CtaBannerVariant/CtaBannerVariant'}
      data-creative-id={fields?.componentName?.value}
      data-promotion-cta={fields?.cTAButtonCopy?.value}
      data-promo-id={fields?.promotionId?.value}
      data-promotion-name={fields?.promotionName?.value}
      data-promotion-copy={fields?.promotionCopy?.value}
      dta-promotion-url={fields?.promotionURL?.value?.href}
    >
      <div className={ctaBannerWrapper()}>
        <div className={contentWrapper()}>
          <RichTextHelper field={fields?.title} className={title()} />
          <RichTextHelper field={fields?.description} className={description()} />
          {fields?.link?.value && (
            <LinkHelper
              field={fields?.link}
              className={link()}
              onClick={() => {
                if (myStoreId && fields?.componentName?.value !== '') {
                  trackObjectForPromotion(GTM_EVENT?.selectPromotion, myStoreId, gtmPromotion);
                }
              }}
            >
              {fields?.link?.value?.href && fields?.link?.value?.href?.length > 0 ? (
                <>
                  {!isEE && fields?.link?.value?.text}
                  <IconHelper icon="chevron-right" className={icon()} />
                </>
              ) : (
                <></>
              )}
            </LinkHelper>
          )}
        </div>
        <div className={img()}>
          <ImageHelper field={fields?.bannerImage} className="max-w-[542px]" />
        </div>
      </div>
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<CtaBannerVariantProps>(CtaBannerVariant);
