/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { ComponentProps } from 'lib/component-props';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import clsx from 'clsx';
import ComponentContext from 'lib/context/ComponentContext';
import { Order } from 'ordercloud-javascript-sdk';
import { FulfillmentType, currentPath } from 'src/helpers/Constants';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { patchOrder } from 'src/redux/ocCurrentOrder';
import { useRouter } from 'next/router';
import useOcCart from 'src/hooks/useOcCart';
import { LineItemWithXp } from 'src/redux/xp';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import { warningDeliveryThresholdTailwindVariant } from 'tailwindVariants/components/warningDeliveryThresholdTailwindVariant';

export type WarningDeliveryThreshold = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.WarningDeliveryThreshold.Fields.WarningDeliveryThreshold;
//Tailwind

const WarningDeliveryThreshold = (props: WarningDeliveryThreshold): JSX.Element => {
  const { description, primaryLink, secondaryLink, title } = props?.fields ?? {};
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedStore = useOcSelector((state: any) => state?.storeReducer?.selectedStore);
  const eligibleValue = selectedStore?.deliveryFee?.minimumAmount;
  const dispatch = useOcDispatch();

  const {
    popUpPad,
    titleLargeBold,
    bodyCopy,
    buttonStyles,
    buttonPrimaryStyle,
    buttonSecondaryStyle,
  } = warningDeliveryThresholdTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  // handle modal Close functionality:
  const handleModalClose = () => {
    setcomponentContextData({
      ...componentContextData,
      showDeliveryThreashold: false,
      // openMiniCart: true,
    });

    // PSP-1914: hardcoding for now, TODO: BED Configeration
    if (!componentContextData?.openMiniCart && pathName == '/checkout') {
      router.push(currentPath?.isShoppingCart);
    }
  };

  const cart = useOcSelector((state: any) => state?.ocCurrentOrder?.order) as Order;
  const router = useRouter();
  const pathName = useRealPathName();
  const { getProductLineItems } = useOcCart();

  // const subTotal = useOcSelector((state: any) => state?.ocCurrentOrder?.order);
  const myStoreData = useOcSelector((state: any) => state?.storeReducer?.selectedStore);
  const productlineitems: LineItemWithXp[] = getProductLineItems();

  const subTotal: number = Object.keys(productlineitems).reduce(function (sum, key) {
    const lineTotal: number = productlineitems[parseInt(key)].LineTotal ?? 0;
    return sum + lineTotal;
  }, 0);

  const checkCurrentPage = () => {
    if (pathName == currentPath?.isCheckout) {
      setcomponentContextData({
        ...componentContextData,
        showDeliveryThreashold: false,
      });
      router.push(currentPath?.isShoppingCart);
    }
  };

  async function selectStoreClick() {
    setcomponentContextData({
      ...componentContextData,
      showDeliveryThreashold: false,
      // openMiniCart: true,
      showMinicartLoader: true,
    });
    const request: Order = { xp: { Fulfillment: FulfillmentType.BOPIS } };
    await dispatch(patchOrder({ request })).then((res: any) => {
      res &&
        setcomponentContextData({
          ...componentContextData,
          // openMiniCart: true,
          showMinicartLoader: false,
        });
      // For fixing PSP-1914
      if (!componentContextData?.openMiniCart) {
        setcomponentContextData({
          ...componentContextData,
          showDeliveryThreashold: false,
        });
      }
    });

    if (
      pathName == currentPath?.isShoppingCart &&
      cart?.xp?.Fulfillment == FulfillmentType?.DFS &&
      myStoreData?.deliveryFee?.minimumAmount > subTotal
    ) {
      router.push(currentPath?.isShoppingCart + '?ReturnUrl=' + currentPath?.isLoginCheckout);
    } else {
      router.push('/' + secondaryLink?.value?.href);
    }
  }
  return (
    <div>
      {componentContextData.showDeliveryThreashold && (
        <ModalWrapper
          additionalClassForModal={'!z-[1000]'}
          showCloseButtonInModalHeader={false}
          closeModalOnOverlayClick={true}
          showModal={componentContextData.showDeliveryThreashold}
          onCloseClick={handleModalClose}
          popupWidth="max-w-[995px]"
          popupSpacing={popUpPad()}
          customPopup
        >
          <div className="flex flex-col gap-desk-space-between-tight-vertical">
            <div className="delivery-threashold-popup">
              <Text field={title} tag="p" className={titleLargeBold()} encode={false}></Text>
              {description && (
                <RichTextHelper
                  field={description}
                  className={bodyCopy()}
                  tag="p"
                  updatedField={{
                    value: description?.value?.replaceAll('{minAmount}', '$' + eligibleValue),
                  }}
                />
              )}
              <div className="delivery-threashold-cta flex lg:flex-row flex-col lg:gap-[8px]">
                <LinkHelper
                  field={{
                    value: {
                      href: '/',
                      title: primaryLink?.value?.title,
                      text: primaryLink?.value?.text,
                    },
                  }}
                  onClick={async (e) => {
                    e.preventDefault();
                    setcomponentContextData({
                      ...componentContextData,
                      showDeliveryThreashold: false,
                      // openMiniCart: true,
                    });
                    checkCurrentPage();
                  }}
                  className={clsx(buttonStyles(), buttonPrimaryStyle())}
                />
                <LinkHelper
                  field={{
                    value: {
                      href: secondaryLink?.value?.href,
                      title: secondaryLink?.value?.title,
                      text: secondaryLink?.value?.text,
                    },
                  }}
                  className={clsx(buttonStyles(), buttonSecondaryStyle())}
                  onClick={async (e) => {
                    e.preventDefault();
                    await selectStoreClick();
                  }}
                />
              </div>
            </div>
          </div>
        </ModalWrapper>
      )}
    </div>
  );
};

export default WarningDeliveryThreshold;
