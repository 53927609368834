import React from 'react';

const PspSkeleton = () => {
  const SingleSkeletonCard = () => {
    return (
      <div className="bg-white p-1 sm:p-1 sm:h-128 rounded-md shadow-lg flex flex-col sm:flex-row gap-5 select-none w-full  md:w-[380px] lg:w-[220px]">
        <div className="flex flex-col flex-1 gap-5 sm:p-2 w-full">
          <div className="flex flex-1 flex-col gap-3 w-full">
            <div className="!h-[180px] sm:h-full sm:w-full rounded-md bg-gray-200 animate-pulse"></div>

            <div className="flex flex-col gap-2 mb-4">
              <div className="bg-gray-200 w-3/4 animate-pulse h-4"></div>
              <div className="bg-gray-200 w-full animate-pulse h-4"></div>
              <div className="bg-gray-200 w-3/4 animate-pulse h-4"></div>
            </div>

            <div className="bg-gray-200 w-full animate-pulse h-4"></div>
            <div className="bg-gray-200 w-full animate-pulse h-4"></div>
          </div>
        </div>
      </div>
    );
  };

  const FacetSkeleton = () => {
    return (
      <div className="hidden lg:flex !w-[276px] bg-white p-1 sm:p-1 rounded-md shadow-lg sm:flex-col select-none gap-6">
        <div className="bg-gray-200 w-full animate-pulse h-8 mt-4"></div>

        <div className="flex flex-col gap-2 mb-4">
          <div className="bg-gray-200 w-3/4 animate-pulse h-4"></div>
          <div className="bg-gray-200 w-full animate-pulse h-4"></div>
          <div className="bg-gray-200 w-3/4 animate-pulse h-4"></div>
        </div>

        <div className="bg-gray-200 w-full animate-pulse h-4"></div>
        <div className="bg-gray-200 w-full animate-pulse h-4"></div>

        <div className="flex flex-col gap-2 mb-4">
          <div className="bg-gray-200 w-3/4 animate-pulse h-4"></div>
          <div className="bg-gray-200 w-full animate-pulse h-4"></div>
          <div className="bg-gray-200 w-3/4 animate-pulse h-4"></div>
        </div>

        <div className="bg-gray-200 w-full animate-pulse h-4"></div>
        <div className="bg-gray-200 w-full animate-pulse h-4"></div>
      </div>
    );
  };

  const skeletonCards = Array(12).fill(<SingleSkeletonCard />);

  return (
    <div className="container flex sm:flex-wrap lg:flex-nowrap w-full">
      <div className="hidden lg:flex gap-4 flex-col w-[276px]">
        <FacetSkeleton />
        <FacetSkeleton />
      </div>
      <div className="flex flex-wrap gap-4 md:!justify-between lg:!justify-end w-full lg:w-fit">
        {skeletonCards.map((card, index) => (
          <React.Fragment key={index}>{card}</React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default PspSkeleton;
