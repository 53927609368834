import { tv } from 'tailwind-variants';

export const aboutStoreDetailVariants = tv(
  {
    slots: {
      heading:
        'text-color-text-black h-fit w-full text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold ',
      base: 'container mx-auto',
      contentWrapper: 'w-full bg-color-background-light flex justify-between',
      description:
        'text-body-large-regular font-body-large-regular leading-body-large-regular w-full flex flex-col gap-[25px]',
    },
    variants: {
      size: {
        desktop: {
          base: 'py-desk-padding-tight-y',
          heading: 'max-w-[336px]',
          contentWrapper:
            'flex-nowrap gap-desk-space-between-loose-vertical px-desk-padding-tight-x py-desk-padding-tight-y',
        },
        mobile: {
          base: 'p-mob-padding-tight-y',
          contentWrapper:
            'flex-wrap gap-mob-space-between-loose-vertical px-mob-padding-tight-x py-mob-padding-tight-y',
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
