//global
import React, { useContext, useEffect } from 'react';
//local

//lib
// import { ComponentProps } from 'lib/component-props';
import ComponentContext from 'lib/context/ComponentContext';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import { WarningStoreChangeProps } from 'components/WarningStoreChange/WarningStoreChange';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import { warningUnsavedChangesTailwindVariant } from 'tailwindVariants/components/warningUnsavedChangesTailwindVariant';

//type
export type WarningUnsavedChangesProps = Omit<WarningStoreChangeProps, 'children'>;
//component variants

//main component
const WarningUnsavedChanges: React.FC<WarningUnsavedChangesProps> = ({ fields, params }) => {
  const { base, wrapper, title, btnWrapper, btnPrimary, btnSecondary } =
    warningUnsavedChangesTailwindVariant({
      size: { initial: 'mobile', lg: 'desktop' },
    });
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  useEffect(() => {
    componentContextData?.isWarningPopUp && componentContextData?.isWarningPopUp
      ? document.body.classList.add('overflow-hidden')
      : document.body.classList.remove('overflow-hidden');
  }, [componentContextData?.isWarningPopUp]);
  //if no data then returned empty fragment
  // if (fields === undefined || fields === null) return <></>;
  return (
    <ModalWrapper
      showModal={componentContextData?.isWarningPopUp}
      onCloseClick={() => {
        setcomponentContextData({
          ...componentContextData,
          isEqualFields: false,
          isWarningPopUp: false,
        });
      }}
      additionalClassForPopUpModal={`${base({
        className: params?.Styles ?? '',
      })} !item-start !h-fit translate-y-0  animate-[topAnimation_0.3s_ease-in-out]`}
      popupWidth="max-w-[995px]"
      showCloseButtonInModalHeader={false}
      closeModalOnOverlayClick
      customPopup
    >
      <div className={wrapper()}>
        <TextHelper field={fields?.title} tag="p" className={title()} />
        <TextHelper field={fields?.description} tag="p" className="mt-8" />
        <div className={btnWrapper()}>
          {fields?.primaryLink && fields?.secondaryLink && (
            <>
              <button
                aria-label="primary link"
                className={btnPrimary()}
                onClick={(e) => {
                  e.preventDefault();
                  setcomponentContextData({
                    ...componentContextData,
                    isEqualFields: false,
                    isWarningPopUp: false,
                  });
                }}
              >
                <TextHelper field={{ value: fields?.primaryLink?.value?.text || '' }} />
              </button>
              <button
                aria-label="secondary link"
                className={btnSecondary()}
                onClick={(e) => {
                  e.preventDefault();
                  setcomponentContextData({
                    ...componentContextData,
                    collapse: componentContextData?.currentSection,
                    isWarningPopUp: false,
                    isEqualFields: true,
                  });
                }}
              >
                <TextHelper field={{ value: fields?.secondaryLink?.value?.text || '' }} />
              </button>
            </>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
// export default withDatasourceCheck()<WarningUnsavedChangesProps>(WarningUnsavedChanges);
export default WarningUnsavedChanges;
