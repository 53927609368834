import { tv } from 'tailwind-variants';

export const accountUnlockFormTailwindVariant = tv(
  {
    slots: {
      base: 'flex-col bg-color-background-light accountUnlockForm flex flex-center',
      successBase: 'flex text-center justify-center w-full',
      title: 'font-primary text-body-large-bold leading-body-large-bold font-body-large-bold',
      headingWrapper: 'flex flex-col',
      description:
        'font-primary rte text-body-large-regular leading-body-large-regular font-body-large-regular',
      radioButtonWrapper: 'flex w-fit relative cursor-pointer',
      radioButtonsWrapper: 'flex',
      radioIcon:
        'invisible w-[20px] h-[20px] outline-color-border-mid border-2 rounded-full peer-checked:visible absolute bg-color-brand-primary-1-base',
      radioField:
        'w-[20px] h-[20px] rounded-full cursor-pointer outline-color-border-mid border-2 appearance-none bg-color-background-white rounded-4 peer',
      button:
        'hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark flex justify-center text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold items-center bg-color-brand-primary-1-base text-color-text-white rounded-desk-global-radius-small',
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        mobile: {
          successBase: 'mb-mob-margin-loose-bottom py-mob-padding-tight-y',
          base: 'mb-mob-margin-loose-bottom mx-mob-global-grid-margin gap-mob-global-spacing-spacing-7 px-mob-padding-tight-x py-mob-padding-tight-y',
          headingWrapper: 'gap-mob-margin-base-bottom',
          radioButtonsWrapper: 'gap-mob-margin-tight-right',
          radioButtonWrapper: 'gap-mob-space-between-micro-horizontal',
          button:
            'w-full mt-mob-margin-loose-top px-mob-component-button-full-padding-x py-mob-component-button-full-padding-y',
        },
        desktop: {
          successBase: 'mb-desk-margin-loose-bottom  py-desk-padding-tight-y',
          base: 'mx-auto w-full max-w-[800px] gap-desk-global-spacing-spacing-11 px-desk-padding-tight-x py-desk-padding-tight-y mb-desk-margin-loose-bottom',
          headingWrapper: 'gap-desk-margin-tight-bottom',
          radioButtonWrapper: 'gap-desk-space-between-micro-horizontal',
          radioButtonsWrapper: 'gap-desk-margin-tight-right',
          button:
            'w-fit mt-desk-margin-base-top w-fit px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
