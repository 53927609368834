import { NextImage, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import React from 'react';
import { PSP } from 'models/PetSuppliesPlus.Model';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { checkoutHeaderTailwindVariant } from 'tailwindVariants/components/checkoutHeaderTailwindVariant';

export type CheckoutHeaderProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.CheckoutHeader.Fields.CheckoutHeader;

export default function CheckoutHeader({ fields }: CheckoutHeaderProps) {
  const { base, linkwrapper, deskimagewrapper, mobimagewrapper, iconWrapper, divWrapper } =
    checkoutHeaderTailwindVariant({
      device: { initial: 'mobile', lg: 'desktop' },
    });
  const isEE = useExperienceEditor();
  return (
    <div className={base()} id="checkoutHeader">
      {fields?.link && (
        <div className={divWrapper()}>
          <LinkHelper field={fields?.link} className={linkwrapper()}>
            <>
              <IconHelper icon={'chevron-left'} className={iconWrapper()} />
              {!isEE && fields?.link?.value?.text && <span>{fields?.link?.value?.text}</span>}
            </>
          </LinkHelper>
        </div>
      )}
      <div className={deskimagewrapper()}>
        <LinkHelper field={fields?.link as LinkField} hideLinkInEE={true}>
          <NextImage field={fields?.desktopLogo} />
        </LinkHelper>
      </div>
      <div className={mobimagewrapper()}>
        <LinkHelper field={fields?.link as LinkField} hideLinkInEE={true}>
          <NextImage field={fields?.mobileLogo} />
        </LinkHelper>
      </div>
    </div>
  );
}
