import { tv } from 'tailwind-variants';

export const pSPCrowdTwistLoyaltyTailwindVariant = tv(
  {
    slots: {
      content: ['flex flex-col gap-[35px]'],
      heading:
        'font-heading-desk-xLarge-bold text-color-text-black text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold',
      description:
        '[&_a]:text-color-text-brand-1 [&_a]:underline hover:[&_a]:text-color-brand-tertiary-3-base focus:[&_a]:text-color-brand-tertiary-3-base overflow-y-auto font-primary border-[1px] border-color-border-dark text-body-large-regular font-body-large-regular leading-body-large-regular flex flex-col',
      details: [''],
      top: ['flex justify-between'],
      myRewardsProfile: ['my-rewards-profile-details flex gap-desk-margin-base-right flex-1'],
      myRewardsProfileImage: [
        'w-[64px] h-[64px] border-[3px] p-[5px] border-color-background-brand-1 rounded-full',
      ],
      myRewardsProfileName: [
        'text-color-text-black text-heading-desk-small-bold font-heading-desk-small-bold',
      ],
      myRewardsProfileMemberSince: [''],
      myRewardsProfilePoints: ['font-heading-desk-small-bold'],
    },
    variants: {
      size: {
        mobile: {
          myRewardsProfile: ['pb-desk-padding-micro-x'],
          top: ['flex-col'],
        },
        desktop: {
          top: ['flex-row'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
