import { ImageField, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';

import { ImageProps } from '@sitecore-jss/sitecore-jss-react';
import { ImageProps as NextImageProperties } from 'next/image';

import React from 'react';
import useExperienceEditor from 'src/hooks/useExperienceEditor';

export type ImageHelperProps = ImageProps &
  Partial<NextImageProperties> & {
    className?: string;
    field?: ImageField;
    editable?: boolean;
    priority?: boolean;
  };

// Verify the image URL to identify if the width and heigh are present in the searchParams
// Example: .../psp/Landing-Page-Banner/Store-Event-Mobile1.png?h=184&iar=0&w=486&mw=1080
const checkImageSizeFromUrl = (url: string) => {
  const imageUrl = new URL(url);
  const imageUrlParams = new URLSearchParams(imageUrl.search);
  const imageWidth = imageUrlParams.get('w');
  const imageHeight = imageUrlParams.get('h');

  return {
    width: imageWidth ? parseInt(imageWidth, 10) : null,
    height: imageHeight ? parseInt(imageHeight, 10) : null,
  };
};

export default function ImageHelper({ field, className, editable, priority }: ImageHelperProps) {
  const isEE = useExperienceEditor();
  if (!field) {
    return <></>;
  }

  if (!field?.value?.src && !isEE) {
    return <></>; // Removing following text "Image source is missing" as this will be see on Buyer App side. So return only fragment.
  }

  // Check if the image width and height are missing in the value object
  if ((!field?.value?.width || !field?.value?.height) && !isEE) {
    let imageSizeValues;

    if (field?.value?.src) {
      imageSizeValues = checkImageSizeFromUrl(field?.value?.src);
    }

    // Render the <NextImage> vs <img> if the width and height are present in the URL
    if (imageSizeValues?.width && imageSizeValues?.height) {
      return (
        <NextImage
          field={{
            value: {
              src: field?.value?.src,
              alt: field?.value?.alt,
              width: imageSizeValues?.width,
              height: imageSizeValues?.height,
            },
          }}
          className={className}
          priority={priority}
        />
      );
    } else {
      // Render the default <img> tag if the width and height are not available
      return <img src={field?.value?.src} alt={field?.value?.alt as string} />;
    }
  }
  return (
    <>
      {isEE ? (
        <NextImage
          field={field}
          className={className}
          contentEditable={editable}
          priority={priority}
        />
      ) : (
        field?.value?.src &&
        field?.value?.src?.length > 0 && (
          <NextImage field={field} className={className} priority={priority} />
        )
      )}
    </>
  );
}
