import { tv } from 'tailwind-variants';

//component variants
export const myPetTailwindVariant = tv(
  {
    slots: {
      base: ['myPet flex gap-[35px]'],
      leftInnerDiv: ['petListing', 'w-full'],
      headingWrapper: ['flex', 'items-center'],
      heading: [
        'text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold',
      ],
      petListWrapper: [''],
      petDetailsWraper: ['flex flex-col gap-desk-margin-micro-bottom items-start'],
      loaderWrapper: [
        'w-full flex items-center gap-desk-space-between-base-vertical text-heading-desk-large-bold font-heading-desk-large-bold',
      ],
      addPetWrapper: ['flex items-center', 'rounded-[4px] border border-color-border-light'],
      leftContent: ['bg-color-background-light'],
      rightContent: [],
      addLabel: ['text-body-large-bold font-body-large-bold leading-body-large-bold'],
      link: [
        'text-body-small-bold font-body-small-bold leading-body-small-bold underline text-color-brand-primary-1-base cursor-pointer',
      ],
      imageWrapper: [
        'flex items-start',
        'border border-[3px] border-color-brand-primary-1-base rounded-full overflow-hidden',
      ],
      img: ['h-[104px]'],
      formWrapper: ['addPetForm', 'bg-color-background-light', 'rounded-[8px]'],
      formHeaderWrapper: ['gap-desk-margin-micro-bottom'],
      formFieldWrapper: [''],
      formHeadingTitle: [
        'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
      ],
      dropDownArrow: [
        'appearance-none',
        "bg-[url('/icons/dropdown.svg')]",
        'bg-no-repeat',
        'bg-[position:calc(100%-10px)_center]',
      ],
      textSmallBold: [
        'text-heading-desk-small-bold font-heading-desk-small-bold leading-heading-desk-small-bold',
      ],
      textSmall: ['text-body-small-regular font-body-small-regular leading-body-small-regular'],
      textLargeBold: ['text-body-large-bold font-body-large-bold leading-body-large-bold'],
      textLargeRegular: [
        'text-body-large-regular font-body-large-regular leading-body-large-regular',
      ],
      formInput: [
        'outline-none',
        'border-2',
        'border-color-border-mid',
        'text-color-text-black',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
        'placeholder:text-color-text-black',
        'focus:border-color-brand-primary-1-base',
        'focus:placeholder:opacity-0',
        'placeholder:transition-opacity',
        'placeholder:duration-500',
        'focus:border-color-brand-primary-1-base',
        'disabled:bg-color-scale-3-light-mid',
        'w-full',
        'appearance-none',
        'bg-color-scale-1-white',
      ],
      genderField: ['flex gap-desk-space-between-micro-horizontal'],
      fieldGroup: ['flex gap-desk-space-between-base-vertical'],
      FieldWrapper: ['w-full gap-margin-micro-bottom'],
      imageUploadWrapper: ['flex items-center gap-desk-space-between-tight-vertical'],
      imageUploadField: ['gap-desk-margin-micro-bottom'],
      bannerBase: ['banner max-w-[292px] w-[293px] max-h-[600px]'],
      deskImg: ['bannerImage'],
      imgSizeErrorMessage: ['text-system-red mb-desk-padding-micro-y'],
      mobImg: [''],
      imgFixWidth: ['h-[64px] w-[64px] max-w-[64px] max-h-[64px]'],
      linkWrapper: ['block', 'w-max'],
      ctaLink: [
        'z-10',
        'bg-color-brand-primary-1-base',
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
        'rounded-[0.25rem]',
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
      ],
      radioButton:
        'h-6 w-6 cursor-pointer min-h-6 min-w-6 appearance-none border border-color-border-dark rounded-full outline-none shadow-[inset_22px_0px_6px_6px_color-brand-primary-1-base] border-color-border-mid border-2 bg-color-scale-1-white',
      radioButtonFiller:
        'cursor-pointer absolute top-[1px] left-[1px] h-[22px] w-[22px] bg-color-brand-primary-1-base rounded-full',
      checkbox: [
        'w-[24px]',
        'h-[24px]',
        'outline-color-border-mid',
        'border',
        'appearance-none',
        'bg-color-background-white',
        'peer',
        'checked:bg-color-brand-primary-1-base',
        'rounded-[4px]',
        'border',
        'border-color-border-brand',
      ],
      checkboxIcon: [
        '[&>svg>*>*]:fill-color-background-white absolute [&>svg]:w-[24px] [&>svg]:h-[24px] invisible peer-checked:visible left-0',
      ],
    },

    compoundSlots: [
      { slots: ['addLabel', 'link'], class: ['text-color-brand-primary-1-base'] },
      {
        slots: [
          'formFieldWrapper',
          'formHeaderWrapper',
          'petListWrapper',
          'FieldWrapper',
          'imageUploadField',
        ],
        class: ['flex flex-col'],
      },
    ],
    variants: {
      size: {
        mobile: {
          base: ['flex-col', 'pb-mob-padding-tight-y'],
          headingWrapper: ['mb-mob-margin-base-bottom', 'gap-mob-margin-tight-right'],
          leftContent: ['p-mob-padding-micro-x'],
          rightContent: ['px-mob-padding-micro-x'],
          formWrapper: ['px-mob-padding-micro-x', 'py-mob-padding-tight-y'],
          formFieldWrapper: ['gap-desk-space-between-base-vertical'],
          fieldGroup: ['flex-col'],
          petListWrapper: ['gap-mob-space-between-tight-vertical'],
          formInput: [
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-4',
            'rounded-mob-global-radius-small',
          ],
          imageUploadWrapper: ['py-mob-padding-micro-y'],
          ctaLink: [
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
          ],
          // deskImg: ['hidden'],
        },
        desktop: {
          base: ['flex-row', 'pb-0'],
          headingWrapper: ['mb-desk-margin-base-bottom', 'gap-desk-margin-tight-right'],
          leftContent: ['p-desk-padding-micro-x'],
          rightContent: ['px-desk-padding-micro-x'],
          formWrapper: ['px-desk-padding-micro-x', 'py-desk-padding-tight-y'],
          formFieldWrapper: ['gap-desk-space-between-base-vertical'],
          fieldGroup: ['flex-row'],
          petListWrapper: ['gap-desk-margin-tight-bottom'],
          formInput: [
            'py-desk-global-spacing-spacing-3',
            'px-desk-global-spacing-spacing-4',
            'rounded-desk-global-radius-small',
          ],
          imageUploadWrapper: ['py-desk-padding-micro-y'],
          deskImg: ['block'],
          ctaLink: [
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'hover:bg-color-brand-primary-1-dark',
          ],
          // mobImg: ['hidden'],
        },
      },
      hasErrorStyle: {
        true: {
          formInput: ['border-system-red'],
        },
        false: {
          formInput: ['border-color-border-mid'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
