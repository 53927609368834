import { useRouter } from 'next/router';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { useCookies } from 'react-cookie';
import Link from 'next/link';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { handleCTRewardsSignIn } from 'lib/crowd-twist/ct-sign-in';
import { handleLogout } from 'src/helpers/authHandler';
import useDictionary from 'src/hooks/useDictionary';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import { leftNavigationTailwindVariant } from 'tailwindVariants/components/leftNavigationTailwindVariant';
type MenuItemsProps = {
  url: string;
  displayName: string;
  fields: {
    title: Field<string>;
    url: string;
  };
};

type LeftNavigationProps = {
  fields: {
    leftNavigationBackToMenuText: Field<string>;
    leftNavigationLinks: {
      fields: {
        title: Field<string>;
        url: string;
      };
    }[];
  };
  staticProps: {
    signOutUrl: string;
  };
};

const { sideNav, sideNavItems, sideNavItem, sideNavItemActive, sideNavItemIcon } =
  leftNavigationTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

const LinkIcon = () => {
  return (
    <span className={sideNavItemIcon()}>
      <svg
        aria-label="link-icon"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M11.9213 8.53129L5.84805 14.6045C5.55514 14.8974 5.08027 14.8974 4.78739 14.6045L4.07905 13.8962C3.78664 13.6038 3.78608 13.1299 4.0778 12.8368L8.89095 8.00095L4.0778 3.16516C3.78608 2.87207 3.78664 2.39816 4.07905 2.10576L4.78739 1.39741C5.0803 1.10451 5.55517 1.10451 5.84805 1.39741L11.9213 7.47063C12.2142 7.76351 12.2142 8.23838 11.9213 8.53129Z"
          fill="#231F1F"
        />
      </svg>
    </span>
  );
};

const LeftNavigation = ({ fields }: LeftNavigationProps) => {
  const isLoyaltyAccepted = useOcSelector((state) => state?.ocUser?.user?.xp?.LoyaltyAccepted);
  const unityId = useOcSelector((state) => state?.ocUser?.user?.xp?.UnityId) ?? '';
  const router = useRouter();
  const [cookies, setCookie, removeCookie] = useCookies(['ct-auth']);

  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);

  const dispatch = useOcDispatch();
  const pathName = useRealPathName();
  const isExperienceEditor = useExperienceEditor();
  const ctAuthCookie = cookies['ct-auth'];
  const { getDictionaryValue } = useDictionary();

  const handleCTSignIn = async (itemUrl: string) => {
    if (unityId && isLoyaltyAccepted === true && !ctAuthCookie) {
      const ctSignInRedirectUrl = await handleCTRewardsSignIn();

      if (ctSignInRedirectUrl) {
        setCookie('ct-auth', true);
        router.push(ctSignInRedirectUrl);
      } else {
        // The iframe widgets won't load without refresh (with router)
        window.location.href = itemUrl.toString();
      }
    } // User didn't accept Loyalty Terms and Conditions
    else {
      // The iframe widgets won't load without refresh (with router)
      window.location.href = itemUrl.toString();
    }
  };

  const handleLogoutClick = () => {
    // moved to helper function for better leveraging.
    handleLogout(dispatch, router, removeCookie, ctAuthCookie);
  };

  if (!fields) {
    return null;
  }

  return (
    <ul id="LeftNavigationMenu" className={sideNav()}>
      {fields?.leftNavigationLinks.map((item: MenuItemsProps, index: number) => (
        <li key={index} className={sideNavItems()}>
          {item?.url && item?.url?.includes('/accountmanagement/my-rewards') ? (
            <a
              aria-label={item?.displayName}
              onClick={() => handleCTSignIn(item?.url)}
              className={`${sideNavItem()} ${pathName === item?.url && sideNavItemActive()}`}
            >
              {/* <RichText field={item.fields.title} /> */}
              <span>{item?.displayName}</span>
              <LinkIcon />
            </a>
          ) : (
            <Link
              aria-label={item?.displayName}
              href={item?.url}
              className={`${sideNavItem()} ${pathName === item?.url && sideNavItemActive()}`}
            >
              {/* <RichText field={item.fields.title} /> */}
              <span>{item?.displayName}</span>
              <LinkIcon />
            </Link>
          )}
        </li>
      ))}
      {!isAnonymous && !isExperienceEditor && (
        <li className={sideNavItems()}>
          <a aria-label="logout" className={sideNavItem()} onClick={() => handleLogoutClick()}>
            {getDictionaryValue('LogoutLabel').toLocaleUpperCase()}
            <LinkIcon />
          </a>
        </li>
      )}
    </ul>
  );
};

// export const getStaticProps: GetStaticComponentProps = async (_rendering, layoutData) => {
//   const siteName = layoutData.sitecore.context.site?.name ?? '';
//   const site = siteResolver.getByName(siteName);
//   const protocol = (process.env.PUBLIC_URL as string).startsWith('https') ? 'https' : 'http:';
//   const baseUrl = `${protocol}://${site.hostName}`;

//   const staticProps = await getCrowdTwistSignOutURL(siteName, baseUrl);
//   return staticProps;
// };

export default LeftNavigation;
