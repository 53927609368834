import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

const useGraphQlDataContext = () => {
  const sitecoreContext = useSitecoreContext();
  return (
    sitecoreContext.sitecoreContext.graphQlData ?? {
      configuration: null,
      storeServices: [],
      countriesRegions: [],
    }
  );
};

export { useGraphQlDataContext };
