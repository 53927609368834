// helpers/SplideSlider.tsx
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useRef } from 'react';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import {
  Options,
  Splide as Slider,
  SplideEventHandlers,
  SplideSlide,
} from '@splidejs/react-splide';
import Splide from '@splidejs/splide';
import useExperienceEditor from 'src/hooks/useExperienceEditor';

interface SplideSliderProps extends Partial<SplideEventHandlers> {
  options?: Options; // Options for configuring the Splide slider.
  children: ReactNode; // React children to be used as slides.
  /*if is autoplay is seted true from the sitecore side then we need also need to provide this props because 
  to manage the arrow click */
  isAutoPlay?: boolean;
  setVisibleIndex?: Dispatch<SetStateAction<number>>;
}

const SplideSlider: React.FC<SplideSliderProps> = ({
  options = {},
  children,
  isAutoPlay,
  setVisibleIndex,
  ...props
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const splideRef = useRef<any>(undefined);
  const initialRenderRef = useRef(true);
  const isEE = useExperienceEditor();

  const handleMove = (splide: Splide) => {
    if (isAutoPlay) {
      splide.Components.Autoplay.play();
    }
  };

  const handleVisible = (splide: Splide) => {
    setVisibleIndex?.(splide.index);
  };

  useEffect(() => {
    if (!initialRenderRef.current && splideRef.current) {
      // Force Splide to refresh when options change
      splideRef.current.splide.refresh();
    }
    initialRenderRef.current = false;
  }, [options.perPage]);

  // Dummy slide trick only works if there is 1 slide per page.  Needs modifications to work for multiple sides
  // const renderDummySlide = !options.perPage || options.perPage === 1; // If it's not set, the default is 1, so that's fine too.
  const renderDummySlide =
    ((initialRenderRef.current && splideRef.current && splideRef.current?.options.perPage === 1) ||
      !options.perPage) &&
    !isEE;

  // Splide component with combined options.
  return (
    <>
      <Slider
        ref={splideRef}
        options={options}
        onMoved={handleMove}
        onVisible={handleVisible}
        {...props}
        // This takes some time to load.  Hide it completely until it's active
        // There is default styling to change visibility to hidden, but were're rendering the first
        // slide below while Splider is loading and immediately swapping out once it's loaded
        // Since the swap is done with CSS selectors it is instant with no flash
        className={renderDummySlide ? 'peer hidden [&.is-active]:block' : ''}
      >
        {/* Map over children and wrap each in a SplideSlide component. */}
        {React.Children.map(children, (child) => (
          <SplideSlide>{child}</SplideSlide>
        ))}
      </Slider>
      {/* Render the first slide right away, and hide it once Splide has loaded. . */}
      {renderDummySlide ? (
        <div className="peer-[.is-active]:hidden">{React.Children.toArray(children)?.[0]}</div>
      ) : null}
    </>
  );
};

export default SplideSlider;
