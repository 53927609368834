import { tv } from 'tailwind-variants';

//component variants
export const loyaltyTermsOfUseTailwindVariant = tv(
  {
    slots: {
      base: 'flex flex-col',
      title: 'font-primary',
      buttonWrapper: 'flex',
      button:
        'flex justify-center items-center hover:no-underline font-heading-desk-medium-bold leading-heading-desk-medium-bold text-heading-desk-medium-bold text-color-text-white flex',
      agreeText:
        'text-body-large-bold font-body-large-bold font-primary leading-body-large-bold w-fit',
      description:
        '[&_a]:text-color-text-brand-1 [&_a]:underline hover:[&_a]:text-color-brand-tertiary-3-base focus:[&_a]:text-color-brand-tertiary-3-base overflow-y-auto font-primary border-[1px] border-color-border-dark text-body-large-regular font-body-large-regular leading-body-large-regular flex flex-col',
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        mobile: {
          base: 'gap-mob-padding-loose-bottom px-mob-global-grid-margin',
          title:
            'font-heading-mob-xLarge-bold text-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold',
          description: 'max-h-[387px] px-mob-padding-micro-y py-mob-padding-micro-x',
          buttonWrapper: 'flex-col gap-mob-global-spacing-spacing-6',
          button:
            'mb-mob-padding-loose-bottom rounded-mob-global-radius-small px-mob-component-button-full-padding-x py-mob-component-button-full-padding-y',
        },
        desktop: {
          base: 'gap-desk-padding-tight-bottom px-0',
          buttonWrapper: 'flex-row items-center gap-desk-global-spacing-spacing-10',
          title:
            'font-heading-desk-xLarge-bold text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold',
          description: 'max-h-[295px] px-desk-padding-micro-x py-desk-padding-micro-y ',

          button:
            'mb-0 w-fit rounded-desk-global-radius-small px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y',
        },
      },
      buttonDisabled: {
        true: {
          button: 'focus:outline-none bg-color-brand-primary-1-disabled cursor-not-allowed',
        },
        false: {
          button:
            'bg-color-brand-primary-1-base cursor-pointer hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
