import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import React, { useState, useEffect } from 'react';
import { PSP } from '../../../models/PetSuppliesPlus.Model';
import { ComponentProps } from 'lib/component-props';
import Link from 'next/link';
import { bactToTopTailwindVariant } from 'tailwindVariants/components/backToTopTailwindVariant';

export type BackToTopProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.BackToTop.Fields.BackToTopArrow;

/**
 * BackToTop component scrolls the page to the top when clicked.
 */
const BackToTop: React.FC<BackToTopProps> = ({ fields, params }) => {
  const [isVisible, setIsVisible] = useState(false);

  const { base, icon } = bactToTopTailwindVariant({
    isVisible,
  });
  // Show the button when the user scrolls down 200 pixels
  const handleScroll = () => {
    setIsVisible(window.scrollY > 200);
  };

  // Scroll to top when the button is clicked
  const scrollToTop = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    // Attach scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`${params?.Styles ?? ''}`}>
      <Link aria-label="back to top" href="/" onClick={scrollToTop} className={base()}>
        <IconHelper className={icon()} icon={'chevron-up'} />
        <Text tag="span" fields={fields?.backToTopText} className="sr-only" />
      </Link>
    </div>
  );
};

export default BackToTop;
