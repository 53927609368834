import { tv } from 'tailwind-variants';

const deliveryFrequencyVariant = tv(
  {
    slots: {
      autoshipLink:
        'text-color-brand-primary-1-base underline font-[700] focus:text-color-brand-tertiary-3-base hover:text-color-brand-tertiary-3-base',
      AutoshipFrequencyWrapper: ['flex', 'flex-col', 'gap-3'],
      autoshipSelectTitleWrapper: [
        'font-primary',
        'min-w-[280px]',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
        'text-color-text-black',
      ],
      autoshipSelect: [
        'relative',
        'appearance-none',
        'min-w-[280px]',
        'w-fit',
        'border-2',
        'border-color-border-mid',
        'font-body-large-regular',
        'text-body-large-regular',
        'leading-body-large-regular',
        'font-primary',
        'focus:outline-none',
        'text-color-text-black',
        '!bg-white',
      ],
      base: ['w-full', 'flex', 'flex-col', 'bg-color-background-light'],
      autoshipSelectDescriptionWrapper: [
        'font-primary',
        'font-body-small-bold',
        'text-body-small-bold',
        'leading-body-small-bold',
        'text-color-text-black',
      ],
      titleWrapper: [
        'flex',
        'flex-row',
        'gap-desk-margin-base-right',
        'items-center',
        // 'justify-start',
        'justify-between',
        'self-stretch',
        'shrink-0',
        'relative',
      ],
      title: ['text-color-text-black'],
      linkWithUnderline: [
        'text-left',
        'text-color-brand-primary-1-base',
        'relative',
        'font-body-large-bold_underline',
        'text-body-large-bold_underline',
        'leading-body-large-bold_underline',
        'underline',
        'hover:cursor-pointer',
      ],
      itemWrapper: [
        'border-solid',
        'border-l-0',
        'border-color-border-mid',
        'border',
        'flex',
        'items-start',
        'justify-start',
        'self-stretch',
        'shrink-0',
        'relative',
        'xl:w-[561px]',
        'xl:gap-desk-margin-loose-right',
        'xl:px-desk-padding-micro-x',
      ],
      cardInfoWrapper: [
        'flex',
        'flex-col',
        'flex-grow',
        'items-start',
        'justify-start',
        'shrink-0',
        'relative',
      ],
      cardTitleQtyContainer: [
        'flex',

        // 'gap-[53px]',
        'items-start',
        'justify-start',
        'justify-between',
        'self-stretch',
        'shrink-0',
        'relative',
      ],
      cartTitle: [
        'text-color-text-black',
        'text-left',
        'font-body-large-bold',
        'text-body-large-bold',
        'leading-body-large-bold',
      ],
      cartTitleRed: ['text-system-red'],
      cartTitleRedPill: [
        'bg-system-red',
        'text-color-text-white',
        'px-1',
        'rounded-mob-global-radius-small',
      ],
      cartTitlePurple: [
        'bg-color-brand-autoship',
        'text-color-text-white',
        'px-mob-global-spacing-spacing-2',
        'lg:px-desk-global-spacing-spacing-2',
        'rounded-mob-global-radius-small',
      ],
      hideInMobile: ['hidden', 'md:block'],
      // centerInMobile: [],
      startInMobile: [],
      infoWrapper: ['flex', 'flex-row', 'gap-desk-margin-loose-right'],
      // radioButtonsWrapper: ['flex', 'flex-col', 'gap-[12px]'],

      singleRadioWrapper: ['flex', 'w-fit', 'relative', 'cursor-pointer'],
      radioIcon:
        'invisible w-[20px] h-[20px] outline-color-border-mid border-2 rounded-full peer-checked:visible absolute bg-color-brand-primary-1-base',
      radioField:
        'min-w-[20px] h-[20px] rounded-full cursor-pointer outline-color-border-mid border-2 bg-color-background-white checked:bg-color-brand-primary-1-base rounded-4 peer appearance-none	',
      radioAsCheckboxWrapper: ['flex', 'flex-row', 'absolute', 'top-0', 'right-0', 'z-10'],
      informationTitle: [
        'text-color-text-black',
        'text-left',
        'font-body-large-bold',
        'text-body-large-bold',
        'leading-body-large-bold',
      ],
      radioButtonsWrapper: [
        // 'bg-rose-500',
        'gap-[8px]',
      ],
      radioNote: [
        'text-color-brand-autoship',
        'text-body-small-bold',
        'leading-body-small-bold',
        'font-body-small-bold',
      ],
      borderStyles: [
        'border-[1px]',
        'border-solid',
        'border-color-border-mid',
        'border-r-0',
        'border-l-0',
      ],
      productWrapper: [''],
      lineItemsWrapper: ['flex'],
      priceWrapper: [
        'flex flex-row items-start justify-start self-stretch shrink-0 relative gap-mob-global-spacing-spacing-8 lg:gap-[28px]',
      ],
      centerInMobile: ['max-md:flex-col max-md:items-center', 'lg:flex-col '],
      dropdownClass:
        'cursor-pointer appearance-none bg-[url("/icons/dropdown.svg")] bg-no-repeat bg-[position:calc(100%-10px)_center]',
    },
    variants: {
      size: {
        mobile: {
          autoshipSelect: [
            'rounded-mob-global-radius-small',
            'py-mob-global-spacing-spacing-3',
            'pl-mob-global-spacing-spacing-4',
            'pr-7',
          ],
          base: [
            'py-mob-padding-tight-y',
            'px-mob-padding-micro-x',
            'gap-mob-margin-base-bottom',
            'mb-mob-padding-micro-x',
          ],

          title: [
            'text-heading-mob-medium-bold',
            'font-heading-mob-medium-bold',
            'leading-heading-mob-medium-bold',
          ],
          itemWrapper: [
            // 'pt-mob-padding-tight-y',
            // 'pr-mob-padding-tight-x',
            // 'pb-mob-padding-tight-y',
            // 'pl-mob-padding-tight-x',
            // 'gap-mob-margin-tight-bottom',
            // 'bg-rose-500',
            'p-0',
            'gap-0',
            'flex-col',
            'border-solid',
            'border-l-0',
            'border-r-0',
          ],
          cardInfoWrapper: ['gap-0', 'gap-mob-margin-base-right'],
          cardTitleQtyContainer: ['flex-col', 'justify-center', 'gap-mob-margin-base-right'],
          // centerInMobile: ['max-md:flex-col', 'max-md:items-center'],
          startInMobile: [
            'flex',
            'align-start',
            'gap-mob-margin-loose-right',
            'py-mob-padding-micro-y',
            'px-mob-padding-micro-x',
          ],
          infoWrapper: ['py-mob-padding-micro-y', 'px-mob-padding-micro-x'],
          radioButtonsWrapper: [
            'py-mob-global-spacing-spacing-4',
            'px-mob-global-spacing-spacing-2',
          ],
          cartTitle: ['w-fit'],
          productWrapper: ['flex', 'flex-col'],
          lineItemsWrapper: ['flex-col'],
        },
        desktop: {
          autoshipSelectTitleWrapper: ['min-w-[280px]', 'w-[280px]'],
          autoshipSelect: [
            'rounded-desk-global-radius-small',
            'py-desk-global-spacing-spacing-3',
            'pl-desk-global-spacing-spacing-4',
            'pr-7',
          ],
          base: [
            'py-desk-padding-tight-y',
            'px-desk-padding-micro-x',
            'gap-desk-margin-base-bottom',
            'mb-desk-padding-micro-x',
          ],
          title: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
          ],
          itemWrapper: [
            'xl:flex-row',
            // 'flex-initial',
            // 'justify-between',
            'max-w-[561px]',
            'border-r-[1px]',
            'py-desk-padding-micro-y',
            'px-desk-padding-micro-x',
            'gap-[16px]',
            'pl-[0px]',
            'w-[385px]',
          ],
          cardInfoWrapper: ['gap-desk-margin-loose-bottom'],
          cardTitleQtyContainer: ['xl:flex-row'],
          infoWrapper: ['py-desk-padding-micro-y', 'px-desk-padding-micro-x'],
          radioButtonsWrapper: [
            'py-desk-global-spacing-spacing-4',
            'px-desk-global-spacing-spacing-2',
          ],
          cartTitle: ['max-w-[204px]'],
          lineItemsWrapper: ['flex-row'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
export default deliveryFrequencyVariant;
