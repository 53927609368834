import { tv } from 'tailwind-variants';

//component variants
export const carouselThreeColumnTailwindVariant = tv(
  {
    slots: {
      base: ['carouselThreeColumn', 'font-primary', 'relative'],
      innerDiv: ['container', 'mx-auto'],
      backgroundImageWrapper: [
        'absolute',
        'top-0',
        'left-0',
        'z-[1]',
        'h-full',
        'w-full',
        'bg-cover',
      ],
      backgroundImage: ['h-full', 'w-full', 'object-cover'],
      heading: ['text-center', 'z-[1]'],
      cardWrapper: [
        'z-[1]',
        '[&_.splide_ul_li]:h-full',
        '[&_.splide]:w-[90%]',
        '[&_.splide]:flex',
        '[&_.splide]:justify-center',
        '[&_.splide_ul_li]:h-auto',
        '[&_.splide_.arrow-prev]:-left-[80px]',
        '[&_.splide_.arrow-next]:-right-[70px]',
        '[&_.splide_.splide-button]:bg-white',
        '[&_.splide_.splide-button]:h-[40px]',
        '[&_.splide_.splide-button]:w-[40px]',
        '[&_.splide_.splide-button>svg]:fill-color-brand-primary-1-base',
        '[&_.splide_.splide-button>svg>path]:!drop-shadow-arrows',
        '[&_*_*.splide-button]:border-color-brand-primary-1-base',
        '[&_*_*.splide-button]:border-[2px]',
        '[&_*_*.splide-button]:border-solid',
      ],
      card: [
        'border',
        'border-[5px]',
        'rounded-[24px]',
        'max-w-[358px]',
        'w-full',
        'relative',
        'bg-color-background-white',
      ],
      description: [],
      designation: [''],
      userName: [
        'w-full',
        'break-all',
        'text-heading-desk-small-bold',
        'font-heading-desk-small-bold',
        'leading-heading-desk-small-bold',
      ],
      userWrapper: ['justify-center', 'items-center', 'mt-auto'],
      userDetail: ['gap-desk-margin-micro-bottom'],
      quoteIcon: [
        'absolute',
        'top-[82px]',
        'right-[47px]',
        'h-full',
        'w-full',
        'max-w-[80px]',
        'max-h-[130px]',
      ],
      imageWrapper: [''],
      img: ['h-[140px]', 'w-full', 'max-w-[171px]', 'object-cover', 'self-end', 'z-[1]'],
    },
    compoundSlots: [
      {
        slots: ['description', 'designation'],
        class: ['text-body-large-regular', 'font-body-large-regular', 'leading-body-large-regular'],
      },
      {
        slots: ['cardWrapper', 'userWrapper', 'innerDiv', 'card', 'userDetail'],
        class: ['flex'],
      },
      {
        slots: ['innerDiv', 'card', 'userDetail'],
        class: ['flex-col'],
      },
    ],
    variants: {
      device: {
        mobile: {
          innerDiv: ['py-mob-padding-micro-y', 'pr-0'],
          heading: [
            'text-heading-mob-xLarge-bold',
            'font-heading-mob-xLarge-bold',
            'leading-heading-mob-xLarge-bold',
            'pr-mob-global-grid-margin',
          ],
          card: [
            'h-auto',
            'px-mob-padding-micro-x',
            'pt-mob-padding-micro-top',
            'rounded-global-radius-xLarge',
            'gap-mob-global-spacing-spacing-10',
            'w-full',
            'min-w-[320px]',
          ],
          cardWrapper: [
            // '[&_:last-child]:mr-[15px]',
            'overflow-x-scroll',
            'items-stretch',
            'pl-2',
            'no-scrollbar',
            'py-desk-padding-tight-y',
            'gap-mob-space-between-base-horizontal',
          ],
          cardDetails: ['gap-mob-space-between-tight-vertical'],
          userWrapper: ['gap-mob-space-between-tight-vertical'],
        },
        desktop: {
          innerDiv: ['py-desk-padding-tight-y'],
          heading: [
            'text-heading-desk-xLarge-bold',
            'font-heading-desk-xLarge-bold',
            'leading-heading-desk-xLarge-bold',
            'pr-0',
            'pb-[40px]',
          ],
          card: [
            'h-full',
            'px-desk-padding-micro-x',
            'pt-desk-padding-micro-top',
            'gap-desk-global-spacing-spacing-10',
            'min-w-[0px]',
          ],
          cardWrapper: [
            'items-stretch',
            'py-desk-padding-tight-y',
            'gap-desk-space-between-base-horizontal',
            'px-desk-padding-micro-x',
            'justify-center',
            'overflow-visible',
          ],
          cardDetails: ['gap-desk-space-between-tight-vertical'],
          userWrapper: ['gap-desk-space-between-tight-vertical'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
