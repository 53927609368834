import { PSP } from '../../../models/PetSuppliesPlus.Model';
import { ComponentProps } from 'lib/component-props';
// import clsx from 'clsx';

import React from 'react';
import ButtonHelper from 'src/helpers/commonComponents/Button';
import { anchorNavigationTailwindVariant } from 'tailwindVariants/components/anchorNavigationTailwindVariant';

export type AnchorNavigationProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.Anchor.Fields.Anchor;

const { base, wrapper, linkWrapper, linkLi } = anchorNavigationTailwindVariant({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
});
// Main AnchorNavigation component

const AnchorNavigation: React.FC<AnchorNavigationProps> = ({ fields }) => {
  if (fields === null || fields === undefined) return <></>;
  return (
    <div className={base()}>
      <div className={wrapper()}>
        <ul className={linkWrapper()}>
          {Array.isArray(fields?.list) &&
            fields?.list?.map((Item, index: number) => {
              return (
                <li key={index} className={linkLi()}>
                  <ButtonHelper
                    isLinkField={true}
                    variant="link"
                    size="small"
                    field={Item?.fields?.link}
                  />
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export const Default = (props: AnchorNavigationProps): JSX.Element => {
  return <AnchorNavigation {...props} />;
};
