import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { mobileBackToMenuTailwindVariant } from 'tailwindVariants/components/mobileBackToMenuTailwindVariant';

// MobileBackToMenu in Experience Editor
const MobileBackToMenuEE = () => {
  const { base, text, navLink, backToMenuIcon } = mobileBackToMenuTailwindVariant({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  return (
    <div className={base()}>
      <a aria-label="back to menu" className={navLink()}>
        <IconHelper className={backToMenuIcon()} icon={'chevron-left'} />
        <div className={text()}>Back To Menu</div>
      </a>
    </div>
  );
};

export default MobileBackToMenuEE;
