import { tv } from 'tailwind-variants';

export const forgotPasswordTailwindVariant = tv(
  {
    slots: {
      fieldWrap: ['flex', 'justify-between', 'w-full'],
      base: 'mx-auto flex flex-col items-center text-color-text-white center gap-desk-margin-base-bottom items-start relative',
      formWrapper: 'flex flex-col items-start self-stretch bg-color-background-light',
      form: 'flex flex-col items-start text-color-text-black  flex flex-col items-start',
      fieldsWrapper: 'flex flex-col items-start  gap-desk-space-between-base-vertical',
      halfFieldWrapper: 'flex items-start gap-desk-space-between-base-vertical',
      field: 'flex flex-col  items-start self-stretch gap-desk-margin-micro-bottom',
      halfField: 'flex flex-col w-1/2  items-start self-stretch gap-desk-margin-micro-bottom',
      label: 'font-body-large-bold',
      disclaimer: '',
      button:
        'flex content-center items-center rounded-desk-global-radius-small  text-heading-desk-medium-bold leading-heading-desk-medium-bold font-heading-desk-medium-bold',
      passwordWrapper: 'flex flex-row w-full justify-between items-center ',
      showPasswordWrap:
        ' w-fit align-right font-primary text-heading-desk-small-regular font-heading-desk-small-regular leading-heading-desk-small-regular border-2 border-solid border-color-border-mid border-l-0 h-[43px] px-desk-global-spacing-spacing-4 flex items-center bg-color-scale-1-white cursor-pointer',
      removeBorderRight: 'border-r-0',
      mobileFieldWrapper: 'flex gap-desk-space-between-base-vertical',
      mobileField:
        'custom-checkbox [&>input]:h-[24px] [&>input]:w-[24px] [&>input]:bg-color-brand-primary-1-base flex gap-desk-space-between-tight-horizontal [&>input:checked~span.checkmark]:bg-color-brand-primary-1-base',
      checkboxField:
        'custom-checkbox [&>input]:h-[24px] [&>input]:w-[24px] [&>input]:bg-color-brand-primary-1-base flex gap-desk-space-between-tight-horizontal [&>input:checked~span.checkmark]:bg-color-brand-primary-1-base font-body-large-bold leading-body-large-bold text-body-large-bold',
      createBlock: 'flex flex-col items-start gap-desk-margin-tight-bottom self-stretch',
      createTitle: '',
      createDesc: 'font-body-large-regular',
      ctaLink:
        'text-color-brand-primary-1-base font-body-large-bold_underline text-body-large-bold_underline leading-body-large-bold_underline underline',
      loggedDesc: 'font-body-small-bold text-body-small-bold leading-body-small-bold',
      position: 'relative',
      input:
        'flex flex-col items-start self-stretch font-body-large-regular h-[43px] gap-[8px] py-desk-global-spacing-spacing-0 px-desk-global-spacing-spacing-4 text-color-text-black border-2 border-solid border-color-border-mid bg-color-scale-1-white w-full ',
      formTitle: 'text-color-text-black',
      formDescription: 'text-color-text-black',
      formTitleBlock: ['flex flex-col'],
    },
    compoundSlots: [
      {
        slots: ['createTitle', 'createDesc'],
        class: ['self-stretch', 'font-primary', 'text-color-text-black'],
      },
      {
        slots: ['form', 'fieldsWrapper', 'halfFieldWrapper', 'disclaimer'],
        class: ['w-full', 'self-stretch'],
      },
      {
        slots: ['input', 'ctaLink', 'button'],
        class: ['outline-none', 'focus:border-color-brand-primary-1-base', 'appearance-none '],
      },
      {
        slots: ['input'],
        class: [
          'focus:bg-color-scale-1-white',
          'focus:border-3',
          'focus:border-color-border-mid',
          'focus:border-solid',
        ],
      },
    ],
    variants: {
      site: {
        psp: {
          base: 'bg-color-background-white',
        },
        wnw: {
          base: 'bg-color-background-white',
        },
      },
      size: {
        mobile: {
          base: 'gap-mob-margin-base-bottom',
          formWrapper:
            'flex-col gap-mob-margin-loose-bottom py-mob-padding-tight-y px-mob-global-grid-margin',
          halfFieldWrapper: 'flex-col',
          form: 'w-full gap-mob-margin-loose-bottom',
          field: 'w-full',
          halfField: 'w-full',
          disclaimer: 'text-body-large-regular leading-body-large-regular font-body-large-regular',
          button:
            'gap-mob-component-button-full-space-between py-mob-component-button-full-padding-y px-mob-component-button-full-padding-x ',
          createTitle:
            'font-heading-mob-large-bold leading-heading-mob-large-bold text-heading-mob-large-bold',
          fieldWrap: [
            'gap-x-mob-space-between-loose-horizontal',
            'flex-col',
            'gap-mob-space-between-base-vertical',
          ],
          formTitle: [
            'text-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
            'font-heading-mob-large-bold',
          ],
          formDescription: [
            'text-body-large-regular',
            'leading-body-large-regular',
            'font-body-large-regular',
          ],
          formTitleBlock: ['gap-mob-margin-tight-bottom'],
        },
        desktop: {
          base: 'max-w-desk-global-dimensions-width py-desk-padding-tight-y px-desk-global-grid-margin pb-desk-padding-micro-y    gap-desk-margin-base-bottom',
          formWrapper:
            'flex-row gap-desk-margin-loose-bottom py-desk-padding-tight-y px-desk-padding-tight-x ',
          halfFieldWrapper: 'flex-row',
          form: 'min-w-[290px] max-w-1/2 bg-color-red-300',
          field: 'min-w-[280px]',
          halfField: 'min-w-[280px]',
          disclaimer: 'text-body-small-regular leading-body-small-regular font-body-small-regular',
          button:
            'gap-desk-component-button-full-space-between py-desk-component-button-full-padding-y px-component-button-full-padding-x',
          createTitle:
            'font-heading-desk-large-bold leading-heading-desk-large-bold text-heading-desk-large-bold',
          fieldWrap: [
            'gap-x-desk-space-between-loose-horizontal',
            'flex-row',
            'gap-none',
            'flex flex-cols items-end',
          ],
          formTitle: [
            'text-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
            'font-heading-desk-large-bold',
          ],
          formDescription: [
            'text-body-large-regular',
            'leading-body-large-regular',
            'font-body-large-regular',
          ],
          formTitleBlock: ['gap-desk-margin-tight-bottom'],
        },
      },
      outlineCta: {
        true: {
          button: [
            'bg-color-background-white',
            'text-color-text-brand-1',
            'border-2 border-solid border-color-brand-primary-1-dark',
            'hover:bg-color-brand-primary-1-dark hover:text-color-text-white',
            'focus:bg-color-brand-primary-1-dark focus:text-color-text-white',
          ],
        },
        false: {
          button: [
            'bg-color-brand-primary-1-base',
            'text-color-text-white',
            'hover:bg-color-brand-primary-1-dark',
            'focus:bg-color-brand-primary-1-dark',
          ],
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'], // `true` to apply to all screen sizes
  }
);
