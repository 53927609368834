//global
import React, { useEffect } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { PSP } from '../../../models/PetSuppliesPlus.Model';

//local

//lib
import { ComponentProps } from 'lib/component-props';
import clsx from 'clsx';
import { MultiColumnItemProps } from 'components/MultiColumnItem/MultiColumnItem';
import {
  formatDateForGTM,
  Items,
  notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'src/helpers/Constants';
import { useBreakpoints } from 'src/utils/breakpoints';
import { useOcSelector } from 'src/redux/ocStore';
import { multiColumnContainerTailwindVariant } from 'tailwindVariants/components/multiColumnContainerTailwindVariant';

export type MultiColumnContainerProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.MultiColumnContainer.RenderingParameter.Fields.MultiColumnContainer & {
    children?: React.ReactElement<React.HTMLProps<HTMLElement>> | null;
    placeholders?: object; // Add the placeholders property here
  };

//check withDataSourceCheck If it is not then show blank instead of error.
function extractFieldsData(data: MultiColumnContainerProps, deviceName: string, myStoreId: string) {
  const fieldsData: Pick<MultiColumnItemProps, 'fields'>[] = [];
  const placeholders = data.rendering.placeholders;

  for (const column in placeholders) {
    if (Object.prototype.hasOwnProperty.call(placeholders, column)) {
      const columnData = placeholders[column];
      if (Array.isArray(columnData)) {
        columnData.forEach((item: PlaceholderItem) => {
          if (item.fields) {
            fieldsData.push(item?.fields);
          }
        });
      }
    }
  }

  const gtmPromotion = fieldsData
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ?.map((data: any) => {
      return data?.componentName?.value?.length > 0
        ? {
            promotion_id: notAvailableIfNullOrEmpty(data.promotionId?.value),
            promotion_name: notAvailableIfNullOrEmpty(data.promotionName?.value),
            creative_name: notAvailableIfNullOrEmpty(data?.componentName?.value),
            creative_slot: Number(data?.creativeSlotNumber?.value),
            promotion_device: deviceName,
            promotion_copy: notAvailableIfNullOrEmpty(data?.promotionCopy?.value),
            promotion_dates: notAvailableIfNullOrEmpty(
              formatDateForGTM(
                `${data?.promotionDateFROM?.value} - ${data?.promotionDateTO?.value}`
              )
            ),
            promotion_cta: notAvailableIfNullOrEmpty(data?.cTAButtonCopy?.value),
            promotion_url: notAvailableIfNullOrEmpty(data?.promotionURL?.value?.href),
          }
        : null;
    })
    .filter((item) => item !== null);

  if (gtmPromotion !== null) {
    gtmPromotion?.length > 0 &&
      trackObjectForPromotion(GTM_EVENT?.viewPromotion, myStoreId, gtmPromotion as Items[]);
  }
}

//main component
const MultiColumnContainer: React.FC<MultiColumnContainerProps> = ({
  params,
  children,
  rendering,
}) => {
  const { base, wrapper, wrapperSub } = multiColumnContainerTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const { deviceName }: { deviceName: string } = useBreakpoints();
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const data = { rendering, params };
  useEffect(() => {
    myStoreId && deviceName && data && extractFieldsData(data, deviceName, myStoreId);
  }, [myStoreId, deviceName]);
  return (
    <div className={base()}>
      <div className={wrapper()}>
        <div
          className={clsx(wrapperSub(), {
            'lg:grid-cols-2 grid-cols-1 gap-y-[20px]':
              params?.FieldNames == 'Default' || params?.FieldNames == 'TwoColumnContainer',
            'lg:grid-cols-3 grid-cols-1': params?.FieldNames == 'ThreeColumnContainer',
            'lg:grid-cols-4 grid-cols-1': params?.FieldNames == 'FourColumnContainer',
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

interface PlaceholderItem {
  fields?: Pick<MultiColumnItemProps, 'fields'>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const Default = (props: MultiColumnContainerProps): JSX.Element => {
  return (
    <MultiColumnContainer {...props}>
      <>
        <Placeholder name="column-1" rendering={props.rendering} />
        <Placeholder name="column-2" rendering={props.rendering} />
      </>
    </MultiColumnContainer>
  );
};
export const TwoColumnContainer = (props: MultiColumnContainerProps): JSX.Element => {
  return <Default {...props} />;
};

export const ThreeColumnContainer = (props: MultiColumnContainerProps): JSX.Element => {
  // const { deviceName }: { deviceName: string } = useBreakpoints();
  // const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  // props?.fields && extractFieldsData(props, deviceName, myStoreId);
  return (
    <MultiColumnContainer {...props}>
      <>
        <Placeholder name="column-1" rendering={props.rendering} />
        <Placeholder name="column-2" rendering={props.rendering} />
        <Placeholder name="column-3" rendering={props.rendering} />
      </>
    </MultiColumnContainer>
  );
};

export const FourColumnContainer = (props: MultiColumnContainerProps): JSX.Element => {
  // const { deviceName }: { deviceName: string } = useBreakpoints();
  // const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  // props?.fields && extractFieldsData(props, deviceName, myStoreId);
  return (
    <MultiColumnContainer {...props}>
      <>
        <Placeholder name="column-1" rendering={props.rendering} />
        <Placeholder name="column-2" rendering={props.rendering} />
        <Placeholder name="column-3" rendering={props.rendering} />
        <Placeholder name="column-4" rendering={props.rendering} />
      </>
    </MultiColumnContainer>
  );
};
