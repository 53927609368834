import { tv } from 'tailwind-variants';

export const orderConfirmationSummaryVariant = tv(
  {
    slots: {
      base: [
        'flex',
        'flex-col',
        'w-full',
        'bg-color-scale-2-light',
        'border-[1px]',
        'border-solid',
        'border-color-border-mid',
      ],
      formContainer: [
        'container',
        'flex',
        'flex-col',
        'contact-us',
        'w-full',
        '[&>*]:w-full',
        'gap-[24px]',
      ],
      orderTitle: ['text-body-large-bold', 'font-body-large-bold', 'leading-body-large-bold'],
      listGroup: ['flex', 'flex-col', 'w-full', 'gap-mob-margin-tight-bottom'],
      loaderWrapper: ['flex', 'items-center'],
    },
    variants: {
      device: {
        mobile: {
          base: ['gap-mob-margin-tight-bottom', 'py-mob-padding-micro-y', 'px-mob-padding-micro-x'],
          formContainer: ['p-mob-padding-micro-x'],
        },
        desktop: {
          base: [
            'gap-desk-margin-tight-bottom',
            'max-w-[560px]',
            'py-desk-padding-micro-y',
            'px-desk-padding-micro-x',
          ],
          formContainer: ['px-inherit', 'px-0'],
          listGroup: ['gap-desk-margin-tight-bottom'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
