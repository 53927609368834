/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { ComponentParams, RichText, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import Link from 'next/link';
import { useOcSelector } from 'src/redux/ocStore';
import { PSP } from 'models/PetSuppliesPlus.Model';
import {
  trackObjectForPromotion,
  formatDateForGTM,
  notAvailableIfNullOrEmpty,
} from 'src/utils/sendGTMEvent';
import { useBreakpoints } from 'src/utils/breakpoints';
import { GTM_EVENT } from 'src/helpers/Constants';
import withPersonalization from 'src/helpers/withPersonalization/withPersonalization';
import { ComponentProps } from 'lib/component-props';
import { useRouter } from 'next/router';
import { alertPromoTailwindVariant } from 'tailwindVariants/components/alertPromoTailwindVariant';

export type AlertPromoProps = PSP.Sitecore.templates.PetSuppliesPlus.AlertPromo.Fields.AlertPromo &
  ComponentProps & {
    params: ComponentParams;
    fields?: {
      [key: string]: any; // will have to remove this once we have correct leprechaun generated.
    };
  };
export type AlertPromoLookups = {
  lookupName: string;
  children: {
    results: [];
  };
  items: {
    list: {
      fields: {
        Text: {
          value: string;
        };
        Value: {
          value: string;
        };
      };
    }[];
  };
}[];

const AlertPromo = ({ fields, params }: AlertPromoProps) => {
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const { deviceName }: { deviceName: string } = useBreakpoints();
  const { base, container, title, heading, closeButton, screenReaderOnly } =
    alertPromoTailwindVariant({
      size: {
        initial: 'mobile',
        lg: 'desktop',
      },
    });
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  const { isReady } = useRouter();

  const gtmPromotion = [
    {
      promotion_id: notAvailableIfNullOrEmpty(fields?.promotionId?.value),
      promotion_name: notAvailableIfNullOrEmpty(fields?.promotionName?.value),
      creative_name: notAvailableIfNullOrEmpty(fields?.componentName?.value),
      creative_slot: Number(fields?.creativeSlotNumber?.value),
      promotion_device: deviceName,
      promotion_copy: notAvailableIfNullOrEmpty(fields?.promotionCopy?.value),
      promotion_dates: notAvailableIfNullOrEmpty(
        formatDateForGTM(`${fields?.promotionDateFROM?.value} - ${fields?.promotionDateTO?.value}`)
      ),
      promotion_cta: notAvailableIfNullOrEmpty(fields?.cTAButtonCopy?.value),
      promotion_url: notAvailableIfNullOrEmpty(fields?.promotionURL?.value?.href),
    },
  ];
  useEffect(() => {
    if (isReady && myStoreId && fields?.componentName?.value !== '') {
      trackObjectForPromotion(GTM_EVENT?.viewPromotion, myStoreId, gtmPromotion);
    }
  }, [myStoreId, isReady]);
  const SD_COLOR = fields?.backgroundColor;
  const backgroundColor = SD_COLOR?.fields?.Value?.value;

  //return empty fragment if field value is null or undefined
  if (fields === undefined || fields === null) return <></>;

  return (
    <div
      data-component={'components/AlertPromo/AlertPromo'}
      data-creative-id={fields?.componentName?.value}
      data-promotion-cta={fields?.cTAButtonCopy?.value}
      data-promo-id={fields?.promotionId?.value}
      data-promotion-name={fields?.promotionName?.value}
      data-promotion-copy={fields?.promotionCopy?.value}
      dta-promotion-url={fields?.promotionURL?.value?.href}
      className={`${base({ className: params?.Styles ?? '' })} ${
        isBannerVisible ? 'alert-promo-banner' : 'hidden'
      }`}
      tabIndex={-1}
      style={{
        backgroundColor: backgroundColor ? backgroundColor : 'transparent',
      }}
    >
      <div className={container()}>
        {fields?.linkhref?.value && fields?.linkhref?.value?.href ? (
          <Link
            aria-label="title link"
            className={heading()}
            href={fields?.linkhref?.value.href}
            target={fields?.linkhref?.value?.target || '_self'}
            onClick={() =>
              myStoreId &&
              fields?.componentName?.value !== '' &&
              trackObjectForPromotion(GTM_EVENT?.selectPromotion, myStoreId, gtmPromotion)
            }
          >
            <RichText className={title()} field={fields?.linkText} tag="div" />
          </Link>
        ) : (
          <div className={heading()}>
            <RichText className={title()} field={fields?.linkText} tag="div" />
          </div>
        )}
      </div>
      <button
        aria-label="close button"
        onClick={handleCloseBanner}
        type="button"
        className={closeButton()}
      >
        <svg
          aria-label="close button"
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
        >
          <g clipPath="url(#clip0_5837_10352)">
            <path
              d="M31.3333 10.35L28.9833 8L19.6667 17.3167L10.35 8L8 10.35L17.3167 19.6667L8 28.9833L10.35 31.3333L19.6667 22.0167L28.9833 31.3333L31.3333 28.9833L22.0167 19.6667L31.3333 10.35Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_5837_10352">
              <rect width="40" height="40" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span className={screenReaderOnly()}>Close banner</span>
      </button>
    </div>
  );
};

// export default AlertPromo;
export default withDatasourceCheck()<AlertPromoProps>(withPersonalization(AlertPromo));
