import { tv } from 'tailwind-variants';

export const heroTailwindVariant = tv(
  {
    slots: {
      base: ['flex w-full justify-center'],
      container: [
        'relative',
        'grid',
        'max-w-desk-global-dimensions-width',
        'h-full',
        'mx-auto',
        '[&_.sc-image-wrapper]:absolute',
        '[&_.sc-image-wrapper]:h-full',
        '[&_.sc-image-wrapper]:w-full',
        '[&>.sc-image-wrapper]:z-[1]',
      ],
      headingSection: ['flex', 'relative', 'items-center'],
      headingBox: ['max-w-[548px]'],
      subtitle: [
        'rte',
        'font-primary',
        'text-color-text-black',
        'mb-desk-margin-micro-bottom',
        'relative',
        'z-[2]',
      ],
      title: [
        'rte',
        'font-primary',
        'text-color-text-dark',
        'text-heading-desk-xLarge-bold',
        'font-heading-desk-xLarge-bold',
        'leading-heading-desk-xLarge-bold',
        'relative',
        'z-[2]',
      ],
      content: ['rte', 'text-body-large-regular', 'mb-desk-global-spacing-spacing-4'],
      cta: ['flex', 'relative', 'z-[2]'],
      buttonStyles: [
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
        'p-4',
        'w-full',
        'hover:no-underline',
        'flex',
        'justify-center',
        'items-center',
        'text-center',
        'duration-300',
        'rounded',
      ],
      buttonPrimaryStyle: [
        'bg-color-brand-primary-1-base',
        'hover:bg-color-brand-primary-1-dark',
        'border-color-brand-primary-1-base',
        'text-white',
      ],
      buttonSecondaryStyle: [
        'text-color-brand-primary-1-base',
        'border-2 border-color-brand-primary-1-base',
        'rounded bg-white',
        'hover:bg-color-brand-primary-1-base',
        'hover:text-white',
      ],
      heroBackground: ['absolute'],
      heroImagesSection: ['flex', 'justify-center', 'relative', 'z-[2]'],
      heroImageGifStyle: ['absolute'],
      heroImageStyle: ['w-4/5', 'relative', 'mx-auto'],
    },
    variants: {
      size: {
        mobile: {
          base: ['p-0'],
          headingSection: [
            'order-last',
            'py-desk-global-spacing-spacing-6',
            'pr-desk-global-spacing-spacing-4',
            'pl-mob-padding-loose-x',
          ],
          subtitle: [
            'text-heading-mob-small-bold',
            'font-heading-mob-small-bold',
            'leading-heading-mob-small-bold',
          ],
          title: ['mb-desk-global-spacing-spacing-4'],
          content: ['mb-desk-global-spacing-spacing-5'],
          heroBackground: ['max-h-desk-component-image-careers-page-height', 'hidden'],
          heroImagesSection: ['bg-[#b8e5c9]'],
          heroImageGifStyle: ['top-[5px]', 'h-[100px]'],
          heroImageStyle: [''],
          cta: ['flex-col', 'gap-desk-global-spacing-spacing-4'],
        },
        desktop: {
          base: ['h-[575px]'],
          container: [
            'grid-cols-12',
            'mx-desk-global-spacing-spacing-4',
            'xl:mx-desk-global-grid-margin',
            '[&_.sc-image-wrapper]:absolute',
            '[&_.sc-image-wrapper]:h-full',
            '[&_.sc-image-wrapper]:w-full',
            '[&>.sc-image-wrapper]:z-[1]',
          ],
          heroImagesSection: ['col-span-6', 'max-h-full', 'mt-0', 'p-0', 'bg-transparent'],
          headingSection: [
            'col-span-6',
            'order-none',
            'h-full',
            'justify-end',
            'pl-desk-global-spacing-spacing-9',
            'xl:pl-desk-global-spacing-spacing-11',
            'mx-auto',
          ],
          content: ['relative', 'z-[2]'],
          subtitle: [
            'text-heading-desk-small-bold',
            'font-heading-desk-small-bold',
            'leading-heading-desk-small-bold',
          ],
          title: ['mb-desk-global-spacing-spacing-3'],
          headingBox: [],
          heroBackground: ['max-h-full', 'block'],
          heroImageGifStyle: ['w-full', 'top-[90px]', 'left-0', 'right-0', 'h-[150px]'],
          heroImageStyle: [''],
          cta: ['flex-row', 'gap-desk-global-spacing-spacing-5'],
          buttonStyles: [
            'w-auto',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
          ],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
