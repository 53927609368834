import { tv } from 'tailwind-variants';

export const bactToTopTailwindVariant = tv({
  slots: {
    base: 'backToTop fixed transition-opacity flex flex-cols justify-center items-center duration-1000 ease-in bottom-[104px] z-[2] right-8 p-4 rounded-full text-color-scale-1-white bg-color-brand-primary-1-base cursor-pointer shadow-[0px_2px_8px_0px_rgba(0,0,0,0.25)] w-[40px] h-[40px]',
    icon: '[&>svg>*>*]:fill-color-brand-primary-1-base h-6 w-6 [&_svg]:max-w-6 [&_svg]:max-h-6',
  },
  variants: {
    isVisible: {
      true: {
        base: 'opacity-100 z-0',
      },
      false: {
        base: 'opacity-0 z-[-1]',
      },
    },
  },
});
