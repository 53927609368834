import React, { useEffect } from 'react';
import {
  Field,
  NextImage,
  RichText,
  Text,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import { useTheme } from 'lib/context/ThemeContext';
import {
  HideLinkFromStoreNavigation,
  ShowLinkFromStoreNavigation,
} from 'src/helpers/StoreLandingHelper';
import { useGraphQlDataContext } from 'lib/context/GraphQlDataContext';
import { StoreService } from 'lib/page-props-factory/graphql/store-services';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { servicesStoreDetailTailwindVariants } from 'tailwindVariants/components/servicesStoreDetailTailwindVariants';

const getSelectedService = (index: string, servicesData: StoreService[]) => {
  return servicesData?.find((service) => service?.serviceId?.value?.toString() === index);
};
const RenderServices = ({
  services,
  servicesData,
}: {
  services: string[];
  servicesData: StoreService[];
}) => {
  const { themeName } = useTheme();
  const {
    servicesWrapper,
    serviceWrapper,
    serviceTitle,
    serviceDetailWrapper,
    serviceDescription,
    link,
    linkWrapper,
  } = servicesStoreDetailTailwindVariants({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
    theme: themeName,
  });
  return (
    <div className={servicesWrapper()}>
      {services?.length > 0 &&
        services?.map((serviceIndex, index) => {
          const data = getSelectedService(serviceIndex, servicesData);
          return (
            <div className={serviceWrapper()} key={index}>
              {data?.link?.value?.href ? (
                <LinkHelper
                  field={{
                    value: {
                      href: data?.link?.value?.href,
                    },
                  }}
                  className={linkWrapper()}
                >
                  <NextImage field={data?.icon} />
                </LinkHelper>
              ) : (
                <NextImage field={data?.icon} />
              )}
              <div className={serviceDetailWrapper()}>
                <Text tag="p" className={serviceTitle()} field={data?.name} />
                <RichText tag="p" className={serviceDescription()} field={data?.description} />
              </div>
              <LinkHelper className={link()} field={data?.link} />
            </div>
          );
        })}
    </div>
  );
};
const Services = ({
  fields,
}: {
  fields: {
    data: {
      datasource: {
        servicesText: Field<string>;
      };
      storeServices: {
        services: Field<string>;
      };
    };
  };
}) => {
  const { storeServices } = useGraphQlDataContext();

  const { base, headingWrapper, heading } = servicesStoreDetailTailwindVariants({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const storeDetails: PSP.Sitecore.templates.PetSuppliesPlus.PageTypes.Fields.StoreDetail['fields'] =
    useSitecoreContext()?.sitecoreContext?.route?.fields;

  useEffect(() => {
    if (
      !(
        storeDetails?.services?.value &&
        storeDetails?.services?.value?.split('|') &&
        storeDetails?.services?.value?.split('|')?.length > 0 &&
        storeServices?.length
      )
    ) {
      HideLinkFromStoreNavigation('services');
    } else {
      ShowLinkFromStoreNavigation('services');
    }
  }, [storeDetails, storeServices]);
  return (
    <>
      {storeDetails?.services?.value &&
        storeDetails?.services?.value?.split('|') &&
        storeDetails?.services?.value?.split('|')?.length > 0 &&
        storeServices?.length && (
          <div className={base()} id="services">
            <div className={headingWrapper()}>
              <Text tag="p" className={heading()} field={fields?.data?.datasource?.servicesText} />
            </div>
            <RenderServices
              services={storeDetails?.services?.value?.split('|') || []}
              servicesData={storeServices}
            />
          </div>
        )}
    </>
  );
};

export default Services;
