import { tv } from 'tailwind-variants';

export const iconWithDescriptionTailwindVariant = tv(
  {
    slots: {
      base: ['iconWithDescription', 'container'],
      contentWrapper: ['max-w-[954px] ', 'mx-auto', 'gap-3', 'w-full'],
      cardWrapper: [''],
      card: ['flex', 'group'],
      cardContentWrapper: [''],
      cardTitle: [
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
      ],
      cardDescription: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      cardIcon: ['max-w-[70px]', 'max-h-[70px]', '[&>img]:max-w-max', 'lg:group-hover:hidden'],
      cardIconHovered: ['Hovered hidden lg:group-hover:block'],
      img: ['h-[70px]', 'w-[70px]'],
      titleWrapper: ['w-full'],
      descriptionWrapper: ['w-full'],
    },
    compoundSlots: [
      { slots: ['base', 'contentWrapper', 'cardContentWrapper'], class: ['flex', 'flex-col'] },
      {
        slots: ['cardIcon', 'cardIconHovered'],
        class: ['max-w-[70px]', 'max-h-[70px]', '[&>img]:max-w-max'],
      },
    ],
    variants: {
      device: {
        mobile: {
          base: ['py-mob-padding-tight-y', 'bg-[#B8E5C9]'],
          cardWrapper: [
            'pt-mob-padding-tight-y',
            'flex',
            'overflow-x-scroll',
            'items-start',
            '[&>*:last-child]:mr-[5px]',
            'gap-mob-global-grid-margin',
            'items-stretch',
          ],
          titleWrapper: [
            'pt-mob-padding-tight-y',
            'text-heading-mob-xLarge-bold',
            'font-heading-mob-xLarge-bold',
            'leading-heading-mob-xLarge-bold',
          ],
          descriptionWrapper: ['pr-mob-global-grid-margin'],
          contentWrapper: ['text-center'],
          cardContentWrapper: ['gap-mob-margin-tight-bottom'],
          card: [
            'flex-col',
            'text-center',
            'bg-white',
            'h-auto',
            'py-mob-padding-tight-y',
            'px-mob-global-grid-margin',
            'gap-mob-margin-tight-bottom',
            'items-center',
            'justify-start',
            'min-w-[260px]',
            'min-h-[453px]',
          ],
          cardDescription: ['min-w-[235px]'],
          cardIconHovered: [''],
        },
        desktop: {
          base: ['py-desk-padding-tight-y', 'bg-transparent'],
          cardWrapper: [
            'grid',
            'grid-cols-2',
            'pt-desk-padding-tight-y',
            'gap-x-desk-global-grid-gutter',
            'gap-y-desk-margin-loose-bottom',
            'overflow-x-hidden',
            '[&>*:last-child]:mr-0',
          ],
          titleWrapper: [
            'pt-0',
            'text-heading-desk-xLarge-bold',
            'font-heading-desk-xLarge-bold',
            'leading-heading-desk-xLarge-bold',
          ],
          descriptionWrapper: ['pr-0'],
          contentWrapper: ['gap-3'],
          cardDescription: [''],
          card: [
            'py-0',
            'my-mob-padding-tight-y',
            'px-desk-padding-micro-x',
            'gap-desk-margin-base-right',
            'min-w-[360px]',
            'min-h-[0px]',
            'flex-row',
            'items-start',
            'w-full',
            'shadow-none',
          ],
          cardContentWrapper: ['text-left'],
        },
      },
      site: {
        psp: {},
        wnw: { titleWrapper: ['font-secondary'] },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
