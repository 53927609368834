import { tv } from 'tailwind-variants';

export const favouriteFriendsTailwindVariant = tv(
  {
    slots: {
      slider: 'w-full',
      base: 'bg-color-background-light',
      container: 'container',
      sliderContainer:
        'flex flex-col items-center [&_.splide]:w-full [&_.slider-arrow]:rounded-[23px] [&_.slider-arrow]:top-[44px] [&_.slider-arrow]:transform-none [&_.slider-arrow_svg]:w-3 [&_.slider-next]:-right-[35px] [&_.slider-prev]:-left-[35px] [&_.slider-arrow_svg]:h-3 [&_.slider-arrow]:max-w-6 [&_.slider-arrow]:max-h-6 [&_.slider-arrow]:border-2 [&_.slider-arrow]:border-color-background-dark [&_.slider-arrow]:border-solid [&_.slider-arrow]:bg-color-background-light [&_.slider-arrow]:!opacity-100 [&_.is-active.is-visible_.slide]:border-b-[4px] [&_.is-active.is-visible_.slide]:border-b-color-brand-primary-1-base [&_.is-active.is-visible_.slide]:pb-2',
      sliderTitle: 'rte w-full font-primary text-center',
      slideContainer:
        'cursor-pointer slide w-full flex flex-col items-center [&_img]:w-[120px] [&_img]:h-[120px]',
    },
    variants: {
      size: {
        desktop: {
          slider: 'px-0 ',
          sliderContainer: 'py-desk-padding-tight-y gap-desk-margin-loose-bottom',
          sliderTitle:
            'p-0 text-heading-desk-xLarge-medium font-heading-desk-xLarge-medium leading-heading-desk-xLarge-medium',
          slideContainer: 'max-w-[197px] pb-desk-margin-tight-bottom gap-desk-margin-tight-bottom',
        },
        mobile: {
          slider: 'px-mob-margin-loose-right',
          sliderContainer:
            'py-mob-padding-tight-y gap-mob-margin-loose-bottom [&_.is-active.is-visible_.slide]:pb-1',
          sliderTitle:
            'text-heading-mob-xLarge-bold font-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold',
          slideContainer: 'pb-mob-margin-tight-bottom gap-mob-margin-tight-bottom',
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
