//global
import React from 'react';
import { NextImage, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';

//local
import { PSP } from 'models/PetSuppliesPlus.Model';

//lib
import { ComponentProps } from 'lib/component-props';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { textBannerTailwindVariant } from 'tailwindVariants/components/textBannerTailwindVariant';

//type
export type TextBannerProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.TextBanner.Fields.TextBanner;

//main component
const TextBanner: React.FC<TextBannerProps> = ({ fields, params }) => {
  //check if pageEditor or not
  const isEE = useExperienceEditor();

  //variaints
  const { base, heading, desktopImage, mobileImage, img } = textBannerTailwindVariant({
    device: { initial: 'mobile', lg: 'desktop' },
  });
  const backgroundColor =
    fields?.hexColor && fields?.hexColor?.value?.length > 0
      ? fields?.hexColor?.value
      : 'bg-color-brand-primary-1-base';
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div
      className={params?.Styles + ' ' + base({ class: backgroundColor })}
      style={{ backgroundColor: backgroundColor }}
    >
      {fields?.leftImage?.value?.src && fields?.leftImage?.value?.src?.length > 0 && (
        <div className={desktopImage()}>
          <NextImage field={fields?.leftImage} />
        </div>
      )}

      <RichTextHelper tag="h1" field={fields?.heading} className={heading()} />

      {isEE && (
        <>
          Background color :<TextHelper field={fields?.hexColor} />
        </>
      )}
      {fields?.rightImage?.value?.src && fields?.rightImage?.value?.src?.length > 0 && (
        <div className={desktopImage()}>
          <NextImage field={fields?.rightImage} />
        </div>
      )}
      {fields?.mobileImage?.value?.src && fields?.mobileImage?.value?.src?.length > 0 && (
        <div className={mobileImage()}>
          <NextImage field={fields?.mobileImage} className={img()} />
        </div>
      )}
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<TextBannerProps>(TextBanner);
