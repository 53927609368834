import { tv } from 'tailwind-variants';

export const headingWithDescriptionTailwindVariants = tv(
  {
    slots: {
      base: ['headingWithDescription container'],
      titleWrapper: [''],
      descriptionWrapper: [
        '[&>a]:no-underline',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      contentWrapper: ['flex ', 'flex-col'],
    },
    variants: {
      textAlignment: {
        LeftAlign: {
          base: ['text-left'],
          contentWrapper: ['max-w-[954px]'],
        },
        LeftAlignFullWidth: {
          base: ['text-left'],
        },
        CenterAlignFullWidth: {
          base: ['text-center'],
        },
        CenterAlign: {
          base: ['text-center'],
          contentWrapper: ['max-w-[954px]', 'mx-auto'],
        },
        Default: {
          base: ['text-center'],
          contentWrapper: ['max-w-[954px]', 'mx-auto'],
        },
      },
      site: {
        psp: {},
        wnw: { titleWrapper: ['font-secondary'] },
      },
      device: {
        mobile: {
          base: ['pt-mob-padding-tight-y pb-5'],
          titleWrapper: [
            'text-heading-mob-xLarge-bold ',
            'font-heading-mob-xLarge-bold ',
            'leading-heading-mob-xLarge-bold',
          ],
          contentWrapper: ['gap-2'],
        },
        desktop: {
          base: ['pt-desk-padding-tight-y pb-desk-padding-micro-y'],
          titleWrapper: [
            'text-heading-desk-xLarge-bold ',
            'font-heading-desk-xLarge-bold ',
            'leading-heading-desk-xLarge-bold',
          ],
          descriptionWrapper: ['[&>a]:cursor-pointer'],
          contentWrapper: ['gap-3'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
