//global
import React, { useContext, useEffect, useState } from 'react';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import ProductDetailSliderComponent, {
  SlideProps,
} from 'components/ProductDetailSliderComponent/ProductDetailSliderComponent';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { useBreakpoints } from 'src/utils/breakpoints';
import useDictionary from 'src/hooks/useDictionary';
import StoreDetailTab from 'components/StoreDetailTab/StoreDetailTab';
import useOcCart from 'src/hooks/useOcCart';
import { useOcSelector } from 'src/redux/ocStore';
import Head from 'next/head';
import BazarVoiceReviews from 'components/BazarVoice/BazarVoiceReviews';
import ComponentContext from 'lib/context/ComponentContext';
import { isProductInWishlist, updateProductInWishlist } from 'src/redux/wishlist';
import { useDispatch } from 'react-redux';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useRouter } from 'next/router';
import urlConfig from 'src/utils/urlConfig';
import { useAutoshipMessage } from 'src/helpers/utility/AutoshipHelper';
import { PriceScheduleWithXp, ProductXp } from 'src/redux/xp';
import useOcCurrentOrder from 'src/hooks/useOcCurrentOrder';
import clsx from 'clsx';
import { Order } from 'ordercloud-javascript-sdk';
import {
  FulfillmentType,
  GTMLabels,
  GTM_EVENT,
  getProductDetailPath,
  retailUnitMeasureFieldName,
} from 'src/helpers/Constants';
import { patchOrder } from 'src/redux/ocCurrentOrder';

//type
import { useProductPriceForUI } from 'src/hooks/useProductPrice';
import { getGTMSessionStorage, sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { ProductSearchResultModelWithVariants } from 'src/helpers/search/SearchResults/types';
import { getFirstProductImage, getSortedProductImages } from 'src/helpers/productDetailHelper';
import { extractDomainUrlFromConfig } from 'src/helpers/publicURLTrailingSlash';
import { trackAddEvent, trackEntityPageViewEvent } from '@sitecore-search/react';
import Loader from 'components/Loader/Loader';
import { productDetailTailwindVariant } from 'tailwindVariants/components/productDetailTailwindVariant';
export type ProductData = {
  error?: string;
  SEOMetadata?: {
    Title?: string;
    Description?: string;
    Keywords?: string;
  };
  PCMProduct?: {
    Product?: {
      GuaranteedAnalysisNutrientName?: string;
      Ingredients?: string;
      ProductDetailsBullets?: string[];
    };
  };
  ID: string;
  ParentID: string;
  Name: string;
  PriceSchedule?: PriceScheduleWithXp;
  Xp?: ProductXp;
};

type InitialState = 'OutOfStock' | 'LowStock' | 'InStock';
interface FacetValue {
  Value: string;
  Count: number;
}

interface Facet {
  Name: string;
  XpPath: string;
  Values: FacetValue[];
  xp: { [key: string]: string };
}

interface Product {
  [key: string]: string[];
}

interface FilteredProductsData {
  filteredProducts?: Product[];
  filterFacetKeys?: string[];
  facets?: Facet[];
}

//main component
const ProductDetail = () => {
  const { getDictionaryValue } = useDictionary();
  const {
    base,
    title,
    learnMoreLink,
    productDetailsWrapper,
    productOptionsWrapper,
    productOptionsWrapperForPickup,
    productOptionText,
    deliveryOptionsWrapper,
    deliveryOptionWrapper,
    productOptions,
    productOption,
    productOptionLabel,
    productOptionDropdown,
    pickUpOptionLabel,
    pickUpOptionText,
    changeStoreText,
    changeStoreIcon,
    autoShipOptionWrapper,
    autoShipLabel,
    autoShipWrapper,
    centerDiv,
    autoShipText,
    toolbarIcon,
    tooltipWrapper,
    topTooltipWrapper,
    tooltipContainer,
    tooltipArrowFirstDiv,
    tooltipArrowSecondDiv,
    toolTipTitle,
    toolTipDescription,
    topToolTipDescription,
    tooltipPhoneNumber,
    productActionWrapper,
    addToFavoritesWrapper,
    addToCartButton,
    productQtyWrapper,
    productQtyInput,
    productQtyButtonWrapper,
    productQtyButton,
    availabilityWrapper,
    priceText,
    // storePickUpText,
    priceWrapper,
    // loginText,
    pricesWrapper,
    priceContainer,
    productInfoWrapper,
    heartIconWithTitle,
    checkAvailabilityText,
    stockAvailability,
    errorMessage,
    radioButton,
    relative,
    storeName,
    titleWrapper,
    toolbarIconWrapper,
    addToFavoriteIcon,
    topTooltipArrowFirstDiv,
    topTooltipArrowSecondDiv,
    radioButtonFiller,
    relativeDiv,
    reviewWrapper,
    tooltipText,
    productInput,
    inputWrapper,
    dropdownClass,
  } = productDetailTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const fulfillmentMethod = useOcSelector((state) => state?.ocCurrentOrder?.order?.xp?.Fulfillment);

  const { sitecoreContext } = useSitecoreContext();
  const productData = sitecoreContext.productData;
  const parentId = productData?.ParentID;
  const productSku = productData?.Xp?.UPC;

  const siteName = sitecoreContext?.site?.name ?? '';
  const domainUrl = extractDomainUrlFromConfig(siteName);

  const [error, setError] = useState<string | null>(null);
  const [qty, setQty] = useState<number>(1);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isError, setIsError] = useState<boolean | undefined>(undefined);
  const [productInventory, setProductInventory] = useState(0);
  const [remainingProductInventory, setRemainingProductInventory] = useState<number>();
  const [productAvailability, setProductAvailability] = useState<InitialState>();
  const [showIMapPriceTooltip, setShowIMapPriceTooltip] = useState(false);
  const [inventoryRecordId, setInventoryRecordId] = useState<string>();
  const [showStoreTooltip, setShowStoreTooltip] = useState(false);
  const [isPickUpSelected, setIsPickUpSelected] = useState(false);
  const [isProductAddedWishlist, setIsProductAddedWishlist] = useState<boolean>(false);
  const [filteredProductsData, setFilteredProductsData] = useState<FilteredProductsData>({});
  const [selectedDropdownValues, setSelectedDropdownValues] = useState<{ [key: string]: string }>(
    {}
  );

  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const pathArray = router?.asPath?.split('/');
  const productCategory = pathArray?.[4];
  const productParentId = pathArray?.[pathArray?.length - 1]?.split('?')?.[0];
  const renderSEOMetaData = () => {
    return (
      <Head>
        <title>{productData?.SEOMetadata?.Title?.toString() || 'Page'}</title>
        {productData?.SEOMetadata?.Description && (
          <meta name="description" content={productData?.SEOMetadata?.Description?.toString()} />
        )}
        {productData?.SEOMetadata?.Keywords && (
          <meta name="keywords" content={productData?.SEOMetadata?.Keywords?.toString()} />
        )}
      </Head>
    );
  };

  const { isMobile } = useBreakpoints();
  const { getProductAvailability, addToCart, getProductOptions } = useOcCart();

  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const [address, setAddress] = useState<{
    Street1?: string;
  }>({});

  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const ecommerceDisabled = myStoreData?.ecommerceDisabled;
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const wishlistData = useOcSelector((s) => s.wishlist?.wishlist);
  const { lineItems } = useOcCurrentOrder();
  const currentOrder = useOcSelector((state) => state?.ocCurrentOrder);
  const { currentPage, pageItem, position } = getGTMSessionStorage();
  const dummyProductImage = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.noProductImage
  );
  const autoshipMessage = useAutoshipMessage({
    //TODO: will refactor this later once we will get data from middleware call
    product: { ...productData, xp: productData?.Xp },
    autoshipMarketingText: 'AutoshipMarketingPDP',
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const newMethod = localStorage.getItem('selected_fulfillment_Method') || fulfillmentMethod;
      setIsPickUpSelected(newMethod === FulfillmentType.DFS);
    }
  }, [fulfillmentMethod, myStoreData?.storeId, componentContextData?.isDFSMethodChanged]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (currentOrder?.lineItems && currentOrder?.lineItems?.length > 0) {
        setAddress(currentOrder?.lineItems?.[0]?.ShippingAddress || {});
      } else if (localStorage.getItem('selected_delivery_address')) {
        setAddress(JSON.parse(localStorage.getItem('selected_delivery_address') || ''));
      } else {
        setAddress({});
      }
    }
  }, [currentOrder?.lineItems, myStoreData?.storeId, componentContextData?.isDFSMethodChanged]);

  const dataObj = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    '@id': `${domainUrl}${getProductDetailPath({
      ...productData,
      xp: productData?.Xp,
      ParentID: parentId,
    })}`,
    name: productData?.Name,
    image: getFirstProductImage(productData?.Xp?.Images)?.Url,
    description: productData?.Xp?.Bullets?.[0],
    sku: productSku,
    brand: productData?.Xp?.Brand,
  };

  const addToFavoriteClick = (product: ProductData) => {
    if (productData?.ID) {
      if (isAnonymous) {
        sendProductsPromotion({
          eventName: GTM_EVENT?.addToWishlist,
          data: product as ProductSearchResultModelWithVariants,
          totalCount: qty,
          listPrice: product.PriceSchedule?.xp?.ListPrice,
          currency: true,
          currentPage: currentPage,
          pageItem: pageItem,
          storeId: myStoreData?.storeId,
          position: Number(position),
          click_from: GTMLabels?.PDP,
          isRatingShow: true,
        });
        router.push(
          `${urlConfig?.loginWithReturnUrl}${router.asPath}&favoritesku=${productData?.ID}`
        );
      } else {
        dispatch(
          updateProductInWishlist({
            product: product,
            index: Number(position),
            storeId: myStoreData?.storeId,
            clickFrom: GTMLabels?.PDP,
            currentPage: currentPage,
            pageItem: pageItem,
          })
        );
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fetchProductAvailability = async (productId: any, storeId: any, lowStockSetting: any) => {
    const data = (await getProductAvailability(productId, storeId, lowStockSetting)) as {
      availability?: InitialState;
      productInventory?: number;
      inventoryRecordId?: string;
      remainingInventory?: number;
    };
    return data;
  };

  const getProductData = async () => {
    if (myStoreData?.storeId && productData?.ID) {
      if (parentId) {
        const productOptionData = await getProductOptions(parentId, myStoreData?.storeId);
        if (productOptionData) {
          const selectedDropdownValue: { [key: string]: string } = {};
          productOptionData?.filterFacetKeys?.forEach((key: string) => {
            const productXpDynamic = productData?.Xp as {
              [key: string]: string | string[] | undefined;
            };
            if (key === retailUnitMeasureFieldName) {
              selectedDropdownValue[key] =
                productData?.Xp?.RetailUnit + ' ' + productData?.Xp?.RetailMeasure?.toUpperCase();
            } else if (productXpDynamic?.[key]) {
              const val = productXpDynamic?.[key];
              const value = Array.isArray(val) ? val.join('; ') : val;
              selectedDropdownValue[key] = value ?? '';
            }
          });
          setSelectedDropdownValues(selectedDropdownValue);
          setFilteredProductsData(productOptionData);
        }
      }
      if (currentOrder?.initialized === true) {
        const data = await fetchProductAvailability(
          productData?.ID,
          myStoreData?.storeId,
          myStoreData?.deliveryFee?.lowStock
        );
        if (data) {
          if (data?.availability) {
            if (data?.availability == 'OutOfStock') {
              setIsError(true);
            } else {
              setIsError(false);
            }
            setProductAvailability(data?.availability);
          }
          if (data?.inventoryRecordId) {
            setInventoryRecordId(data?.inventoryRecordId);
          }
          if (data?.productInventory) {
            setProductInventory(data?.productInventory);
          } else {
            setIsError(true);
            setProductAvailability('OutOfStock');
            setProductInventory(0);
          }
          if (data?.remainingInventory !== null) {
            setRemainingProductInventory(data?.remainingInventory);
          }
        } else {
          setIsError(true);
          setProductAvailability('OutOfStock');
          setProductInventory(0);
        }
      }
    }
  };
  useEffect(() => {
    if (typeof parentId === 'string' && typeof productSku === 'string') {
      const getData = async () => {
        try {
          setIsError(false);
          setQty(1);
          // getProductData();
          //Triggering PDP tracking event for Search
          if (productData?.ID) {
            //console.log('Triggering PDP tracking event');
            trackEntityPageViewEvent('product', {
              items: [
                {
                  id: productData?.ID,
                  attributes: {
                    brand: productData?.Xp?.Brand ?? '',
                  },
                },
              ],
              actionSubtype: 'conversion',
            });
          }
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          } else {
            setError('An unknown error occurred');
          }
        }
      };
      if (parentId && productSku && myStoreData?.storeId && productData?.ID) {
        getData();
      }
    }
  }, [myStoreData?.storeId, productData?.ID]);

  useEffect(() => {
    if (
      currentOrder?.initialized === true &&
      parentId &&
      productSku &&
      myStoreData?.storeId &&
      productData?.ID
    ) {
      getProductData();
    }
  }, [
    lineItems,
    currentOrder?.initialized,
    productData?.ID,
    parentId,
    productSku,
    myStoreData?.storeId,
  ]);

  useEffect(() => {
    if (productData?.ID && wishlistData) {
      setIsProductAddedWishlist(isProductInWishlist(wishlistData, productData?.ID));
    }
  }, [wishlistData, productData?.ID]);

  // Sending GTM promotion data
  useEffect(() => {
    const checkGtmLoad = () => {
      const isEventFired =
        window &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)['dataLayer']?.filter((val: any) => val?.event === GTM_EVENT?.viewItem)
          ?.length > 0;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any)?.google_tag_manager?.dataLayer?.gtmLoad && !isEventFired) {
        productData &&
          sendProductsPromotion({
            eventName: GTM_EVENT?.viewItem,
            data: productData as ProductSearchResultModelWithVariants,
            currentPage: currentPage,
            pageItem: pageItem,
            parentId: productParentId,
            position: position ?? 0,
            category: productCategory,
            affiliation: myStoreData?.storeId,
            isRatingShow: true,
          });
      } else {
        setTimeout(() => {
          checkGtmLoad();
        }, 1000);
      }
    };

    checkGtmLoad();
  }, [productData]);

  //----------------------
  const updateQuantity = (newQty: number) => {
    const currentLineItemQty =
      lineItems?.find((item) => item.ProductID === productData?.ID)?.Quantity ?? 0;

    const isValidQuantity =
      productInventory === 1
        ? newQty <= productInventory && newQty > 0
        : newQty + currentLineItemQty <= productInventory && newQty > 0;

    setQty(newQty > 0 ? newQty : 1);
    setIsError(!isValidQuantity);
  };

  const priceForPDP = useProductPriceForUI(productData?.ID ?? '');

  const renderConditionalPrice = () => (
    <>
      {!priceForPDP?.hasListandMemberPrice ? (
        <div className={priceWrapper()}>
          <div>
            <span
              className={priceText({
                priceText: `${
                  priceForPDP?.hasImapPrice || priceForPDP?.showViewPriceInCart
                    ? 'isListPriceWithMemberPrice'
                    : 'hasListPrice'
                }`,
              })}
            >
              {getDictionaryValue('CurrencySymbol')}
              {priceForPDP?.hasImapPrice
                ? priceForPDP?.imapPrice?.toFixed(2)
                : priceForPDP?.hasListPrice || priceForPDP?.showViewPriceInCart
                ? priceForPDP?.listPrice?.toFixed(2)
                : ''}
            </span>

            <span className={priceText()}>{getDictionaryValue('ListPrice')}</span>
            {(priceForPDP?.hasImapPrice ||
              (priceForPDP?.listPrice != null &&
                priceForPDP?.imapPrice != null &&
                priceForPDP?.memberPrice != null &&
                priceForPDP.listPrice > priceForPDP.imapPrice &&
                priceForPDP.imapPrice > priceForPDP.memberPrice)) && (
              <div className="flex flex-row">
                <span className={priceText({ priceText: 'hasMemberPrice' })}>
                  {getDictionaryValue('ViewPriceInCartPDP')}
                </span>
                <span
                  className={relative()}
                  onMouseEnter={() => {
                    setShowIMapPriceTooltip(true);
                  }}
                  onMouseLeave={() => {
                    setShowIMapPriceTooltip(false);
                  }}
                >
                  <span className={priceText({ priceText: 'hasImapPrice' })}>
                    {getDictionaryValue('WhyWeDoThisPDP')}
                  </span>
                  {showIMapPriceTooltip && (
                    <div className={topTooltipWrapper()}>
                      <div className={tooltipContainer()}>
                        <div className={topTooltipArrowFirstDiv()}></div>
                        <div className={topTooltipArrowSecondDiv()}></div>
                        <p className={topToolTipDescription()}>
                          {getDictionaryValue('WhyWeDoThisTooltipPDP')}
                        </p>
                      </div>
                    </div>
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
      ) : (
        // This will render if hasListandMemberPrice is true (Determines if listPrice price is available and greater than PPC and PPC is greater then IMAP prices)
        <div className={priceWrapper()}>
          <p>
            <span className={priceText({ priceText: 'isListPriceWithMemberPrice' })}>
              {getDictionaryValue('CurrencySymbol')}
              {priceForPDP?.listPrice.toFixed(2)}
            </span>
            <span className={priceText()}>{getDictionaryValue('ListPrice')}</span>
          </p>
          <p>
            <span className={priceText({ priceText: 'hasMemberPrice' })}>
              {getDictionaryValue('CurrencySymbol')}
              {priceForPDP?.memberPrice.toFixed(2)}
            </span>
            <span className={priceText()}>{getDictionaryValue('MemberPricePDP')}</span>
          </p>
        </div>
      )}
    </>
  );
  const renderPrices = () => {
    return (
      <div className={pricesWrapper()}>
        <div className={priceContainer()}>{renderConditionalPrice()}</div>
        {getDictionaryValue('WriteReviewPDP') && (
          <div className={reviewWrapper()}>
            {productSku && <BazarVoiceReviews bvProductId={productSku} />}
          </div>
        )}
      </div>
    );
  };
  const renderProductInfo = () => (
    <>
      <div>
        <div className={productInfoWrapper()}>
          <div className={titleWrapper()}>
            <div className={title()}>
              <span>{productData?.Name}</span>
              <IconHelper
                className={heartIconWithTitle()}
                onClick={() => {
                  productData && addToFavoriteClick(productData);
                }}
                icon={isProductAddedWishlist ? 'heart' : 'heart-stroke'}
              />
            </div>
          </div>
          <LinkHelper
            className={learnMoreLink()}
            onClick={(e) => {
              e.preventDefault();
              const productDetailsSection =
                document.getElementsByClassName('product-details-section')?.[0];
              productDetailsSection && productDetailsSection.scrollIntoView({ behavior: 'smooth' });
            }}
            field={{
              value: { text: getDictionaryValue('LearnMorePDP'), href: '/' },
            }}
          />
        </div>
        {renderPrices()}
      </div>
      <div className={availabilityWrapper()}>
        <p>{getDictionaryValue('AvailabilityPDP')}</p>
        {/* <p className={stockAvailability({ availability: productAvailability || 'OutOfStock' })}>
          {getDictionaryValue(productAvailability || 'loading')}
        </p> */}
        <p className={stockAvailability({ availability: productAvailability || 'OutOfStock' })}>
          {productAvailability ? (
            getDictionaryValue(productAvailability || 'OutOfStock')
          ) : (
            <Loader className="ml-[5px]" />
          )}
        </p>
      </div>
    </>
  );
  const onBOPISClick = async () => {
    if (currentOrder?.order && currentOrder?.order?.ID) {
      const request: Order = { xp: { Fulfillment: FulfillmentType.BOPIS } };
      await dispatch(patchOrder({ request }));
    } else {
      localStorage.setItem('selected_fulfillment_Method', FulfillmentType.BOPIS);
      setIsPickUpSelected(false);
    }
  };
  const onDFSClick = async () => {
    if (!address?.Street1) {
      setcomponentContextData({
        ...componentContextData,
        showDeliveryAvailabilityPopup: true,
        openDeliveryAvailabilityPopupFromPDP: productData?.ID,
      });
    } else {
      if (currentOrder?.order && currentOrder?.order?.ID) {
        const request: Order = { xp: { Fulfillment: FulfillmentType.DFS } };
        await dispatch(patchOrder({ request }));
      } else {
        localStorage.setItem('selected_fulfillment_Method', FulfillmentType.DFS);
        setIsPickUpSelected(true);
      }
    }
  };
  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>, facetData: Facet) => {
    try {
      setSelectedDropdownValues({
        ...selectedDropdownValues,
        [facetData.Name]: e.target?.value,
      });

      const foundObject = filteredProductsData?.filteredProducts?.find((obj: Product) => {
        return (
          filteredProductsData?.filterFacetKeys &&
          filteredProductsData?.filterFacetKeys.every((key) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const productValue: string = Array.isArray(obj[key] as any)
              ? obj[key]?.join('; ')
              : obj[key]?.toString();
            if (productValue) {
              if (key?.toLowerCase() === e?.target?.id?.toLowerCase()) {
                return productValue?.toLowerCase() === e.target?.value?.toLowerCase();
              }
              if (key === retailUnitMeasureFieldName) {
                return (
                  productValue?.toLowerCase() ===
                  productData?.Xp?.RetailUnit + ' ' + productData?.Xp?.RetailMeasure?.toLowerCase()
                );
              }
              const productXpDynamic = productData?.Xp as {
                [key: string]: string[] | undefined;
              };
              return (
                productValue?.toLowerCase() === productXpDynamic?.[key]?.join('; ')?.toLowerCase()
              );
            } else {
              return false;
            }
          })
        );
      });
      if (foundObject?.sku) {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('sku', foundObject?.sku?.toString());
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.location.href = newUrl;
      }
    } catch (e) {
      console.error('Error', e);
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }
  if (!parentId || (parentId && productData?.error)) return <></>;
  function renderProductSchema() {
    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(dataObj) }}
      />
    );
  }
  const imageData = getSortedProductImages(productData?.Xp?.Images);
  return (
    <div>
      {renderProductSchema()}
      {renderSEOMetaData()}
      <div className={base()}>
        {isMobile && renderProductInfo()}
        {Array.isArray(productData?.Xp?.Images) ? (
          <ProductDetailSliderComponent
            productName={productData?.Name}
            USMade={productData?.Xp?.USMade}
            saleLabel={productData?.Xp?.OnSale ? getDictionaryValue('Sale') : undefined}
            offerLabel={productData?.Xp?.PromoTag}
            dataareaid={myStoreData?.dataareaid}
            priceGroupArray={myStoreData?.priceGroupArray}
            RetailUnit={productData?.Xp?.RetailUnit}
            RetailMeasure={productData?.Xp?.RetailMeasure}
            sliderData={
              imageData?.length && imageData?.length > 0
                ? imageData
                : ([
                    {
                      Url: dummyProductImage?.jsonValue?.value?.src,
                      Name: dummyProductImage?.jsonValue?.value?.alt,
                      Position: 1,
                    },
                  ] as SlideProps[])
            }
            video={productData?.Xp?.Video}
          />
        ) : (
          <></>
        )}
        <div className={productDetailsWrapper()}>
          {!isMobile && renderProductInfo()}
          {filteredProductsData?.facets?.length && filteredProductsData?.facets?.length > 0 ? (
            <div>
              <p className={productOptionsWrapper()}>
                {myStoreData?.disableDelivery !== 1 &&
                  (productData?.Xp?.Delivery || productData?.Xp?.Autoship) && <>1.</>}
                <span
                  className={productOptionText({
                    className:
                      myStoreData?.disableDelivery !== 1 &&
                      (productData?.Xp?.Delivery || productData?.Xp?.Autoship)
                        ? ''
                        : '!ml-0',
                  })}
                >
                  {getDictionaryValue('SelectYourOptionsPDP')}
                </span>
              </p>
              <div className={productOptions()}>
                {(filteredProductsData?.facets?.length ?? 0) > 0 ? (
                  <>
                    {filteredProductsData?.facets
                      ?.filter((x) => x?.Values?.length > 0)
                      .map((facetData: Facet, index: number) => (
                        <div className={productOption()} key={facetData.Name}>
                          <p className={productOptionLabel()}>
                            {getDictionaryValue(facetData?.Name)}
                          </p>
                          <div className={relative()}>
                            <select
                              aria-label={`product variations ${index}`}
                              name={facetData?.Name}
                              id={facetData?.Name}
                              className={clsx(productOptionDropdown(), dropdownClass())}
                              value={
                                selectedDropdownValues?.[facetData?.Name]
                                  ?.charAt(0)
                                  ?.toUpperCase() +
                                selectedDropdownValues?.[facetData?.Name]?.slice(1)
                              }
                              onChange={(e) => onSelectChange(e, facetData)}
                            >
                              {facetData?.Values?.map((value, key) => (
                                <option
                                  value={
                                    value?.Value?.charAt(0)?.toUpperCase() + value?.Value?.slice(1)
                                  }
                                  id={facetData?.Name}
                                  data-key={key}
                                  key={key}
                                >
                                  {value?.Value?.charAt(0)?.toUpperCase() + value?.Value?.slice(1)}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ))}
                  </>
                ) : null}
              </div>
            </div>
          ) : null}
          <div>
            <p className={productOptionsWrapperForPickup()}>
              {myStoreData?.disableDelivery !== 1 &&
              (productData?.Xp?.Delivery || productData?.Xp?.Autoship) &&
              filteredProductsData?.facets?.length &&
              filteredProductsData?.facets?.length > 0
                ? '2.'
                : ''}
              {myStoreData?.disableDelivery !== 1 &&
                (productData?.Xp?.Delivery || productData?.Xp?.Autoship) && (
                  <span
                    className={
                      filteredProductsData?.facets?.length &&
                      filteredProductsData?.facets?.length > 0
                        ? productOptionText()
                        : ''
                    }
                  >
                    {getDictionaryValue('SelectPickupOrDeliveryPDP')}
                  </span>
                )}
            </p>
            <div className={deliveryOptionsWrapper({ className: 'PDP_address_selection' })}>
              {productData?.Xp?.Curbside && (
                <div
                  className={deliveryOptionWrapper({
                    isPickUpSelected: !isPickUpSelected,
                    className: 'PDP_address_selection_pickup',
                  })}
                  onClick={onBOPISClick}
                >
                  <div className={relativeDiv()}>
                    <div className={centerDiv()}>
                      <input
                        aria-label="delivery_option_bopis"
                        type="radio"
                        className={radioButton()}
                        onChange={onBOPISClick}
                        name="delivery_option"
                        checked={!isPickUpSelected}
                        value={getDictionaryValue('FreeCurbsidePickupPDP')}
                      />
                      {!isPickUpSelected && <div className={radioButtonFiller()}></div>}
                    </div>
                    <label className={pickUpOptionLabel()}>
                      {getDictionaryValue('FreeCurbsidePickupPDP')}
                    </label>
                  </div>
                  <div className={pickUpOptionText()}>
                    {getDictionaryValue('PickupAtYourStorePDP')}
                    {myStoreData?.storeName ? (
                      <span className={relative()}>
                        <span
                          className={storeName()}
                          onMouseEnter={() => {
                            setShowStoreTooltip(true);
                          }}
                          onMouseLeave={() => {
                            setShowStoreTooltip(false);
                          }}
                        >
                          {myStoreData?.storeName?.toString()}
                        </span>
                        {showStoreTooltip && (
                          <div className={topTooltipWrapper()}>
                            <div className={tooltipContainer()}>
                              <div className={topTooltipArrowFirstDiv()}></div>
                              <div className={topTooltipArrowSecondDiv()}></div>
                              <div className={topToolTipDescription()}>
                                <p>{getDictionaryValue('OrderReadyMessagePDP')}</p>
                                <div className={tooltipText()}>
                                  <p>
                                    <span>{`${myStoreData?.street1}, ${myStoreData?.city}, ${myStoreData?.state}`}</span>
                                  </p>
                                  {myStoreData?.phone ? (
                                    <p className={tooltipPhoneNumber()}>
                                      {myStoreData?.phone as React.ReactNode}
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <LinkHelper
                    className={changeStoreText()}
                    onClick={(e) => {
                      e.preventDefault();
                      setcomponentContextData({
                        ...componentContextData,
                        showChangeStorePopup: true,
                        productId: productData?.ID,
                      });
                    }}
                    field={{ value: { href: '/', text: getDictionaryValue('ChangeStorePDP') } }}
                  >
                    <>
                      <Text field={{ value: getDictionaryValue('ChangeStorePDP') }} tag="p" />
                      <IconHelper className={changeStoreIcon()} icon={'chevron-right'} />
                    </>
                  </LinkHelper>
                </div>
              )}
              {myStoreData?.disableDelivery !== 1 &&
                (productData?.Xp?.Delivery || productData?.Xp?.Autoship) && (
                  <div
                    className={autoShipOptionWrapper({
                      isPickUpSelected: isPickUpSelected,
                    })}
                    onClick={onDFSClick}
                  >
                    {autoshipMessage && <p className={autoShipLabel()}>{autoshipMessage}</p>}
                    <div
                      className={autoShipWrapper({ className: 'PDP_address_selection_delivery' })}
                    >
                      <div className={relativeDiv()}>
                        <div className={centerDiv()}>
                          <input
                            aria-label="delivery_option_dfs"
                            type="radio"
                            className={radioButton()}
                            checked={isPickUpSelected}
                            onChange={onDFSClick}
                            name="delivery_option"
                            value={getDictionaryValue('DeliveryOrAutoshipPDP')}
                          />
                          {isPickUpSelected && <div className={radioButtonFiller()}></div>}
                        </div>
                        <label className={autoShipText()}>
                          {getDictionaryValue('DeliveryOrAutoshipPDP')}
                          <div className={toolbarIconWrapper()}>
                            <IconHelper
                              className={toolbarIcon()}
                              icon={'info'}
                              onMouseEnter={() => {
                                setShowTooltip(true);
                              }}
                              onMouseLeave={() => {
                                setShowTooltip(false);
                              }}
                            />
                            {showTooltip && (
                              <div className={tooltipWrapper()}>
                                <div className={tooltipContainer()}>
                                  <div className={tooltipArrowFirstDiv()}></div>
                                  <div className={tooltipArrowSecondDiv()}></div>
                                  <ul className={toolTipDescription()}>
                                    {getDictionaryValue('DeliveryLearnMoreButtonToolipOrderPDP')
                                      ?.split('|')
                                      ?.map((value: string, index: number) => (
                                        <li key={index}>{value}</li>
                                      ))}
                                  </ul>
                                  <p className={toolTipTitle()}>
                                    {getDictionaryValue('AutoshipEnrollmentPDP')}
                                  </p>
                                  <ul className={toolTipDescription()}>
                                    {getDictionaryValue(
                                      'DeliveryLearnMoreButtonToolipEnrollmentPDP'
                                    )
                                      ?.split('|')
                                      ?.map((value: string, index: number) => (
                                        <li key={index}>{value}</li>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                            )}
                          </div>
                        </label>
                      </div>
                      {address?.Street1 && <p className={pickUpOptionText()}>{address?.Street1}</p>}
                      <LinkHelper
                        className={checkAvailabilityText()}
                        onClick={(e) => {
                          e.preventDefault();
                          setcomponentContextData({
                            ...componentContextData,
                            showDeliveryAvailabilityPopup: true,
                            openDeliveryAvailabilityPopupFromPDP: productData?.ID,
                          });
                        }}
                        field={{
                          value: {
                            href: '/',
                            text: address?.Street1
                              ? getDictionaryValue('ChangeStorePDP')
                              : getDictionaryValue('CheckAvailabilityPDP'),
                          },
                        }}
                      >
                        <>
                          <Text
                            field={{
                              value: address?.Street1
                                ? getDictionaryValue('ChangeAddressPDP')
                                : getDictionaryValue('CheckAvailabilityPDP'),
                            }}
                            tag="p"
                          />
                          <IconHelper className={changeStoreIcon()} icon={'chevron-right'} />
                        </>
                      </LinkHelper>
                    </div>
                  </div>
                )}
            </div>
          </div>
          <div>
            {isError && (
              <>
                <p className={errorMessage()}>
                  {!productInventory && productAvailability === 'OutOfStock'
                    ? getDictionaryValue('NoProductUnitsAvailablePDP')
                    : productInventory === 1
                    ? getDictionaryValue('OneProductUnitsAvailablePDP')
                    : getDictionaryValue('ProductUnitsAvailablePDP')?.replace(
                        '{productUnit}',
                        remainingProductInventory && remainingProductInventory > 0
                          ? remainingProductInventory?.toString()
                          : parseInt(productInventory?.toString()) < 0
                          ? '0'
                          : productInventory?.toString() ?? '0'
                      )}
                </p>
              </>
            )}
            <div className={productActionWrapper()}>
              <div className={productQtyWrapper()}>
                <div className={relative()}>
                  <div className={productQtyInput()}></div>
                  <div className={inputWrapper()}>
                    <p>{getDictionaryValue('QuantityPDP')}</p>
                    <input
                      aria-label="quantity"
                      type="text"
                      className={productInput()}
                      //as per FSD min will be 1
                      onChange={(e) => {
                        e.preventDefault();
                        const value = parseInt(e.target?.value, 10);
                        if (!Number.isNaN(value)) {
                          updateQuantity(value);
                        } else {
                          setQty(1);
                        }
                      }}
                      onBlur={(e) => {
                        const value = parseInt(e.target?.value, 10);
                        if (Number.isNaN(value) || value <= 0) {
                          setQty(1);
                        } else {
                          updateQuantity(value);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          // Reset to 1 if empty or 0 on Enter key press

                          if (qty === 0) {
                            setQty(1);
                            updateQuantity(1);
                          } else {
                            updateQuantity(Number(qty));
                          }
                        }
                      }}
                      //placeholder={`1`}
                      value={`${qty}`}
                    />
                  </div>
                  <div className={productQtyButtonWrapper()}>
                    <button
                      aria-label="add to cart"
                      className={productQtyButton()}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!Number.isNaN(qty)) {
                          updateQuantity(Number(qty) + 1);
                        }
                      }}
                    >
                      +
                    </button>
                    <button
                      aria-label="remove from cart"
                      className={productQtyButton()}
                      onClick={(e) => {
                        e.preventDefault();
                        if (!Number.isNaN(qty) && Number(qty) > 1) {
                          updateQuantity(Number(qty) - 1);
                        }
                      }}
                    >
                      -
                    </button>
                  </div>
                </div>
              </div>
              {ecommerceDisabled === 0 && (
                <>
                  <button
                    aria-label="add to cart"
                    onClick={async () => {
                      if (!isError && productData?.ID) {
                        try {
                          await addToCart({
                            productId: productData?.ID,
                            quantity: +qty,
                            storeId: myStoreData?.storeId,
                            inventoryItemId: inventoryRecordId,
                          });
                          const availabilityData = await fetchProductAvailability(
                            productData?.ID,
                            myStoreData?.storeId,
                            myStoreData?.deliveryFee?.lowStock
                          );
                          {
                          }
                          if (availabilityData?.availability) {
                            if (availabilityData?.availability == 'OutOfStock') {
                              setIsError(true);
                            } else {
                              setIsError(false);
                            }
                            setProductAvailability(availabilityData?.availability);
                          }
                          if (availabilityData?.inventoryRecordId) {
                            setInventoryRecordId(availabilityData?.inventoryRecordId);
                          }
                          if (availabilityData?.productInventory) {
                            setProductInventory(availabilityData?.productInventory);
                          }
                          if (availabilityData?.remainingInventory) {
                            setRemainingProductInventory(availabilityData?.remainingInventory);
                          } else {
                            setIsError(true);
                          }
                        } catch (_error) {
                          setIsError(true);
                        }
                      }
                      sendProductsPromotion({
                        eventName: GTM_EVENT?.addToCart,
                        data: productData as ProductSearchResultModelWithVariants,
                        currentPage: currentPage,
                        pageItem: pageItem,
                        position: 0,
                        click_from: GTMLabels?.PDP,
                        quantity: qty as number,
                        isRatingShow: true,
                        fulfillment_option: isPickUpSelected ? GTMLabels?.DFS : GTMLabels?.BOPIS,
                      });

                      //Sending Add Cart event to Search
                      trackAddEvent('product', 'cart', {
                        items: [{ entityType: 'product', id: productData?.ID as string }],
                        actionSubtype: 'conversion',
                      });
                    }}
                    className={clsx(
                      addToCartButton({ isError: isError }),
                      productAvailability === 'OutOfStock' && 'opacity-[0.5] pointer-events-none'
                    )}
                  >
                    {getDictionaryValue('AddToCartCTAPDP')}
                  </button>
                </>
              )}

              <button
                className={addToFavoritesWrapper()}
                onClick={() => {
                  productData && addToFavoriteClick(productData);
                }}
                aria-label="add to favorites"
              >
                <IconHelper
                  className={addToFavoriteIcon()}
                  icon={isProductAddedWishlist ? 'heart' : 'heart-stroke'}
                />
                <span>
                  {isProductAddedWishlist
                    ? getDictionaryValue('RemoveFromFavoritesPDP')
                    : getDictionaryValue('AddToFavoritesPDP')}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <StoreDetailTab
        bvProductId={productSku}
        bullets={productData?.PCMProduct?.Product?.ProductDetailsBullets}
        ingredientsComposition={productData?.PCMProduct?.Product?.Ingredients}
        guaranteedAnalysisNutrientName={
          productData?.PCMProduct?.Product?.GuaranteedAnalysisNutrientName
        }
        GuaranteedAnalysisPDP={getDictionaryValue('GuaranteedAnalysisPDP')}
      />
    </div>
  );
};
//check withDataSourceCheck If it is not then show blank instead of error.
export default ProductDetail;
