import { tv } from 'tailwind-variants';

//component variants
export const myAccountDetailsTailwindVariant = tv(
  {
    slots: {
      base: [
        'myAccountDetails',
        'border-y-[1px] border-color-border-mid',
        'py-desk-global-spacing-spacing-6',
      ],
      pageTitle: [
        'block',
        'text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold',
        'mb-[35px]',
      ],
      heading: [
        'text-heading-mob-xLarge-bold font-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold',
      ],
      textLabelMedium: [
        'text-heading-desk-small-bold font-heading-desk-small-bold leading-heading-desk-small-bold',
      ],
      textLabelBold: [
        'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
      ],
      textLabel: ['text-body-large-regular font-body-large-regular leading-body-large-regular'],
      smallLabel: ['text-body-small-regular font-body-small-regular leading-body-small-regular '],
      labelBold: ['text-body-large-bold font-body-large-bold leading-body-large-bold '],
      content: [''],
      editingContent: ['bg-color-background-light', 'rounded-desk-global-radius-medium'],
      inlineFields: ['flex', 'flex-1', 'gap-desk-space-between-base-horizontal'],
      informationContainer: [
        'w-full',
        'flex',
        'gap-[26px]',
        'flex-col',
        'mt-desk-margin-base-bottom',
      ],
      fieldWrapper: ['w-full'],
      textareaWrapper: ['flex flex-col gap-desk-margin-micro-bottom'],
      linkText: [
        'text-color-text-brand-1 text-body-large-bold font-body-large-bold leading-body-large-bold underline',
      ],
      userInfo: ['mt-desk-margin-tight-bottom'],
      headigWrapper: ['flex justify-between items-start gap-y-desk-margin-micro-bottom'],
      cancelBtn: ['border-[2px]', 'border-color-brand-primary-1-dark !text-color-text-brand-1'],
      submitBtn: [
        'bg-color-brand-primary-1-base',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
      ],
      errorText: ['text-system-red'],
      loaderWrapper: ['flex', 'items-center'],
      changePasswordLoader: [
        'absolute top-0 z-[1] backdrop-blur-sm flex justify-center items-center left-0 h-full w-full bg-[rgba(0,0,0,0.15)]',
      ],
      googleImageWrapper: ['flex justify-end'],
    },
    compoundSlots: [
      {
        slots: ['cancelBtn', 'submitBtn'],
        class: [
          'hover:no-underline',
          'transition-all',
          'ease-linear',
          'overflow-hidden',
          'text-color-text-white',
          'rounded-mob-global-radius-small',
        ],
      },
      {
        slots: ['content', 'editingContent'],
        class: ['overflow-hidden transition-height duration-500 ease-linear'],
      },
    ],
    variants: {
      size: {
        mobile: {
          base: [''],
          pageTitle: ['text-center'],
          inlineFields: ['flex-col'],
          informationContainer: ['mb-mob-margin-base-bottom'],
          editingContent: ['py-mob-padding-tight-y px-mob-padding-micro-x'],
          submitBtn: [
            'w-full',
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'rounded-desk-global-radius-small',
          ],
          cancelBtn: [
            'w-full',
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'rounded-desk-global-radius-small',
            '!text-color-text-brand-1',
          ],
          loaderWrapper: [
            'gap-mob-component-button-full-space-between',
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
        },
        desktop: {
          base: [''],
          pageTitle: ['text-left'],
          inlineFields: ['flex-row'],
          informationContainer: ['w-full', 'flex', 'gap-[26px]', 'flex-col'],
          editingContent: ['py-desk-padding-tight-y px-desk-padding-micro-x'],
          linkText: ['cusrsor-pointer'],
          submitBtn: [
            'max-w-[280px]',
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'hover:bg-color-brand-primary-1-dark',
          ],
          cancelBtn: [
            'max-w-[280px]',
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'hover:bg-color-brand-primary-1-dark',
            'hover:!text-color-text-white',
          ],
          loaderWrapper: [
            'gap-desk-component-button-full-space-between',
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
