import { tv } from 'tailwind-variants';

export const titleBannerWithImageAndLinkTailwindVariants = tv(
  {
    slots: {
      base: ['container', 'mx-auto'],
      ctaButton: [
        '!text-heading-desk-medium-bold',
        '!font-heading-desk-medium-bold',
        '!leading-heading-desk-medium-bold',
      ],
      link: ['hover:no-underline', 'flex', 'justify-between', 'text-white', 'items-center'],
      img: ['h-[110px]', 'w-[112px]'],
      imgWrapper: ['gap-mob-space-between-micro-horizontal'],
      title: [''],
      description: ['max-w-[596px]', 'text-center'],
    },
    compoundSlots: [
      { slots: ['base', 'imgWrapper'], class: ['items-center'] },
      { slots: ['title', 'description'], class: ['w-full'] },
    ],
    variants: {
      size: {
        mobile: {
          link: [
            'py-mob-global-spacing-spacing-5',
            'flex-col',
            'px-mob-padding-tight-x',
            'gap-mob-space-between-loose-horizontal',
            'after:!content-none',
          ],
          imgWrapper: ['hidden'],
          title: [
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          description: [
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
        },
        desktop: {
          link: [
            'py-desk-global-spacing-spacing-3',
            'flex-row',
            'gap-auto',
            'px-desk-padding-tight-x',
            'gap-desk-space-between-loose-horizontal',
          ],
          description: [
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          imgWrapper: ['flex'],
          title: [
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
