import { publicURLTrailingSlash } from 'src/helpers/publicURLTrailingSlash';
import { SubscriptionWithXp } from 'src/redux/xp';

const envEndPoint = process.env.NEXT_PUBLIC_API_ENDPOINT;
const publicURL = publicURLTrailingSlash(); // Add this for XMC Pages.
const storePageAPI = { yelpAPIEndpoint: '/GetYelpReviews?yelpbusinessid=' };

const bazarVoiceReviewAPI = {
  bazarVoiceReviewAPIEndpoint: publicURL + 'api/bazaarVoice/GetBVToken',
  bazarVoiceReviewAPIEndpointFromMiddleware: envEndPoint + '/GetBVToken',
};

const acceptLoyaltyProgramAgreementAPI = {
  acceptLoyaltyProgramAgreementEndpoint: envEndPoint + '/acceptloyaltyprogramagreement',
};

const lockedUserRequestAPI = {
  lockedUserRequestEndpoint: '/lockeduserrequest',
  unlockUserEndpoint: '/unlockuser',
};

const authenticationAPI = {
  userExistAPI: envEndPoint + '/userexist',
  registerUserAPI: envEndPoint + '/userregister',
  resetPasswordAPI: envEndPoint + '/resetpassword',
  activateUserAPI: envEndPoint + '/useractivate',
  ValidateResetPasswordLinkAPI: envEndPoint + '/validateresetpasswordlink',
  lockeduserrequest: envEndPoint + '/lockeduserrequest',
  forgotPasswordAPI: envEndPoint + '/forgotPassword',
  unlockuser: envEndPoint + '/unlockuser',
  userActivate: envEndPoint + '/useractivate',
  resetpassword: envEndPoint + '/resetpassword',
};

const productAPI = {
  getProductAPIServerSide: envEndPoint + '/pdp-data',
  storeSellsProduct: envEndPoint + '/store-sells-product',
};

const storeAPI = {
  getStoreAPI: `${publicURL}api/store/get-store-details?storeId={storeId}`,
};

const promotionalSignupAPI = {
  promotionalSignup: envEndPoint + '/promotionsignup',
};

const petsAPI = {
  allPets: envEndPoint + '/all-pets',
  getGender: envEndPoint + '/genders',
  getSpecies: envEndPoint + '/species',
  getBreed: envEndPoint + '/breeds',
  addPet: envEndPoint + '/add-pet',
  getPet: envEndPoint + '/get-pet',
  updatePet: envEndPoint + '/update-pet',
  deletePets: envEndPoint + '/delete-pet',
  addPetImage: envEndPoint + '/add-pet-image',
};

const groomingAPI = {
  addNewBreedAndAssignToPet: envEndPoint + '/add-new-breed-and-assign-to-pet',
  createUpdatePet: envEndPoint + '/create-update-pet',
  groomingServices: envEndPoint + '/grooming-services',
  nextAvailableTimeslots: envEndPoint + '/next-available-timeslots',
  timeslotsByDateRangeAndGroomer: envEndPoint + '/timeslots-by-date-range-and-groomer',
  getMoreAvailableTimeSlots: envEndPoint + '/get-more-available-time-slots',
  scheduleAppointment: envEndPoint + '/schedule-appointment',
  availableGroomers: envEndPoint + '/available-groomers',
  appointments: envEndPoint + '/appointments',
  cancelAppointment: envEndPoint + '/cancel-appointment',
};

const auth = {
  authToken: 'oauth/token',
};

const login = {
  login: envEndPoint + '/login',
};

const checkout = {
  submitorder: envEndPoint + '/submitocorder',
  calculateAnonDiscount: envEndPoint + '/CalculateAnonDiscount',
  getAnonDiscount: envEndPoint + '/GetAnonDiscount',
};

const yelpAPI = {
  yelp: envEndPoint + '/GetYelpReviews?yelpbusinessid=',
};

const couponImage = {
  imageUrl: process.env.NEXT_PUBLIC_PSP_PROMOTION_COUPON_IMAGE,
  getCouponImage: '/GetPromotionCoupon',
};

const orderPromotion = {
  addPromotion: envEndPoint + '/addPromotion',
  promotionsignup: envEndPoint + '/promotionsignup',
  getPromotionCoupon: envEndPoint + '/GetPromotionCoupon',
};

const autoship = {
  skipAutoship: envEndPoint + '/skip-subscription',
  cancelAutoship: envEndPoint + '/cancel-subscription',
};

export const contactUs = (apiEndpoint: string) => {
  return envEndPoint + apiEndpoint;
};

export const jobInquiry = (apiEndpoint: string) => {
  return envEndPoint + apiEndpoint;
};

export interface SubscriptionResponse {
  Subscription: SubscriptionWithXp;
  Success: boolean;
  EmailSuccess: boolean;
}

const api = {
  envEndPoint,
  storePageAPI,
  acceptLoyaltyProgramAgreementAPI,
  lockedUserRequestAPI,
  authenticationAPI,
  promotionalSignupAPI,
  productAPI,
  storeAPI,
  bazarVoiceReviewAPI,
  petsAPI,
  groomingAPI,
  auth,
  couponImage,
  orderPromotion,
  autoship,
  login,
  yelpAPI,
  checkout,
  contactUs,
  jobInquiry,
};
export default api;
