import { tv } from 'tailwind-variants';

//component variants
export const loginContentTailwindVariant = tv(
  {
    slots: {
      base: 'LoginContent flex flex-col',
      title: '',
      headingWrapper: 'flex flex-col gap-desk-margin-tight-bottom',
      description: 'text-body-large-regular leading-body-large-regular font-body-large-regular',
      buttonWithOutline:
        'flex hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark text-color-text-brand-1 justify-center items-center bg-color-background-white border-[2px] border-color-brand-primary-1-base outline-none hover:bg-color-brand-primary-1-dark hover:text-color-text-white focus:bg-color-brand-primary-1-dark focus:text-color-text-white',
      button: [
        'flex hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark text-color-text-white justify-center items-center bg-color-brand-primary-1-base outline-none',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
      ],
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        desktop: {
          base: 'gap-desk-margin-base-bottom',
          title:
            'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
          headingWrapper: 'gap-desk-margin-tight-bottom',
          button:
            'w-fit rounded-desk-global-radius-small text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y gap-desk-component-button-full-space-between',
          buttonWithOutline:
            'w-fit rounded-desk-global-radius-small text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y gap-desk-component-button-full-space-between',
        },
        mobile: {
          base: 'gap-mob-margin-base-bottom',
          title:
            'text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold',
          headingWrapper: 'gap-mob-margin-tight-bottom',
          button:
            'rounded-mob-global-radius-small text-heading-desk-medium-bold leading-heading-desk-medium-bold font-heading-desk-medium-bold px-mob-component-button-full-padding-x py-mob-component-button-full-padding-y gap-mob-component-button-full-space-between',
          buttonWithOutline:
            'rounded-mob-global-radius-small text-heading-desk-medium-bold leading-heading-desk-medium-bold font-heading-desk-medium-bold px-mob-component-button-full-padding-x py-mob-component-button-full-padding-y gap-mob-component-button-full-space-between',
        },
      },
      isDisabled: {
        true: {
          button: [
            'cursor-not-allowed',
            'bg-color-brand-primary-1-disabled',
            'hover:bg-color-brand-primary-1-disabled',
          ],
        },
        false: {
          button: ['pointer'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
