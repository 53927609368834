import { tv } from 'tailwind-variants';

export const anchorJumpLinkTailwindVariant = tv(
  {
    slots: {
      base: 'w-full relative',
      wrapper: 'container mx-auto py-desk-padding-tight-y',
    },
  },
  {
    responsiveVariants: ['sm', 'lg'],
  }
);
