import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import ComponentContext from 'lib/context/ComponentContext';
import { useContext, useEffect, useState } from 'react';

import { PSP } from '../../../models/PetSuppliesPlus.Model';
import { ComponentProps } from 'lib/component-props';
import { setStore } from 'src/redux/storeDetailsSlice';
import { updateMyStore } from 'src/helpers/StoreHelper';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import useOcCart from 'src/hooks/useOcCart';
import { sendStoreGTMData } from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'src/helpers/Constants';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import { warningStoreChangeTailwindVariant } from 'tailwindVariants/components/warningStoreChangeTailwindVariant';

export type WarningStoreChangeProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.WarningStoreChange.Fields.WarningStoreChange & {
    children?: React.ReactElement<React.HTMLProps<HTMLElement>> | null;
  };

const { title, description, popUpPad, buttonWrapper, buttonWithOutline, button } =
  warningStoreChangeTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
// Main WarningStoreChange component

export const WarningStoreChange: React.FC<WarningStoreChangeProps> = ({ fields }) => {
  const [isCartEmpty, setIsCartEmpty] = useState<boolean>(true);

  const handleModalClose = () => {
    setcomponentContextData({ ...componentContextData, storeId: false });
  };
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const { numberOfItemsInCart } = useOcCart();
  useEffect(() => {
    setcomponentContextData({ ...componentContextData, storeId: false });
  }, []);

  useEffect(() => {
    //Check if cart empty or not
    if ((numberOfItemsInCart() ?? 0) <= 0 && componentContextData?.storeId) {
      setIsCartEmpty(true);
      if (componentContextData?.storeId != myStoreData?.storeId) {
        changeMyStore();
      }
    } else if ((numberOfItemsInCart() ?? 0) > 0 && componentContextData?.storeId) {
      setIsCartEmpty(false);
    }
    // setIsCartEmpty(true);
  }, [componentContextData?.storeId]);
  const dispatch = useOcDispatch();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const changeMyStore = async () => {
    sendStoreGTMData({
      eventName:
        componentContextData?.fromFulfillment === true
          ? GTM_EVENT?.selectStore
          : GTM_EVENT?.shopThisStore,
      storeId: componentContextData?.storeId,
      storesShown: componentContextData?.storeCount,
    });
    await dispatch(setStore(await updateMyStore(componentContextData?.storeId)));
    localStorage.removeItem('selected_delivery_address');
    localStorage.removeItem('selected_fulfillment_Method');
    if (componentContextData?.callBackFunctionAfterStoreChange) {
      const callbackFunction = componentContextData?.callBackFunctionAfterStoreChange;
      setcomponentContextData({
        ...componentContextData,
        storeId: false,
        callBackFunctionAfterStoreChange: false,
      });
      callbackFunction();
    } else {
      setcomponentContextData({ ...componentContextData, storeId: false });
    }
  };
  if (fields === null || fields === undefined) return <></>;

  return (
    <div className="WarningStoreChangeModal">
      {componentContextData &&
        componentContextData?.storeId &&
        componentContextData?.storeId != myStoreData?.storeId && (
          <>
            {!isCartEmpty && (
              <ModalWrapper
                additionalClassForModal={'!z-[1210]'} // Store Change warning pop-up
                showCloseButtonInModalHeader={false}
                closeModalOnOverlayClick={true}
                onCloseClick={handleModalClose}
                showModal={true}
                popupWidth="max-w-[995px]"
                popupSpacing={popUpPad()}
                customPopup
              >
                <div className="flex flex-col gap-desk-space-between-tight-vertical">
                  <RichText className={title()} field={fields?.title} />
                  <RichText className={description()} field={fields?.description} />
                  <div className={buttonWrapper()}>
                    {fields?.primaryLink?.value?.text && (
                      <button
                        aria-label="primary link"
                        onClick={changeMyStore}
                        className={button()}
                      >
                        {fields?.primaryLink?.value.text}
                      </button>
                    )}
                    {fields?.secondaryLink?.value?.text && (
                      <button
                        aria-label="secondary link"
                        className={buttonWithOutline()}
                        onClick={handleModalClose}
                      >
                        {fields?.secondaryLink?.value.text}
                      </button>
                    )}
                  </div>
                </div>
              </ModalWrapper>
            )}
          </>
        )}
    </div>
  );
};

export const Default = (props: WarningStoreChangeProps): JSX.Element => {
  return <WarningStoreChange {...props} />;
};
