import { tv } from 'tailwind-variants';

export const dividerTailwindVariant = tv(
  {
    slots: {
      base: ['divider', 'h-full', ' w-full', 'flex'],
      wrapper: 'container mx-auto',
      mobileIcon: [''],
      desktopIcon: [''],
    },
    compoundSlots: [{ slots: ['desktopIcon', 'mobileIcon'], class: ['w-full'] }],
    variants: {
      device: {
        mobile: {
          base: ['pt-[16.8px]', 'pb-[13px]'],
          mobileIcon: ['flex justify-center'],
          desktopIcon: ['hidden'],
        },
        desktop: {
          base: ['pt-[15px]', 'pb-[3px]'],
          mobileIcon: ['hidden'],
          desktopIcon: ['flex justify-center'],
        },
      },
    },
  },
  {
    responsiveVariants: ['md'],
  }
);
