import { Field, ImageField, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { useState, useEffect, useRef, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import ComponentContext from 'lib/context/ComponentContext';
import {
  FilterGeo,
  PageController,
  WidgetDataType,
  useSearchResults,
  widget,
} from '@sitecore-search/react';
import { setStore } from 'src/redux/storeDetailsSlice';
import { updateMyStore } from 'src/helpers/StoreHelper';
import useDictionary from 'src/hooks/useDictionary';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { convertMilesToKM } from 'src/utils/milestokm';
import { goeLocationPopupTailwindVariant } from 'tailwindVariants/components/goeLocationPopupTailwindVariant';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import ButtonHelper from 'src/helpers/commonComponents/Button';

export type GeoLocationPopupProps = GeoLocationProps &
  PSP.Sitecore.templates.PetSuppliesPlus.GeolocationPopup.Fields.GeoLocationPopupItem & {
    rfkId?: string;
  };

// Remove the custom types when the PSP.Sitecore.templates is fixed
type GeoLocationProps = {
  fields: {
    data: {
      datasource: DataSourceType;
    };
  };
};

type DefaultStoreType = {
  targetItem: {
    city: FieldType<string>;
    latitude: FieldType<string>;
    jsonValue: FieldType<string>;
    longitude: FieldType<string>;
    state: FieldType<string>;
    storeid: FieldType<string>;
    storename: FieldType<string>;
  };
};

type DataSourceType = {
  changeStoreCTAText: FieldType<string>;
  continueShoppingCTAText: FieldType<string>;
  defaultStore: DefaultStoreType;
  neighborhoodText: FieldType<string>;
  radiusInMiles?: FieldType<number>;
  rfkid?: FieldType<string>;
  logo: {
    jsonValue: ImageField;
  };
  neighborhoodTextNotFound: {
    jsonValue: Field<string>;
  };
  changeStoreCTATextNotFound: {
    jsonValue: Field<string>;
  };
};

type FieldType<T> = {
  jsonValue: Field<T>;
};

const MODAL_GEOLOCATION_KEY = 'modal-geolocation-displayed';

function GeolocationPopupDetail({ fields }: GeoLocationPopupProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showStoreBasedOnLocation, setShowStoreBasedOnLocation] = useState<
    | {
        lat: number;
        lng: number;
      }
    | false
  >(false);
  const { getDictionaryValue } = useDictionary();
  const [storeNotFound, setStoreNotFound] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const [cookies, setCookie] = useCookies(['MyLatitude', 'MyLongitude', MODAL_GEOLOCATION_KEY]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedStore = useOcSelector((state: any) => state?.storeReducer?.selectedStore);
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const handleModalClose = () => {
    setCookie(MODAL_GEOLOCATION_KEY, 'true');
    setIsModalOpen(false);
  };
  const {
    queryResult: { isFetching, isLoading, data: { content: storesList = [], errors = [] } = {} },
    query,
  } = useSearchResults({
    state: {
      itemsPerPage: 1,
      sortType: 'near_by_distance_asc',
    },
    query: (query) => {
      const geoFilter = new FilterGeo(
        'location',
        `${convertMilesToKM(fields?.data?.datasource?.radiusInMiles?.jsonValue?.value || 50)}km` //adding fallback value as 50 because currently on dev there no radiusInMiles field
      );
      query.getRequest().setSearchFilter(geoFilter);
      query.setEnabled(false);
    },
  });
  useEffect(() => {
    const modalShown = cookies?.[MODAL_GEOLOCATION_KEY];
    if ((!isFetching || !isLoading) && !modalShown && showStoreBasedOnLocation) {
      if (storesList?.length > 0 && !(errors?.length > 0 && storesList[0]?.firstname)) {
        changeMyStore();
        setStoreNotFound(false);
      } else {
        setStoreNotFound(true);
      }
    }
  }, [cookies?.[MODAL_GEOLOCATION_KEY], storesList]);
  useEffect(() => {
    const modalShown = cookies?.[MODAL_GEOLOCATION_KEY];
    if (!modalShown && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          PageController.getContext().setGeo({
            location: { lat: position?.coords?.latitude, lon: position?.coords?.longitude },
          });
          query.setEnabled(true);
          setCookie('MyLatitude', position?.coords?.latitude);
          setCookie('MyLongitude', position?.coords?.longitude);
          setShowStoreBasedOnLocation({
            lat: position?.coords?.latitude,
            lng: position?.coords?.longitude,
          });
          // if (fields?.data?.datasource?.radiusInMiles?.jsonValue?.value) {
          //   const geoFilter = new FilterGeo(
          //     'location',
          //     `${convertMilesToKM(fields?.data?.datasource?.radiusInMiles?.jsonValue?.value)}km`
          //   );
          //   query.getRequest().setSearchFilter(geoFilter);
          // }
        },
        (error) => {
          console.error('Geolocation error', error);
        }
      );
    }

    let timer: NodeJS.Timeout;

    if (!modalShown) {
      timer = setTimeout(() => {
        setIsModalOpen(true);
      }, 1000);
    } else {
      setIsModalOpen(false);
    }

    const handleClickOutsideModal = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node) &&
        !(event.target as HTMLElement).closest('.StoreLocatorGlobalModal') &&
        !(event.target as HTMLElement).closest('.pac-container') &&
        !(event.target as HTMLElement).closest('.WarningStoreChangeModal') &&
        !(event.target as HTMLElement).closest('.cart-warning-popup') &&
        !(event.target as HTMLElement).closest('.cookiePopup')
      ) {
        handleModalClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutsideModal);

    return () => {
      clearTimeout(timer);
      document.removeEventListener('mousedown', handleClickOutsideModal);
    };
  }, []);
  const {
    base,
    container,
    modalPanel,
    modalContent,
    content,
    logo,
    text,
    shoppingButtonWrapper,
    shoppingButton,
    storeButtonWrapper,
    storeButton,
    linkIcon,
    storeDetailWrapper,
    // missingStoreButtonWrapper,
  } = goeLocationPopupTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const dispatch = useOcDispatch();

  const changeMyStore = async () => {
    if (storesList?.[0]?.storeid !== selectedStore?.storeId) {
      await dispatch(setStore(await updateMyStore(storesList?.[0]?.storeid)));
    }
  };

  return (
    <div className={base()}>
      <div className={container()}>
        <ModalWrapper
          showCloseButtonInModalHeader={false}
          closeModalOnOverlayClick={true}
          onCloseClick={handleModalClose}
          showModal={isModalOpen && !storeNotFound && selectedStore?.storeId}
          customPopup={true}
          popupWidth="max-w-[997px]"
          popupSpacing="p-0"
          additionalClassForModal={'z-[1150]'} // Global Location Popup
        >
          <>
            <div className={modalPanel()} ref={modalRef}>
              <div className={modalContent()}>
                {fields?.data?.datasource?.logo?.jsonValue?.value && (
                  <div className={logo()}>
                    <NextImage
                      field={fields?.data?.datasource?.logo?.jsonValue}
                      width={302}
                      height={69}
                      priority
                    />
                  </div>
                )}
                <div className={content()}>
                  {!storeNotFound ? (
                    <>
                      <div className={storeDetailWrapper()}>
                        {fields?.data?.datasource?.neighborhoodText?.jsonValue?.value && (
                          <RichText
                            className={text()}
                            field={fields?.data?.datasource?.neighborhoodText?.jsonValue}
                          />
                        )}
                        <p className={text()}>
                          {showStoreBasedOnLocation &&
                          (!isFetching || !isLoading) &&
                          storesList?.length > 0 &&
                          storesList[0]?.firstname &&
                          !(errors?.length > 0)
                            ? storesList[0]?.firstname
                            : selectedStore?.storeName}
                          <span className="ml-1">{getDictionaryValue('Store') || 'Store'}</span>
                        </p>
                      </div>
                      {fields?.data?.datasource?.continueShoppingCTAText?.jsonValue?.value && (
                        <div className={shoppingButtonWrapper()}>
                          <ButtonHelper
                            field={fields?.data?.datasource?.continueShoppingCTAText?.jsonValue}
                            variant={'primary'}
                            isLinkField={false}
                            size="default"
                            className={shoppingButton()}
                            onClickHandler={handleModalClose}
                          />
                        </div>
                      )}
                      {fields?.data?.datasource?.changeStoreCTAText?.jsonValue && (
                        <div className={storeButtonWrapper()}>
                          <LinkHelper
                            field={{
                              value: {
                                text: fields?.data?.datasource?.changeStoreCTAText?.jsonValue
                                  ?.value,
                                href: '/',
                              },
                            }}
                            onClick={(e) => {
                              e?.preventDefault();
                              setcomponentContextData({
                                ...componentContextData,
                                showStoreLocatorGlobalModal: true,
                                callbackFunctionForStoreLocatorGlobalModal: () => {
                                  handleModalClose();
                                },
                              });
                            }}
                            className={storeButton()}
                          >
                            <>
                              <Text
                                field={fields?.data?.datasource?.changeStoreCTAText?.jsonValue}
                                tag="p"
                              />
                              <IconHelper className={linkIcon()} icon={'chevron-right'} />
                            </>
                          </LinkHelper>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {/* //TODO: We will cover this later */}
                      {/* {fields?.data?.datasource?.neighborhoodTextNotFound?.jsonValue && (
                        <RichText
                          className={text()}
                          field={fields?.data?.datasource?.neighborhoodTextNotFound?.jsonValue}
                        />
                      )}
                      <div className={missingStoreButtonWrapper()}>
                         {fields?.data?.datasource?.changeStoreCTATextNotFound?.jsonValue && (
                        <div className={storeButtonWrapper()}>
                          <LinkHelper
                            field={{
                              value: {
                                text: fields?.data?.datasource?.changeStoreCTATextNotFound?.jsonValue
                                  ?.value,
                                href: '/',
                              },
                            }}
                            onClick={(e) => {
                              e?.preventDefault();
                              setcomponentContextData({
                                ...componentContextData,                               
                                showStoreLocatorGlobalModal: true,
                              });
                            }}
                            className={storeButton()}
                          >
                            <>
                              <Text
                                field={fields?.data?.datasource?.changeStoreCTATextNotFound?.jsonValue}
                                tag="p"
                              />
                              <IconHelper className={linkIcon()} icon={'chevron-right'} />
                            </>
                          </LinkHelper>
                        </div>
                      )}
                      </div> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        </ModalWrapper>
      </div>
    </div>
  );
}
const GeolocationPopupWidget = widget(
  GeolocationPopupDetail,
  WidgetDataType.SEARCH_RESULTS,
  'store'
);
export default function GeolocationPopup({ fields }: GeoLocationPopupProps) {
  return (
    <GeolocationPopupWidget
      fields={fields}
      rfkId={fields?.data?.datasource?.rfkid?.jsonValue?.value || 'rfk_Store_Locator_Geo_Location'}
    />
  );
}
