import { RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';
import { PSP } from '../../../models/PetSuppliesPlus.Model';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import { getPromotionObject, trackObjectForPromotion } from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'src/helpers/Constants';
import { useOcSelector } from 'src/redux/ocStore';
import { useBreakpoints } from 'src/utils/breakpoints';
import { useEffect } from 'react';
import { heroTailwindVariant } from 'tailwindVariants/components/heroTailwindVariant';

export type HeroProps = PSP.Sitecore.templates.PetSuppliesPlus.Hero.Fields.Hero;

export default function Hero({ fields }: HeroProps) {
  const isEE = useExperienceEditor();

  const {
    base,
    container,
    headingSection,
    headingBox,
    title,
    subtitle,
    content,
    heroBackground,
    heroImagesSection,
    heroImageGifStyle,
    heroImageStyle,
    cta,
    buttonStyles,
    buttonPrimaryStyle,
    buttonSecondaryStyle,
  } = heroTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  //Getting current Store ID
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const { deviceName }: { deviceName: string } = useBreakpoints();
  // Promotion GTM data Object
  const gtmPromotion = getPromotionObject(fields, deviceName);
  //Promotion GTM data push on load
  useEffect(() => {
    if (myStoreId && fields?.componentName?.value !== '') {
      trackObjectForPromotion(GTM_EVENT?.viewPromotion, myStoreId, gtmPromotion);
    }
  }, [myStoreId]);
  if (!fields) {
    return null;
  }
  return (
    <section
      className={base()}
      data-component={'components/Hero/Hero'}
      data-creative-id={fields?.componentName?.value}
      data-promotion-cta={fields?.cTAButtonCopy?.value}
      data-promo-id={fields?.promotionId?.value}
      data-promotion-name={fields?.promotionName?.value}
      data-promotion-copy={fields?.promotionCopy?.value}
      dta-promotion-url={fields?.promotionURL?.value?.href}
    >
      <div className={container()}>
        {fields?.backgroundImage?.value?.src && fields?.backgroundImage?.value?.src?.length > 0 && (
          <>
            {!isEE ? (
              <ImageHelper field={fields?.backgroundImage} className={heroBackground()} />
            ) : (
              <ImageHelper field={fields?.backgroundImage} className={heroBackground()} />
            )}
          </>
        )}
        <div className={headingSection()}>
          <div className={headingBox()}>
            {fields.subTitle && <RichText className={subtitle()} field={fields.subTitle} />}
            {fields?.title && <RichText className={title()} field={fields?.title} />}
            {fields?.description && <RichText className={content()} field={fields?.description} />}
            <div className={cta()}>
              {fields?.primaryLink && fields?.primaryLink?.value?.href && (
                <LinkHelper
                  field={fields?.primaryLink}
                  className={clsx(buttonStyles(), buttonPrimaryStyle())}
                  onClick={() => {
                    if (myStoreId && fields?.componentName?.value !== '') {
                      trackObjectForPromotion(GTM_EVENT?.selectPromotion, myStoreId, gtmPromotion);
                    }
                  }}
                />
              )}
              {fields?.secondaryLink && fields?.secondaryLink?.value?.href && (
                <LinkHelper
                  field={fields?.secondaryLink}
                  className={clsx(buttonStyles(), buttonSecondaryStyle())}
                  onClick={() => {
                    trackObjectForPromotion(GTM_EVENT?.selectPromotion, myStoreId, gtmPromotion);
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className={heroImagesSection()}>
          {!isEE ? (
            <>
              {fields?.imageGif?.value?.src && fields?.imageGif?.value?.src?.length > 0 && (
                <ImageHelper field={fields?.imageGif} className={heroImageGifStyle()} />
              )}

              {fields?.image?.value?.src && fields?.image?.value?.src?.length > 0 && (
                <ImageHelper field={fields?.image} className={heroImageStyle()} />
              )}
            </>
          ) : (
            <>
              {fields?.imageGif?.value?.src && fields?.imageGif?.value?.src?.length > 0 && (
                <ImageHelper
                  field={fields?.imageGif}
                  className={heroImageGifStyle({
                    className: `z-[2] ${isEE ? 'w-auto' : 'w-full'}`,
                  })}
                />
              )}

              {fields?.image?.value?.src && fields?.image?.value?.src?.length > 0 && (
                <ImageHelper field={fields?.image} className={heroImageStyle()} />
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
}
