import PaymentMethodsResponse from '@adyen/adyen-web/dist/types/core/ProcessResponse/PaymentMethodsResponse';
import { Amount } from '@adyen/api-library/lib/src/typings/amount';
import { PaymentResult } from '@adyen/api-library/lib/src/typings/payment/paymentResult';
import axios from 'axios';

export const AdyenClientApiWrapper = {
  deleteStoredPaymentMethod,
  getPaymentMethods,
  handleShopperRedirect,
  initiatePayment,
  submitOrder,
};

async function deleteStoredPaymentMethod(storedPaymentMethodId: string, shopperReference: string) {
  const storeId = localStorage.getItem('storeId') ?? '';
  const params = new URLSearchParams({
    storedPaymentMethodId,
    storeId,
    shopperReference,
  });
  const paramsString = params ? '?' + params : '';
  const response = await fetch('/api/adyen/deleteStoredPaymentMethod' + paramsString);

  return response.status >= 200 && response.status < 300 ? true : await response.json();
}

async function getPaymentMethods(
  storeId: string,
  locale: string,
  amount: Amount,
  shopperReference?: string
) {
  if (typeof window === 'undefined') {
    return;
  }
  const params = new URLSearchParams({
    locale,
    storeId,
    amountValue: amount?.value.toString(),
    shopperReference: shopperReference ?? '',
  });
  const paramsString = params ? '?' + params : '';
  const response = await fetch('/api/adyen/getPaymentMethods' + paramsString);
  return (await response.json()) as PaymentMethodsResponse;
}

async function handleShopperRedirect(redirectResult: string, payload: string) {
  const params = new URLSearchParams({
    redirectResult,
    payload,
  });
  const paramsString = params ? '?' + params : '';
  const response = await fetch('/api/adyen/handleShopperRedirect' + paramsString);
  // TODO
  return (await response.json()) as PaymentMethodsResponse;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function initiatePayment(data: any, amount: Amount, isAutoship: boolean) {
  const storeId = localStorage.getItem('storeId');
  const response = await axios.post<PaymentResult>('/api/adyen/initiatePayment', {
    ...data,
    amount,
    storeId,
    isAutoship,
  });
  /*
  if (response?.data?.resultCode == 'Authorised') {
    const payment: PartialDeep<Payment> = {
      ID: data?.merchantReference,
      //  Accepted: true,//set this in backend
      // Type: 'CreditCard',
      xp: {
        ZeroAuth: response?.data,
        EncryptedSecurityCode: data?.paymentMethod.encryptedSecurityCode,
      },
    };
    await Payments.Patch('All', data?.merchantReference, data?.merchantReference, payment);
  }
  */
  const result: ZeroAuthResponse = {
    encryptedSecurityCode: data?.paymentMethod.encryptedSecurityCode,
    storePaymentMethod:
      (data?.storePaymentMethod || data?.paymentMethod?.storedPaymentMethodId || isAutoship) ??
      false,
    paymentResponse: response.data,
  };
  return result; //response.data;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function submitOrder(data: any, orderid: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const response = await axios.post<any>('middleware', {
    ...data,
    orderid,
  });
  return response;
}
export interface ZeroAuthResponse {
  encryptedSecurityCode: string;
  storePaymentMethod: boolean;
  paymentResponse: PaymentResult & {
    refusalReasonCode?: string;
  };
}
