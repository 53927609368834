import { tv } from 'tailwind-variants';

export const resetPasswordTailwindVariant = tv(
  {
    slots: {
      linkError:
        'text-system-red-dark bg-system-red-light p-[15px] border border-system-red-default mb-5 rounded-mob-global-radius-small font-primary text-body-large-bold font-body-large-bold leading-body-large-bold',
      linkErrorWrapper: 'container py-[15px]',
      base: 'resetPasswordForm flex flex-col items-center text-color-text-white center gap-[8px] self-stretch container mx-auto',
      formWrapper:
        'flex flex-col items-start self-stretch bg-color-background-light py-desk-padding-tight-y bg-color-background-light max-w-[800px] w-full mx-auto',
      form: 'flex flex-col items-start text-color-text-black [&>last-child]:gap-0',
      successMsg:
        '!text-color-text-dark text-body-large-regular font-body-large-regular leading-body-large-regular',
      button:
        'flex content-center rounded-desk-global-radius-small bg-color-brand-primary-1-base text-color-text-white text-heading-desk-medium-bold leading-heading-desk-medium-bold font-heading-desk-medium-bold hover:bg-color-brand-primary-1-dark',

      field: 'flex flex-col  items-start self-stretch gap-desk-margin-micro-bottom',
      fieldWrap: ['flex', 'justify-between', 'w-full', ''],
      removeBorderRight: 'border-r-0',
      input:
        'flex flex-col items-start self-stretch font-body-large-regular h-[43px] gap-[8px] py-desk-global-spacing-spacing-0 px-desk-global-spacing-spacing-4 text-color-text-black border-2 border-solid border-color-border-mid bg-color-scale-1-white w-full ',
      title:
        'text-center font-heading-mob-xLarge-bold text-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold text-color-text-dark py-mob-padding-tight-y px-mob-global-grid-margin pb-mob-padding-micro-y',
      position: 'relative',
    },
    compoundSlots: [
      {
        slots: ['form', 'title'],
        class: ['w-full', 'self-stretch'],
      },
      {
        slots: ['input', 'button'],
        class: ['outline-none'],
      },
      {
        slots: ['input'],
        class: [
          'focus:bg-color-scale-1-white',
          'focus:border-3',
          'focus:border-color-border-mid',
          'focus:border-solid',
        ],
      },
      {
        slots: ['button'],
        class: ['focus:bg-color-brand-primary-1-dark'],
      },
    ],
    variants: {
      site: {
        psp: {
          base: 'bg-color-background-white',
        },
        wnw: {
          base: 'bg-color-background-white',
        },
      },
      size: {
        mobile: {
          base: 'py-mob-padding-tight-y gap-mob-margin-base-bottom px-0',
          formWrapper:
            'flex-col gap-mob-global-spacing-spacing-7 px-mob-padding-tight-x py-mob-padding-tight-y',
          form: 'w-full gap-mob-margin-base-bottom',
          field: 'w-full',
          button:
            'gap-mob-component-button-full-space-between py-mob-component-button-full-padding-y px-mob-component-button-full-padding-x w-full items-center justify-center mt-[24px]',
          createTitle:
            'font-heading-mob-large-bold leading-heading-mob-large-bold text-heading-mob-large-bold',
          fieldWrap: ['gap-mob-space-between-loose-horizontal', 'flex-col'],
        },
        desktop: {
          base: 'py-desk-padding-tight-y pb-desk-margin-loose-bottom pt-none',
          formWrapper:
            'gap-desk-global-spacing-spacing-11 px-desk-padding-tight-x py-desk-padding-tight-y',
          form: 'min-w-[290px] gap-desk-margin-loose-bottom',
          field: 'min-w-[280px]',
          button:
            'gap-desk-component-button-full-space-between py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x items-start justify-between w-[241px] w-auto -mt-[16px]',
          createTitle:
            'font-heading-desk-large-bold leading-heading-desk-large-bold text-heading-desk-large-bold',
          fieldWrap: [
            'gap-x-desk-space-between-loose-horizontal',
            'flex-row',
            'flex flex-cols items-end',
          ],
        },
      },
    },
  },
  {
    responsiveVariants: ['md'], // `true` to apply to all screen sizes
  }
);
