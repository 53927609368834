import { tv } from 'tailwind-variants';

export const accountVerificationTailwindVariant = tv(
  {
    slots: {
      successBase: '',
      loaderWrapper: '',
      errorMessage: 'text-system-red',
      button: [
        'flex',
        'content-center',
        'items-center',
        'rounded-desk-global-radius-small',
        'text-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'outline-none',
        'justify-center',
        'hover:bg-color-brand-primary-1-dark',
        'bg-color-brand-primary-1-base',
        'text-color-text-white',
      ],
    },
    compoundSlots: [
      {
        class: [
          'flex',
          'items-center',
          'justify-center',
          'w-full',
          'text-center',
          'py-mob-padding-tight-y',
          'px-mob-global-grid-margin',
          'lg:py-desk-padding-tight-y',
          'lg:px-desk-global-grid-margin',
        ],
        slots: ['successBase', 'loaderWrapper', 'errorMessage'],
      },
    ],
    variants: {
      device: {
        mobile: {
          successBase:
            'mb-mob-margin-loose-bottom py-mob-padding-tight-y px-mob-global-grid-margin ',
          loaderWrapper: [
            'gap-mob-component-button-full-space-between',
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          button: [
            'gap-mob-component-button-full-space-between',
            'py-mob-component-button-full-padding-y',
            'px-mob-component-button-full-padding-x',
            'w-full',
          ],
        },
        desktop: {
          successBase:
            'mb-desk-margin-loose-bottom y-desk-padding-tight-y px-desk-global-grid-margin',
          loaderWrapper: [
            'gap-desk-component-button-full-space-between',
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          button: [
            'gap-desk-component-button-full-space-between',
            'py-desk-component-button-full-padding-y',
            'px-component-button-full-padding-x',
            'w-fit',
          ],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
