import { Field, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import DropdownField from 'src/helpers/Form/DropdownField';
import PhoneField from 'src/helpers/Form/PhoneField';
import StateDropdown from 'src/helpers/Form/StateDropdownField';
import TextAreaField from 'src/helpers/Form/TextAreaField';
import TextField from 'src/helpers/Form/TextField';
import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import { ComponentProps } from 'lib/component-props';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import useDictionary from 'src/hooks/useDictionary';
import { useSiteName } from 'src/hooks/useSiteName';
import { useOcSelector } from 'src/redux/ocStore';
import { contactUsFormTailwindVariant } from 'tailwindVariants/components/contactUsFormTailwindVariant';
import { apiRequest } from 'src/utils/apiWrapper';
import { FormFieldsProps, transformData } from 'src/utils/formUtils';
import { contactApi } from 'src/utils/nextApiConfig';
import Link from 'next/link';
import ComponentContext from 'lib/context/ComponentContext';

export type FormPropsType = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      data: {
        data: {
          title?: Field<string>;
          shortDescription?: Field<string>;
          submitButtonText?: Field<string>;
          formFields: {
            targetItems: Array<FormFieldsProps>;
          };
          submitEndpoint: {
            jsonValue: Field<string>;
          };
          failureMessage?: Field<string>;
          successRedirectUrl?: {
            jsonValue: { value: { [key: string]: string } };
          };
        };
        messagetopics?: {
          items: { targetItems: Array<MessageTopic> };
        };
        wnwmessagetopics?: {
          items: { targetItems: Array<MessageTopic> };
        };
        countries_region: {
          countries: {
            results: Array<Country>;
          };
        };
      };
    };
  };

interface MessageTopic {
  text: Field<string>;
  value: Field<string>;
}
interface Country {
  name: Field<string>;
  code: Field<string>;
  regions: {
    targetItems: [
      {
        code: { value: string };
        name: { value: string };
        value: string;
      }
    ];
  };
}
interface FormValuesTypes {
  [key: string]: string | boolean;
}

const ContactUsForm = ({ fields, params }: FormPropsType): JSX.Element => {
  const { getDictionaryValue } = useDictionary();
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const token = useAuthToken();
  const {
    formContainer,
    form,
    fieldWrap,
    title,
    cta,
    contentTitleSection,
    formWrapper,
    submitBtn,
    shortDescription,
  } = contactUsFormTailwindVariant({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const router = useRouter();
  const sitename = useSiteName()?.toUpperCase() || '';
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const [initialValues, setInitialValues] = useState({
    FirstName: '',
    LastName: '',
    EmailAddress: '',
    MessageTopicId: '',
    MessageTopic: '',
    Country: 'US',
    State: '',
    ZipCode: '',
    City: '',
    PhoneNumber: '',
    Message: '',
    IsPSP: sitename === 'PSP' ? true : false,
    StoreId: '',
    Store: '',
  });
  useEffect(() => {
    if (myStoreData) {
      setInitialValues((prevValues) => ({
        ...prevValues,
        StoreId: Number(myStoreData?.storeId).toString() || '',
        Store: `${myStoreData?.state} - ${myStoreData.storeName} (${myStoreData.city})` || '',
      }));
    }
  }, [myStoreData]);

  const transFormFields = transformData(fields.data?.data?.formFields);
  //

  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const formSubmitAction = async (
    formvalues: FormValuesTypes,
    endpointURL: string,
    routeURL: string
  ) => {
    const updatedPostData = Object.keys(formvalues).reduce((acc: FormValuesTypes, key: string) => {
      if (key === 'MessageTopic') {
        const splitValues = formvalues[key].toString().split('||');
        acc[key] = splitValues[1].trim();
        acc['MessageTopicId'] = splitValues[0].trim() as string;
      } else {
        acc[key] = formvalues[key];
      }
      return acc;
    }, {});
    const headersData = {
      Authorization: token,
      site: sitename,
      endpointURL: endpointURL,
    };

    try {
      setShowErrorMsg(false);

      const options = { method: 'POST', headers: headersData, data: updatedPostData };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await apiRequest(contactApi?.contactUs, options);
      router.push(routeURL); // TODO: Need to manage globally with the status code.
    } catch (error) {
      setShowErrorMsg(true);
      throw new Error(`Error fetching ${endpointURL}: ${error}`);
    }
  };
  return (
    <div className={formWrapper({ className: params?.Syles ?? '' })}>
      <div className={contentTitleSection()}>
        {fields.data?.data?.title?.value && (
          <Text field={fields.data?.data?.title} className={title()} tag="h3" />
        )}
        {fields.data?.data?.shortDescription?.value && (
          <p className={shortDescription()}>{fields.data?.data?.shortDescription?.value}</p>
        )}
      </div>
      <div className={formContainer()}>
        {showErrorMsg && (
          <div className="text-system-red">
            <RichText field={fields?.data?.data?.failureMessage} className="rte" />
          </div>
        )}
        <Formik
          initialValues={initialValues}
          enableReinitialize={true} // Reinitialize form when initialValues change
          onSubmit={async (
            values: FormValuesTypes,
            { setSubmitting }: FormikHelpers<FormValuesTypes>
          ) => {
            setSubmitting(true);
            const successRedirectionURL =
              fields.data?.data?.successRedirectUrl?.jsonValue?.value.href || '';
            await formSubmitAction(
              values,
              fields.data?.data?.submitEndpoint?.jsonValue?.value,
              successRedirectionURL
            );
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) => (
            <FormikForm className={form()}>
              <div className={fieldWrap()}>
                <TextField {...transFormFields.FirstName} />
                <TextField {...transFormFields.LastName} />
              </div>
              <div className={fieldWrap()}>
                <TextField {...transFormFields.EmailAddress} />
              </div>
              <div className={fieldWrap()}>
                <DropdownField
                  {...transFormFields.Country}
                  options={fields.data.countries_region?.countries.results}
                  defaultValue={'US'}
                  firstOptionData={getDictionaryValue('Select')}
                />
                <TextField {...transFormFields.ZipCode} />
              </div>
              <div className={fieldWrap()}>
                <TextField {...transFormFields.City} />
                <StateDropdown
                  {...transFormFields.State}
                  options={fields.data.countries_region?.countries.results}
                  dependentfieldname={transFormFields?.Country?.name as string}
                />
              </div>
              <div className={fieldWrap({ className: 'relative' })}>
                <TextField
                  {...transFormFields.Store}
                  className="[&>*>input]:pr-[70px] lg:[&>*>input]:pr-0 [&>*>input:disabled]:bg-white [&>*>input:disabled]:hover:cursor-not-allowed"
                />
                <Link
                  href={'#'}
                  className={cta()}
                  onClick={(e) => {
                    e.preventDefault();
                    setcomponentContextData({
                      ...componentContextData,
                      showStoreLocatorGlobalModal: true,
                    });
                  }}
                >
                  {getDictionaryValue('Change')}
                </Link>
              </div>
              <div className={fieldWrap()}>
                <DropdownField
                  {...transFormFields.MessageTopic}
                  firstOptionData={getDictionaryValue('Select')}
                  options={
                    sitename === 'PSP'
                      ? fields.data.messagetopics?.items.targetItems
                      : fields.data.wnwmessagetopics?.items.targetItems
                  }
                  mergeTextAndValue={true}
                />
                <PhoneField {...transFormFields.PhoneNumber} />
              </div>
              <div className={fieldWrap()}>
                <TextAreaField {...transFormFields.Message} />
              </div>
              <div>
                <button
                  aria-label="submit"
                  className={submitBtn()}
                  type="submit"
                  data-disabled={isSubmitting}
                  disabled={isSubmitting}
                >
                  {fields.data?.data?.submitButtonText?.value}
                </button>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ContactUsForm;
