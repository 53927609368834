import { tv } from 'tailwind-variants';
const searchResultsTailwind = tv(
  {
    slots: {
      plpWrapper: ['PLP', 'bg-color-scale-2-light'],
      cardWrapper: [
        'cardWrapper',
        'relative',
        'border-[1px]',
        'border-color-border-mid',
        'bg-color-background-white',
        'w-[220px]',
        'flex',
        'flex-col',
        '!gap-[12px]',
      ],
      sitecoreProductRoot: [
        'relative',
        'productTile',
        // 'cursor-pointer',
        'border-[1px]',
        'border-color-border-mid',
        'bg-color-background-white',
        'justify-between',
        '!w-auto',
        // 'w-fit',
        // 'block border-solid border-grey-400 shadow-md w-48 px-4 py-4 cursor-pointer',
      ],
      sitecoreMarketingTile: ['marketingTile'],
      resultsWrapper: [
        'grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4',
        // 'sm:gap-[14px] md:gap-[24px]',
        'flex flex-row flex-wrap',
        'gap-[24px]',
        // 'flex',
        // 'flex-wrap',
        // 'gap-[24px]',
        // 'flex flex-wrap sm:flex-nowrap md:flex-wrap md:flex-nowrap lg:flex-wrap',
        // 'flex-grow-4 flex-shrink',
      ],
      //   sitecoreRightSection: ['flex flex-col flex-grow-4 flex-shrink'],
      sitecoreRightSection: [
        'flex flex-col',
        'md:max-w-[99%]',
        // 'lg:max-w-[72%]',
        // 'lg:max-w-[80%]',
        // 'lg:max-w-[77%]',
        'lg:max-w-[940px]',
        // 'xl:max-w-[74%]',
        // 'xl:max-w-[994px]',
        'gap-mob-margin-base-bottom',
      ],
      sitecoreLeftSection: ['sitecoreLeftSection', 'relative'],
      sitecoreResultsWrapper: [
        'flex',
        'w-full',
        // 'justify-between',
        // 'sm:gap-[14px] md:gap-[24px] lg:gap-[48px]',
        'sm:gap-[14px] md:gap-[24px]',
      ],
      productInfoWrapper: [
        'flex',
        'flex-col',
        'gap-[24px]',
        'justify-around',
        'min-h-[276px]',
        'spefic-range-wrapper-min-h',
        '!gap-[12px]',
      ],
      sitecoreProductName: [
        'sitecoreProductLink',
        'text-color-text-dark',
        'text-left',
        'font-body-large-regular',
        'text-body-large-regular',
        'leading-body-large-regular',
        'inline-block whitespace-break-spaces',
        'overflow-hidden overflow-ellipsis',
      ],
      productLabels: ['flex', 'justify-center'],
      productButtonCTA: [
        'py-mob-component-button-condensed-padding-y',
        'px-mob-component-button-condensed-padding-x',
        'rounded-global-radius-small',
        'border-[2px]',
        'bg-blue-300',
        'text-center',
      ],
      submitBtn: [
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
        'rounded-mob-global-radius-small',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
        // 'py-mob-component-button-condensed-padding-y',
        // 'px-mob-component-button-condensed-padding-x',
        'text-center',
        'p-auto',
        'w-full',
        'h-[48px]',
      ],
      listPrice: ['w-full', 'flex', 'flex-row', 'justify-between'],
      autoship: [
        'text-color-brand-autoship',
        'text-center',
        'flex',
        'items-center',
        'justify-center',
        'gap-[4px]',
      ],

      productBottomWrapper: ['flex', 'flex-col'],
      productImage: ['w-auto', 'max-h-[140px]'],
      productTopWrapper: ['flex', 'justify-between'],
      queryAndFilter: ['flex'],
      filterTitle: [
        'font-heading-desk-xLarge-bold',
        'text-heading-desk-xLarge-bold',
        'leading-heading-desk-xLarge-bold',
      ],
      clearText: ['text-color-brand-tertiary-1-dark', 'hover:underline'],
      selectedFilter: [
        'flex',
        'flex-col',
        'justify-start',
        'gap-mob-space-between-micro-horizontal',
        'text-color-brand-tertiary-1-dark',
      ],
      accordionList: [
        'flex',
        'flex-col',
        'gap-space-between-base-vertical',
        'gap-mob-space-between-base-vertical',
      ],
      accordionItem: ['flex', 'justify-start', 'w-full', 'items-center'],
      facetItemLabel: [
        'text-color-text-dark',
        'text-ellipsis',
        'whitespace-nowrap',
        'w-full',
        'cursor-pointer',
        'hover:text-color-scale-5-mid',
      ],
      facetCount: ['text-[#67768B]'],
      facetCheckbox: [
        'bg-white w-[16px] h-[15px] flex items-center justify-center border border-gray-300 rounded cursor-pointer',
        '[&>*>*>*]:fill-color-brand-primary-1-base',
        '[&>svg>*]:fill-color-brand-primary-1-base',
        'mr-desk-margin-tight-right',
        'outline-none',
        'checked:[&~div>label]:text-red-500',
        'rounded-[4px]',
        'focus:border-color-brand-primary-1-base',
      ],
      facetRoot: [
        'flex',
        'flex-col',
        'py-mob-padding-tight-y',
        'px-mob-global-spacing-spacing-0',
        'border-b-[1px]',
        'border-color-border-mid',
        'gap-mob-margin-base-bottom',
      ],
      //sitecore-accordion-value-list
      accordionWrapper: ['flex', 'flex-col', 'gap-mob-space-between-base-vertical', 'item-center'],
      accordionItemWrapper: ['flex', 'w-full', 'justify-between'],
      facetIcon: [
        '[&>svg]:w-[16px]',
        '[&>svg>*]:fill-color-brand-primary-1-base',
        'transition-transform duration-300',
        'group-data-[state=closed]:-rotate-90',
        'data-[state=closed]:-rotate-90',
      ],
      searchInput: [
        'border-[1px]',
        'rounded-mob-global-radius-small',
        'bg-color-background-white',
        'border-color-border-mid',
        'outline-none',
        'py-mob-global-spacing-spacing-1',
        'px-mob-global-spacing-spacing-2',
        'rounded-[4px]',
        'focus:border-color-brand-primary-1-base',
        'relative',
      ],
      facetLabel: ['w-full flex items-center justify-between group'],
      inputOperator: ['ml-[4px]'],
      iconSearch: [
        '[&>svg]:w-[16px]',
        '[&>svg]:h-fit',
        '[&>svg>*]:fill-color-brand-primary-1-base',
        'absolute',
        'translate-y-1/2',
      ],
      sortSelectParent: [
        // '[&>div]:hidden',
        '[&>*>div>div]:padding-0',
        '[&>*>div>div]:border-[2px]',
        '[&>*>div>div]:border-bg-color-border-mid',
        '[&>*>div>div]:overflow-y-hidden',
        '[&>*>div>div]:outline-none',
        '[&>*>div>div>div]:px-[12px]',
        '[&>*>div>div>div]:py-[8px]',
        '[&>*>div]:!relative',
        '!relative',
        '[&>div]:!absolute',
        '[&>div]:!left-0',
        '[&>div]:!top-[12%]',
        '[&>div]:!mt-0',
        '[&*>div>div]:!mt-0',
        '[&>*>div>div>div[data-highlighted]]:bg-color-primary-brand-1',
        '[&>*>div>div>div[data-highlighted]]:text-color-background-white',
        '[&>*>div>div>div[data-highlighted]]:outline-none',
      ],
      sortSelectTrigger: [
        'relative',
        'flex',
        'justify-between',
        'w-[240px]',
        'items-center',
        'cursor-pointer',
        'py-mob-padding-micro-y',
        'px-mob-global-spacing-spacing-3',
        'bg-color-background-white',
        'border-[1px]',
        'rounded-desk-global-radius-small',
        'outline-none',
        'data-[state=open]:border-[1px]',
        'data-[state=open]:border-color-brand-primary-1-base',
      ],
      sortSelectContent: [
        'bg-primary-contrastText',
        'overflow-hidden',
        'text-primary-main',
        'shadow-md',
        'absolute',
        'top-12',
        'z-50',
        'privacyShadow',
        'w-full',
        '!bg-color-background-white',
        'rounded-[4px]',
        '!border-[1px]',
        '!border-color-brand-primary-1-base',
      ],
      sortIcon: [
        'sortIcon',
        '[&_svg]:w-[16px]',
        '[&_svg]:h-[100%]',
        '[&>svg>*]:fill-color-brand-primary-1-base',
        'absolute',
        'right-[8px]',
        'transition-transform duration-300',
      ],
      sortOption: ['data-[highlighted]:bg-color-brand-primary-1-base'],
      optionAvailable: [
        'outline-none',
        'min-h-[40px]',
        'py-desk-component-button-condensed-padding-y',
        // 'px-[12px]',
        'px-auto',
        'text-center',
        'bg-color-background-white',
        'text-color-brand-primary-1-base',
        'hover:text-color-text-white',
        'border-[2px]',
        'rounded-desk-global-radius-small',
        'border-color-brand-primary-1-base',
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
        'delay-100',
        'hover:bg-color-brand-primary-1-base',
        'focus-visible:!bg-color-brand-primary-1-base',
        'focus-visible:!text-color-text-white',
        'h-[40px]',
        'h-auto',
      ],

      selectedFiltersWrapper: [
        'w-full',
        'flex flex-col',
        'gap-[12px]',
        'list-style-none',
        'pb-[16px]',
        'border-b-[1px]',
        'border-color-border-mid',
      ],
      selectedFacetCategory: ['text-color-text-black'],
      selectedFilterButton: [
        'filter-button',
        'flex items-center border-none cursor-pointer [&>span]:mr-[8px]',
        'hover:underline',
        '',
      ],
      selectedFilterFacet: [],
      clearLabel: [
        'mr-mob-space-between-base-horizontal',
        'text-color-brand-tertiary-1-dark',
        'hover:underline',
      ],
      splideLabel: [
        'h-full',
        'w-full',
        'py-mob-global-spacing-spacing-1',
        'px-mob-global-spacing-spacing-3',
        'text-center',
      ],
      splideImageWrapper: ['flex items-center'],
      splideImage: [
        // 'w-[60px]'
        'h-[60px]',
        'w-auto',
        'text-center',
        'max-h-[76px]',
        // 'object-cover',
      ],
      splideWrapper: [
        'splideWrapper',
        'w-full',
        '[&_li]:h-[132px]',
        '[&_li]:bg-color-background-white',
        '[&_li]:flex',
        '[&_li]:flex-col',
        '[&_li]:justify-between',
        'gap-0',
        '[&_li]:border-[1px]',
        '[&_li]:border-r-[0px]',
        '[&>li:last-of-type]:border-r-[1px]',
        '[&_li]:border-color-background-mid',
        // '[&_li]:max-w-[136px]',
        // '[&_li]:align-center',
        '[&_li]:items-center',
        '[&_li]:!mr-0',
        // '[&_li]:gap-mob-global-spacing-spacing-1',
        // '[&_button>splide__arrow--prev]:border-[2px]',
        // '[&_button>splide__arrow--prev]:border-green-500',
        'xl:[&_li]:max-w-[134.5px]',
        'lg:max-w-[704px]',
        'xl:max-w-fit',
      ],
      visualNextArrow:
        'splide__arrow--next visual-slider-next !bg-[transparent] [&_svg]:!h-6 [&_svg]:!w-6 !right-[-20px] disabled:hidden',
      visualPrevArrow:
        'splide__arrow--prev visual-slider-prev !bg-[transparent] [&_svg]:!h-6 [&_svg]:!w-6 !left-[-20px] disabled:hidden',
      facetPagination: ['flex', 'items-center', 'justify-center', 'h-[32px]'],
      facetPaginationBlock: ['flex', 'items-center'],
      paginationPages: ['flex', 'gap-mob-space-between-tight-horizontal'],
      paginationPage: [
        'py-mob-global-spacing-spacing-1',
        'px-mob-global-spacing-spacing-2',
        'border-[1px]',
        'border-color-brand-primary-1-base',
        'bg-color-background-white',
        'outline-none',
        'text-color-brand-primary-1-base',
        'hover:no-underline',
        'hover:text-color-text-white',
        'hover:bg-color-brand-primary-1-base',
        'data-[current=true]:bg-color-brand-primary-1-base',
        'data-[current=true]:text-color-text-white',
        'cursor-pointer',
      ],
      paginationArrow: ['[&_path]:fill-color-scale-7-mid-dark', 'cursor-pointer'],
      flagIcon: ['w-[27px]', 'h-[18px]'],
      filterButton: [
        'w-full',
        'py-mob-global-spacing-spacing-2',
        'px-mob-padding-tight-x',
        '!justify-center',
        '!text-center',
        '!font-normal',
        '!items-center',
        'border-[1px]',
        'rounded-mob-global-radius-small',
        'bg-color-background-white',
        'border-color-brand-primary-1-base',
        'text-color-brand-primary-1-base',
        'my-mob-margin-base-bottom',
      ],
      filterModal: [
        '!absolute',
        'shadow-privacyShadow',
        'py-[32px]',
        'px-mob-padding-tight-x',
        'lg:py-desk-padding-tight-y',
        'lg:px-desk-padding-tight-x',
        'top-[8%]',
      ],
      querySummary: [
        // 'mx-auto',
        // 'my-0',
        'text-left',
        'text-color-scale-8-dark',
        '[&_span]:font-body-large-bold',
        '[&_span]:text-body-large-bold',
        '[&_span]:leading-body-large-bold',
      ],
      searchTitle: ['text-color-scale-8-dark'],
      sitecoreImage: [
        'cursor-pointer',
        'h-[185px]',
        'relative',
        'overflow-hidden',
        'border-b-[1px]',
        'border-color-border-mid',
      ],
      sitecoreImageWrapper: [
        'mx-10',
        'my-auto',
        'relative',
        'h-[156px]',
        'flex',
        'justify-center',
        'items-center',
        'overflow-hidden',
      ],
      productStockPriceInfo: [
        'flex',
        'flex-col',
        'gap-[12px]',
        'items-center',
        'justify-between',
        // 'h-[112px]',
        '!h-full',
      ],
      showMoreLess: ['hover:underline'],
      noResultsBox: ['noResultsBox flex flex-col gap-[8px] [&_ul]:list-disc [&_ul]:pl-[40px]'],
      noResultQuery: [
        '!font-bold',
        '!font-body-large-regular',
        '!text-body-large-regular',
        '!leading-body-large-regular',
      ],
      bodySmallRegular: [
        '!font-body-small-regular',
        '!text-body-small-regular',
        '!leading-body-small-regular',
      ],
      cancelLastAction: [
        'text-color-brand-primary-1-base',
        'hover:cursor-pointer',
        'hover:text-color-brand-primary-1-dark',
        'hover:underline',
      ],
    },
    compoundSlots: [
      {
        slots: [
          'submitBtn',
          'productLabels',
          'productButtonCTA',
          'submitBtn',
          'listPrice',
          'facetLabel',
          'optionAvailable',
        ],
        class: ['font-body-large-bold', 'text-body-large-bold', 'leading-body-large-bold'],
      },
      {
        slots: [
          'clearText',
          'facetCount',
          'facetItemLabel',
          'inputOperator',
          'sortSelectTrigger',
          'selectedFilterFacet',
          'paginationPage',
          'filterButton',
          'querySummary',
          'noResultsBox',
        ],
        class: ['font-body-large-regular', 'text-body-large-regular', 'leading-body-large-regular'],
      },
      {
        slots: ['searchInput', 'sortSelectContent'],
        class: ['font-body-small-regular', 'text-body-small-regular', 'leading-body-small-regular'],
      },
      {
        slots: ['splideLabel', 'selectedFilterFacet', 'autoship'],
        class: ['text-body-small-bold', 'font-body-small-bold', 'leading-body-small-bold'],
      },
      {
        slots: ['visualPrevArrow', 'visualNextArrow'],
        class: [
          '[&_svg]:!fill-color-background-brand-1',
          '[&_svg]:!border-[2px]',
          '[&_svg]:!rounded-mob-global-radius-pill',
          '[&_svg]:!p-[3px]',
          '[&_svg]:!bg-color-background-white',
          '[&_svg]:!border-color-background-brand-1',
        ],
      },
      {
        slots: ['optionAvailable', 'submitBtn'],
        class: ['break-words'],
      },
    ],
    variants: {
      size: {
        mobile: {
          sitecoreResultsWrapper: ['flex flex-col'],
          cardWrapper: [
            'w-full',
            'py-mob-global-spacing-spacing-2',
            'px-mob-global-spacing-spacing-5',
          ],
          sitecoreProductRoot: ['flex', 'flex-col'],
          sitecoreImage: '-mx-mob-global-spacing-spacing-5',
          sitecoreProductName: [
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-5',
            'h-[138px]',
            '!pb-[0px]',
            'flex-grow overflow-hidden text-ellipsis whitespace-nowrap',
          ],
          productBottomWrapper: ['gap-[7px]'],
          sitecoreLeftSection: ['min-w-full', 'bg-color-scale-2-light'],
          filterButton: ['block'],
          iconSearch: ['right-[23px]'],
          searchTitle: [
            'font-heading-mob-xLarge-bold',
            'text-heading-mob-xLarge-bold',
            'leading-heading-mob-xLarge-bold',
          ],
          optionAvailable: ['w-full'],
          queryAndFilter: [
            'flex-col',
            'w-full',
            'items-start',
            'justify-between',
            'items-center',
            'gap-mob-margin-base-bottom',
          ],
          querySummary: ['w-full'],
          sortSelectParent: ['w-full', '[&>*>div>div]:w-full', '[&_button]:w-full'],
          sitecoreMarketingTile: [
            'w-auto',
            '[&_img]:w-full',
            '[&_img]:h-full',
            '[&_img]:object-cover',
          ],
          facetPaginationBlock: ['gap-mob-space-between-tight-vertical'],
          splideImage: [],
          splideImageWrapper: ['pt-mob-padding-micro-top', 'px-mob-padding-micro-x'],
        },
        desktop: {
          sitecoreResultsWrapper: ['flex-row'],
          cardWrapper: ['p-desk-global-spacing-spacing-2'],
          sitecoreProductRoot: [''],
          sitecoreImage: '-mx-desk-global-spacing-spacing-2',
          sitecoreProductName: [
            'px-desk-global-spacing-spacing-3 py-desk-global-spacing-spacing-3',
            '!pb-[0px]',
            'h-[106px]',
          ],
          productBottomWrapper: ['gap-[10px]'],
          sitecoreLeftSection: [
            'min-w-fit',
            'block',
            'w-full',
            // 'lg:w-[12%]',
            'lg:w-[20%]',
            'lg:max-w-[276px]',
          ],
          filterButton: ['hidden'],
          iconSearch: ['right-[8px]'],
          searchTitle: [
            'font-heading-desk-xLarge-bold',
            'text-heading-desk-xLarge-bold',
            'leading-heading-desk-xLarge-bold',
          ],
          queryAndFilter: ['flex-row', 'w-full', 'justify-between'],
          querySummary: ['w-fit'],
          sortSelectParent: ['!w-[250px]', '[&>*>div>div]:w-[250px]'],
          facetItemLabel: ['max-w-[203px]'],
          facetPaginationBlock: ['gap-desk-space-between-tight-vertical'],
          splideImage: [],
          splideImageWrapper: ['pt-desk-padding-micro-y', 'px-desk-padding-micro-x'],
        },
      },
      outlineCta: {
        true: {
          submitBtn: [
            'bg-color-background-white',
            'text-color-text-brand-1',
            'border-2 border-solid border-color-brand-primary-1-dark',
            'hover:bg-color-brand-primary-1-dark hover:text-color-text-white',
            'focus:bg-color-brand-primary-1-dark focus:text-color-text-white',
          ],
        },
        false: {
          submitBtn: [
            'bg-color-brand-primary-1-base',
            'text-color-text-white',
            'hover:bg-color-brand-primary-1-dark',
            'focus:bg-color-brand-primary-1-dark',
          ],
        },
      },
      noResults: {
        true: {
          sitecoreResultsWrapper: ['justify-start'],
          queryAndFilter: ['!items-start'],
        },
        false: {
          sitecoreResultsWrapper: ['justify-between'],
          queryAndFilter: ['!items-center'],
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
export default searchResultsTailwind;
