import { NextImage, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { StoreLocatorConfig, StoreProps } from './GenericStoreLocator';
import ComponentContext from 'lib/context/ComponentContext';
import { useRouter } from 'next/router';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { createOrder, retrieveOrder } from 'src/redux/ocCurrentOrder';
import { unwrapResult } from '@reduxjs/toolkit';
import { closedText, FulfillmentType, GTM_EVENT } from 'src/helpers/Constants';
import { sendStoreGTMData } from 'src/utils/sendGTMEvent';
import { DateTime } from 'luxon';
import { useGraphQlDataContext } from 'lib/context/GraphQlDataContext';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { storeTailwindVariant } from 'tailwindVariants/components/storeTailwindVariant';

interface StoreData {
  storeid: string;
  LatPos: number;
  LngPos: number;
}
const Store = ({
  stores,
  fields,
  location,
  setActiveMarker,
  getStoreName,
  isCareerVariant,
}: {
  stores: StoreProps[];
  fields: StoreLocatorConfig;
  location: string;
  setActiveMarker?: Dispatch<SetStateAction<StoreData>>;
  getStoreName: (location: string) => string | undefined;
  isCareerVariant?: boolean;
}) => {
  const {
    storePawImage,
    base,
    mainWrapper,
    storeWrapper,
    imageLabel,
    storeAddressWrapper,
    storeDetailWrapper,
    storeActionWrapper,
    resultText,
    storeName,
    storeDetails,
    storeAddress,
    openStoreTimingText,
    buttonWrapper,
    buttonWithArrow,
    iconWrapper,
    storeText,
    shopButton,
    storesWrapper,
    storePageArrow,
    myStoreIcon,
    markerWrapper,
  } = storeTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const dispatch = useOcDispatch();
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const router = useRouter();
  const { storeServices } = useGraphQlDataContext();

  const servicesData = storeServices.reduce((prev, curr) => {
    if (curr?.serviceId?.value) {
      return {
        ...prev,
        [curr.serviceId.value.toString()]: curr?.name?.value,
      };
    }
    return prev;
  }, {} as Record<string, string>);

  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const renderServicesList = (servicesList: number[]) => {
    const { serviceList } = storeTailwindVariant({
      size: { initial: 'mobile', lg: 'desktop' },
    });
    return (
      <ul>
        {servicesList &&
          servicesList?.map((service, index) => (
            <li key={index} className={serviceList()}>
              {servicesData?.[service?.toString()]}
            </li>
          ))}
      </ul>
    );
  };
  return (
    <div className={mainWrapper()}>
      {stores?.length > 0 && (
        <div className={base()}>
          {fields?.noofItemsPerPageText && stores?.length > 0 && (
            <RichTextHelper
              tag="p"
              field={fields?.noofItemsPerPageText}
              updatedField={{
                value: fields?.noofItemsPerPageText?.value
                  ?.replace(
                    '{location}',
                    `<strong>&nbsp;${getStoreName(location) || ''}&nbsp;</strong>`
                  )
                  ?.replace('{number}', `<strong>&nbsp;${stores?.length}&nbsp;</strong>`),
              }}
              className={resultText()}
            />
          )}
          <div className={storesWrapper()}>
            {stores?.map((store, index) => {
              const storeButton =
                store?.xp_storetype == 1
                  ? fields?.jobOpportunitiesText?.value
                  : fields?.callToInquire?.value;

              const storeTime = store?.xp_storehours?.find(
                (dayData) => dayData?.Day === new Date()?.getDay() + 1
              );
              return (
                <div
                  id={`google_map_store_${store?.storeid}`}
                  key={index}
                  className={storeWrapper()}
                  onClick={(e) => {
                    e.preventDefault();
                    const element = document.getElementById('google-map');
                    if (element) {
                      window.scrollTo({
                        top: element.offsetTop,
                        behavior: 'smooth',
                      });
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      if (setActiveMarker) setActiveMarker(store as any);
                    }
                  }}
                >
                  {fields?.storePawIcon?.value?.src && (
                    <div
                      className={markerWrapper()}
                      onClick={(e) => {
                        e.preventDefault();
                        const element = document.getElementById('google-map');
                        if (element) {
                          window.scrollTo({
                            top: element.offsetTop,
                            behavior: 'smooth',
                          });
                          // eslint-disable-next-line @typescript-eslint/no-explicit-any
                          if (setActiveMarker) setActiveMarker(store as any);
                        }
                      }}
                    >
                      <NextImage
                        className={storePawImage()}
                        field={{
                          value: {
                            src: fields?.storePawIcon?.value?.src,
                            alt: store?.state,
                            height: '40',
                            width: '40',
                          },
                        }}
                      />
                      <p className={imageLabel()}>{index + 1}</p>
                    </div>
                  )}
                  <div className={storeDetailWrapper()}>
                    <div className={storeDetails({ isCareerVariant: isCareerVariant })}>
                      <div>
                        <p className={storeName()}>{store?.firstname}</p>
                        <div className={storeAddressWrapper()}>
                          <div className="flex">
                            {isCareerVariant && (
                              <NextImage
                                className="w-[30px] h-[30px]"
                                field={fields?.myLocationPointIcon}
                              />
                            )}
                            <Text
                              className={storeAddress()}
                              tag="p"
                              field={{ value: store?.street1 }}
                            />
                          </div>
                          <Text
                            className={storeAddress({
                              className: isCareerVariant ? 'ml-[30px]' : '',
                            })}
                            tag="p"
                            field={{ value: `${store?.city}, ${store?.state}` }}
                          />
                          {store.phone && (
                            <div className="flex">
                              {isCareerVariant && (
                                <NextImage
                                  className="w-[30px] h-[30px]"
                                  field={fields?.phoneIcon}
                                />
                              )}
                              <LinkHelper
                                className={storeAddress()}
                                onClick={(e) => e.stopPropagation()}
                                field={{
                                  value: {
                                    text: store?.phone,
                                    href: `tel:${store?.phone}`,
                                  },
                                }}
                              />
                            </div>
                          )}
                          {isCareerVariant && storeButton ? (
                            <LinkHelper
                              className={shopButton({
                                className:
                                  'w-full mt-mob-margin-base-bottom lg:w-fit lg:mt-desk-margin-base-bottom',
                              })}
                              onClick={(e) => e.stopPropagation()}
                              field={{
                                value: {
                                  href:
                                    store?.xp_storetype == 1
                                      ? fields?.jobOpportunitiesURL?.value?.href ?? ''
                                      : store?.phone
                                      ? `tel:${store?.phone}`
                                      : '',
                                  target:
                                    store?.xp_storetype == 1
                                      ? fields?.jobOpportunitiesURL?.value?.target ?? '_blank'
                                      : '',
                                  text: storeButton,
                                },
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div>{!isCareerVariant && renderServicesList(store.xp_storeservices)}</div>
                    </div>
                    {!isCareerVariant && (
                      <div className={storeActionWrapper()}>
                        {fields?.storeLocatorRemainingOpenTimeText && (
                          <RichTextHelper
                            tag="p"
                            field={fields?.storeLocatorRemainingOpenTimeText}
                            updatedField={{
                              value:
                                storeTime && storeTime?.OpenTime && storeTime?.CloseTime
                                  ? storeTime?.OpenTime === storeTime?.CloseTime
                                    ? closedText
                                    : fields?.storeLocatorRemainingOpenTimeText?.value?.replace(
                                        '{storeLocatorRemainingOpenTime}',
                                        storeTime?.CloseTime
                                          ? DateTime.fromFormat(
                                              storeTime?.CloseTime,
                                              'HH:mm:ss'
                                            ).toFormat('h a')
                                          : ''
                                      ) || ''
                                  : closedText,
                            }}
                            className={openStoreTimingText()}
                          />
                        )}

                        <div className={buttonWrapper()}>
                          {fields?.viewStorePageButtonText?.value && (
                            <LinkHelper
                              className={buttonWithArrow()}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                router.push(
                                  `/store/${store?.state?.toLowerCase()}/${store?.city?.toLowerCase()}/${store?.storeid?.toLowerCase()}-${store?.city?.toLowerCase()}/${store?.storeid?.toLowerCase()}`?.replaceAll(
                                    ' ',
                                    '-'
                                  )
                                );
                              }}
                              field={{
                                value: {
                                  href: `/store/${store?.state?.toLowerCase()}/${store?.city?.toLowerCase()}/${store?.storeid?.toLowerCase()}-${store?.city?.toLowerCase()}/${store?.storeid?.toLowerCase()}`.replaceAll(
                                    ' ',
                                    '-'
                                  ),
                                  text: fields?.viewStorePageButtonText?.value,
                                },
                              }}
                            >
                              <>
                                <Text field={fields?.viewStorePageButtonText} />
                                <IconHelper className={storePageArrow()} icon={'chevron-right'} />
                              </>
                            </LinkHelper>
                          )}
                          {myStoreData?.storeId === store?.storeid ? (
                            <>
                              {fields?.myStoreText?.value && (
                                <div className={iconWrapper()}>
                                  <NextImage
                                    className={myStoreIcon()}
                                    field={fields?.myStoreIcon}
                                  />
                                  <Text
                                    field={{ value: fields?.myStoreText?.value }}
                                    tag="p"
                                    className={storeText()}
                                  ></Text>
                                </div>
                              )}
                            </>
                          ) : (
                            <>
                              {fields?.shopThisStoreText?.value && (
                                <LinkHelper
                                  className={shopButton()}
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (store?.storeid) {
                                      setcomponentContextData({
                                        ...componentContextData,
                                        storeId: store?.storeid,
                                        storeCount: stores?.length,
                                        callBackFunctionAfterStoreChange: async () => {
                                          const redirectUrl =
                                            fields?.afterStoreChangeUrl?.value || '/';
                                          const retrieveStoreOrder = await dispatch(
                                            retrieveOrder()
                                          );
                                          const responsesStoreOrder =
                                            unwrapResult(retrieveStoreOrder);
                                          if (responsesStoreOrder === undefined) {
                                            await dispatch(
                                              createOrder(
                                                localStorage.getItem(
                                                  'selected_fulfillment_Method'
                                                ) === FulfillmentType?.DFS
                                              )
                                            );
                                            localStorage.removeItem('selected_fulfillment_Method');
                                          } else {
                                            stores?.length &&
                                              sendStoreGTMData({
                                                eventName: GTM_EVENT?.shopThisStore,
                                                storeId: store?.storeid,
                                                storesShown: stores?.length,
                                              });
                                            await dispatch(retrieveOrder());
                                          }
                                          router?.push(redirectUrl);
                                        },
                                      });
                                    }
                                  }}
                                  field={{
                                    value: { href: '/', text: fields?.shopThisStoreText?.value },
                                  }}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Store;
