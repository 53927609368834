import { tv } from 'tailwind-variants';

export const doubleCalloutBlockTailwindVariant = tv({
  slots: {
    base: 'doubleCallout',
    wrapper: 'container mx-auto',
    heading: '',
    headingLink:
      'w-fit block hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
    linkIcon:
      'w-4 h-4 [&_svg]:max-w-4 [&_svg]:max-h-4 [&>svg>*]:fill-color-text-brand-1 group-hover:[&>svg>*]:fill-color-brand-tertiary-3-base group-focus:[&>svg>*]:fill-color-brand-tertiary-3-base',
    buttonWrapper: 'block hover:no-underline   w-fit',
    noButtonWrapper: '',
    colHeading:
      'text-color-scale-8-dark w-fit font-primary font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold',
    colDescription:
      'font-body-large-regular text-color-scale-8-dark font-primary text-body-large-regular leading-body-large-regular  lg:mb-0',
    colWrapper: 'flex flex-wrap',
    headingWrapper: 'block w-full h-auto  lg:flex-1 ',
    headingWrapperForCol: 'block w-full h-auto lg:flex-1 ',
    contentWrapper:
      'pb-mob-margin-micro-bottom lg:pb-desk-margin-micro-bottom gap-mob-margin-tight-bottom lg:gap-desk-margin-tight-bottom',
    icon: 'flex',
    link: 'flex items-center group font-body-large-bold w-fit font-primary leading-body-large-bold text-body-large-bold text-color-text-brand-1 mt-2 lg:mt-3 hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
    description: '',
  },
  variants: {
    isPrimary: {
      true: {
        linkIcon: 'ml-desk-space-between-micro-horizontal',
        colWrapper:
          'gap-mob-space-between-base-vertical lg:flex-nowrap lg:gap-desk-global-grid-gutter',
        wrapper: 'px-mob-global-grid-margin py-mob-padding-base-y lg:py-desk-padding-tight-y',
        contentWrapper: 'pb-mob-margin-tight-bottom lg:pb-desk-margin-micro-bottom lg:mt-3',
        heading:
          'font-primary text-color-text-brand-1 font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold mb-mob-margin-tight-bottom lg:mb-desk-margin-tight-bottom',
        headingLink:
          'font-primary text-color-text-brand-1 font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold mb-mob-margin-tight-bottom lg:mb-desk-margin-tight-bottom',
        headingWrapper:
          'lg:w-1/4 lg:min-w-1/4 pb-mob-margin-micro-bottom lg:pb-0 px-mob-padding-micro-x lg:px-desk-padding-micro-x',
        headingWrapperForCol:
          'lg:w-1/4 flex py-mob-padding-micro-y px-mob-padding-micro-x lg:block lg:py-0 gap-mob-margin-base-right lg:gap-0 flex-row lg:flex-col lg:min-w-1/4 px-mob-padding-micro-x lg:px-desk-padding-micro-x',
        icon: ' w-mob-component-image-70-icons-width h-mob-component-image-70-icons-width max-h-mob-component-image-70-icons-width max-w-mob-component-image-70-icons-width mb-mob-margin-tight-bottom lg:w-desk-component-image-70-icons-width lg:h-desk-component-image-70-icons-width lg:max-h-desk-component-image-70-icons-width lg:max-w-desk-component-image-70-icons-width ',
        link: 'flex font-body-large-bold font-primary leading-body-large-bold text-body-large-bold',
        description:
          'font-body-large-regular text-color-scale-9-black font-primary text-body-large-regular leading-body-large-regular',
        colDescription: 'mt-2 lg:mt-3',
      },
      false: {
        colHeading:
          'text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold lg:font-heading-desk-large-bold lg:text-heading-desk-large-bold lg:leading-heading-desk-large-bold',
        linkIcon: 'ml-mob-space-between-micro-horizontal',
        colWrapper:
          'gap-mob-margin-tight-bottom lg:flex-nowrap lg:gap-desk-global-grid-gutter lg:h-fit',
        wrapper: 'py-mob-padding-tight-y lg:py-desk-padding-tight-y',
        headingWrapperForCol:
          'flex lg:flex-col lg:border-r-[1px] gap-mob-margin-base-right lg:gap-desk-margin-tight-bottom lg:border-r-color-border-mid lg:border-solid lg:last:border-r-0 items-start lg:w-1/3 lg:min-w-1/3 py-mob-padding-tight-y px-mob-global-grid-margin lg:py-0 lg:px-desk-padding-micro-x',
        icon: 'w-[70px] h-[70px] max-w-[70px] max-h-[70px] lg:w-[110px] lg:h-[110px] lg:max-h-[110px] lg:max-w-[110px]',
        headingWrapper:
          'lg:w-1/3 lg:min-w-1/3 py-mob-padding-tight-y px-mob-global-grid-margin lg:p-0',
        colDescription: 'text-start ',
        contentWrapper:
          'flex flex-col items-start pb-mob-margin-tight-bottom lg:pb-desk-margin-micro-bottom',
      },
    },
    showHeading: {
      true: {
        headingWrapper: 'block',
      },
      false: {
        headingWrapper: 'hidden lg:block',
      },
    },
    showBackgroundColor: {
      true: {
        base: 'bg-color-background-light',
      },
    },
  },
});
