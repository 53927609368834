import { tv } from 'tailwind-variants';

export const multiColumnCarouselTailwindVariant = tv(
  {
    slots: {
      base: [
        'carouselMultiColumnCarousel',
        'font-primary',
        'relative',
        '[&_.splide_.arrow-prev]:-left-mob-global-spacing-spacing-10',
        '[&_.splide_.arrow-next]:-right-mob-global-spacing-spacing-10',
        '[&_.splide_.splide-button]:bg-color-background-light',
        '[&_.splide_.splide-button]:h-[40px]',
        '[&_.splide_.splide-button]:w-[40px]',
        '[&_.splide_.splide-button>svg]:text-body-xSmall-regular',
        '[&_.splide_.splide-button>svg]:fill-color-brand-primary-1-base',
        '[&_.splide_.splide-button>svg>path]:!drop-shadow-arrows',
        '[&_*_*.splide-button]:border-color-["#777777"]',
        '[&_*_*.splide-button]:border-[2px]',
        '[&_*_*.splide-button]:border-solid',
      ],
      innerDiv: ['container', 'mx-auto'],
      wrapper: [''],
      row: ['py-desk-component-button-full-padding-y'],
      heading: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
        'mt-desk-margin-base-top',
        'mb-desk-margin-tight-bottom',
      ],
      card: ['w-full', 'px-desk-padding-micro-x'],
      cardWrapper: ['flex', 'flex-col', 'items-center', 'gap-desk-margin-tight-bottom'],
      cardImageWrapper: ['max-w-[200px]'],
      cardImage: [''],
      content: ['flex', 'flex-col', 'w-full', 'items-center', 'gap-desk-margin-tight-bottom'],
      // text: ['w-full'],
      title: [
        'w-full',
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
        'text-center',
      ],
      description: [
        'mb-desk-margin-micro-bottom',
        'w-full',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
        'text-center',
      ],
      cta: [
        'text-color-text-brand-1',
        'text-heading-mob-small-bold',
        'font-heading-mob-small-bold',
        'leading-heading-mob-small-bold',
      ],
    },
    variants: {
      site: {
        psp: {},
        wnw: { heading: ['font-secondary'] },
      },
      device: {
        mobile: {
          innerDiv: ['px-0 pl-4'],
        },
        desktop: {
          wrapper: ['px-desk-component-button-full-padding-y'],
          row: ['mx-desk-global-spacing-spacing-8'],
          // card: ['px-desk-padding-micro-x'],
          cardImage: ['max-w-desk-component-image-content-circle-width'],
          heading: ['-ml-16', '-mr-16'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
