import { tv } from 'tailwind-variants';

export const fulfillmentSwitcherTailwindVariant = tv(
  {
    slots: {
      container: ['fulfillment-switcher', 'flex', 'mb-[15px]'],
      pageTitle: [
        'text-heading-desk-xLarge-bold',
        'font-heading-desk-xLarge-bold',
        'leading-heading-desk-xLarge-bold',
      ],
      cartNumberWrapper: ['flex', 'items-center'],
      cartText: [
        'font-heading-desk-xLarge-bold',
        'font-primary',
        'leading-heading-desk-xLarge-bold',
        'text-heading-desk-xLarge-bold',
        'text-color-text-black',
      ],
      cartNumber: [
        'text-body-large-regular',
        'leading-body-large-regular',
        'font-body-large-regular',
        'flex',
        'gap-[3px]',
      ],
    },
    compoundSlots: [],
    variants: {
      device: {
        mobile: {
          container: ['flex-col', 'items-start'],
          cartNumberWrapper: ['gap-mob-margin-base-right'],
        },
        desktop: {
          container: ['justify-between', 'items-center', 'self-stretch', 'flex-row'],
          cartNumberWrapper: ['gap-desk-margin-base-right'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
