import React, { useEffect, useState } from 'react';
import { ComponentProps } from 'lib/component-props';

import { Field, NextImage, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import { tv } from 'tailwind-variants';
import { apiRequest } from 'src/utils/apiWrapper';
import {
  HideLinkFromStoreNavigation,
  ShowLinkFromStoreNavigation,
} from 'src/helpers/StoreLandingHelper';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { useTheme } from 'lib/context/ThemeContext';
import { yelpAPI } from 'src/utils/nextApiConfig';

export const YelpReviewVariants = tv(
  {
    slots: {
      averageReviewWrapper: 'flex',
      headingWrapper: '',
      writeReview:
        'flex items-center hover:no-underline justify-center bg-color-brand-primary-1-base rounded-desk-global-radius-small w-fit h-fit font-primary text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold text-color-text-white hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
      headingLeftContent: '',
      averageReviewImage: 'lg:w-[162px] lg:h-[30px] lg:mr-3 ',
      averageReviewText:
        'font-primary text-heading-desk-large-regular font-heading-desk-large-regular leading-heading-desk-large-regular ',
      buttonWrapper: 'flex lg:gap-desk-space-between-tight-horizontal',
      container: 'container',
      yelpImage: 'lg:mr-[42px] max-w-[184px] max-h-[90px]',
      reviewWrapper: 'w-full lg:max-w-[962px] ',
      reviewsWrapper: 'flex flex-col',
      helpfulReviewButton:
        'bg-color-scale-5-mid flex justify-center items-center font-primary font-body-large-regular text-body-large-regular leading-body-large-regular text-color-text-white hover:shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] focus:shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] hover:no-underline  lg:py-desk-global-spacing-spacing-3 lg:px-desk-global-spacing-spacing-4',
      base: 'bg-color-scale-2-light flex flex-col lg:gap-[79px] ',
      reviewLeftContentWrapper: 'lg:max-w-[184px] lg:mb-0',
      reviewRightContentWrapper: 'lg:max-w-[734px]',
      reviewDate:
        'font-primary font-body-large-regular text-body-large-regular leading-body-large-regular text-color-text-dark',
      reviewAuthor:
        'font-primary text-color-text-black font-heading-mob-large-bold text-heading-mob-large-bold leading-heading-mob-large-bold',
      reviewMoreLink:
        'block font-primary w-fit font-body-large-regular_underline text-body-large-regular_underline leading-body-large-regular_underline underline text-color-text-brand-1 hover:text-color-brand-tertiary-3-base focus:color-brand-tertiary-3-base',
      reviewImage: 'lg:mb-desk-space-between-tight-vertical',
      showMoreText:
        'font-primary w-fit font-heading-desk-medium-bold text-heading-desk-medium-bold leading-heading-desk-medium-bold underline text-color-text-brand-1 hover:text-color-brand-tertiary-3-base focus:color-brand-tertiary-3-base mt-4 lg:mt-0',
      reviewText:
        'font-primary font-body-large-regular text-body-large-regular leading-body-large-regular text-color-text-dark',
      helpFulReviewText:
        'font-primary font-body-large-regular text-body-large-regular leading-body-large-regular text-color-text-dark',
      reviewsNumber:
        'font-primary lg:mb-0 lg:text-heading-desk-large-regular lg:font-heading-desk-large-regular lg:leading-heading-desk-large-regular',
    },
    variants: {
      size: {
        desktop: {
          reviewsNumber: '',
          headingWrapper: 'flex justify-between items-center',
          writeReview:
            'px-desk-component-button-full-padding-x mt-0 py-desk-component-button-full-padding-y',
          container: 'py-desk-padding-tight-y',
          averageReviewWrapper: 'flex-row',
          headingLeftContent: 'flex-row items-center',
          averageReviewImage: '',
          yelpImage: 'mb-0',
          reviewMoreLink: 'mb-[15px]',
          reviewImage: 'mb-desk-space-between-tight-vertical',
          reviewWrapper: 'flex justify-between items-start',
          base: 'py-desk-padding-tight-y px-desk-padding-tight-x',
          reviewLeftContentWrapper: 'flex flex-col w-full max-w-[184px] pr-desk-margin-base-right',
          reviewText: 'mb-desk-space-between-micro-vertical',
          helpFulReviewText: 'mb-desk-space-between-tight-vertical',
          reviewRightContentWrapper: 'w-full',
          buttonWrapper: '',
          reviewsWrapper: 'gap-desk-margin-loose-bottom',
          reviewAuthor: 'mb-desk-space-between-tight-vertical',
          helpfulReviewButton: 'rounded-desk-global-radius-small ',
        },
        mobile: {
          reviewsNumber:
            ' text-heading-mob-large-regular font-heading-mob-large-regular mb-3 leading-heading-mob-large-regular',
          averageReviewWrapper: 'flex-col-reverse',
          headingLeftContent: 'flex flex-col',
          yelpImage: 'mb-[42px]',
          container: 'py-mob-padding-tight-y',
          reviewImage: 'mb-mob-space-between-tight-vertical',
          base: 'gap-mob-padding-loose-bottom py-mob-padding-tight-y px-mob-padding-tight-x',
          reviewText: 'mb-mob-space-between-micro-vertical',
          reviewMoreLink: 'mb-[15px] ',
          helpFulReviewText: 'mb-mob-space-between-tight-vertical',
          buttonWrapper: 'gap-mob-space-between-tight-horizontal',
          reviewsWrapper: 'gap-mob-margin-loose-bottom',
          reviewAuthor: 'mb-mob-space-between-tight-vertical',
          reviewLeftContentWrapper: 'mb-mob-margin-base-bottom',
          writeReview:
            'mt-mob-margin-loose-bottom px-mob-component-button-full-padding-x mt-0 py-mob-component-button-full-padding-y',
          helpfulReviewButton:
            'rounded-mob-global-radius-small py-mob-global-spacing-spacing-3 px-mob-global-spacing-spacing-4',
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);

interface Review {
  id: string;
  rating: number;
  ReviewerName: string;
  ReviewDateString: string;
  ReviewText: string;
  ReviewStarsImage: string;
  ReviewUrl: string;
}
export type YelpProps = ComponentProps & {
  fields: {
    data?: {
      contextItem?: { yelpbusinessid?: { value: string } };
      datasource?: {
        moreText: Field<string>;
        noText: Field<string>;
        showMoreReviews: Field<string>;
        wasThisReviewHelpful: Field<string>;
        writeReviewText: Field<string>;
        yelpReviewsText: Field<string>;
        yesText: Field<string>;
      };
    };
  };
};
interface ReviewProps {
  AverageRating?: number;
  ReviewStarsImage?: string;
  ReviewUrl?: string;
  TotalReviews?: number;
  Reviews?: Review[];
}
const Yelp: React.FC = ({ fields }: YelpProps) => {
  const [reviewsData, setReviewsData] = useState<ReviewProps>({});
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const storeDetails: any = useSitecoreContext()?.sitecoreContext?.route?.fields ?? {};
  const token = useAuthToken();
  const { themeNameUpper } = useTheme();
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const headersData = {
          Authorization: token,
          site: themeNameUpper,
          yelpBusinessID: storeDetails?.yelpbusinessid?.value,
        };
        const options = {
          method: 'GET',
          headers: headersData,
        };

        const response: ReviewProps = await apiRequest(`${yelpAPI?.yelp}`, options);
        setReviewsData(response);
      } catch (error) {
        console.error('Error fetching reviewsData:', error);
      }
    };
    fetchReviews();
  }, []);

  const renderHeading = () => {
    return (
      <div className={headingWrapper()}>
        <div className={headingLeftContent()}>
          <NextImage
            className={yelpImage()}
            field={{
              value: {
                src: `/images/yelp.png`,
                height: '90',
                width: '184',
                alt: 'User Review',
              },
            }}
          />
          <div className={averageReviewWrapper()}>
            {reviewsData?.ReviewStarsImage && (
              <NextImage
                className={averageReviewImage()}
                field={{
                  value: {
                    src: `/images/${reviewsData?.ReviewStarsImage}`,
                    height: '30',
                    width: '162',
                    alt: reviewsData?.AverageRating?.toString(),
                  },
                }}
              />
            )}
            {reviewsData?.TotalReviews && fields?.data?.datasource?.yelpReviewsText?.value ? (
              <Text
                tag="p"
                className={reviewsNumber()}
                field={{
                  value: `${reviewsData?.TotalReviews} ${fields?.data?.datasource?.yelpReviewsText?.value}`,
                }}
              />
            ) : (
              ''
            )}
          </div>
        </div>
        {reviewsData?.ReviewUrl && fields?.data?.datasource?.writeReviewText?.value && (
          <LinkHelper
            field={{
              value: {
                href: reviewsData?.ReviewUrl,
                text: fields?.data?.datasource?.writeReviewText?.value,
                target: '_blank',
              },
            }}
            className={writeReview()}
          />
        )}
      </div>
    );
  };
  const renderReview = (review: Review) => {
    return (
      <li className={reviewWrapper()} key={review.id}>
        <div className={reviewLeftContentWrapper()}>
          <NextImage
            className={reviewImage()}
            field={{
              value: {
                src: `/images/${review?.ReviewStarsImage}`,
                height: '30',
                width: '162',
                alt: 'review-image',
              },
            }}
          />
          <Text field={{ value: review.ReviewerName }} tag="p" className={reviewAuthor()} />
          <Text field={{ value: review.ReviewDateString }} tag="p" className={reviewDate()} />
        </div>
        <div className={reviewRightContentWrapper()}>
          <Text tag="p" className={reviewText()} field={{ value: review.ReviewText }} />
          {fields?.data?.datasource?.moreText?.value && (
            <LinkHelper
              field={{
                value: {
                  href: review?.ReviewUrl || '',
                  text: fields?.data?.datasource?.moreText?.value,
                  target: '_blank',
                },
              }}
              className={reviewMoreLink()}
            />
          )}
          {fields?.data?.datasource?.wasThisReviewHelpful && (
            <Text
              tag="p"
              className={helpFulReviewText()}
              field={fields?.data?.datasource?.wasThisReviewHelpful}
            />
          )}
          <div className={buttonWrapper()}>
            {fields?.data?.datasource?.yesText?.value && (
              <LinkHelper
                field={{
                  value: {
                    href: review?.ReviewUrl || '',
                    text: fields?.data?.datasource?.yesText?.value,
                    target: '_blank',
                  },
                }}
                className={helpfulReviewButton()}
              />
            )}
            {fields?.data?.datasource?.noText?.value && (
              <LinkHelper
                field={{
                  value: {
                    href: review?.ReviewUrl || '',
                    text: fields?.data?.datasource?.noText?.value,
                    target: '_blank',
                  },
                }}
                className={helpfulReviewButton()}
              />
            )}
          </div>
        </div>
      </li>
    );
  };
  const renderReviews = () => {
    return (
      <ul className={reviewsWrapper()}>
        {reviewsData?.Reviews?.map((review: Review, index: number) => {
          return <React.Fragment key={index}>{renderReview(review)}</React.Fragment>;
        })}
        {reviewsData?.TotalReviews &&
        reviewsData?.ReviewUrl &&
        reviewsData?.Reviews?.length &&
        fields?.data?.datasource?.showMoreReviews?.value &&
        reviewsData?.TotalReviews > reviewsData?.Reviews?.length ? (
          <LinkHelper
            field={{
              value: {
                href: reviewsData?.ReviewUrl,
                text: fields?.data?.datasource?.showMoreReviews?.value,
                target: '_blank',
              },
            }}
            className={showMoreText()}
          />
        ) : (
          ''
        )}
      </ul>
    );
  };
  const {
    container,
    base,
    reviewWrapper,
    reviewLeftContentWrapper,
    reviewText,
    reviewMoreLink,
    helpFulReviewText,
    helpfulReviewButton,
    buttonWrapper,
    reviewRightContentWrapper,
    reviewsWrapper,
    reviewAuthor,
    reviewDate,
    reviewImage,
    yelpImage,
    averageReviewImage,
    headingLeftContent,
    showMoreText,
    writeReview,
    headingWrapper,
    reviewsNumber,
    averageReviewWrapper,
  } = YelpReviewVariants({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  useEffect(() => {
    if (!(reviewsData?.Reviews && reviewsData?.Reviews?.length > 0)) {
      HideLinkFromStoreNavigation('store-reviews');
    } else {
      ShowLinkFromStoreNavigation('store-reviews');
    }
  }, [reviewsData]);
  return (
    <>
      {reviewsData?.Reviews && reviewsData?.Reviews?.length > 0 && (
        <div className={container()} id="store-reviews">
          <div className={base()}>
            {renderHeading()}
            {renderReviews()}
          </div>
        </div>
      )}
    </>
  );
};

export default Yelp;
