import { createSlice } from '@reduxjs/toolkit';
import { OCController } from '@sitecore-search/react';
import { GlobalStoreSettings } from 'components/StoreInfo/StoreInfo';
import { StoreObjectData } from 'src/pages/api/store/get-store-details';

const initialState = {
  selectedStore: {} as StoreObjectData,
  globalStoreSettings: {} as GlobalStoreSettings,
};
export const storeDetailsSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStore: (state, action) => {
      if (action.payload?.storeId) {
        state.selectedStore = action.payload;
        updateOCProxyContext(state.selectedStore.storeId);
      }
    },
    setGlobalStoreSettings: (state, action) => {
      state.globalStoreSettings = action.payload;
    },
  },
});

export const { setStore, setGlobalStoreSettings } = storeDetailsSlice.actions;

export default storeDetailsSlice.reducer;

// // We need to set certain properties when we update the store so that the OC Proxy to Search can pass the correct data
function updateOCProxyContext(storeId: string) {
  const context = OCController.getContext();
  context.setSellerId(storeId);
  context.setRequiredInventoryLocations([`*-${storeId}`]);
}
