import { tv } from 'tailwind-variants';

export const faqContainerTailwindVariant = tv(
  {
    slots: {
      base: ['flex justify-center'],
      container: ['container'],
      top: ['flex', 'flex-col', 'gap-desk-margin-loose-right', 'pb-desk-margin-base-bottom'],
      heading: [''],
      title: [
        'font-primary',
        'text-color-text-black',
        'text-heading-desk-xLarge-bold',
        'font-heading-desk-xLarge-bold',
        'leading-heading-desk-xLarge-bold',
        'mb-desk-margin-micro-bottom',
      ],
      titleWithoutDescription: [
        'text-heading-desk-small-bold',
        'font-heading-desk-small-bold',
        'leading-heading-desk-small-bold',
      ],
      description: [
        'font-primary',
        'text-color-text-black',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      faqTabs: [
        'flex',
        'flex-wrap',
        'items-end',
        'self-stretch',
        'gap-desk-space-between-base-horizontal',
      ],
      faqAccordion: ['pt-desk-margin-base-bottom'],
    },
    variants: {
      size: {
        mobile: {
          container: ['my-mob-padding-base-y', 'mob-global-grid-margin'],
        },
        desktop: {
          container: ['my-desk-global-grid-margin'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
