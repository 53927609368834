import { apiRequest } from 'src/utils/apiWrapper';
import { petsApi } from 'src/utils/nextApiConfig';

export const REQUEST_METHOD = {
  get: 'GET',
  post: 'POST',
};

export const BASE_URL = process.env.NEXT_PUBLIC_API_ENDPOINT;

//set the gender details

export const setPetsGender = async () => {
  const options = {
    method: REQUEST_METHOD?.get,
  };
  try {
    const response = await apiRequest(petsApi.getGender, options);
    if (response) {
      return response;
    }
    return {};
  } catch (error) {
    console.error('@@PetsGenderError', error);
    return {};
  }
};
//set the species details
export const setPetsSpecies = async () => {
  const options = {
    method: REQUEST_METHOD?.post,
  };
  try {
    const response = await apiRequest(petsApi.getSpecies, options);
    if (response) {
      return response;
    }
    return {};
  } catch (error) {
    console.error('@@PetsSpeciesError', error);
    return {};
  }
};
