import { tv } from 'tailwind-variants';

export const storeInfoStoreDetailTailwindVariant = tv(
  {
    slots: {
      base: 'container mx-auto',
      tabsWrapper: 'container mx-auto flex justify-center items-center flex-wrap',
      heading: 'w-fit text-color-text-dark',
      subHeading: 'w-fit text-color-text-dark uppercase',
      headingWrapper: '',
      myStoreIcon:
        '[&>svg>*]:stroke-color-text-brand-1 [&>svg>*]:stroke-[3.5] h-[26px] w-[26px] [&_svg]:max-w-[26px] [&_svg]:max-h-[26px] mr-3',
      tabLink:
        'p-2 text-color-text-brand-1 text-body-large-bold font-body-large-bold leading-body-large-bold',
      storeDetailWrapper: '',
      storeText: 'text-color-text-brand-1 h-fit',
      iconWrapper: 'flex h-fit',
      icon: '[&_svg_path]:fill-text-color-text-brand-1 h-6 w-6 [&_svg]:max-w-6 [&_svg]:max-h-6 mr-3',
      sliderWrapper:
        ' [&_button]:h-6 [&_button]:w-6 [&_button]:bg-color-background-white [&_button]:!opacity-100 [&_svg]:!fill-color-background-brand-1 [&_svg]:!max-w-3 [&_svg]:!max-h-3',
      sliderImage: 'w-full object-cover',
      offerImage: 'w-full',
      rightContentWrapper: '',
      shopButton:
        'rounded-desk-global-radius-small bg-color-text-brand-1 h-fit w-fit hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark active:bg-color-brand-primary-1-dark',
      storeDetailHeading: 'font-primary text-color-text-dark',
      leftContentWrapper: 'flex flex-col',
      storeDetailLink:
        'hover:text-color-brand-tertiary-3-base group w-fit focus:text-color-brand-tertiary-3-base text-color-text-brand-1 text-body-large-bold font-body-large-bold leading-body-large-bold flex items-center',
      firstStoreDetailLink:
        'hover:text-color-brand-tertiary-3-base w-fit group focus:text-color-brand-tertiary-3-base block text-color-text-brand-1 text-body-large-bold font-body-large-bold leading-body-large-bold flex items-center',
      linkIcon:
        'w-4 h-4 [&_svg]:max-w-4 [&_svg]:max-h-4 group-hover:[&>svg>*]:fill-color-brand-tertiary-3-base group-focus:[&>svg>*]:fill-color-brand-tertiary-3-base [&>svg>*]:fill-color-text-brand-1',
      storeDetailDescription:
        'font-primary text-color-text-dark text-body-large-regular font-body-large-regular leading-body-large-regular',
    },
    variants: {
      size: {
        desktop: {
          linkIcon: 'ml-desk-space-between-micro-horizontal',
          shopButton:
            'mt-0 rounded-desk-global-radius-small text-color-text-white text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y ml-desk-margin-loose-right mb-0',
          firstStoreDetailLink: 'mb-desk-margin-tight-bottom',
          leftContentWrapper: 'gap-desk-margin-loose-bottom mb-0',
          storeDetailHeading:
            'mb-desk-margin-micro-bottom text-heading-desk-small-bold font-heading-desk-small-bold leading-heading-desk-small-bold',
          storeDetailDescription: '',
          rightContentWrapper: 'flex gap-desk-margin-base-right pr-desk-margin-base-right',
          offerImage: 'mx-0 max-w-[233px] min-w-[233px] mt-0 max-h-[312px] min-h-[312px]',
          base: 'py-desk-padding-tight-y',
          tabsWrapper: 'py-desk-padding-micro-y gap-desk-space-between-base-horizontal',
          sliderWrapper: 'w-full h-full max-w-[518px] max-h-[371px]',
          storeDetailWrapper: 'flex justify-between mt-desk-margin-loose-bottom',
          iconWrapper: 'mt-0 ml-desk-margin-loose-right mb-0 ',
          headingWrapper: 'flex flex-wrap items-center',
          sliderImage: 'min-w-[518px] min-h-[371px] max-w-[518px] max-h-[371px]',
          storeText:
            'font-primary text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold ',
          heading:
            'font-primary mb-0 text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold tracking-heading-desk-xLarge-bold',
          subHeading:
            'mt-desk-margin-tight-bottom w-full order-4 font-primary  text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold tracking-heading-desk-medium-bold',
        },
        mobile: {
          linkIcon: 'ml-mob-space-between-micro-horizontal',
          sliderImage: 'min-h-[337px] h-auto w-full max-h-[338px]',
          shopButton:
            'mt-mob-margin-loose-bottom rounded-mob-global-radius-small px-mob-component-button-condensed-padding-x py-mob-component-button-condensed-padding-y mb-mob-margin-loose-bottom text-color-text-white block text-body-large-bold font-body-large-bold leading-body-large-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y',
          firstStoreDetailLink: 'mb-mob-margin-tight-bottom',
          leftContentWrapper: 'mb-[52px] gap-mob-margin-loose-bottom',
          storeDetailHeading:
            'mb-mob-margin-micro-bottom text-heading-mob-small-bold font-heading-mob-small-bold leading-heading-mob-small-bold',
          storeDetailDescription: '',
          storeDetailWrapper: 'mt-mob-margin-loose-bottom',
          offerImage: 'max-h-[479px] mt-mob-margin-base-right',
          sliderWrapper: 'w-full',
          storeText:
            'text-color-text-brand-1 h-fit font-primary text-body-large-bold font-body-large-bold leading-body-large-bo',
          base: 'p-mob-padding-tight-y',
          tabsWrapper:
            'px-mob-global-grid-margin py-mob-padding-micro-y gap-mob-space-between-base-horizontal',
          heading:
            'font-primary mb-mob-margin-tight-bottom text-heading-mob-xLarge-bold font-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold tracking-heading-mob-xLarge-bold',
          iconWrapper: 'mt-mob-margin-loose-bottom mb-mob-margin-loose-bottom',
          subHeading:
            'font-primary  text-heading-mob-medium-bold font-heading-mob-medium-bold leading-heading-mob-medium-bold tracking-heading-mob-medium-bold',
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
