import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import Link from 'next/link';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { useOcSelector } from 'src/redux/ocStore';
import { quickLinksTailwindVariant } from 'tailwindVariants/components/quickLinksTailwindVariant';

type QuickLinksProps = {
  fields: {
    heading: Field<string>;
    displayName: string;
    links: {
      fields: {
        link: {
          value: {
            href: '';
          };
        };
      };
      name: string;
    }[];
  };
};

const { base, headingWrapper, heading, headingLinkWrapper, headingLink } =
  quickLinksTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

const PSPQuickLinks = ({ fields }: QuickLinksProps) => {
  const isLoyaltyAccepted = useOcSelector((state) => state?.ocUser?.user?.xp?.LoyaltyAccepted);

  if (isLoyaltyAccepted === true) {
    return (
      <div id="quick-links" className={base()}>
        {/* <pre>{JSON.stringify(fields, null, 4)}</pre> */}
        <div className={headingWrapper()}>
          <RichTextHelper className={heading()} field={fields?.heading} />
        </div>
        <ul>
          {fields?.links?.map((item, index) => (
            <li key={index} className={headingLinkWrapper()}>
              <Link
                aria-label="heading link"
                href={item?.fields?.link?.value?.href}
                className={headingLink()}
              >
                {item?.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return;
};

export default PSPQuickLinks;
