import { Text, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { useCookies } from 'react-cookie';
import { PSP } from '../../../models/PetSuppliesPlus.Model';
import { ComponentProps } from 'lib/component-props';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { cookiePopupTailwindVariant } from 'tailwindVariants/components/cookiePopupTailwindVariant';
export type CookiePopupProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.CookiePopup.Fields.CookiePopup;

/**
 * A simple Content Block component, with a heading and rich text block.
 * This is the most basic building block of a content site, and the most basic
 * JSS component that's useful.
 */
const CookiePopup = ({ fields }: CookiePopupProps) => {
  const dateLogic = () => {
    const currentDate = new Date(); // Current date
    const endDate = new Date(); // Adding days to the current date

    const formattedStartDate = currentDate.toISOString().split('T')[0];
    if (fields?.cookieExpiry?.value) {
      endDate.setDate(currentDate.getDate() + fields?.cookieExpiry?.value);
    } else {
      endDate.setDate(currentDate.getDate() + 0);
    }
    const formattedEndDate = endDate.toISOString().split('T')[0]; // Format the result in ISO 8601 format (YYYY-MM-DD)

    setCookie('cookieEndDate', formattedEndDate, { expires: endDate });
    setCookie('cookieStartDate', formattedStartDate, { expires: endDate });
  };

  const [cookies, setCookie] = useCookies([
    'cookieDurationDays',
    'cookieOverrideFlag',
    'cookieEndDate',
    'cookieStartDate',
    'storeId',
  ]);

  const [showCookie, setShowCookie] = useState(false);

  const { base, heading, content, title, closeIcon } = cookiePopupTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const modifyCookie = () => {
    dateLogic(); //sets start & end date for cookie expiration
    setShowCookie(false);
  };

  useEffect(() => {
    setCookie('cookieOverrideFlag', fields?.overrideCookieExpiry?.value);
    setCookie('cookieDurationDays', fields?.cookieExpiry?.value ? fields?.cookieExpiry?.value : 0);

    // multiple conditions to show CookiePopup:
    const shouldShowPopup =
      !cookies?.cookieEndDate ||
      cookies?.cookieDurationDays === 0 ||
      fields?.overrideCookieExpiry?.value || // as was undefined while fetching at first load
      new Date() > new Date(cookies?.cookieEndDate);

    if (shouldShowPopup) {
      setShowCookie(true);
    }
  }, [fields?.cookieExpiry?.value, fields?.overrideCookieExpiry?.value]);

  const checkFields = fields?.popupContentText && fields?.popupTitle;

  const isEE = useExperienceEditor();
  if (isEE) {
    return <></>;
  }

  return (
    <>
      {showCookie && checkFields && (
        <div className={base()}>
          <div className={heading()}>
            <Text tag="h6" className={title()} field={fields?.popupTitle} />
            <button aria-label="close privacy modal" onClick={modifyCookie}>
              <IconHelper icon="close" className={closeIcon()} />
            </button>
          </div>
          <RichText className={content()} field={fields?.popupContentText} />
        </div>
      )}
    </>
  );
};
// export default withDatasourceCheck()(CookiePopup);
export default CookiePopup;
