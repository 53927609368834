import { tv } from 'tailwind-variants';

export const storeTailwindVariant = tv(
  {
    slots: {
      base: 'flex flex-col',
      markerWrapper: 'relative h-[40px] w-[40px] cursor-pointer',
      serviceList: 'font-primary text-[18px] font-[400] text-color-text-black leading-[28px]',
      loadMoreButton: 'w-full flex justify-center',
      storesWrapper: 'flex-col flex',
      mainWrapper: '',
      storeText: 'text-color-text-brand-1 h-fit ',
      storeAddress:
        'hover:no-underline font-primary text-heading-desk-large-regular leading-heading-desk-large-regular font-heading-desk-large-regular',
      shopButton:
        'rounded-desk-global-radius-small flex justify-center min-w-[221px] bg-color-text-brand-1 h-fit hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark active:bg-color-brand-primary-1-dark',
      loadMore:
        'rounded-desk-global-radius-small bg-color-text-brand-1 h-fit w-fit hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark active:bg-color-brand-primary-1-dark text-body-large-bold leading-body-large-bold font-body-large-bold',
      storeDetailWrapper: 'flex w-full',
      storeActionWrapper: 'flex flex-col ',
      buttonWrapper: 'flex flex-col ',
      openStoreTimingText:
        'w-max text-heading-desk-medium-regular leading-heading-desk-medium-regular font-heading-desk-medium-regular',
      resultText: 'font-primary inline-block',
      resultTextBold: 'font-primary',
      storeDetails: 'flex flex-col',
      storeAddressWrapper: '',
      iconWrapper: 'flex h-fit',
      storePawImage: 'min-h-[40px] min-w-[40px] max-h-[40px] max-w-[40px]',
      buttonWithArrow:
        'flex items-center text-heading-desk-medium-bold font-heading-desk-medium-bold font-primary leading-heading-desk-medium-bold hover:text-color-brand-primary-1-base focus:text-color-brand-primary-1-base',
      storeName:
        'cursor-pointer font-primary text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
      storeWrapper:
        'bg-color-background-white hover:bg-color-store-hover-background focus:bg-color-store-hover-background w-full flex border-b border-b-color-scale-5-mid border-solid	',
      imageLabel:
        'font-primary absolute left-[49%] -translate-x-[75%] text-white bottom-[2px] text-[14px] font-[700]',
      storePageArrow:
        'ml-1 [&>svg>*]:stroke-color-text-brand-1 [&>svg>path]:fill-color-text-brand-1 h-[16px] w-[16px] [&_svg]:max-w-[16px] [&_svg]:max-h-[16px]',
      myStoreIcon:
        '[&>svg>*]:stroke-color-text-brand-1 [&>svg>*]:stroke-[3.5] h-[26px] w-[26px] [&_svg]:max-w-[26px] max-w-[26px] [&_svg]:max-h-[26px] max-h-[26px] mr-3',
    },
    variants: {
      size: {
        desktop: {
          storesWrapper: 'gap-desk-margin-loose-bottom',
          base: 'gap-desk-margin-base-bottom',
          iconWrapper: 'mt-0 min-w-[221px] justify-end mb-0 ',
          shopButton:
            'mt-0 w-fit max-w-max min-w-max rounded-desk-global-radius-small text-color-text-white text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y mb-0',
          loadMore:
            'mt-0 rounded-desk-global-radius-small text-color-text-white px-desk-component-button-condensed-padding-x py-desk-component-button-condensed-padding-y mb-0',
          storeDetails: 'gap-desk-margin-tight-bottom',
          storeText:
            'font-primary text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold ',
          storeAddressWrapper: 'mt-3',
          buttonWrapper: 'gap-desk-margin-base-bottom items-end',
          resultText:
            'inline-block text-heading-desk-large-regular font-heading-desk-large-regular leading-heading-desk-large-regular',
          resultTextBold:
            'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
          storeActionWrapper: 'mt-0 gap-desk-space-between-base-vertical items-end',
          storeDetailWrapper: 'justify-between flex-row gap-desk-margin-tight-bottom',
          storeWrapper:
            'flex-row rounded-desk-global-radius-medium gap-desk-margin-base-right py-desk-padding-micro-y px-desk-padding-micro-x',
        },
        mobile: {
          storesWrapper: 'gap-mob-margin-loose-bottom',
          storeDetails: 'gap-mob-margin-tight-bottom',
          base: 'gap-mob-margin-base-bottom',
          buttonWrapper: 'gap-mob-margin-base-bottom',
          storeAddressWrapper: 'mt-3',
          storeActionWrapper: 'mt-4 gap-mob-space-between-base-vertical -mt-2',
          iconWrapper: 'mt-mob-margin-loose-bottom mb-mob-margin-loose-bottom',
          shopButton:
            'rounded-mob-global-radius-small px-mob-component-button-condensed-padding-x py-mob-component-button-full-padding-y text-color-text-white block text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold px-desk-component-button-full-padding-x py-desk-component-button-full-padding-y w-full',
          loadMore:
            'rounded-mob-global-radius-small text-color-text-white block px-mob-component-button-full-padding-x py-mob-component-button-full-padding-y',
          storeText:
            'text-color-text-brand-1 h-fit font-primary text-body-large-bold font-body-large-bold leading-body-large-bo',
          resultText: 'hidden',
          resultTextBold:
            'text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold',
          storeDetailWrapper: 'flex-col gap-mob-margin-tight-bottom',
          storeWrapper:
            'flex-col rounded-mob-global-radius-medium gap-mob-margin-base-right py-mob-padding-micro-x px-mob-padding-micro-x',
        },
      },
      isCareerVariant: {
        true: { storeDetails: 'w-full' },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
