import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { PSP } from '../../../models/PetSuppliesPlus.Model';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { ComponentProps } from 'lib/component-props';
import React from 'react';
import { anchorJumpLinkTailwindVariant } from 'tailwindVariants/components/anchorJumpLinkTailwindVariant';

export type AnchorJumpLinkProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.Anchor.Fields.AnchorJump;

const { base, wrapper } = anchorJumpLinkTailwindVariant({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

// Main AnchorJumpLink component

const AnchorJumpLink: React.FC<AnchorJumpLinkProps> = ({ fields, rendering }) => {
  const isEE = useExperienceEditor();

  if (isEE) {
    return (
      <div className={base()}>
        <div className={wrapper()}>
          This is {rendering.componentName} Component <Text field={fields?.AnchorJumpText} />
        </div>
      </div>
    );
  }
  if (fields === null || fields === undefined) return <></>;

  return <div className={base()} id={fields?.AnchorJumpText?.value}></div>;
};

export const Default = (props: AnchorJumpLinkProps): JSX.Element => {
  return <AnchorJumpLink {...props} />;
};
