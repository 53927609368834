//global
import React from 'react';

//lib
import Head from 'next/head';
import { BazaarVoiceScript } from 'src/helpers/BazaarVoice/BazaarVoiceScript';

//main component
const BVContainer = () => {
  return (
    <>
      <Head>
        <link rel="canonical" href="/bvcontainer" />
        <meta name="bv:pageType" content="container" />
      </Head>
      <BazaarVoiceScript />
    </>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default BVContainer;
