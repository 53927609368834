import React, { useEffect, useState } from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import AutoCompleteWrapper from 'src/helpers/Form/AutoCompleteWrapper';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { useBreakpoints } from 'src/utils/breakpoints';
import Store from './Store';
import { StoreData, StoreLocatorConfig, StoreProps } from './GenericStoreLocator';
import GoogleMap from './GoogleMap';
import Loader from 'components/Loader/Loader';
import { sendStoreGTMData } from 'src/utils/sendGTMEvent';
import { GTM_EVENT, GTMLabels } from 'src/helpers/Constants';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { storeLocatorPageTailwindVariant } from 'tailwindVariants/components/storeLocatorPageTailwindVariant';

const StoreLocatorPage = ({
  fields,
  options,
  handleSelect,
  address,
  setAddress,
  selectedSuggestions,
  stores,
  coordinates,
  isStoreLoading,
  setIsErrorInStoreData,
  isErrorInStoreData,
  totalStores,
  onLoadMoreClick,
  isCareerVariant,
}: {
  fields: StoreLocatorConfig;
  isErrorInStoreData: boolean;
  setIsErrorInStoreData: (value: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any;
  handleSelect: (value: string) => void;
  address: string;
  setAddress: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedSuggestions: any;
  stores: StoreProps[];
  coordinates: { lat: number; lng: number };
  isStoreLoading: boolean;
  totalStores: number;
  onLoadMoreClick: () => void;
  isCareerVariant?: boolean;
}) => {
  const [activeMarker, setActiveMarker] = useState<StoreData>({});
  const { isMobile }: { isMobile: boolean } = useBreakpoints();
  const {
    base,
    headingWrapper,
    formLabel,
    subHeadingWrapper,
    heading,
    resultText,
    noSearchResultsFoundText,
    input,
    searchIcon,
    searchButton,
    loadingTextWrapper,
    loadingText,
    loadMoreButton,
    loadMore,
    headingSmall,
  } = storeLocatorPageTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const getStoreName = (location: string) => {
    if (location?.length > 0) {
      return location || '';
    } else {
      return `${stores?.[0]?.city}, ${stores?.[0]?.state}` || '';
    }
  };

  //sending GTM data regarding to Store not found error
  useEffect(() => {
    if (
      isErrorInStoreData &&
      !(stores?.length > 0 && selectedSuggestions?.Keywords?.length > 0 && isStoreLoading)
    ) {
      sendStoreGTMData({ eventName: GTM_EVENT?.storeLocator });
      sendStoreGTMData({ eventName: GTM_EVENT?.storeLocatorError, error: GTMLabels?.storeError });
    }
  }, [isErrorInStoreData]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const renderForm = (onAutoSuggestionSubmit: any) => (
    <>
      <form className="relative">
        <input
          aria-label="google-auto-suggestion-address"
          name="google-auto-suggestion-address"
          id="google-auto-suggestion-address"
          value={address}
          placeholder={fields?.searchboxPlaceholderText?.value}
          className={input({ isError: isErrorInStoreData })}
          onChange={(e) => {
            setAddress(e?.target?.value);
            setIsErrorInStoreData(false);
          }}
        />
        <button
          aria-label="search"
          onClick={async (e) => {
            e.preventDefault();
            const inputElement = document.getElementById(
              'google-auto-suggestion-address'
            ) as HTMLInputElement;
            if (inputElement) {
              const inputValue = inputElement?.value;
              setAddress(inputValue);
              setIsErrorInStoreData(false);
              if (inputValue) {
                handleSelect(await onAutoSuggestionSubmit(inputValue));
              }
            }
          }}
          className={searchButton()}
        >
          <IconHelper className={searchIcon()} icon={'search'} />
        </button>
      </form>
      {isErrorInStoreData && (
        <>
          {fields?.noSearchResultsFoundText && (
            <RichText
              tag="p"
              field={fields?.noSearchResultsFoundText}
              className={noSearchResultsFoundText()}
            />
          )}
        </>
      )}
    </>
  );
  return (
    <>
      <div className={`storeLocator container`}>
        <div className={base()}>
          <div className="flex flex-col">
            <div className={headingWrapper()}>
              <RichText
                tag="p"
                class={isCareerVariant ? headingSmall() : heading()}
                field={fields?.headerText}
              />
              <div className={subHeadingWrapper()}>
                <Text tag="p" className={formLabel()} field={fields?.searchboxText} />
                <AutoCompleteWrapper
                  options={options}
                  addressLine1={address}
                  hideSuggestions={address?.length < Number(fields?.minCharactersForSearch?.value)}
                  onHandleChange={handleSelect}
                >
                  {/*  eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                  {({ onAutoSuggestionSubmit }: any) => {
                    return renderForm(onAutoSuggestionSubmit);
                  }}
                </AutoCompleteWrapper>
              </div>
              {fields?.noofItemsPerPageText && stores?.length > 0 && (
                <RichTextHelper
                  tag="p"
                  field={fields?.noofItemsPerPageText}
                  updatedField={{
                    value: fields?.noofItemsPerPageText?.value
                      ?.replace(
                        '{location}',
                        `<strong>&nbsp;${getStoreName(
                          selectedSuggestions?.Keywords
                        )}&nbsp;</strong>`
                      )
                      ?.replace('{number}', `<strong>&nbsp;${stores?.length}&nbsp;</strong>`),
                  }}
                  className={resultText()}
                />
              )}
              {isMobile && (
                <GoogleMap
                  fields={fields}
                  coordinates={coordinates}
                  activeMarker={activeMarker}
                  setActiveMarker={setActiveMarker}
                  storeCoordinates={stores?.map((store) => {
                    return {
                      storeid: store?.storeid,
                      LatPos: +store?.xp_latitude,
                      LngPos: +store?.xp_longitude,
                    };
                  })}
                />
              )}

              {stores?.length > 0 && (
                <>
                  <Store
                    isCareerVariant={isCareerVariant}
                    getStoreName={getStoreName}
                    stores={stores}
                    setActiveMarker={setActiveMarker}
                    location={selectedSuggestions?.Keywords}
                    fields={fields || {}}
                  />
                </>
              )}
              {isStoreLoading && (
                <div className={loadingTextWrapper()}>
                  <Loader />
                  <RichText
                    tag="p"
                    field={fields?.loadingNearbyStoresText}
                    className={loadingText()}
                  />
                </div>
              )}
            </div>
            {stores?.length > 0 &&
              fields?.loadMoreButtonText?.value &&
              totalStores > stores?.length && (
                <div className={loadMoreButton()}>
                  <button aria-label="load more" onClick={onLoadMoreClick} className={loadMore()}>
                    {fields?.loadMoreButtonText?.value}
                  </button>
                </div>
              )}
          </div>
          {!isMobile && (
            <GoogleMap
              coordinates={coordinates}
              fields={fields || {}}
              activeMarker={activeMarker}
              setActiveMarker={setActiveMarker}
              storeCoordinates={stores?.map((store) => {
                return {
                  storeid: store?.storeid,
                  LatPos: +store?.xp_latitude,
                  LngPos: +store?.xp_longitude,
                };
              })}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default StoreLocatorPage;
