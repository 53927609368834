//global
import React, { useEffect } from 'react';
// import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
//local

//lib
import { ComponentProps } from 'lib/component-props';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { useOcSelector } from 'src/redux/ocStore';
import { localOffersTailwindVariant } from 'tailwindVariants/components/localOffersTailwindVariant';
//type
export type LocalOffersProps = ComponentProps & {
  fields: unknown;
};

//main component
const LocalOffers: React.FC<LocalOffersProps> = ({ params }) => {
  const { base } = localOffersTailwindVariant({ size: { initial: 'mobile', lg: 'desktop' } });
  //store data
  const storeData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  //if no data then returned empty fragment
  const PublicationLinkArr = storeData?.zmaglink?.split('/');
  const PublicationID = PublicationLinkArr && PublicationLinkArr[4];
  useEffect(() => {
    // Dynamically import the viewer.js script
    const script = document.createElement('script');
    script.src = 'https://secure.api.viewer.zmags.com/viewer/viewer.js';
    script.async = true;

    script.onload = () => {
      const ele = document?.getElementById('localOffers');
      ele?.contains(ele?.getElementsByTagName('div')[0]) &&
        ele?.removeChild(ele?.getElementsByTagName('div')[0]);
      // Initialize the viewer once script is loaded
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const viewer = new (window as any).com.zmags.api.Viewer();
      viewer.setPublicationID(PublicationID);
      viewer.setParentElementID('localOffers');
      viewer.show();
    };
    // Append the script to the document body
    document.body.appendChild(script);
    // remove the script when component is unmounted
    return () => {
      document?.body?.contains(script) && document.body.removeChild(script);
    };
  }, [PublicationID]);
  // if (fields === undefined || fields === null) return <></>;
  const isEE = useExperienceEditor();
  return (
    <div className={base({ className: params?.Style ?? '' })} id={'localOffers'}>
      {isEE ? <span>Local Offers copmonent will be visible on Front facing site.</span> : ''}
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default LocalOffers;
