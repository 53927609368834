//global
import React, { useEffect } from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';

//local
import { PSP } from 'models/PetSuppliesPlus.Model';
import { useOcSelector } from 'src/redux/ocStore';

//lib
import { ComponentProps } from 'lib/component-props';

//helpers
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import ButtonHelper from 'src/helpers/commonComponents/Button';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import {
  // formatDateForGTM,
  getPromotionObject,
  // notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'src/helpers/Constants';
import { useBreakpoints } from 'src/utils/breakpoints';
import clsx from 'clsx';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { useRouter } from 'next/router';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { titleBannerWithImageAndLinkTailwindVariants } from 'tailwindVariants/components/titleBannerWithImageAndLinkTailwindVariant';

//type
export type TitleBannerWithImageAndLinkProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.TitleBannerWithImageAndLink.Fields.TitleBannerWithImageAndLink;

/**
 * Representing a Main component
 */
const TitleBannerWithImageAndLink: React.FC<TitleBannerWithImageAndLinkProps> = ({
  fields,
  params,
}) => {
  const { base, imgWrapper, img, title, description, ctaButton, link } =
    titleBannerWithImageAndLinkTailwindVariants({
      size: { initial: 'mobile', lg: 'desktop' },
    });

  //Getting current Store ID
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const { deviceName }: { deviceName: string } = useBreakpoints();
  const isEE = useExperienceEditor();
  const { push } = useRouter();

  // Promotion GTM data Object
  const gtmPromotion = getPromotionObject(fields, deviceName);

  //Promotion GTM data push on load
  useEffect(() => {
    if (myStoreId && fields?.componentName?.value !== '') {
      trackObjectForPromotion(GTM_EVENT?.viewPromotion, myStoreId, gtmPromotion);
    }
  }, [myStoreId]);

  //handing button click
  const handleButtonClick = () => {
    push(fields?.link?.value?.href as string);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const bg = (fields as any)?.backgroundColor?.fields?.Value?.value;

  if (fields === undefined || fields === null) return <></>;

  return (
    <div className={'titleBannerWithImageAndLink'}>
      <div
        className={base({
          className: `${params?.Styles ?? ''} ${isEE ? `bg-[${bg}]` : ''} `,
        })}
        style={{
          backgroundColor: bg,
        }}
        data-component={'components/TitleBannerWithImageAndLink/TitleBannerWithImageAndLink'}
        data-creative-id={fields?.componentName?.value}
        data-promotion-cta={fields?.cTAButtonCopy?.value}
        data-promo-id={fields?.promotionId?.value}
        data-promotion-name={fields?.promotionName?.value}
        data-promotion-copy={fields?.promotionCopy?.value}
        dta-promotion-url={fields?.promotionURL?.value?.href}
        onClick={() => {
          if (myStoreId && fields?.componentName?.value !== '') {
            trackObjectForPromotion(GTM_EVENT?.selectPromotion, myStoreId, gtmPromotion);
          }
        }}
      >
        <LinkHelper field={fields?.link} className={link()} hideLinkInEE={true}>
          <div className={imgWrapper()}>
            <ImageHelper field={fields?.image} className={img()} />
            <RichTextHelper
              tag={'p'}
              field={fields?.title}
              className={clsx(title(), isEE ? 'text-black' : '')}
            />
          </div>
          <div className={description()}>
            <RichTextHelper
              field={fields?.description}
              className={clsx(description(), isEE ? 'text-black' : '')}
            />
          </div>
          <ButtonHelper
            field={fields?.link}
            variant={'secondary'}
            isLinkField={true}
            size="medium"
            asButton={true}
            buttonClickHandler={() => {
              handleButtonClick();
            }}
            className={ctaButton({ className: 'whitespace-nowrap' })}
          />
        </LinkHelper>
      </div>
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<TitleBannerWithImageAndLinkProps>(TitleBannerWithImageAndLink);
