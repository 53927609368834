import { useOcSelector } from 'src/redux/ocStore';
import { ProductSearchResultModel } from './types';

export type InventoryStatus = 'InStock' | 'LowStock' | 'OutOfStock';

export function useProductInventory(product: ProductSearchResultModel): InventoryStatus {
  const store = useOcSelector((state) => state.storeReducer.selectedStore);

  const quantityAvailable =
    product.supplier_inventory ??
    product.inventoryrecords?.find((x) => x.ownerid === store.storeId)?.quantityavailable ??
    0;

  const lowStock = store?.deliveryFee?.lowStock ? Math.max(store.deliveryFee.lowStock - 1, 1) : 1;

  if (quantityAvailable > lowStock) {
    return 'InStock';
  }
  if (quantityAvailable > 0) {
    return 'LowStock';
  }
  return 'OutOfStock';
}
