import { tv } from 'tailwind-variants';

export const meetTeamStoreDetailTailwindVariant = tv(
  {
    slots: {
      teamImage: 'w-full h-auto aspect-[1/1]	',
      base: 'container',
      teamWrapper: 'bg-color-scale-2-light',
      teamMembersContentWrapper: 'bg-color-scale-2-light',
      teamMembersWrapper: '',
      teamImageWrapper: 'w-full flex justify-center',
      teamMemberDescription:
        'font-primary text-body-large-regular font-body-large-regular leading-body-large-regular text-color-scale-8-dark',
      teamMemberTitle:
        'font-primary text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold text-color-scale-8-dark',
      teamWrapperDescription:
        'font-primary font-body-large-regular text-body-large-regular leading-body-large-regular text-color-text-dark',
      teamWrapperTitle: 'text-color-text-dark',
      teamMemberWrapper:
        'w-full text-center flex [&_img]:rounded-desk-global-radius-pill [&_img]:object-cover items-center flex-col ',
    },
    variants: {
      size: {
        desktop: {
          base: 'py-desk-padding-tight-y',
          teamWrapperTitle:
            'font-primary font-heading-desk-xLarge-bold text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold',
          teamWrapperDescription:
            'mt-3 font-primary font-body-large-regular text-body-large-regular leading-body-large-regular',
          teamWrapper: 'pt-desk-padding-tight-y pb-0 text-left',
          teamMembersContentWrapper: 'py-desk-padding-tight-y',
          teamMembersWrapper: 'grid gap-desk-global-grid-gutter',
          teamImageWrapper: 'max-w-full',
          teamMemberWrapper:
            ' mb-0 pb-desk-margin-micro-bottom px-[11px] pt-0 gap-desk-margin-tight-bottom',
        },
        mobile: {
          teamMembersWrapper: 'flex flex-col gap-mob-margin-loose-bottom',
          teamWrapperDescription: 'mt-2',
          teamImageWrapper: 'max-w-[140px]',
          teamWrapper: 'py-mob-padding-tight-y text-center',
          teamWrapperTitle:
            'font-primary font-heading-mob-xLarge-bold text-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold',
          teamMembersContentWrapper: 'py-mob-padding-tight-y',
          teamMemberWrapper:
            'gap-mob-margin-tight-bottom mb-[8px] py-mob-padding-tight-y px-mob-global-grid-margin',
        },
      },
      theme: {
        psp: {
          base: '',
          teamMembersWrapper: 'lg:grid-cols-4',
          teamWrapper: 'lg:px-desk-global-grid-margin',
          teamMembersContentWrapper: ' lg:px-desk-global-grid-margin',
        },
        wnw: {
          base: 'bg-transparent lg:!py-0 [&_*]:bg-transparent',
          teamMembersWrapper: 'lg:grid-cols-3',
          teamImage: 'max-h-[240px] max-w-[240px]',
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
