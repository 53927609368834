import { tv } from 'tailwind-variants';

// TV Stylings to pass into the modal:
export const newsletterModalTailwindVariant = tv(
  {
    slots: {
      contentWrapperM: 'flex flex-col items-start self-stretch',
      titleM: '',
      descriptionM: '',
    },
    compoundSlots: [
      {
        slots: ['titleM', 'descriptionM'],
        class: ['font-primary', 'w-full', 'text-color-text-dark'],
      },
    ],
    variants: {
      size: {
        small: {
          contentWrapperM: 'gap-mob-space-between-tight-vertical',
          titleM:
            'text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold',
        },
        large: {
          contentWrapperM: 'gap-desk-space-between-tight-vertical',
          titleM:
            'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
