import React, { useContext } from 'react';
import {
  ComponentParams,
  ComponentRendering,
  Placeholder,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';
import ComponentContext from 'lib/context/ComponentContext';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';

const BACKGROUND_REG_EXP = new RegExp(
  /[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/gi
);

interface ComponentProps {
  rendering: ComponentRendering & { params: ComponentParams };
  params: ComponentParams;
}

const containerVariants = tv(
  {
    slots: {
      containerClasses: ['container', 'max-w-[1024px]', 'bg-color-background-light'],
      overlayContainerClasses: ['container', 'max-w-[1024px]', 'px-0'],
    },
    variants: {
      device: {
        mobile: {
          containerClasses: [
            'py-mob-padding-tight-y',
            'px-mob-padding-tight-x',
            'mb-mob-padding-tight-y',
          ],
        },
        desktop: {
          containerClasses: [
            'py-desk-padding-tight-y',
            'px-desk-padding-tight-x',
            'mb-desk-padding-tight-y',
          ],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

const DefaultContainer = (props: ComponentProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const containerStyles = props.params && props.params.Styles ? props.params.Styles : '';
  const styles = `${props.params.GridParameters} ${containerStyles}`.trimEnd();
  const phKey = `container-${props.params.DynamicPlaceholderId}`;
  const id = props.params.RenderingIdentifier;
  let backgroundImage = props.params.BackgroundImage as string;
  let backgroundStyle: { [key: string]: string } = {};

  if (backgroundImage) {
    const prefix = `${sitecoreContext.pageState !== 'normal' ? '/sitecore/shell' : ''}/-/media/`;
    backgroundImage = `${backgroundImage?.match(BACKGROUND_REG_EXP)?.pop()?.replace(/-/gi, '')}`;
    backgroundStyle = {
      backgroundImage: `url('${prefix}${backgroundImage}')`,
    };
  }

  return (
    <div className={`${props?.params?.Styles ?? ''}`}>
      <div className={`component container-default ${styles}`} id={id ? id : undefined}>
        <div className="component-content" style={backgroundStyle}>
          <div className="row">
            <Placeholder name={phKey} rendering={props.rendering} />
          </div>
        </div>
      </div>
    </div>
  );
};

const OverlayContainer = (props: ComponentProps): JSX.Element => {
  const phKey = `container-${props.params.DynamicPlaceholderId}`;
  const id = props.params.RenderingIdentifier;
  const containerStyles = props.params && props.params.Styles ? props.params.Styles : '';
  const styles = `${props.params.GridParameters} ${containerStyles}`.trimEnd();

  return (
    <div className={`component container-default ${styles}`} id={id ? id : undefined}>
      <div className="component-content">
        <div className="row flex flex-col gap-mob-margin-loose-bottom lg:gap-desk-margin-loose-bottom">
          <Placeholder name={phKey} rendering={props.rendering} />
        </div>
      </div>
    </div>
  );
};

export const Default = (props: ComponentProps): JSX.Element => {
  const splitStyles = props.params?.Styles?.split(' ');
  const overlayStyle = props.params?.Styles;
  const { containerClasses, overlayContainerClasses } = containerVariants({
    device: { initial: 'mobile', lg: 'desktop' },
  });

  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);

  // handle modal Close functionality:
  const handleModalClose = () => {
    setcomponentContextData({ ...componentContextData, ToggleLoginOverlayForm: false });
    // setAfterSubmit(false);
  };
  if (splitStyles && splitStyles.includes('container')) {
    return (
      <div className="container-wrapper">
        <DefaultContainer {...props} />
      </div>
    );
  }

  if (splitStyles && splitStyles.includes('login-wrapper')) {
    return (
      <div className={containerClasses()}>
        <DefaultContainer {...props} />
      </div>
    );
  }

  if (overlayStyle && overlayStyle == 'login-overlay') {
    return (
      <ModalWrapper
        showCloseButtonInModalHeader={false}
        closeModalOnOverlayClick={true}
        onCloseClick={handleModalClose}
        showModal={componentContextData?.ToggleLoginOverlayForm}
        customPopup={true}
        popupSpacing="py-mob-padding-tight-y px-mob-padding-tight-x lg:py-desk-padding-tight-y lg:px-desk-padding-tight-x"
      >
        {componentContextData?.ToggleLoginOverlayForm ? (
          <div className={overlayContainerClasses()}>
            <OverlayContainer {...props} />
          </div>
        ) : null}
      </ModalWrapper>
    );
  }

  return <DefaultContainer {...props} />;
};
