import { tv } from 'tailwind-variants';

//component variants
export const multiColumnItemTailwindVariant = tv(
  {
    slots: {
      base: ['flex', 'flex-col', 'text-center', 'relative'],
      baseWithAndWithoutCTA: [' '],
      imageWrapper: ['flex justify-center'],
      imageWrapperWithCTA: [
        'rounded-full',
        'shadow-multiColumnItem2',
        'border-4',
        'border-color-background-white',
      ],
      imageWrapperDoubleCTA: [
        '[&>img]:w-full',
        '[&>img]:duration-100',
        'hover:scale-[1.01]',
        'hover:shadow-multiColumnItem',
      ],
      image: [],
      roundImage: ['rounded-full '],
      content: ['flex', 'flex-col'],
      contentDoubleCTA: ['flex', 'flex-col', 'h-full'],
      primaryCta: ['duration-300'],
      secondaryCta: ['duration-300'],
      ImageContentWrapper: [
        'flex',
        'flex-col',
        'h-full',
        'bg-color-background-light',
        'hover:bg-color-background-white',
        'p-[1px]',
        'duration-100',
        'hover:scale-[1.01]',
        'hover:shadow-multiColumnItem',
      ],
      title: [
        'text-color-scale-8-dark',
        'text-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
        'font-heading-desk-large-bold',
      ],
      subHeading: ['text-color-scale-7-mid-dark'],
      titleWithoutCTA: [
        'text-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
        'font-heading-desk-large-bold',
      ],
      titleDoubleCTA: [
        'text-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
        'font-heading-desk-large-bold',
      ],

      description: [
        'text-color-scale-8-dark',
        'text-body-large-regular',
        'leading-body-large-regular',
        'font-body-large-regular',
      ],
      ButtonHelperWithCTA: [
        '!p-0',
        'before:content-[""]',
        'before:absolute',
        'before:w-full',
        'before:h-full',
        'before:top-0',
        'before:left-0',
      ],
      buttonWrapperWithCTA: ['flex', 'justify-center'],
      buttonWrapperDoubleCTA: [
        'flex',
        'justify-center',
        'flex-col',
        'mt-auto',
        'gap-desk-global-spacing-spacing-3',
      ],
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        mobile: {
          base: ['gap-desk-global-grid-gutter'],
          baseWithAndWithoutCTA: ['px-mob-padding-tight-y', 'py-mob-global-grid-margin'],

          content: ['gap-mob-margin-tight-bottom'],
          contentDoubleCTA: [
            'py-mob-padding-micro-y',
            'px-mob-padding-micro-x',
            'gap-mob-margin-tight-bottom',
          ],

          subHeading: [
            'text-heading-mob-medium-bold',
            'leading-heading-mob-medium-bold',
            'font-heading-mob-medium-bold',
          ],
          description: ['mb-mob-margin-micro-bottom'],
        },

        desktop: {
          base: ['gap-desk-global-grid-gutter'],
          baseWithAndWithoutCTA: ['px-desk-padding-micro-x', 'py-desk-padding-none'],
          content: ['gap-desk-margin-tight-bottom'],
          contentDoubleCTA: [
            'py-desk-padding-micro-y',
            'px-desk-padding-micro-x',
            'gap-desk-margin-tight-bottom',
          ],
          subHeading: [
            'text-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
          ],
          description: ['mb-desk-margin-micro-bottom'],
        },
      },
      backgroundTheme: {
        Green: {
          primaryCta: '',
          secondaryCta:
            '!border-color-brand-primary-1-base hover:!border-color-brand-primary-1-light hover:!bg-color-brand-primary-1-light !text-color-brand-primary-1-base hover:!text-color-text-white',
          titleDoubleCTA: 'text-color-brand-primary-1-base',
          titleWithoutCTA: 'text-color-brand-primary-1-base',
        },
        Red: {
          primaryCta: '!bg-color-accent-red hover: !bg-[#df1218]',
          secondaryCta:
            '!border-color-accent-red hover:!border-color-accent-light-red hover:!bg-color-accent-light-red !text-color-accent-red hover:!text-color-text-white',
          titleDoubleCTA: 'text-color-accent-red',
          titleWithoutCTA: 'text-color-accent-red',
        },
        Blue: {
          primaryCta: '!bg-color-accent-blue hover:!bg-[#0085b5]',
          secondaryCta:
            '!border-color-accent-blue hover:!border-color-accent-light-blue hover:!bg-color-accent-light-blue !text-color-accent-blue hover:!text-color-text-white',
          titleDoubleCTA: 'text-color-accent-blue',
          titleWithoutCTA: 'text-color-accent-blue',
        },
        Purple: {
          primaryCta: '!bg-color-accent-purple hover:!bg-[#7f2d91]',
          secondaryCta:
            '!border-color-accent-purple hover:!border-color-accent-quote-purple hover:!bg-color-accent-quote-purple !text-color-accent-purple hover:!text-color-text-white',
          titleDoubleCTA: 'text-color-accent-purple',
          titleWithoutCTA: 'text-color-accent-purple',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
