//global
import React from 'react';
import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
//local

//lib
import { ComponentProps } from 'lib/component-props';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import { headingTailwindVariant } from 'tailwindVariants/components/headingTailwindVariant';
//type
export type HeadingProps = ComponentProps & {
  fields: unknown;
};
//component variants

//main component
const Heading: React.FC<HeadingProps> = ({ params }) => {
  const { base, title } = headingTailwindVariant({ size: { initial: 'mobile', lg: 'desktop' } });
  const siteContext = useSitecoreContext();

  return (
    <div className={base({ className: params?.Style ?? '' })}>
      <TextHelper
        field={siteContext?.sitecoreContext?.route?.fields?.heading as Field<string>}
        tag="p"
        className={title()}
      />
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default Heading;
