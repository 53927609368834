import React from 'react';
import Link from 'next/link';
import { breadcrumbVariants } from 'tailwindVariants/components/breadcrumbTailwindVariant';
import clsx from 'clsx';
import { useBreakpoints } from 'src/utils/breakpoints';
import IconHelper from 'src/helpers/commonComponents/IconHelper';

interface BreadcrumbItem {
  breadcrumbTitle?: { value: string };
  title: { value: string };
  url: { path: string };
  id: string;
}

export interface BreadcrumbData {
  breadcrumb: {
    breadcrumbTitle?: { value: string };
    title: { value: string };
    url: { path: string };
    id: string;
    ancestors: BreadcrumbItem[];
  };
}

interface BreadcrumbPDPProps {
  gqldata: BreadcrumbData;
  productName: string;
}

// Tailwind Variants Styles
const { breadcrumbText, breadcrumbContainer, ancestorsWrapper } = breadcrumbVariants({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const DividerIcon = () => {
  return (
    <span className={''}>
      <IconHelper icon={'breadcrumb-divider'} />
    </span>
  );
};

const buildBreadcrumb = (
  breadcrumbData: BreadcrumbItem[],
  currentItem: BreadcrumbItem,
  productName: string
): BreadcrumbItem[] => {
  const pathsToExclude = ['/categories', '/'];
  const filteredAncestors =
    breadcrumbData?.filter((item) => !pathsToExclude.includes(item?.url?.path)).reverse() || '';

  //Basically this is to fix if OrederCloud product don't have Path object then it will get the category page ancestors.
  //create a variable to exclude /categories item doesn't have breadcrumbTitle.
  const checkCurrentItemBreadCrumbTitle = currentItem?.breadcrumbTitle
    ? currentItem
    : { title: { value: '' }, url: { path: '' }, id: '' };
  const lastItem = {
    ...checkCurrentItemBreadCrumbTitle,
    title: { value: productName },
    breadcrumbTitle: undefined,
    // Prevent duplicate id, which is used for React `key`
    id: productName,
  };
  return [...filteredAncestors, checkCurrentItemBreadCrumbTitle, lastItem];
};

const BreadcrumbPDP: React.FC<BreadcrumbPDPProps> = ({ gqldata, productName }) => {
  const { breadcrumb } = gqldata;
  const breadcrumbItems = buildBreadcrumb(breadcrumb?.ancestors, breadcrumb, productName);
  const { isMobile }: { isMobile: boolean } = useBreakpoints();
  return (
    <>
      {!isMobile && (
        <>
          {/* Breadcrumb Rendering */}
          <nav aria-label="breadcrumb" className={clsx(breadcrumbContainer())}>
            <ul className={ancestorsWrapper()}>
              {breadcrumbItems.map((item, index) => {
                const title = item?.breadcrumbTitle?.value || item?.title?.value;
                const isCurrentItem = index === breadcrumbItems.length - 1;
                const isSecondOrThirdLevel = index === 1 || index === 2;
                return (
                  <li key={item?.id} className={clsx(ancestorsWrapper(), 'relative', 'group')}>
                    {!isCurrentItem && isSecondOrThirdLevel ? (
                      <>
                        {title !== '' && (
                          <>
                            <Link
                              aria-label={'title link' + index}
                              href={item?.url?.path}
                              className={clsx(breadcrumbText())}
                            >
                              {title}
                            </Link>
                            {index < breadcrumbItems.length - 1 && <DividerIcon />}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {title !== '' && (
                          <>
                            <span className={clsx(breadcrumbText())}>{title}</span>
                            {index < breadcrumbItems.length - 1 && <DividerIcon />}
                          </>
                        )}
                      </>
                    )}
                  </li>
                );
              })}
            </ul>
          </nav>
        </>
      )}
    </>
  );
};

export default BreadcrumbPDP;
