import {
  ComponentParams,
  ComponentRendering,
  Placeholder,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { useGraphQlDataContext } from 'lib/context/GraphQlDataContext';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { useOcSelector } from 'src/redux/ocStore';
import { accountManagementSplitterTailwindVariant } from 'tailwindVariants/components/accountManagementSplitterTailwindVariant';
import urlConfig from 'src/utils/urlConfig';

interface ComponentProps {
  props: {
    rendering: ComponentRendering & { params: ComponentParams };
    params: ComponentParams;
  };
}

const { base, rowClasses, leftColumn, rightColumn } = accountManagementSplitterTailwindVariant({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const AccountManagementSplitter = ({ props }: ComponentProps): JSX.Element => {
  const isExperienceEditor = useExperienceEditor();
  const isOcAuth = useOcSelector((state) => state?.ocAuth);
  const ocUserDetails = useOcSelector((s) => s?.ocUser);
  const { configuration } = useGraphQlDataContext();

  const isLoyaltyAccepted = useOcSelector((state) => state?.ocUser?.user?.xp?.LoyaltyAccepted);
  const router = useRouter();

  useEffect(() => {
    // Redirect to the rewards page if the Terms and condition is not accepted
    if (
      router.asPath.includes('/accountmanagement/my-rewards-products') &&
      isLoyaltyAccepted === false
    ) {
      window.location.href = configuration?.TermsRewardPage || '/accountmanagement/my-rewards';
    }
    if (
      !isExperienceEditor &&
      Object.keys(isOcAuth).length > 0 &&
      !ocUserDetails?.loading &&
      ocUserDetails?.user?.Email &&
      !ocUserDetails?.loading &&
      isOcAuth?.isAnonymous === true
    ) {
      if (router) {
        const myAccountUrl = encodeURIComponent(router.asPath);
        const loginUrl = `${urlConfig?.loginWithReturnUrl}${myAccountUrl}`;
        router.push(loginUrl);
      }
    }
  }, [isOcAuth, isOcAuth?.loading, ocUserDetails?.loading]);

  if (
    isExperienceEditor ||
    (Object.keys(isOcAuth).length > 0 && !isOcAuth?.loading && isOcAuth?.isAnonymous === false)
  ) {
    return <Default props={props} />;
  }

  return <></>;
};

const Default = ({ props }: ComponentProps) => {
  const enabledPlaceholders = props?.params?.EnabledPlaceholders?.split(',');
  return (
    <div className={`${base()}`}>
      <div className={rowClasses()}>
        {enabledPlaceholders.map((ph, index) => {
          const phKey = `column-${ph}-{*}`;
          const widthColumn: { [key: string]: string } = {
            'column-1-{*}': leftColumn(),
            'column-2-{*}': rightColumn(),
          };
          const columnWidth = widthColumn[phKey];

          return (
            <div key={index} id={`AccountManagement-${phKey}`} className={columnWidth}>
              <div key={index} className="row">
                <Placeholder key={index} name={phKey} rendering={props.rendering} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AccountManagementSplitter;
