//global
import React, { useState, useEffect, useContext } from 'react';
import progressBarTailwind from 'tailwindVariants/components/progressBarTailwindVariant';
import { useOcSelector } from 'src/redux/ocStore';
import { FulfillmentType } from 'src/helpers/Constants';
import ComponentContext from 'lib/context/ComponentContext';
import { useCartPriceForUI } from 'src/hooks/useCartPriceForUI';

//lib
// import { ComponentProps } from 'lib/component-props';
//type
export type ProgressBarProps = {
  eligibleValue: number;
  freeDeliveryThreshold: number;
};

//main component

const ProgressBar: React.FC<ProgressBarProps> = () => {
  const { base, label, wrapper, progressFill } = progressBarTailwind({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const [progress, setProgress] = useState(0);

  const [setprogressBar, setshowProgressBar] = useState<boolean>(false);
  const [amount, setAmount] = useState<number | null>(null);
  const [deliveryStatus, setDeliveryStatus] = useState<1 | 2 | 3>(1);

  const cart = useOcSelector((state) => state?.ocCurrentOrder?.order);
  const ocCurrentOrderObject = useOcSelector((state) => state?.ocCurrentOrder);
  const cartPricing = useCartPriceForUI(ocCurrentOrderObject);
  const subTotal = cart?.Subtotal || 0; // this is current cart value

  const currentValue = cartPricing?.subTotal;

  const [showProgressBar, setProgressBar] = useState<boolean>();
  const { componentContextData } = useContext(ComponentContext);

  useEffect(() => {
    const newMethod = localStorage.getItem('selected_fulfillment_Method') || cart?.xp?.Fulfillment;

    setProgressBar(newMethod === FulfillmentType.DFS);
  }, [cart?.xp?.Fulfillment, componentContextData?.isDFSMethodChanged]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectedStore = useOcSelector((state: any) => state?.storeReducer?.selectedStore);
  const eligibleValue = selectedStore?.deliveryFee?.minimumAmount;
  const freeDeliveryThreshold = selectedStore?.deliveryFee?.maximumAmount;

  useEffect(() => {
    const calculateProgress = async () => {
      let calcAmount;
      let deliveryStatusFlag = 1;
      let progressValue = 0;

      setshowProgressBar(cartPricing?.subTotal > 0);
      if (currentValue < eligibleValue) {
        deliveryStatusFlag = 1;
        calcAmount = await (eligibleValue - currentValue);
        setAmount(calcAmount);
        const totalRange = eligibleValue;
        progressValue = Math.min(Math.max(currentValue / totalRange, 0), 1) * 100;
      }

      if (currentValue >= eligibleValue && currentValue < freeDeliveryThreshold) {
        deliveryStatusFlag = 2;
        calcAmount = freeDeliveryThreshold - currentValue;
        setAmount(calcAmount);
        const totalRange = freeDeliveryThreshold - eligibleValue;
        progressValue = Math.min(Math.max((currentValue - eligibleValue) / totalRange, 0), 1) * 100;
      }

      if (currentValue >= freeDeliveryThreshold) {
        deliveryStatusFlag = 3;
        calcAmount = currentValue - freeDeliveryThreshold;
        setAmount(calcAmount);
        progressValue = 100;
      }

      setProgress(progressValue);
      setDeliveryStatus(deliveryStatusFlag as 1 | 2 | 3);
    };

    calculateProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eligibleValue, freeDeliveryThreshold, subTotal, cartPricing?.subTotal]);

  //if no data then returned empty fragment
  // if (fields === undefined || fields === null) return <></>;

  if (!showProgressBar) {
    return null;
  }

  return (
    <div className={base()}>
      {showProgressBar && setprogressBar && (
        <>
          {amount != null && (
            <>
              {deliveryStatus === 1 && (
                <p className={label()}>${amount.toFixed(2)} until eligible for delivery</p>
              )}
              {deliveryStatus === 2 && (
                <p className={label()}>${amount.toFixed(2)} to go for free delivery</p>
              )}
              {deliveryStatus === 3 && <p className={label()}>Order qualifies for free delivery</p>}
            </>
          )}

          <div className={wrapper()}>
            <div className={progressFill()} style={{ width: `${progress}%` }}></div>
          </div>
        </>
      )}
    </div>
  );
};

// export const getStaticProps = async () => {
//   // if (process.env.JSS_MODE === constants.JSS_MODE.DISCONNECTED) {
//   //   return null;
//   // }
//   const graphQLClient = new GraphQLRequestClient(config.graphQLEndpoint, {
//     apiKey: config.sitecoreApiKey,
//   });
//   const result = await graphQLClient.request<ProgressBarStoreDataProps>(
//     // eslint-disable-next-line @typescript-eslint/no-explicit-any
//     GetStoreByIdDocument as any,
//     {
//       storeId: '3002',
//       language: 'en',
//     }
//   );
//   return result;
// };

//check withDataSourceCheck If it is not then show blank instead of error.
// export default withDatasourceCheck()<ProgressBarProps>(ProgressBar);
export default ProgressBar;
