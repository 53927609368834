// Global
import React, { useState } from 'react';

// Lib
import { Data as FooterStaticDataProps } from 'lib/types/footer';
import footerQuery from 'lib/footer.graphql';

// Local
import FooterNav from './FooterNav';
import { GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import graphqlClientFactory from 'lib/graphql-client-factory';
import { ComponentProps } from 'lib/component-props';
import { footerTailwindVariant } from 'tailwindVariants/components/footerTailwindVariant';

// Types
export type FooterProps = ComponentProps & {
  footerData: FooterStaticDataProps;
};

export default function Footer({ footerData }: FooterProps) {
  const [isToggled, setisToggled] = useState(-1);
  const { base, divWrapper } = footerTailwindVariant();

  /*
   * Rendering
   */

  if (!footerData) {
    return <></>;
  }

  return (
    <div className={base()} id="footer">
      <div className={divWrapper()}>
        {footerData?.datasource?.list?.targetItems.map((links, index) => {
          return (
            <FooterNav
              navitems={links?.subLink}
              data={footerData}
              index={index}
              link={links?.link}
              linkTitle={links?.linkTitle}
              key={index}
              isToggled={isToggled}
              setisToggled={setisToggled}
            />
          );
        })}
      </div>
    </div>
  );
}

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData) => {
  const graphQLClient = graphqlClientFactory({});
  const result = await graphQLClient.request<FooterStaticDataProps>(footerQuery, {
    datasource: rendering.dataSource,
    params: rendering.params,
    language: layoutData?.sitecore?.context?.language,
    itemID: layoutData?.sitecore?.route?.itemId,
  });

  return { footerData: result };
};
