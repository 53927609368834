import { tv } from 'tailwind-variants';

export const personalizedImageTailwindVariant = tv(
  {
    slots: {
      linkWrapper: [
        'group',
        'text-heading-mob-medium-bold',
        'leading-heading-mob-medium-bold',
        'gap-mob-space-between-micro-horizontal',
        'items-center',
      ],
      contentWrapper: [],
    },
    variants: {
      size: {
        mobile: {
          contentWrapper: [
            'py-mob-padding-micro-y',
            'gap-mob-margin-tight-bottom',
            'flex-col',
            'items-start',
            'hidden',
          ],
        },
        desktop: {
          contentWrapper: ['gap-desk-margin-tight-bottom', 'flex-row', 'block'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
