import { LineItemWithXp } from 'src/redux/xp';
import { useProductPriceListForUI } from './useProductPrice';
import { OrderPromotion } from 'ordercloud-javascript-sdk';
import useOcCart from './useOcCart';
import { useOcSelector } from 'src/redux/ocStore';

export interface UILineItemPrice {
  /** The list price to display for the line item */
  lineItemListPrice?: number;
  /** The member price to display for the line item, without promotions.  Will be undefined if missing or not less than list price */
  lineItemBaseMemberPrice?: number;
  /** The member price to display for the line item, including promotions.  Will be undefined if missing or not less than list price */
  lineItemMemberPrice?: number;
  /** The member price to display for the line item, including promotions and autoship discount.  Will be undefined if missing or not less than list price */
  lineItemAutoshipPrice?: number;
  /**
   * The final price to display for the line item, including all discounts.  Will always be present.
   * The reason we have this is for guest promo calculation, this will be for the promo pricing line item
   * not the real line item.
   */
  lineItemFinalPrice?: number;

  pluDiscount?: number;
  // Below is in use to show loader until we fetch the price and it's ready to go on UI
  productPriceLoader?: boolean;

  isListPrice?: boolean;
  isListandMemberPrice?: boolean;
}

export function useLineItemPriceForUI(
  lineItem: LineItemWithXp,
  useAnonDiscount: boolean
): UILineItemPrice {
  const isAnonymous = useOcSelector((state) => state?.ocAuth.isAnonymous);
  const useAnonDiscountPricing = isAnonymous && useAnonDiscount;
  const { getAnonDiscountLineItems } = useOcCart();
  const { orderPromotions, anonDiscountPromotions } = useOcSelector(
    (state) => state?.ocCurrentOrder
  );
  const anonDiscountLineItem = useAnonDiscountPricing
    ? getAnonDiscountLineItems(lineItem.ID)
    : null;

  const finalLineItem = anonDiscountLineItem ?? lineItem;
  const finaleOrderPromos =
    (useAnonDiscountPricing ? anonDiscountPromotions : null) ?? orderPromotions ?? [];

  return useLineItemPriceListForUI([finalLineItem], finaleOrderPromos)[finalLineItem.ID ?? ''];
}

export function useLineItemPriceListForUI(
  lineItems: LineItemWithXp[],
  promotions: OrderPromotion[]
): Record<string, UILineItemPrice> {
  const productPriceLookup = useProductPriceListForUI(lineItems.map((x) => x.ProductID));

  const result: Record<string, UILineItemPrice> = {};

  for (let i = 0; i < lineItems.length; i++) {
    const lineItem = lineItems[i];
    const linePromotions = promotions.filter((p) => p.LineItemID == lineItem.ID);
    const autoshipdiscount = linePromotions
      .filter((p) => p.ID?.includes('AUTOSHIP'))
      .reduce((acc, curr) => acc + (curr.Amount ?? 0), 0);
    const pluDiscount = linePromotions
      .filter((p) => p.xp?.IsPLU === true)
      .reduce((acc, curr) => acc + (curr.Amount ?? 0), 0);
    const nonPluDiscount = linePromotions
      .filter((p) => !p.ID?.includes('AUTOSHIP'))
      .filter((p) => !(p.xp?.IsPLU === true))
      .reduce((acc, curr) => acc + (curr.Amount ?? 0), 0);

    const productPrice = productPriceLookup?.[lineItem.ProductID];

    const lineItemListPrice =
      productPrice?.listPrice !== undefined
        ? productPrice.listPrice * (lineItem?.Quantity ?? 0)
        : undefined;

    const baseMemberPrice =
      productPrice?.memberPrice !== undefined
        ? productPrice.memberPrice * (lineItem?.Quantity ?? 0)
        : undefined;

    const productImapPrice =
      productPrice?.imapPrice !== undefined
        ? productPrice.imapPrice * (lineItem?.Quantity ?? 0)
        : undefined;

    const isListPrice =
      ((lineItemListPrice ?? 0) &&
        (lineItemListPrice ?? 0) > (productImapPrice ?? 0 ?? 0) &&
        (productImapPrice ?? 0 ?? 0) > (baseMemberPrice ?? 0)) ||
      ((lineItemListPrice ?? 0) &&
        (lineItemListPrice ?? 0) > (productImapPrice ?? 0 ?? 0) &&
        (lineItemListPrice ?? 0) == (baseMemberPrice ?? 0)) ||
      ((lineItemListPrice ?? 0) > (productImapPrice ?? 0 ?? 0) &&
        (productImapPrice ?? 0 ?? 0) == (baseMemberPrice ?? 0));

    const isListandMemberPrice =
      ((lineItemListPrice ?? 0) &&
        (lineItemListPrice ?? 0) > (baseMemberPrice ?? 0) &&
        (baseMemberPrice ?? 0) > (productImapPrice ?? 0 ?? 0)) ||
      false;

    const lineItemBaseMemberPrice =
      baseMemberPrice !== undefined && baseMemberPrice <= (lineItemListPrice ?? 0)
        ? baseMemberPrice
        : undefined;

    const memberpricewithofferid =
      baseMemberPrice !== undefined ? baseMemberPrice - (pluDiscount + nonPluDiscount) : undefined;

    const lineItemMemberPrice =
      memberpricewithofferid !== undefined && memberpricewithofferid < (lineItemListPrice ?? 0)
        ? memberpricewithofferid
        : undefined;

    const nonZeroPrices = [
      lineItemListPrice ?? 0,
      lineItemMemberPrice ?? 0,
      lineItemBaseMemberPrice ?? 0,
    ].filter((x) => x > 0);

    const lineItemAutoshipPrice =
      autoshipdiscount > 0 ? Math.min(...nonZeroPrices) - autoshipdiscount : undefined;

    result[lineItem.ID ?? ''] = {
      productPriceLoader: productPriceLookup !== undefined,
      lineItemListPrice: lineItemListPrice,
      lineItemBaseMemberPrice: lineItemBaseMemberPrice,
      lineItemMemberPrice: lineItemMemberPrice,
      lineItemAutoshipPrice: lineItemAutoshipPrice,
      lineItemFinalPrice: lineItem.LineTotal,
      isListPrice: isListPrice,
      pluDiscount: pluDiscount,
      isListandMemberPrice: isListandMemberPrice,
    };
  }

  console.warn(result);
  return result;
}
