import { NormalizedProductPrice } from 'lib/types/products';
import { ProductSearchResultModel } from './search/SearchResults/types';
import { ProductData } from 'components/ProductDetail/ProductDetail';

// Defines the structure of product price information
export interface UIProductPrice {
  productId?: string;
  listPrice: number;
  memberPrice: number;
  imapPrice: number;
  hasListPrice?: boolean;
  hasMemberPrice?: boolean;
  hasImapPrice?: boolean;
  hasListandMemberPrice?: boolean;
  showViewPriceInCart?: boolean;
}

export function getProductPriceFromSearch(
  product: ProductSearchResultModel | ProductData
): UIProductPrice {
  const productSearch = product as ProductSearchResultModel;
  const productData = product as ProductData;
  const normalizedPrice: NormalizedProductPrice = {
    productId: productSearch?.id ?? productData.ID,
    listPrice:
      productSearch?.priceschedule?.xp_listprice ?? productData.PriceSchedule?.xp?.ListPrice,
    memberPrice:
      productSearch?.priceschedule?.xp_ppcprice ?? productData.PriceSchedule?.xp?.PPCPrice,
    iMapPrice:
      productSearch?.priceschedule?.xp_imapprice ?? productData.PriceSchedule?.xp?.IMapPrice,
  };
  return getProductPriceUI(normalizedPrice);
}

// Retrieves and processes product price information for UI display
export function getProductPriceUI(product: NormalizedProductPrice): UIProductPrice {
  // Extracts price information, fallback to zero if not available
  const productId = product.productId;
  const listPrice = product?.listPrice ?? 0;
  const memberPrice = product?.memberPrice ?? 0;
  const imapPrice = product?.iMapPrice ?? 0;

  // Determines if IMAP price is available and greater than list and PPC prices
  const hasImapPrice =
    (imapPrice > 0 && imapPrice > listPrice && listPrice > memberPrice) ||
    (imapPrice > 0 && imapPrice == listPrice && listPrice > memberPrice) ||
    (imapPrice > 0 && imapPrice > listPrice && !memberPrice) ||
    (imapPrice > 0 && imapPrice > listPrice && listPrice == memberPrice);

  // Determines if list price is available and greater than IMAP and PPC prices
  const hasListPrice =
    (listPrice > 0 && listPrice > imapPrice && imapPrice > memberPrice) ||
    (listPrice > 0 && listPrice > imapPrice && !memberPrice) ||
    (listPrice > 0 && listPrice > imapPrice && listPrice == memberPrice) ||
    (listPrice > 0 && listPrice == imapPrice && imapPrice == memberPrice);

  // For PLP
  const showViewPriceInCart = listPrice > 0 && listPrice > imapPrice && imapPrice > memberPrice;

  // Determines if PPC price is available and greater than list and IMAP prices
  const hasMemberPrice = memberPrice > 0 && memberPrice > listPrice && memberPrice > imapPrice;

  // Determines if listPrice price is available and greater than PPC and PPC is greater then IMAP prices
  const hasListandMemberPrice =
    listPrice > 0 && listPrice > memberPrice && memberPrice >= imapPrice;

  return {
    productId,
    listPrice,
    memberPrice,
    imapPrice,
    hasListPrice,
    hasMemberPrice,
    hasImapPrice,
    hasListandMemberPrice,
    showViewPriceInCart,
  };
}
