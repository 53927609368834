import { tv } from 'tailwind-variants';

export const alternatingGridTailwindVariant = tv(
  {
    slots: {
      base: ['alternatingGrid', 'container', 'mx-auto'],
      cardLink: ['hover:no-underline', 'h-fit'],
      card: ['flex-col', 'border-[1px] border-color-border-mid', 'max-w-[304px]'],
      img: ['max-w-[302px]', '[&>img]:w-full'],
      title: [''],
      link: [
        'text-color-text-brand-1',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
        // 'hover:text-color-brand-tertiary-3-base',
      ],
      linkWrapper: [
        'h-fit',
        'items-center',
        '[&>span]:hover:text-color-brand-tertiary-3-base',
        '[&>span>svg>*]:hover:fill-color-brand-tertiary-3-base',
      ],
      icon: [
        '[&>svg>*]:fill-color-text-brand-1',
        '[&>svg]:h-4',
        '[&>svg]:w-4',
        '[&>*>*]:fill-color-text-brand-1]',
        'ml-mob-space-between-micro-horizontal',
      ],
      description: [
        'mt-mob-margin-micro-bottom',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      contentWrapper: ['p-desk-padding-micro-x'],
    },
    compoundSlots: [
      { slots: ['card', 'linkWrapper'], class: ['flex'] },
      { slots: ['card', 'contentWrapper'], class: ['w-full'] },
    ],
    variants: {
      size: {
        mobile: {
          base: [
            'flex',
            'py-mob-padding-tight-x',
            'pr-0',
            'overflow-x-scroll',
            'no-scrollbar',
            'gap-mob-space-between-base-horizontal',
          ],
          contentWrapper: [''],
          title: [
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          // card: ['gap-mob-space-between-base-horizontal'],
          img: ['min-w-[302px]'],
          linkWrapper: ['mt-mob-margin-tight-bottom'],
        },
        desktop: {
          base: [
            'py-mob-padding-tight-x',
            'overflow-x-hidden',
            'gap-6',
            'pr-4',
            'grid',
            'grid-cols-4',
          ],
          contentWrapper: [''],
          title: [
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          // card: ['gap-desk-space-between-base-horizontal'],
          img: ['min-w-0'],
          linkWrapper: ['mt-desk-margin-tight-bottom'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
