//global
import React, { useEffect } from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import {
  formatDateForGTM,
  Items,
  notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'src/helpers/Constants';
import { useOcSelector } from 'src/redux/ocStore';
import { useBreakpoints } from 'src/utils/breakpoints';
import { alternatingGridTailwindVariant } from 'tailwindVariants/components/alternatingGridTailwindVariant';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
//type
export type AlternatingGridProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.AlternatingGrid.Fields.AlternatingGrid;

//main component
const AlternatingGrid: React.FC<AlternatingGridProps> = ({ fields }) => {
  const { base, cardLink, card, contentWrapper, title, description, icon, img, linkWrapper, link } =
    alternatingGridTailwindVariant({
      size: { initial: 'mobile', lg: 'desktop' },
    });
  const isEE = useExperienceEditor();
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const length = fields?.gridCards?.length || 0;
  const { deviceName }: { deviceName: string } = useBreakpoints();

  //creating promotion data obj for GTM data layer
  const gtmPromotion = fields?.gridCards
    ?.map((card) => {
      return {
        promotion_id: notAvailableIfNullOrEmpty(card?.fields?.promotionId?.value),
        promotion_name: notAvailableIfNullOrEmpty(card?.fields?.promotionName?.value),
        creative_name: notAvailableIfNullOrEmpty(card?.fields?.componentName?.value),
        creative_slot: Number(card?.fields?.creativeSlotNumber?.value),
        promotion_device: deviceName,
        promotion_copy: notAvailableIfNullOrEmpty(card?.fields?.promotionCopy?.value),
        promotion_dates: notAvailableIfNullOrEmpty(
          formatDateForGTM(
            `${card?.fields?.promotionDateFROM?.value} - ${card?.fields?.promotionDateTO?.value}`
          )
        ),
        promotion_cta: notAvailableIfNullOrEmpty(card?.fields?.cTAButtonCopy?.value),
        promotion_url: notAvailableIfNullOrEmpty(card?.fields?.promotionURL?.value?.href),
      };
    })
    ?.reverse();

  //sending select_promotion data card click
  const sendGtmData = (index: number) => {
    const obj = [];
    obj?.push(gtmPromotion && gtmPromotion[index]);
    gtmPromotion && trackObjectForPromotion(GTM_EVENT?.selectPromotion, myStoreId, obj as Items[]);
  };

  //sending view_promotion data on page load
  useEffect(() => {
    if (myStoreId) {
      trackObjectForPromotion(GTM_EVENT?.viewPromotion, myStoreId, gtmPromotion as Items[]);
    }
  }, [myStoreId]);

  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div className={base()}>
      {fields?.gridCards?.map((gridCard, index) => {
        const even = (index + 1) % 2 == 0;
        return (
          <div
            className={`${index === length - 1 ? 'pr-[10px] lg:pr-0' : ''}`}
            key={index}
            data-creative-id={gridCard?.fields?.componentName?.value}
            data-promotion-cta={gridCard?.fields?.cTAButtonCopy?.value}
            data-promo-id={gridCard?.fields?.promotionId?.value}
            data-promotion-name={gridCard?.fields?.promotionName?.value}
            data-promotion-copy={gridCard?.fields?.promotionCopy?.value}
            dta-promotion-url={gridCard?.fields?.promotionURL?.value?.href}
            onClick={() => sendGtmData(index)}
          >
            {!isEE ? (
              <LinkHelper field={gridCard?.fields?.link} className={cardLink()}>
                <div
                  className={card({
                    className: even ? 'flex-col-reverse bg-color-background-light' : '',
                  })}
                >
                  <div className={img()}>
                    <ImageHelper field={gridCard?.fields?.image} />
                  </div>
                  <div className={contentWrapper()}>
                    <RichTextHelper
                      tag={'h3'}
                      field={gridCard?.fields?.title}
                      className={title()}
                    />
                    <RichTextHelper
                      field={gridCard?.fields?.description}
                      className={description()}
                    />
                    <LinkHelper field={gridCard?.fields?.link} className={linkWrapper()}>
                      {gridCard?.fields?.link?.value?.href &&
                        gridCard?.fields?.link?.value?.href?.length > 0 && (
                          <>
                            {!isEE && (
                              <span className={link()}>{gridCard?.fields?.link?.value?.text}</span>
                            )}
                            <IconHelper icon={'chevron-right'} className={icon()} />
                          </>
                        )}
                    </LinkHelper>
                  </div>
                </div>
              </LinkHelper>
            ) : (
              <div
                className={card({
                  className: even ? 'flex-col-reverse bg-color-background-light' : '',
                })}
              >
                <div className={img()}>
                  <ImageHelper field={gridCard?.fields?.image} />
                </div>
                <div className={contentWrapper()}>
                  <RichTextHelper tag={'h3'} field={gridCard?.fields?.title} className={title()} />
                  <RichTextHelper field={gridCard?.fields?.description} className={description()} />
                  <LinkHelper field={gridCard?.fields?.link} className={linkWrapper()}>
                    {gridCard?.fields?.link?.value?.href &&
                      gridCard?.fields?.link?.value?.href?.length > 0 && (
                        <>
                          {!isEE && (
                            <span className={link()}>{gridCard?.fields?.link?.value?.text}</span>
                          )}
                          <IconHelper icon={'chevron-right'} className={icon()} />
                        </>
                      )}
                  </LinkHelper>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<AlternatingGridProps>(AlternatingGrid);
