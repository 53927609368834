import { ComponentProps } from 'lib/component-props';
import { FulfillmentSwitch } from './FulfillmentSwitch';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { useSitecoreContext, Text, TextField } from '@sitecore-jss/sitecore-jss-nextjs';
import useOcCart from 'src/hooks/useOcCart';
import { LineItemWithXp } from 'src/redux/xp';
import { fulfillmentSwitcherTailwindVariant } from 'tailwindVariants/components/fulfillmentSwitcherTailwindVariant';
import { DiscreteLineItem } from 'src/helpers/Constants';

export type FulfillmentSwitcherProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.FulfillmentSwitcher.Fields.FulfillmentSwitcher;

const FulfillmentSwitcher = ({
  fields,
  params,
  rendering,
}: FulfillmentSwitcherProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const pageTitleText = sitecoreContext.route?.fields?.title as TextField;
  const { container, pageTitle, cartNumberWrapper, cartNumber } =
    fulfillmentSwitcherTailwindVariant({
      device: {
        initial: 'mobile',
        lg: 'desktop',
      },
    });
  const showTitle = params?.showCheckoutTitle === '1' ? true : false;
  const showItems = params?.showCartCount === '1' ? true : false;
  const { getProductLineItems } = useOcCart();
  const productlineitems: LineItemWithXp[] = getProductLineItems();
  return (
    <div className={container()}>
      {(showTitle || showItems) && (
        <div className={cartNumberWrapper()}>
          {showTitle && (
            <div className={pageTitle()}>
              {pageTitleText?.value ? <Text field={pageTitleText} tag="h1" /> : <h1>Checkout</h1>}
            </div>
          )}
          {showItems && (
            <p className={cartNumber()}>
              {productlineitems
                ?.filter((x) => x.ProductID !== DiscreteLineItem.TIP)
                .reduce((sum, item: LineItemWithXp) => sum + (item?.Quantity as number), 0)}
              <Text tag="span" field={fields?.itemLabel} />
            </p>
          )}
        </div>
      )}
      <FulfillmentSwitch fields={fields} params={params} componentName={rendering?.componentName} />
    </div>
  );
};

export default FulfillmentSwitcher;
