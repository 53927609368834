import { Pagination } from '@sitecore-search/ui';

import styles from './styles.module.css';
import { SearchResultsStoreState } from '@sitecore-search/react';
import { SearchResultsWidget } from '@sitecore-search/react/dist/esm/types';
import searchResultsTailwind from './SearchResultsTailwind';
import IconHelper from 'src/helpers/commonComponents/IconHelper';

export interface PspPaginationProps {
  actions: SearchResultsWidget['ActionProps'];
  page: SearchResultsStoreState['page'];
  itemsPerPage: SearchResultsStoreState['itemsPerPage'];
  totalItems: number;
}

export const PspPagination = ({ actions, totalItems, page, itemsPerPage }: PspPaginationProps) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const { facetPaginationBlock, paginationPages, paginationPage, paginationArrow } =
    searchResultsTailwind({
      size: {
        initial: 'mobile',
        lg: 'desktop',
      },
    });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  return (
    <Pagination.Root
      currentPage={page}
      defaultCurrentPage={1}
      totalPages={totalPages}
      onPageChange={(page) =>
        actions.onPageNumberChange({
          page,
        })
      }
      className={facetPaginationBlock()}
    >
      <Pagination.PrevPage
        onClick={(e) => {
          e.preventDefault();
          scrollToTop();
        }}
        className={styles['sitecore-pagination-prev-page']}
      >
        <IconHelper icon={'chevron-left'} className={paginationArrow()} />
      </Pagination.PrevPage>
      <Pagination.Pages className={paginationPages()}>
        {(pagination) =>
          Pagination.paginationLayout(pagination, {
            boundaryCount: 1,
            siblingCount: 1,
          }).map(({ page, type }) =>
            type === 'page' ? (
              <Pagination.Page
                key={page}
                aria-label={`Page ${page}`}
                page={page as number}
                onClick={(e) => {
                  e.preventDefault();
                  scrollToTop();
                }}
                className={paginationPage()}
              >
                {page}
              </Pagination.Page>
            ) : (
              <span className={paginationPage()} key={type}>
                ...
              </span>
            )
          )
        }
      </Pagination.Pages>
      <Pagination.NextPage
        onClick={(e) => {
          e.preventDefault();
          scrollToTop();
        }}
        className={styles['sitecore-pagination-next-page']}
      >
        {/* <ArrowRightIcon /> */}
        <IconHelper icon={'chevron-right'} className={paginationArrow()} />
      </Pagination.NextPage>
    </Pagination.Root>
  );
};
