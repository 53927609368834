//global
import React, { useEffect, useState } from 'react';
import { Field, ImageField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import SplideSlider from 'src/helpers/commonComponents/SplideSlider';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { carouselThreeColumnTailwindVariant } from 'tailwindVariants/components/carouselThreeColumnTailwindVariant';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
//type
export type CarouselThreeColumnProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.ThreeSlidesCarousel.Fields.ThreeSlidesCarousel;

export type CardList = {
  userCard: Card;
  index: number;
  cardLength: number;
};
export type Card = {
  fields: {
    userName: Field<string>;
    userImage: ImageField;
    userDetails: Field<string>;
    description: Field<string>;
    borderColor: BorderColor;
    quoteImage: ImageField;
  };
};
export type BorderColor = {
  fields: {
    hexColor: Field<string>;
    quoteImage: ImageField;
    Icon: ImageField;
  };
};

//card component
const Card: React.FC<CardList> = ({ userCard, index, cardLength }) => {
  //component variant
  const { card, description, userWrapper, userDetail, img, quoteIcon, userName, designation } =
    carouselThreeColumnTailwindVariant({
      device: { initial: 'mobile', lg: 'desktop' },
    });
  const isEE = useExperienceEditor();
  const border = userCard?.fields?.borderColor?.fields?.hexColor?.value;
  const icon = userCard?.fields?.borderColor?.fields?.quoteImage;
  return (
    <div className={`${index === cardLength - 1 ? 'pr-2 lg:pr-0' : ''}`}>
      <div
        className={card({ className: `${index === cardLength - 1 ? 'mr-2 lg:mr-0' : 'mr-0'}` })}
        style={{ borderColor: border }}
      >
        {userCard?.fields?.description && userCard?.fields?.description?.value?.length > 0 && (
          <RichTextHelper field={userCard?.fields?.description} className={description()} />
        )}
        {!isEE ? (
          icon && <ImageHelper field={icon} className={quoteIcon()} editable={false} />
        ) : (
          <ImageHelper field={icon} className={quoteIcon()} />
        )}
        <div className={userWrapper()}>
          {userCard?.fields?.userImage && (
            <ImageHelper field={userCard?.fields?.userImage} className={img()} />
          )}
          <div className={userDetail()}>
            {userCard?.fields?.userName && (
              <RichTextHelper field={userCard?.fields?.userName} className={userName()} />
            )}
            {userCard?.fields?.userDetails?.value && (
              <RichTextHelper field={userCard?.fields?.userDetails} className={designation()} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
//main component
const CarouselThreeColumn: React.FC<CarouselThreeColumnProps> = ({ fields, params }) => {
  //state
  const [screen, setScreen] = useState<number>();
  const cardLength = (fields?.cardList && fields?.cardList?.length) ?? 0;
  const isLoop = cardLength > 3 ? 'loop' : '';
  const isArrow = cardLength > 3 ? true : false;
  const isAutoPlay = fields?.autoPlay?.value ?? false;
  const duration =
    fields?.duration?.value === undefined || fields?.duration?.value?.length <= 0
      ? 3000
      : Number(fields?.duration?.value);
  //component variant
  const { base, innerDiv, backgroundImageWrapper, backgroundImage, heading, cardWrapper } =
    carouselThreeColumnTailwindVariant({
      device: { initial: 'mobile', lg: 'desktop' },
    });
  //handle Screen
  useEffect(() => {
    setScreen(window?.innerWidth);
    const handleResize = () => {
      setScreen(window?.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screen]);
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div className={base({ className: params?.Styles ?? '' })}>
      <div className={innerDiv()}>
        <div className={backgroundImageWrapper()}>
          <ImageHelper field={fields?.backgroundImage} className={backgroundImage()} />
        </div>
        {fields?.heading && (
          <RichTextHelper tag="h1" field={fields?.heading} className={heading()} />
        )}
        {fields?.cardList?.length && (
          <div id="slides" className={cardWrapper()}>
            {screen && screen >= 1024 ? (
              <SplideSlider
                isAutoPlay={isAutoPlay}
                options={{
                  perPage: fields?.cardList?.length > 2 ? 3 : fields?.cardList?.length,
                  type: isLoop,
                  pagination: false,
                  perMove: 1,
                  interval: duration,
                  autoplay: isAutoPlay,
                  arrows: isArrow,
                  classes: {
                    prev: 'arrow-prev splide-button splide__arrow--prev your-class-prev',
                    next: 'arrow-next splide-button splide__arrow--next your-class-next',
                  },
                  rewind: true,
                  gap: cardLength > 3 ? '24px' : '10px',
                  rewindSpeed: 3000,
                }}
              >
                {fields?.cardList?.map((userCard, index) => (
                  <Card userCard={userCard} key={index} index={index} cardLength={cardLength} />
                ))}
              </SplideSlider>
            ) : (
              fields?.cardList?.map((userCard, index) => (
                <Card userCard={userCard} key={index} index={index} cardLength={cardLength} />
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<CarouselThreeColumnProps>(CarouselThreeColumn);
