import { tv } from 'tailwind-variants';

export const cultureContainerTailwindVariant = tv(
  {
    slots: {
      base: ['cultureContainer', 'relative'],
      imageWrapper: ['absolute', 'left-0'],
    },
    variants: {
      device: {
        mobile: { base: ['hidden'] },
        desktop: { base: ['block'] },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
