import { tv } from 'tailwind-variants';

export const cookieModalTailwindVariant = tv(
  {
    slots: {
      modalHeading: 'w-full h-auto flex flex-col justify-between align-center',
      modalTitle: 'font-primary text-color-text-dark',
      modalDisclaimer:
        'text-color-text-dark [&>*>a]:text-color-brand-primary-1-base [&>a]:text-color-brand-primary-1-base font-primary text-body-large-regular font-body-large-regular leading-body-large-regular w-full',
      modalButton:
        'flex justify-center items-center  rounded-desk-global-radius-small outline-none',
      buttonWrapper: 'w-full flex justify-around',
      formWrapper: 'flex flex-col items-start self-stretch gap-[24px]',
    },
    compoundSlots: [
      {
        slots: ['modalButton'],
        class: [
          'text-heading-desk-large-bold',
          'leading-heading-desk-large-bold',
          'font-heading-desk-large-bold',
        ],
      },
    ],
    variants: {
      size: {
        mobile: {
          modalTitle:
            'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
          modalButton:
            'py-mob-component-button-full-padding-y px-mob-component-button-full-padding-x gap-mob-component-button-full-spacing-between',
          modalHeading: 'gap-mob-margin-tight-bottom',
        },
        desktop: {
          modalTitle:
            'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
          modalButton:
            'py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x gap-desk-component-button-full-spacing-between',
          buttonWrapper: 'flex items-start items-stretch gap-[24px]',
          modalHeading: 'gap-desk-margin-tight-bottom',
        },
      },
      buttonOutline: {
        false: {
          modalButton: [
            'bg-color-brand-primary-1-base',
            'text-color-text-white',
            'hover:bg-color-brand-primary-1-dark',
            'focus:bg-color-brand-primary-1-dark',
          ],
        },
        true: {
          modalButton: [
            'bg-color-background-white',
            'text-color-text-brand-1',
            'border-2 border-solid border-color-brand-primary-1-dark',
            'hover:bg-color-brand-primary-1-dark hover:text-color-text-white',
            'focus:bg-color-brand-primary-1-dark focus:text-color-text-white',
          ],
        },
      },
    },
  },
  {
    responsiveVariants: ['md'],
  }
);
