import { tv } from 'tailwind-variants';

export const registrationOrderConfirmationTV = tv(
  {
    slots: {
      formContainerCustom: [
        'flex',
        'container',
        'bg-color-background-white',
        'w-full',
        '[&>*]:w-full',
      ],
      formCustom: [
        'bg-color-background-white',
        'flex',
        'flex-col',
        'gap-desk-space-between-base-vertical',
        'w-full',
        'max-w-full',
      ],
      extraCustom: 'justify-center',
    },
    variants: {
      device: {
        mobile: {
          formContainerCustom: ['px-0'],
          formCustom: ['py-mob-padding-tight-y', 'px-mob-padding-tight-x', 'w-full'],
          extraCustom: ['px-mob-padding-tight-x', 'pb-mob-margin-loose-bottom', 'w-full'],
        },
        desktop: {
          formContainerCustom: ['px-0'],
          formCustom: ['py-0', 'px-0', 'w-full'],
          extraCustom: ['px-0', 'pb-0', 'max-w-[627px]'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
