import { tv } from 'tailwind-variants';

export const errorLabelTailwindVariant = tv(
  {
    slots: {
      title: ['text-system-red'],
    },
    variants: {
      size: {
        mobile: {
          title: [
            'text-heading-mob-medium-bold',
            'font-heading-mob-medium-bold',
            'leading-heading-mob-medium-bold',
          ],
        },
        desktop: {
          title: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
          ],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
