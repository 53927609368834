/* eslint-disable @typescript-eslint/no-explicit-any */
//global
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { Text, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
//local
import { navigationTailwindVariant } from 'tailwindVariants/components/navigationTailwindVariant';
import { UtilityNavTargetItem } from 'lib/types/header';
import { PersionalizedHeaderBannerWidget } from './GlobalHeader';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import useDictionary from 'src/hooks/useDictionary';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import { handleLogout } from 'src/helpers/authHandler';
import UserGroupProfileImage, {
  ProfileImagesType,
  UserPetsType,
} from 'components/AccountManagement/UserGroupProfileImage/UserGroupProfileImage';
import { useIsSoftLoginEnabled } from 'src/hooks/useIsSoftLoginEnabled';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';

//props type
interface MobileUtilityProps {
  item: UtilityNavTargetItem;
  handleisUtilityOpen: (val: string) => void;
  isUtilityOpen: string;
  profileImages?: Array<ProfileImagesType>;
}

export default function MobileUtility({
  item,
  handleisUtilityOpen,
  isUtilityOpen,
  profileImages,
}: MobileUtilityProps): JSX.Element {
  const isEE = useExperienceEditor();
  const {
    utilityLi,
    linkWrapper,
    utilityOpen,
    utilityLiOpen,
    justifyBetween,
    justifyCenter,
    navigationLink,
    fillBlack,
    iconSmall,
    iconXSmall,
    border0,
    flexEnd,
    flexCol,
    wFull,
  } = navigationTailwindVariant();
  const userDetails = useOcSelector((state) => state?.ocUser?.user);
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const { getDictionaryValue } = useDictionary();
  const dispatch = useOcDispatch();
  const [cookies, removeCookie] = useCookies(['ct-auth']);
  const router = useRouter();
  const ctAuthCookie = cookies['ct-auth'];
  const ocCurrentOrder = useOcSelector((s) => s.ocCurrentOrder);

  const handleLogoutClick = async () => {
    handleLogout(dispatch, router, removeCookie, ctAuthCookie);
  };

  useEffect(() => {
    // for subtotal:
    if (
      ocCurrentOrder?.initialized === true &&
      ocCurrentOrder?.lineItems &&
      ocCurrentOrder?.order?.Subtotal
    ) {
      sessionStorage.setItem('cartLineItems', JSON.stringify(ocCurrentOrder?.lineItems));
    }

    const softData = {
      isAnonymous: isAnonymous,
      username: '',
      subtotal: 0,
    };

    if (userDetails?.FirstName?.toLowerCase() !== 'default') {
      softData.username = userDetails?.FirstName as string;
    }

    if (ocCurrentOrder?.order?.Subtotal) {
      softData.subtotal = ocCurrentOrder?.order?.Subtotal;
    }

    // only insert when softLogin is not enabled
    const checkSoftLoginEnabled = sessionStorage.getItem('isSoftLoginEnabled') == 'true';

    if (!checkSoftLoginEnabled) {
      // needed when refreshed and redux is empty
      const getData = JSON.parse(sessionStorage.getItem('softlogin_dependencies') as any);
      if (getData?.username) {
        softData.username = getData?.username;
      }

      if (getData?.subtotal) {
        softData.subtotal = getData?.subtotal;
      }
      sessionStorage.setItem('softlogin_dependencies', JSON.stringify(softData));
    } else {
      const hasPetImages = JSON.parse(sessionStorage.getItem('softlogin_pets') as any);
      setLocalPetImage(hasPetImages);
    }

    // if (!isAnonymous) {
    sessionStorage.setItem('isAnonymous', isAnonymous.toString());
    // }
  }, [ocCurrentOrder, userDetails?.FirstName, isAnonymous]);

  const [localPetImage, setLocalPetImage] = useState<UserPetsType>();
  const [localIsUserPetsAvailable, setLocalIsUserPetsAvailable] = useState<boolean>();

  useEffect(() => {
    if (typeof window !== undefined) {
      // const hasLocalPetImage = JSON.parse(localStorage.getItem('softlogin_pets') as any);
      const hasLocalPetImage = JSON.parse(sessionStorage.getItem('softlogin_pets') as any);
      // const hasLocalPetImage = cookies?.softlogin_pets as any;
      if (hasLocalPetImage) {
        setLocalPetImage(hasLocalPetImage);
        setLocalIsUserPetsAvailable(hasLocalPetImage && Object.keys(hasLocalPetImage).length > 0);
      }
    }
  }, []);

  return (
    <li key={item?.name} className={utilityLi()}>
      {item?.childrens?.results && item?.childrens?.results?.length > 0 ? (
        <div
          onClick={() => {
            handleisUtilityOpen(item?.name || '');
          }}
          className={clsx(justifyCenter(), flexCol())}
        >
          <RenderProfileOrImage
            item={item}
            profileImages={profileImages}
            localIsUserPetsAvailable={localIsUserPetsAvailable}
            localPetImage={localPetImage}
          />
          <ShowAccountNameTextMobile item={item} />
        </div>
      ) : (
        item?.mobileLink?.jsonValue?.value?.href && (
          <LinkHelper
            editable={false}
            hideLinkInEE
            field={item?.mobileLink?.jsonValue}
            className={clsx(linkWrapper(), justifyBetween(), justifyCenter(), flexCol())}
          >
            <>
              <div>
                <NextImage field={item?.mobileImage?.jsonValue} height={40} width={40} />
              </div>
              {item?.mobileText && <Text tag="p" field={item?.mobileText} />}
            </>
          </LinkHelper>
        )
      )}
      {item?.childrens?.results?.length ? (
        <ul
          id={'isUtilityOpen'}
          className={clsx(utilityOpen(), {
            '!h-fit bottom-full': isUtilityOpen === item?.name,
            'translate-y-full bottom-0': isUtilityOpen !== item?.name,
          })}
        >
          <li className={clsx(utilityLiOpen(), border0(), flexEnd())}>
            <IconHelper
              icon={'close'}
              className={clsx(fillBlack(), iconSmall())}
              onClick={() => handleisUtilityOpen('')}
            />
          </li>
          {item?.childrens?.results?.map((mlink, index) => {
            return (
              <li className={clsx(utilityLiOpen(), justifyBetween())} key={index}>
                {mlink?.link?.jsonValue && (
                  <>
                    {!mlink?.link?.jsonValue?.value?.href?.includes(
                      '/accountmanagement/my-rewards'
                    ) ? (
                      <LinkHelper
                        onClick={() => handleisUtilityOpen('')}
                        editable={false}
                        field={mlink?.link?.jsonValue}
                        className={clsx(navigationLink(), justifyBetween())}
                      >
                        {!isEE && <p className={wFull()}>{mlink?.link?.jsonValue?.value?.text}</p>}
                        <IconHelper
                          icon={'chevron-right'}
                          className={clsx(fillBlack(), iconXSmall())}
                        />
                      </LinkHelper>
                    ) : (
                      <a
                        aria-label="sublink"
                        onClick={() => handleisUtilityOpen('')}
                        href={mlink?.link?.jsonValue?.value?.href}
                        className={clsx(navigationLink(), justifyBetween())}
                      >
                        {!isEE && <p className={wFull()}>{mlink?.link?.jsonValue?.value?.text}</p>}
                        <IconHelper
                          icon={'chevron-right'}
                          className={clsx(fillBlack(), iconXSmall())}
                        />
                      </a>
                    )}
                  </>
                )}
              </li>
            );
          })}

          {/* Logout label goes here: */}
          {item?.name == 'My Account' && !isAnonymous && (
            <LinkHelper
              editable={false}
              field={{
                value: {
                  href: '/',
                  text: getDictionaryValue('LogoutLabel'),
                },
              }}
              onClick={(e) => {
                e.preventDefault();
                handleLogoutClick();
              }}
              className={clsx(utilityLiOpen())}
            />
          )}

          {item?.rfkID?.value && (
            <PersionalizedHeaderBannerWidget rfkId={item?.rfkID?.value.toString()} />
          )}
        </ul>
      ) : (
        ''
      )}
    </li>
  );
}

// renders account name based on various conditions including SoftLogin:
const ShowAccountNameTextMobile = ({ item }: { item: UtilityNavTargetItem }) => {
  const userDetails = useOcSelector((state) => state?.ocUser?.user);
  const isAnonymous = useOcSelector((s) => s.ocAuth.isAnonymous);
  const isSoftLoginEnabled = useIsSoftLoginEnabled();

  if (!isSoftLoginEnabled) {
    // no softlogin, show regular stuff
    if (
      !isAnonymous &&
      userDetails &&
      userDetails.FirstName &&
      (item?.mobileText?.value === 'Account' || item?.mobileText?.value === 'My Account')
    ) {
      return <span>{userDetails.FirstName}</span>;
    } else {
      return <Text tag={'p'} field={item?.mobileText} />;
    }
  } else {
    // has softLogin, take from SessionStorage:
    if (sessionStorage.getItem('softLoginState')) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const softlogin_dependencies = JSON.parse(
        sessionStorage.getItem('softlogin_dependencies') as any
      );
      if (softlogin_dependencies?.username) {
        // setAccountName(softLoginState?.accountName);
        if (item?.mobileText?.value === 'Account' || item?.mobileText?.value === 'My Account') {
          return <span>{softlogin_dependencies?.username}</span>;
        } else {
          return <Text tag={'p'} field={item?.mobileText} />;
        }
      } else {
        //  TODO: For handling case where username is missing in SoftLogin enabled state.
        return <Text tag={'p'} field={item?.mobileText} />;
      }
    }
  }
  return null; // Ensure to return something if isSoftLoginEnabled is true
};

// function for image to render on profile based on user authentication
const RenderProfileOrImage = ({
  item,
  profileImages,
  localIsUserPetsAvailable,
  localPetImage,
}: {
  item: UtilityNavTargetItem;
  profileImages?: ProfileImagesType[];
  localPetImage?: UserPetsType;
  localIsUserPetsAvailable?: boolean;
}) => {
  const isSoftLoginEnabled = useIsSoftLoginEnabled();

  const userPets = useOcSelector((s) => s.ocUser?.user?.xp?.pets);

  const isUserPetsAvailable = userPets && Object.keys(userPets).length > 0;

  const shouldShowProfileImage =
    (isSoftLoginEnabled && item?.name === 'My Account' && localIsUserPetsAvailable) ||
    (!isSoftLoginEnabled && item?.name === 'My Account' && isUserPetsAvailable);

  const selectedProfileImages = isSoftLoginEnabled ? profileImages ?? [] : profileImages;
  const selectedUserPets = isSoftLoginEnabled ? localPetImage : userPets;
  const isProfileImageAvailable = isSoftLoginEnabled
    ? localIsUserPetsAvailable
    : isUserPetsAvailable;

  return (
    <>
      {shouldShowProfileImage ? (
        <UserGroupProfileImage
          profileImages={selectedProfileImages ?? []}
          userPets={selectedUserPets ?? {}}
          isUserPetsAvailable={isProfileImageAvailable}
          isWhiteImage={true}
        />
      ) : (
        <ImageHelper
          field={{
            value: {
              src: item?.mobileImage?.jsonValue?.value?.src,
              alt: item?.mobileImage?.jsonValue?.value?.alt,
              width: '40',
              height: '40',
            },
          }}
        />
      )}
    </>
  );
};
