/* eslint-disable */

import { bVPLPRatingTailwindVariant } from 'tailwindVariants/components/bVPLPRatingTailwindVariant';

const BVPLPRating = ({
  bvProductId,
  bvRedirectUrl,
  showLeftAlign,
}: {
  bvProductId: string | undefined;
  bvRedirectUrl: string;
  showLeftAlign?: boolean;
}) => {
  const { base } = bVPLPRatingTailwindVariant({
    device: { initial: 'mobile', lg: 'desktop' },
  });

  return (
    <div
      data-bv-show="inline_rating"
      data-bv-product-id={bvProductId}
      data-bv-redirect-url={bvRedirectUrl}
      data-bind="attr:{'data-bv-product-id:SKU(), 'data-bv-redirect-url':ProductUrl()}"
      className={base({
        className: showLeftAlign ? 'justify-start' : 'justify-center',
      })}
      id="ratingSummary"
    ></div>
  );
};
export default BVPLPRating;
