import { tv } from 'tailwind-variants';
export const nextDeliveryModalTailwindVariant = tv(
  {
    slots: {
      base: 'NextDelivery flex flex-col',
      buttonWrapper: 'flex',
      title:
        'font-primary text-color-text-black font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold',
      description:
        'font-primary text-color-text-black font-body-large-bold text-body-large-bold leading-body-large-bold',
      warningHeading:
        'font-primary text-color-text-black font-body-large-regular text-body-large-regular leading-body-large-regular',
      loadingWrapper:
        'font-primary w-full py-desk-component-button-full-padding-y flex text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold px-desk-component-button-full-padding-y gap-desk-component-button-full-space-between',
      buttonWithBg:
        'h-fit w-full rounded-desk-global-radius-small py-desk-component-button-full-padding-y flex justify-center text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold text-color-text-white bg-color-brand-primary-1-base hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
      button:
        'h-fit w-fit px-desk-component-button-full-padding-x rounded-desk-global-radius-small py-desk-component-button-full-padding-y flex justify-center text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold text-color-brand-primary-1-base bg-color-background-white border-2 border-color-brand-primary-1-base hover:text-color-text-white focus:text-color-text-white hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark ',
    },
    variants: {
      size: {
        desktop: {
          base: 'px-desk-padding-tight-x py-desk-padding-tight-y gap-desk-space-between-tight-vertical',
          warningHeading: 'mt-desk-margin-micro-bottom',
          buttonWrapper: 'mt-desk-margin-base-top flex-row gap-desk-space-between-base-horizontal',
        },
        mobile: {
          base: 'px-mob-padding-tight-x py-mob-padding-tight-y gap-mob-space-between-tight-vertical',
          warningHeading: 'mt-mob-margin-micro-bottom',
          buttonWrapper: 'mt-mob-margin-base-top flex-col gap-mob-space-between-base-horizontal',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
