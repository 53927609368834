import { tv } from 'tailwind-variants';

export const storeEventsTailwindVariant = tv(
  {
    slots: {
      viewMoreWrapper: 'w-full flex justify-center',
      viewMoreLink:
        'w-fit group underline flex items-center hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base gap-mob-space-between-micro-horizontal lg:gap-desk-space-between-micro-horizontal text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold text-color-text-brand-1',
      viewMoreIcon:
        '[&_svg_*]:fill-color-text-brand-1 group-hover:[&_svg_*]:fill-color-brand-tertiary-3-base group-focus:[&_svg_*]:fill-color-brand-tertiary-3-base [&_svg]:w-4 [&_svg]:h-4',
      base: 'container mx-auto flex flex-col',
      heading:
        'font-primary font-heading-desk-xLarge-medium text-heading-desk-xLarge-medium leading-heading-desk-xLarge-medium',
      eventWrapper: 'flex flex-col',
      eventContentWrapper: 'border-b border-b-color-border-light',
      eventTime:
        'font-primary font-heading-desk-small-bold text-heading-desk-small-bold leading-heading-desk-small-bold',
      eventName:
        'font-primary font-heading-desk-small-regular leading-heading-desk-small-regular text-color-text-brand-1 text-heading-desk-small-regular',
      eventLeftContent: '',
      eventDescription:
        'font-primary text-body-large-regular font-body-large-regular leading-body-large-regular',
      eventDateText:
        'font-primary font-heading-desk-small-bold text-heading-desk-small-bold leading-heading-desk-small-bold border-b border-b-color-border-light',
    },
    variants: {
      size: {
        desktop: {
          eventLeftContent: 'flex gap-desk-margin-base-right',
          eventDescription: 'mt-desk-margin-tight-bottom max-w-[614px]',
          base: 'py-desk-padding-tight-y gap-desk-margin-base-bottom',
          eventWrapper: 'gap-desk-margin-base-bottom',
          eventDateText: 'py-desk-padding-micro-y',
          eventName: 'mt-0',
          eventContentWrapper: 'flex flex-row justify-between items-end pb-desk-margin-base-bottom',
        },
        mobile: {
          base: 'py-mob-padding-tight-y gap-mob-margin-base-bottom',
          eventDescription: 'mt-mob-margin-tight-bottom',
          eventWrapper: 'gap-mob-margin-base-bottom',
          eventName: 'mt-mob-margin-base-right',
          eventDateText: 'py-mob-padding-micro-y',
          eventContentWrapper: 'flex flex-col gap-[29px] pb-mob-margin-loose-bottom',
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
