/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { DecodedToken, Tokens } from 'ordercloud-javascript-sdk';
import parseJwt from '../../utils/parseJwt';
import login from './login';
import logout from './logout';
import authAnonymous from './authAnonymous';
import softlogout from './softlogout';
import { getSerializableObject } from '../utils';

export interface OcAuthState {
  isAuthenticated: boolean;
  isAdmin: boolean;
  decodedToken?: DecodedToken;
  isAnonymous: boolean;
  error?: SerializedError;
  loading: boolean;
  initialized: boolean;
  isSoftLogin?: boolean | null | string;
}

const initialState: OcAuthState = {
  isAuthenticated: false,
  isAdmin: false,
  isAnonymous: true,
  loading: false,
  initialized: false,
  isSoftLogin: null,
};

const ocAuthSlice = createSlice({
  name: 'ocAuth',
  initialState,
  reducers: {
    initializeAuth: (state) => {
      const initialAccessToken = Tokens.GetAccessToken();
      let isAnonymous = true;
      let isAdmin = false;
      let decodedToken: DecodedToken = {
        usr: '',
        cid: '',
        usrtype: 'buyer',
        role: '',
        iss: '',
        aud: '',
        exp: 0,
        nbf: 0,
      };
      // let isSoftLogin = false;

      if (initialAccessToken) {
        decodedToken = parseJwt(initialAccessToken) as DecodedToken;
        isAnonymous = !!decodedToken.orderid;
        isAdmin = decodedToken.usrtype === 'admin';
      }
      state.isAuthenticated = !!initialAccessToken;
      state.isAnonymous = isAnonymous;
      state.isAdmin = isAdmin;
      state.decodedToken = decodedToken;
      state.initialized = true;
    },
    setIsAnonymous: (state, isAnonymous: PayloadAction<boolean>) => {
      state.isAnonymous = isAnonymous.payload;
    },
  },
  extraReducers: (builder) => {
    // LOGIN CASES
    builder.addCase(login.pending, (state) => {
      // state.isSoftLogin = 'destroy';
      // localStorage.removeItem('setSoftLogin');
      // localStorage.removeItem('softLoginState');

      state.loading = true;
      state.error = undefined;
      state.isAuthenticated = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isAnonymous = false;
      state.isAuthenticated = true;
      state.decodedToken = parseJwt(action.payload.access_token);
      state.isAdmin = state.decodedToken.usrtype === 'admin';
      state.loading = false;
      // localStorage.setItem('setSoftLogin', '0');
    });
    builder.addCase(login.rejected, (state, action) => {
      state.error = getSerializableObject(action.error);
      state.isAuthenticated = false;
      state.loading = false;
    });

    // LOGOUT CASES
    builder.addCase(logout.pending, (state) => {
      // state.isSoftLogin = state.isAnonymous == false ? true : false;
      state.loading = true;
      state.decodedToken = undefined;
      state.isAuthenticated = false;
      state.isAnonymous = true;
      state.error = undefined;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      // state.isSoftLogin = state.isAnonymous == false ? true : false;
      // const softLoginState = {
      //   preferredStore: localStorage.getItem('storeId'),
      //   mycart: null,
      // };
      // state.isSoftLogin = 'logout';
      // localStorage.setItem('setSoftLogin', '1');
      // localStorage.setItem('softLoginState', JSON.stringify(softLoginState));
      state.isAnonymous = true;
      state.isAdmin = false;
      state.isAuthenticated = false;
      state.decodedToken = action.payload
        ? parseJwt(action.payload.access_token as never)
        : undefined;
      state.loading = false;
    });
    builder.addCase(logout.rejected, (state, action) => {
      state.error = getSerializableObject(action.error);
      state.loading = false;
    });

    // ANONYMOUS LOGIN CASES
    builder.addCase(authAnonymous.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(authAnonymous.fulfilled, (state, action) => {
      // state.isSoftLogin = state.isAnonymous == false ? true : false;
      state.isSoftLogin = 'anony';
      state.isAnonymous = true;
      state.isAdmin = false;
      state.isAuthenticated = true;
      state.decodedToken = action.payload
        ? parseJwt(action.payload.access_token as never)
        : undefined;
      state.loading = false;
    });

    // SOFT LOGIN CASES:
    builder.addCase(softlogout.pending, (state) => {
      state.isAnonymous = true;
      state.isAdmin = false;
      state.isAuthenticated = false;
      state.loading = false;
      state.isSoftLogin = true;

      // const { ocCurrentOrder } = ThunkAPI.getState();

      // const name = await ocUser?.user?.FirstName;
      // const name =

      // const currentLinteItems =
      //   (await ocCurrentOrder?.lineItems?.filter(
      //     (x) => ![DiscreteLineItem.TIP, DiscreteLineItem.CORDF].includes(x.ProductID)
      //   )) ?? [];

      // const { getProductLineItems } = await useOcCart();
      // const productlineitems: LineItemWithXp[] = await getProductLineItems();

      // const softLoginState = {
      //   preferredStore: localStorage.getItem('storeId'),
      //   accountName: name,
      //   cartItem: currentLinteItems,
      // };
      // await localStorage.setItem('softLoginState', JSON.stringify(softLoginState));
      // await localStorage.setItem('testingSoftLogin', 'true');
    });

    builder.addCase(softlogout.fulfilled, (state, _action) => {
      // const softLoginState = {
      //   preferredStore: localStorage.getItem('storeId'),
      //   mycart: null,
      // };
      // state.isSoftLogin = 'logout';
      // localStorage.setItem('setSoftLogin', '1');
      // localStorage.setItem('softLoginState', JSON.stringify(softLoginState));
      state.isAnonymous = true;
      state.isAdmin = false;
      state.isAuthenticated = false;
      state.loading = false;
    });

    builder.addCase(softlogout.rejected, (state, action) => {
      state.error = getSerializableObject(action.error);
      state.loading = false;
    });
  },
});

export const { initializeAuth, setIsAnonymous } = ocAuthSlice.actions;

export default ocAuthSlice.reducer;
