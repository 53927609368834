import { tv } from 'tailwind-variants';
export const cancelAutoshipModalTailwindVariant = tv(
  {
    slots: {
      base: 'CancelAutoship flex flex-col',
      errorMessage:
        'font-primary text-body-small-regular leading-body-small-regular font-body-small-regular text-system-red',
      buttonWrapper: 'flex',
      title:
        'font-primary text-color-text-black font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold',
      description:
        'font-primary text-color-text-black font-body-large-bold text-body-large-bold leading-body-large-bold',
      warningHeading:
        'font-primary text-color-text-black font-body-large-regular text-body-large-regular leading-body-large-regular',
      loadingWrapper:
        'font-primary w-full py-desk-component-button-full-padding-y flex text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold px-desk-component-button-full-padding-y gap-desk-component-button-full-space-between',
      buttonWithBg:
        'h-fit w-full rounded-desk-global-radius-small py-desk-component-button-full-padding-y flex justify-center text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold text-color-text-white bg-color-brand-primary-1-base hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
      button:
        'h-fit w-full rounded-desk-global-radius-small py-desk-component-button-full-padding-y flex justify-center text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold ',
      contentWrapper: 'flex flex-col',
      reasonWrapper: '',
      cancellationReasonTitle:
        'font-primary mb-desk-margin-micro-bottom text-color-text-black font-body-large-bold text-body-large-bold leading-body-large-bold',
      cancellationReasonSelect:
        'focus:outline-none w-full border-2 rounded-mob-global-radius-small focus:border-color-border-brand cursor-pointer appearance-none py-desk-global-spacing-spacing-3 px-desk-global-spacing-spacing-4',
      cancellationReasonInput:
        'focus:outline-none w-full border-2 border-color-border-mid rounded-mob-global-radius-small py-desk-global-spacing-spacing-3 px-desk-global-spacing-spacing-4',
      cancellationReasonSelectWrapper: 'relative w-[280px]',
      cancellationReasonSelectWrapperInput: 'relative w-full',
      dropdownClass:
        'appearance-none bg-[url("/icons/dropdown.svg")] bg-no-repeat bg-[position:calc(100%-10px)_center]',
    },

    variants: {
      size: {
        desktop: {
          contentWrapper: 'gap-desk-margin-micro-bottom',
          reasonWrapper: 'mt-desk-margin-base-top',
          base: 'px-desk-padding-tight-x py-desk-padding-tight-y gap-desk-space-between-tight-vertical',
          // warningHeading: 'mt-desk-margin-micro-bottom',
          buttonWrapper: 'mt-desk-margin-base-top flex-row gap-desk-space-between-base-horizontal',
          cancellationReasonTitle: 'mb-desk-margin-micro-bottom',
          cancellationReasonSelect:
            'rounded-mob-global-radius-small py-desk-global-spacing-spacing-3 px-desk-global-spacing-spacing-4',
          cancellationReasonInput:
            'rounded-mob-global-radius-small py-desk-global-spacing-spacing-3 px-desk-global-spacing-spacing-4',
          errorMessage: 'mt-desk-margin-micro-bottom',
        },
        mobile: {
          errorMessage: 'mt-mob-margin-micro-bottom',
          contentWrapper: 'gap-mob-margin-micro-bottom',
          cancellationReasonTitle: 'mb-mob-margin-micro-bottom',
          cancellationReasonSelect:
            'rounded-mob-global-radius-small py-mob-global-spacing-spacing-3 px-mob-global-spacing-spacing-4',
          cancellationReasonInput:
            'rounded-mob-global-radius-small py-mob-global-spacing-spacing-3 px-mob-global-spacing-spacing-4',

          reasonWrapper: 'mt-mob-margin-base-top',
          base: 'px-mob-padding-tight-x py-mob-padding-tight-y gap-mob-space-between-tight-vertical',
          // warningHeading: 'mt-mob-margin-micro-bottom',
          buttonWrapper: 'mt-mob-margin-base-top flex-col gap-mob-space-between-base-horizontal',
        },
      },
      isError: {
        true: { cancellationReasonSelect: 'border-system-red' },
        false: { cancellationReasonSelect: 'border-color-border-mid' },
      },
      isLoading: {
        true: {
          button: 'bg-color-brand-primary-1-disabled cursor-not-allowed text-color-text-white',
        },
        false: {
          button:
            'text-color-brand-primary-1-base bg-color-background-white border-2 border-color-brand-primary-1-base hover:text-color-text-white focus:text-color-text-white hover:border-color-brand-primary-1-dark focus:border-color-brand-primary-1-dark hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
