/* eslint-disable */
import { useEffect } from 'react';
import { bVRatingSummaryTailwindVariant } from 'tailwindVariants/components/bVRatingSummaryTailwindVariant';

const BVRatingSummary = ({ bvProductId }: { bvProductId: string }) => {
  const { base } = bVRatingSummaryTailwindVariant({
    device: { initial: 'mobile', lg: 'desktop' },
  });
  useEffect(() => {}, []);
  return <div data-bv-show="reviews" data-bv-product-id={bvProductId} className={base()} />;
};
export default BVRatingSummary;
