import orderSummary from 'tailwindVariants/components/orderSummaryTailwindVariant';
import { Dispatch, SetStateAction, useState } from 'react';
import { useOcDispatch, useOcSelector } from 'src/redux/ocStore';
import { applyPromo } from 'src/redux/ocCurrentOrder';
import ButtonHelper from 'src/helpers/commonComponents/Button';
import { OrderPromotion } from 'ordercloud-javascript-sdk';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import Loader from 'components/Loader/Loader';

type CouponCodeProps = {
  unrecognizedPlu?: string;
  couponLimitText?: string;
  unrecognizedBarcode?: string;
  ineligibleCoupon?: string;
  errorPopupTitle?: string;
  applyButtonText?: string;
  isAnonymous: boolean;
  isPromotionSubmiting: boolean;
  setIsPromotionSubmiting: Dispatch<SetStateAction<boolean>>;
};

type CouponErrorModalProps = {
  isErrorModalOpen: boolean;
  setErrorIsModalOpen: Dispatch<SetStateAction<boolean>>;
  promoCode: string;
  modalTitle: string;
  modalDescription: string;
};

const LoaderIcon = () => {
  return (
    <div className={'items-center flex-col flex'}>
      <Loader />
    </div>
  );
};

// Tailwind Variant
const {
  coupon,
  couponContainer,
  linkWithUnderline,
  errorPopupSpacing,
  errorPopupWrapper,
  errorPopupHeading,
  errorPopupDescription,
  errorPopupButtonWrapper,
  formContainer,
} = orderSummary({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

const handleModalClose = (setErrorIsModalOpen: { (value: SetStateAction<boolean>): void }) => {
  setErrorIsModalOpen(false);
};

const CouponErrorModal: React.FC<CouponErrorModalProps> = ({
  setErrorIsModalOpen,
  isErrorModalOpen,
  promoCode,
  modalTitle,
  modalDescription,
}) => {
  return (
    <ModalWrapper
      showCloseButtonInModalHeader={false}
      closeModalOnOverlayClick={true}
      onCloseClick={() => {
        handleModalClose(setErrorIsModalOpen);
      }}
      showModal={isErrorModalOpen}
      customPopup={true}
      popupSpacing={errorPopupSpacing()}
      popupWidth={'max-w-[995px]'}
    >
      <div className={errorPopupWrapper()}>
        <p className={errorPopupHeading()}>{modalTitle}</p>
        <p className={errorPopupDescription()}>
          {modalDescription.replace('{promoCode}', promoCode)}
        </p>
        <div className={errorPopupButtonWrapper()}>
          <ButtonHelper
            isLinkField={true}
            variant="primary"
            size="default"
            field={{ value: { href: '/', text: 'Ok' } }}
            className={''}
            onClickHandler={(e) => {
              e?.preventDefault();
              setErrorIsModalOpen(false);
            }}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};
const CouponCode: React.FC<CouponCodeProps> = ({
  unrecognizedPlu,
  couponLimitText,
  unrecognizedBarcode,
  ineligibleCoupon,
  errorPopupTitle,
  applyButtonText,
  isAnonymous,
  isPromotionSubmiting,
  setIsPromotionSubmiting,
}) => {
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const ocpromotionData = useOcSelector(
    (state) => state?.ocCurrentOrder?.orderPromotions
  ) as OrderPromotion[];
  const dispatch = useOcDispatch();
  const [promoCode, setPromoCode] = useState('');
  const [isErrorModalOpen, setErrorIsModalOpen] = useState(false);
  const [errorModalTitle] = useState(errorPopupTitle ?? 'Error');
  const [errorModalDescription, setErrorModalDescription] = useState(unrecognizedPlu);
  const handlePromotionInput = (input: string) => {
    setPromoCode(input);
  };
  const handlePromotionSubmit = async () => {
    //Not to submit if there is no promocode text.
    if (!promoCode || promoCode.length === 0) {
      return;
    }
    //Not to submit if already promocode applied.
    if (ocpromotionData?.filter((p) => p?.xp?.IsPLU === true).length) {
      setErrorIsModalOpen(true);
      //setErrorModalTitle('Error');
      setErrorModalDescription(couponLimitText);
      return;
    }
    //Not to submit if promocode text length is less then 5.
    if (promoCode.length < 5 || !myStoreData?.dataareaid) {
      setErrorIsModalOpen(true);
      //setErrorModalTitle('Error');
      setErrorModalDescription(unrecognizedPlu?.replace('{promocode}', promoCode));
      return;
    }

    setIsPromotionSubmiting(true);
    // Promocode submission to OC check
    try {
      const res = await dispatch(
        applyPromo({
          promoCode: promoCode,
          dataareaId: myStoreData?.dataareaid,
        })
      );
      // if user enters an Invalid promocode then show error.
      if (res?.payload?.Success === true) {
        // if user enters an valid promocode then promocode input text sets "blank".
        setPromoCode('');
        /*
         if (promoCode.length == 20) {
           const request: Order = { xp: { CouponCode: promoCode } };
           await dispatch(patchOrder(request));
         }
         */
      } else {
        setErrorIsModalOpen(true);
        let error = '';
        if (res?.payload?.Error?.includes('NotEligible')) {
          error = ineligibleCoupon?.replace('{promocode}', promoCode) ?? '';
        } else if (promoCode.length == 20) {
          error = unrecognizedBarcode?.replace('{promocode}', promoCode) ?? '';
        } else {
          error = unrecognizedPlu?.replace('{promocode}', promoCode) ?? '';
        }
        setErrorModalDescription(error);
      }
    } catch (e) {
      console.error('An unknown error occurred', e);
    } finally {
      setIsPromotionSubmiting(false);
    }
  };

  return (
    <div className={couponContainer()}>
      {isAnonymous && <input type="text" className={coupon({ guest: isAnonymous })} disabled />}
      {!isAnonymous && (
        <form
          className={formContainer()}
          onSubmit={(e) => {
            e.preventDefault();
            handlePromotionSubmit();
          }}
        >
          <input
            id="promoCodeInput"
            type="text"
            className={coupon()}
            onChange={(e) => {
              handlePromotionInput(e.target?.value);
            }}
            value={promoCode}
          />
          {isPromotionSubmiting ? (
            <>
              <LoaderIcon />
            </>
          ) : (
            <button className={linkWithUnderline({ guest: isAnonymous })}>
              {applyButtonText ?? 'APPLY'}
            </button>
          )}
        </form>
      )}
      <CouponErrorModal
        setErrorIsModalOpen={setErrorIsModalOpen}
        isErrorModalOpen={isErrorModalOpen}
        promoCode={promoCode}
        modalTitle={errorModalTitle}
        modalDescription={errorModalDescription ?? ''}
      />
    </div>
  );
};

export default CouponCode;
