// Global
import React, { LegacyRef, RefObject, useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { useContentBlock, widget, WidgetDataType } from '@sitecore-search/react';

// Sitecore fields
import {
  ComponentRendering,
  Field,
  GetStaticComponentProps,
  ImageField,
  Placeholder,
  Text,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { navigationTailwindVariant } from 'tailwindVariants/components/navigationTailwindVariant';

// Local
import DesktopNav from './DesktopMenu';
import MobileNav from './MobileNav';
import UtilityNav from './UtilityNav';
import MobileUtility from './MobileUtility';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import OriginalPreviewSearch from 'src/helpers/search/PreviewSearch';
import graphqlClientFactory from 'lib/graphql-client-factory';
import { currentPath, rfkidOf, SiteNameUppercase } from 'src/helpers/Constants';

// Lib
import { useOcSelector } from 'src/redux/ocStore';
import { useBreakpoints } from 'src/utils/breakpoints';
import { useStateAnimate } from 'src/hooks/useStateAnimate';
import ComponentContext from 'lib/context/ComponentContext';
import useOcCart from 'src/hooks/useOcCart';

// Types & interfaces
import { ComponentProps } from 'lib/component-props';
import { Data } from 'lib/types/header';
import headerQuery from 'lib/header.graphql';
import MobileHeaderQuery from 'lib/mobileheader.graphql';
import getProfileImages from './profile.graphql';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import { personalizedHeaderBannerTailwindVariant } from 'tailwindVariants/components/personalizedHeaderBannerTailwindVariant';
import { useIsSoftLoginEnabled } from 'src/hooks/useIsSoftLoginEnabled';

export type DefaultData = ComponentProps & {
  uid: string;
  componentName: string;
  dataSource: string;
  fields: { data: Data };
  headerDesktopData: Data;
  headerMobileData: Data;
  profileImages?: ProfileImagesProps;
};

type SpecieDataProps = {
  jsonValue: ImageField;
};

type ProfileImagesProps = {
  item: {
    children: {
      results: {
        Specie: string;
        SpecieData: SpecieDataProps;
      }[];
    };
  };
};

type BannerData = {
  image_url: Field<string>;
  image_url_mobile: Field<string>;
  heading: Field<string>;
  link: Field<string>;
  clickthrough_url: Field<string>;
  linkTitle: Field<string>;
};
//Personalized Header banner variants

//Persionalized Header Banner
const PersionalizedHeaderBannerWithLink: React.FC = (): JSX.Element => {
  const { deskImg, mobImg, contentWrapper, linkWrapper } = personalizedHeaderBannerTailwindVariant({
    device: { initial: 'mobile', lg: 'desktop' },
  });
  const {
    queryResult: { data: { appearance: { variables: data = [] } = {} } = {} },
  } = useContentBlock();
  const bannerData: BannerData = data as unknown as BannerData;
  return (
    <div>
      <Link href={bannerData?.clickthrough_url?.value || '/'} aria-label="offer-link">
        <>
          {bannerData?.image_url?.value?.length > 0 && (
            <ImageHelper
              field={{
                value: {
                  src: bannerData?.image_url?.value,
                  alt: bannerData?.linkTitle?.value || bannerData?.heading?.value,
                },
              }}
              className={deskImg()}
            />
          )}
          {bannerData?.image_url_mobile?.value?.length > 0 && (
            <ImageHelper
              field={{
                value: {
                  src: bannerData?.image_url_mobile?.value,
                  alt: bannerData?.linkTitle?.value || bannerData?.heading?.value,
                },
              }}
              className={mobImg()}
            />
          )}
        </>
      </Link>
      {bannerData?.heading?.value?.length > 0 || bannerData?.link?.value?.length > 0 ? (
        <div className={contentWrapper()}>
          <Link
            aria-label="banner link"
            href={bannerData?.link?.value || '/'}
            className={linkWrapper()}
          >
            {bannerData?.linkTitle?.value}
          </Link>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
const PersionalizedHeaderBanner: React.FC = (): JSX.Element => {
  const { deskImg, mobImg } = personalizedHeaderBannerTailwindVariant({
    device: { initial: 'mobile', lg: 'desktop' },
  });
  const {
    queryResult: { data: { appearance: { variables: data = [] } = {} } = {} },
  } = useContentBlock();
  const bannerData: BannerData = data as unknown as BannerData;
  return (
    <div>
      <Link href={bannerData?.clickthrough_url?.value || '/'} aria-label="offer-link">
        <>
          {bannerData?.image_url?.value?.length > 0 && (
            <ImageHelper
              field={{
                value: {
                  src: bannerData?.image_url?.value,
                  alt: bannerData?.linkTitle?.value || "today's offer",
                },
              }}
              className={deskImg()}
            />
          )}
          {bannerData?.image_url_mobile?.value?.length > 0 && (
            <ImageHelper
              field={{
                value: {
                  src: bannerData?.image_url_mobile?.value,
                  alt: bannerData?.linkTitle?.value || "today's offer",
                },
              }}
              className={mobImg()}
            />
          )}
        </>
      </Link>
    </div>
  );
};
//creating widget
export const PersionalizedHeaderBannerWidgetWithLink = widget(
  PersionalizedHeaderBannerWithLink,
  WidgetDataType.BANNER,
  'PersionalizedHeaderBanner'
);
export const PersionalizedHeaderBannerWidget = widget(
  PersionalizedHeaderBanner,
  WidgetDataType.BANNER,
  'PersionalizedHeaderBanner'
);

export default function GlobalHeader({
  rendering,
  profileImages,
  headerDesktopData,
  headerMobileData,
}: DefaultData) {
  const [isActive, setisActive] = useState<string>('');
  const [isHamburgerOpenRender, isHamburgerOpenCss, setIsHamburgerOpen, isHamburgerOpenActual] =
    useStateAnimate(false);

  const [isUtilityOpen, setisUtilityOpen] = useState('');
  const [isMobUtilityOpen, setisMobUtilityOpen] = useState('');
  //reference to close the model
  const modelref = useRef<HTMLUListElement>(null);
  const liRef = useRef<HTMLLIElement>(null);
  const storeRef = useRef<HTMLUListElement>(null);
  const mobileStoreRef = useRef<HTMLDivElement | HTMLUListElement>(null);
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  const { isMobile: mobileDetected, isDesktop: desktopDetected } = useBreakpoints();
  const [globalSearchIcon, setGlobalSearchIcon] = useState<boolean>(false);
  const [, setHeightOffset] = useState(0);
  const [heightOffsetwithoutlogobar, setHeightOffsetWithoutLogoBar] = useState(0);
  //Store Data
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const { numberOfItemsInCart, numberOfItemsInCartSoftLogin } = useOcCart();

  //header tailwind-variaints

  const {
    base,
    deskBase,
    navigationLink,
    ulWrapper,
    // searchwrapper,
    mobIcon,
    miniCartIcon,
    mobRightWrapper,
    mobLeftWrapper,
    deskHidden,
    mobUtilityNav,
    utilityLi,
    justifyBetween,
    deskUtilityWrapper,
    utilityUl,
    storeLocator,
    itemWrapper,
    fillBase,
    iconSmall,
    mobileWrapper,
    deskNavItem,
    utilityImage,
    iconFit,
    leftUtilityText,
    mobCloseFont,
    justifyCenter,
    pr10,
    utilityLogo,
    itemsBaseLine,
    justifyNormal,
    cursorNone,
    borderBottom,
    relative,
    bodySmallBold,
    miniCartUtilityOpen,
    deskUtilityOpen,
    utlitiCloseIcon,
    miniCartCloseBtnIcon,
    numberOfMobProduct,
    desktopWrapper,
    uponSearchTrigger,
  } = navigationTailwindVariant({
    isShown: isHamburgerOpenCss,
    device: { initial: 'mobile', lg: 'desktop', md: 'desktop' },
  });

  //handling scroll to top event
  useEffect(() => {
    isHamburgerOpenActual || isMobUtilityOpen !== ''
      ? ((document.body.style.overflowY = 'hidden'),
        (document.getElementsByTagName('html')[0].style.overflow = 'hidden'),
        window.scrollTo({
          top: 0,
        }))
      : ((document.body.style.overflowY = ''),
        (document.getElementsByTagName('html')[0].style.overflow = ''));
  }, [isHamburgerOpenActual, isMobUtilityOpen]);

  //handling mobile device state
  useEffect(() => {
    const handleResize = () => {
      if (window?.innerWidth >= 1024) {
        setIsHamburgerOpen(false);
        setisMobUtilityOpen('');
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //To detect outside click and close the primary navigation model
  useEffect(() => {
    function handleDivModel(event: MouseEvent) {
      const utilityRef = document?.querySelectorAll('[data-component-section=deskutility');
      const clickedOutside = Array.from(utilityRef).every(
        (ref) => !ref.contains(event.target as Node)
      );
      if (clickedOutside) {
        setisActive('');
      }
    }
    document.addEventListener('mouseup', handleDivModel);
    return () => {
      document.removeEventListener('mouseup', handleDivModel);
    };
  }, [modelref, isActive]);

  useEffect(() => {
    function handleLiModel(e: MouseEvent) {
      const utilityRef = document?.querySelectorAll('#utility');
      const minicartRef = document?.querySelectorAll('.mini-cart-utility-open');
      // const minicartIconRef = document?.querySelectorAll('.minicart-icon');

      const clickedOutside = Array.from(utilityRef).every((ref) => !ref.contains(e.target as Node));
      const clickedOutsideMinicart = Array.from(minicartRef).every(
        (ref) => !ref.contains(e.target as Node)
      );
      if (clickedOutside && clickedOutsideMinicart && !liRef.current?.contains(e.target as Node)) {
        if (componentContextData?.openMiniCart && !componentContextData?.showDeliveryThreashold) {
          setisUtilityOpen('');
        } else if (
          (isUtilityOpen !== 'My Cart' && componentContextData?.openMiniCart) ||
          !componentContextData?.showDeliveryThreashold
        ) {
          setisUtilityOpen('');
        }
        document.body.classList.remove('overflow-hidden');
      }
    }
    document.addEventListener('mouseup', handleLiModel);
    return () => {
      document.removeEventListener('mouseup', handleLiModel);
    };
  }, [componentContextData?.showDeliveryThreashold]);
  /***
   * Close the chnage my store pop up when click outside;
   */

  useEffect(() => {
    function handleDivModel(event: MouseEvent) {
      if (
        mobileStoreRef &&
        storeRef &&
        !mobileStoreRef?.current?.contains(event.target as Node) &&
        !storeRef?.current?.contains(event.target as Node)
      ) {
        componentContextData?.isChangeStoreOpen === true
          ? setcomponentContextData({
              ...componentContextData,
              isChangeStoreOpen: false,
            })
          : null;
      }
    }
    document.addEventListener('mouseup', handleDivModel);
    return () => {
      document.removeEventListener('mouseup', handleDivModel);
    };
  }, [mobileStoreRef, storeRef, componentContextData?.isChangeStoreOpen]);

  const pathName = useRealPathName();

  //handling mobile menu
  const handleHeaderMenu = () => {
    setIsHamburgerOpen(!isHamburgerOpenActual);
    setisMobUtilityOpen('');
    setisUtilityOpen((prev) => (prev?.length > 0 ? '' : ''));
  };

  //handling overflow for mobile Utility nav
  useEffect(() => {
    isHamburgerOpenActual
      ? document.getElementById('desknav')?.classList.add('!overflow-x-hidden')
      : document.getElementById('desknav')?.classList.remove('!overflow-x-hidden');
  }, [isHamburgerOpenActual]);

  //Open and close the Desktop Utility
  const handleisDeskUtilityOpen = (val: string) => {
    setIsHamburgerOpen(false);
    setisActive('');
    isUtilityOpen === val ? setisUtilityOpen('') : setisUtilityOpen(val);
    val == 'My Cart'
      ? document.body.classList.add('overflow-hidden')
      : document.body.classList.remove('overflow-hidden');
  };

  //Open and close the Mobile Utility
  const handleismobUtilityOpen = (val: string) => {
    setisUtilityOpen('');
    setIsHamburgerOpen(false);
    val === isMobUtilityOpen ? setisMobUtilityOpen('') : setisMobUtilityOpen(val);
  };

  //Desktop Primary nav toggle
  const handleisActive = (val: string) => {
    setisUtilityOpen('');
    setisMobUtilityOpen('');
    setisActive(val);
  };

  const shouldRenderSearch = pathName !== currentPath?.isSearchPage;

  const activateSearchonMobileFunc = () => {
    setGlobalSearchIcon(!globalSearchIcon);
  };

  const isSoftLoginEnabled = useIsSoftLoginEnabled();

  useEffect(() => {
    // Function to calculate height of elements by class names
    const calculateHeight = (classNames: string[]): number => {
      const innerHeight = window?.innerHeight;
      const outerHeight = window?.outerHeight;
      const navHeight = outerHeight - innerHeight;
      return classNames.reduce((totalHeight, className) => {
        const element = document.querySelector(`.${className}`) as HTMLElement | null;
        if (element) {
          return totalHeight + element.offsetHeight;
        }
        return totalHeight + navHeight;
      }, 0);
    };

    // Function to recalculate heights and update state
    const recalculateHeights = () => {
      const classNamesToCheck = [
        'bottom-utility-nav',
        'mobile-headerlogo-wrapper',
        'alert-promo-banner',
      ];
      // const classNamesToCheckWithoutLogoBar = ['bottom-utility-nav', 'alert-promo-banner'];

      const totalHeight = calculateHeight(classNamesToCheck);
      // const totalHeightWithoutLogoBar = calculateHeight(classNamesToCheckWithoutLogoBar);

      setHeightOffset(totalHeight);
      setHeightOffsetWithoutLogoBar(totalHeight);
      // setHeightOffsetWithoutLogoBar(totalHeightWithoutLogoBar - 20);
    };

    // Initial calculation of heights
    recalculateHeights();

    // Set up a MutationObserver to monitor changes to the DOM
    const observer = new MutationObserver(() => {
      recalculateHeights(); // Recalculate when mutations occur
    });

    // Observe changes in the body (or a more specific parent if needed)
    const targetNode = document.body;
    observer.observe(targetNode, {
      childList: true,
      subtree: true,
    });

    // Cleanup the observer when the component unmounts
    return () => observer.disconnect();
  }, [isHamburgerOpenRender]);

  const RenderItemCount: React.FC = () => {
    const cartCount = isSoftLoginEnabled
      ? numberOfItemsInCartSoftLogin() !== undefined
        ? numberOfItemsInCartSoftLogin()
        : '0'
      : numberOfItemsInCart() !== undefined
      ? numberOfItemsInCart()
      : '0';
    return <span className={numberOfMobProduct()}>{cartCount}</span>;
  };

  //handle MiniCart close Icon Event
  const closeMiniCart = () => {
    setcomponentContextData({
      ...componentContextData,
      openMiniCart: false,
      showMinicartLoader: false,
    });
    handleisDeskUtilityOpen('');
  };

  //To handle miniCart View or Edit CTA link
  useEffect(() => {
    if (
      componentContextData?.openMiniCart === false &&
      componentContextData?.showMinicartLoader === false
    ) {
      handleisDeskUtilityOpen('');
    }
  }, [componentContextData?.openMiniCart]);

  /*
   * Rendering
   */

  return (
    <div id="header" className={base()}>
      {/**Utility Navigation */}

      <div className={clsx(deskUtilityWrapper(), justifyBetween())}>
        {/**Left Utility nav */}
        <div id="leftUtilityNav">
          <ul className={clsx(justifyBetween(), itemsBaseLine())}>
            <li
              className={clsx(
                'top-level-parent',
                itemWrapper({ className: 'mb-1' }),
                leftUtilityText()
              )}
            >
              <ChangeMyStore
                isHamburgerOpenCss={isHamburgerOpenCss}
                data={headerMobileData}
                rendering={rendering}
                storeRef={storeRef}
              />
            </li>
            {headerDesktopData?.datasource?.desktopLeftUtilityNav?.targetItems?.map(
              (links, index) => {
                {
                  /** Left utility Navigation*/
                }
                return (
                  <UtilityNav
                    liref={liRef}
                    key={links?.name}
                    item={links}
                    direction={'left'}
                    isUtilitiOpen={isUtilityOpen}
                    setisutilitiOpen={handleisDeskUtilityOpen}
                    navIndex={index}
                    rendering={rendering}
                  />
                );
              }
            )}
          </ul>
        </div>
        <div className={utilityImage()}>
          {headerDesktopData?.datasource?.logoLink && (
            <LinkHelper
              field={headerDesktopData?.datasource?.logoLink?.jsonValue}
              hideLinkInEE
              editable={false}
            >
              {/** utility Navigation Logo */}
              <div className={justifyCenter()}>
                {
                  <ImageHelper
                    field={headerDesktopData?.datasource?.desktopLogo?.jsonValue}
                    height={68}
                    width={302}
                    className={utilityLogo()}
                    priority
                  />
                }
              </div>
            </LinkHelper>
          )}
        </div>
        <div id="rightUtilityNav">
          {/**Right Utility nav */}
          <ul className={clsx(justifyBetween(), itemsBaseLine())}>
            {headerDesktopData?.datasource?.desktopRightUtilityNav?.targetItems?.map(
              (links, index) => {
                {
                  /** Right utility navigation */
                }
                return (
                  <UtilityNav
                    liref={liRef}
                    key={links?.name}
                    item={links}
                    direction="right"
                    rendering={rendering}
                    isUtilitiOpen={isUtilityOpen}
                    setisutilitiOpen={handleisDeskUtilityOpen}
                    profileImages={profileImages?.item?.children?.results}
                    navIndex={index}
                  />
                );
              }
            )}
          </ul>
        </div>
      </div>
      {/**Primar Navigation */}
      {/* <div className={deskBase({ className: 'overflow-x-hidden' })} id={'desknav'}> */}
      <div className={deskBase()} id={'desknav'}>
        {/**Desktop Navigation */}
        <div className={desktopWrapper()}>
          {
            <div className={mobLeftWrapper({ className: 'globalHeader' })}>
              <div
                className={deskHidden()}
                onClick={() => {
                  handleHeaderMenu();
                }}
              >
                {!isHamburgerOpenActual ? (
                  <IconHelper icon={'menu'} className={mobIcon()} />
                ) : (
                  <IconHelper icon={'close'} className={mobIcon()} />
                )}
              </div>

              {headerMobileData?.datasource?.logoLink.jsonValue && !globalSearchIcon && (
                <LinkHelper
                  field={headerMobileData?.datasource?.logoLink.jsonValue}
                  hideLinkInEE
                  className={cursorNone()}
                  editable={false}
                >
                  <div className={deskHidden()}>
                    <ImageHelper
                      field={headerMobileData?.datasource?.mobileLogo?.jsonValue}
                      priority
                    />
                  </div>
                </LinkHelper>
              )}
            </div>
          }
          <ul className={clsx(ulWrapper({ className: 'flex-wrap' }), deskNavItem())}>
            {headerDesktopData?.datasource?.desktopPrimaryNav?.targetItems?.map((item, index) => {
              return (
                <DesktopNav
                  key={item?.name}
                  item={item}
                  modelref={modelref}
                  isActive={isActive}
                  handleisActive={handleisActive}
                  parentIndex={index}
                  rendering={rendering}
                />
              );
            })}
          </ul>
          <div
            className={clsx(
              mobRightWrapper(),
              {
                '!justify-end': !shouldRenderSearch,
                'lg:mr-[24px]': shouldRenderSearch,
                '[&>div>form>div]:!w-full': shouldRenderSearch && desktopDetected, // for avoiding wrap with DesktopNav items
              },
              'globalSearch'
            )}
          >
            {/* <input aria-label="input" type="text" placeholder="Search" className={searchwrapper()} /> */}
            {shouldRenderSearch && mobileDetected && globalSearchIcon && (
              <OriginalPreviewSearch
                rfkId={rfkidOf?.search}
                isSearchBar={true}
                triggerSearchOnMobile={globalSearchIcon}
                activateSearchonMobile={activateSearchonMobileFunc}
              />
            )}
            {shouldRenderSearch && desktopDetected && (
              <OriginalPreviewSearch
                rfkId={rfkidOf?.search}
                isSearchBar={true}
                triggerSearchOnMobile={globalSearchIcon}
              />
            )}
            <div
              className={clsx(
                deskHidden(),
                justifyBetween(),
                uponSearchTrigger({ turnSearchOn: globalSearchIcon }),
                '!justify-end'
              )}
            >
              {!globalSearchIcon && shouldRenderSearch && (
                <IconHelper
                  icon={'search'}
                  className={mobIcon()}
                  onClick={() => {
                    setGlobalSearchIcon(true);
                  }}
                />
              )}
              {/* Cart Icon for Mobile */}
              <IconHelper
                icon={'shopping-cart'}
                className={clsx(mobIcon(), miniCartIcon(), {})}
                onClick={() => {
                  if (isUtilityOpen === 'My Cart') {
                    document.body.classList.remove('overflow-hidden');
                    setisUtilityOpen('');
                  } else {
                    document.body.classList.add('overflow-hidden');
                    setcomponentContextData({
                      ...componentContextData,
                      showDeliveryThreashold: false,
                      openMiniCart: true,
                    });
                  }
                }}
              />
              <RenderItemCount />
              {isUtilityOpen == 'My Cart' ? (
                <>
                  <div className={isUtilityOpen === 'My Cart' ? 'backdrop-shadow' : ''}></div>
                  <li
                    //ref={liref as React.LegacyRef<HTMLLIElement>}
                    className={clsx(`sticky-div  fixed top-0'}`, miniCartUtilityOpen(), 'right-0')}
                  >
                    <button
                      aria-label={'close the menu'}
                      //className={(flexEnd(), miniCartCloseBtn)()}
                    >
                      <IconHelper
                        icon={'close'}
                        className={clsx(utlitiCloseIcon(), miniCartCloseBtnIcon())}
                        onClick={() => {
                          closeMiniCart();
                        }}
                      />
                    </button>

                    <div className={justifyBetween()}>
                      {rendering && (
                        <Placeholder
                          name="mini-cart"
                          rendering={rendering}
                          onMiniCartCloseClick={closeMiniCart}
                        />
                      )}
                    </div>
                  </li>
                </>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        {/**Mobile Store Locator */}
        {!isHamburgerOpenRender && (
          <div
            className={clsx(relative({ className: 'block lg:hidden mobileStoreLocator' }))}
            ref={mobileStoreRef as LegacyRef<HTMLDivElement>}
          >
            <div
              className={clsx(storeLocator(), justifyBetween(), justifyNormal())}
              onClick={() => {
                setcomponentContextData({
                  ...componentContextData,
                  isChangeStoreOpen: !componentContextData?.isChangeStoreOpen,
                }),
                  setIsHamburgerOpen(false);
              }}
            >
              <div>
                <ImageHelper
                  field={headerMobileData?.datasource?.locationLogo?.jsonValue}
                  height={20}
                  width={20}
                />
              </div>
              <Text field={headerMobileData?.datasource?.locationText} />
              <span className={bodySmallBold()}>{myStoreData?.storeName}</span>
              <IconHelper icon={'Caret'} className={fillBase({ className: 'ml-1' })} />
            </div>
            <li
              className={clsx(
                deskUtilityOpen({
                  className: '!p-0 w-full !min-w-0 !mt-0',
                })
              )}
            >
              {rendering && <Placeholder name="change-store-widget" rendering={rendering} />}
            </li>
          </div>
        )}

        {/**Mobile Navigation */}
        {isHamburgerOpenRender && (
          <div
            className={clsx(mobileWrapper({ className: 'mobileNavigation overflow-y-auto' }))}
            id={'mobileNav'}
            style={{ paddingBottom: `330px`, height: '100vh' }}
          >
            <ul
              className={clsx(ulWrapper(), borderBottom())}
              onClick={() => {
                setIsHamburgerOpen(false);
              }}
            >
              <li className={clsx(navigationLink(), justifyBetween())}>
                {headerMobileData?.datasource?.title && (
                  <TextHelper tag="p" field={headerMobileData?.datasource?.title} />
                )}
                <div className={mobCloseFont()}>
                  {headerMobileData?.datasource?.closeText && (
                    <TextHelper
                      tag="p"
                      field={headerMobileData?.datasource?.closeText}
                      className={pr10()}
                    />
                  )}
                  <IconHelper icon={'close'} className={clsx(fillBase(), iconSmall(), iconFit())} />
                </div>
              </li>
            </ul>
            {headerMobileData?.datasource?.mobilePrimaryNav?.targetItems?.map((item, index) => {
              return (
                item && (
                  <MobileNav
                    setIsHamburger={setIsHamburgerOpen}
                    key={item?.name}
                    item={item}
                    height={heightOffsetwithoutlogobar}
                    isActive={isActive}
                    backText={headerMobileData?.datasource?.backText}
                    parentIndex={index}
                    rendering={rendering}
                  />
                )
              );
            })}
            {/* <div className={h250()}></div> */}
          </div>
        )}
      </div>

      {/**Mobile Utilitynav */}
      <div className={mobUtilityNav({ className: 'bottom-utility-nav' })}>
        <ul className={clsx(justifyBetween(), utilityUl())}>
          {headerMobileData?.datasource?.mobileUtilityNav?.targetItems?.map((mutility) => {
            return (
              <MobileUtility
                key={mutility?.name}
                item={mutility}
                handleisUtilityOpen={handleismobUtilityOpen}
                isUtilityOpen={isMobUtilityOpen}
                profileImages={profileImages?.item?.children?.results}
              />
            );
          })}
          <li className={utilityLi()}>
            <div onClick={() => handleHeaderMenu()}>
              <ImageHelper field={headerMobileData?.datasource?.moreLogo?.jsonValue} />
              <Text field={headerMobileData?.datasource?.moreText} />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export const getStaticProps: GetStaticComponentProps = async (rendering, layoutData, context) => {
  if (rendering.dataSource && layoutData?.sitecore?.context?.language) {
    // Header Data
    const graphQLClient = graphqlClientFactory({});
    const desktopResult = await graphQLClient.request<Data>(headerQuery, {
      datasource: rendering.dataSource,
      params: rendering.params,
      language: layoutData?.sitecore?.context?.language,
      itemID: layoutData?.sitecore?.route?.itemId,
    });

    const mobileResult = await graphQLClient.request<Data>(MobileHeaderQuery, {
      datasource: rendering.dataSource,
      params: rendering.params,
      language: layoutData?.sitecore?.context?.language,
      itemID: layoutData?.sitecore?.route?.itemId,
    });

    // Profile Image with Specie Personalization
    const sitecore = layoutData?.sitecore;
    const sitecoreContext = sitecore?.context;
    const siteName = sitecoreContext?.site?.name as string;
    const siteNameUpper = siteName?.toUpperCase() as SiteNameUppercase;

    let datasourceItem: string;

    if (siteNameUpper === 'PSP') {
      datasourceItem = '{27A2AD57-966E-4EC6-8EE9-F25945F4C151}';
    } else {
      datasourceItem = '{DC436F5A-3AFC-4110-8220-8B493379D6B6}';
    }
    const data = await graphQLClient.request<ProfileImagesProps>(getProfileImages(datasourceItem), {
      datasource: rendering?.dataSource,
      language: layoutData?.sitecore?.context?.language,
    });

    return {
      headerDesktopData: desktopResult,
      headerMobileData: mobileResult,
      profileImages: data,
    };
  }
  throw new Error(
    `No datasource set for rendering: ${rendering.componentName}, Guid: ${
      layoutData.sitecore.route?.itemId
    }, Path: ${
      context.params?.path instanceof Array ? context.params.path.join('/') : context.params?.path
    } `
  );
};

function ChangeMyStore({
  isHamburgerOpenCss,
  data,
  rendering,
  storeRef,
}: {
  isHamburgerOpenCss: boolean;
  data: Data | undefined;
  rendering: ComponentRendering;
  storeRef: RefObject<HTMLUListElement>;
}) {
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);

  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLLIElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      setcomponentContextData({
        ...componentContextData,
        isChangeStoreOpen: !componentContextData?.isChangeStoreOpen,
      });
    } else if (event.key === 'Escape' && componentContextData?.isChangeStoreOpen) {
      setcomponentContextData({
        ...componentContextData,
        isChangeStoreOpen: false,
      });
    }
  };

  const {
    justifyBetween,
    img,
    w30,
    fillBase,
    itemsBaseLine,
    storeLocatorText,
    utilityFont,
    relative,
    flexRow,
    deskUtilityOpen,
  } = navigationTailwindVariant({
    isShown: isHamburgerOpenCss,
    device: { initial: 'mobile', lg: 'desktop', md: 'desktop' },
  });
  return (
    <ul className={clsx(relative())} id={'changeMyStore'} ref={storeRef}>
      <li
        tabIndex={0}
        role="button"
        aria-expanded={componentContextData?.isChangeStoreOpen}
        className={clsx('top-level-parent', justifyBetween({ className: 'cursor-pointer' }))}
        onClick={() => {
          setcomponentContextData({
            ...componentContextData,
            isChangeStoreOpen: !componentContextData?.isChangeStoreOpen,
          });
        }}
        onKeyDown={handleKeyDown}
      >
        <div className={img()}>
          <ImageHelper
            field={data?.datasource?.locationLogo?.jsonValue}
            height={30}
            width={30}
            className={w30()}
          />
        </div>
        <div>
          <Text tag={'p'} field={data?.datasource?.locationText} className={storeLocatorText()} />
          <div className={clsx(flexRow(), itemsBaseLine())}>
            <span className={utilityFont()}>
              {myStoreData?.storeName}, {myStoreData?.state}
            </span>
            <IconHelper icon={'Caret'} className={fillBase({ className: 'ml-2' })} />
          </div>
        </div>
      </li>
      <li className={clsx(deskUtilityOpen({ className: '!p-0 min-w-[425px]' }))}>
        {rendering && <Placeholder name="change-store-widget" rendering={rendering} />}
      </li>
    </ul>
  );
}
