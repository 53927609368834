import React, { useEffect, useState } from 'react';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { storeNavigationStoreDetailTailwindVariant } from 'tailwindVariants/components/storeNavigationStoreDetailTailwindVariant';

interface linkList {
  id?: string;
  url?: string;
  name?: string;
  displayName?: string;
  fields?: {
    link?: LinkField;
  };
}
export type StoreNavigationProps = {
  fields?: {
    data?: {
      storeLandingSection?: {
        sectionList?: { jsonValue?: linkList[] };
      };
    };
  };
};

const StoreNavigation = ({ fields }: StoreNavigationProps): JSX.Element => {
  const { tabsWrapper, tabLink, base } = storeNavigationStoreDetailTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const [navigationHeight, setNavigationHeight] = useState(0);

  useEffect(() => {
    const sectionSelector = document.getElementById('section-selector');
    if (sectionSelector) {
      setNavigationHeight(sectionSelector.offsetHeight);
    }
  }, []);
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, id: string | undefined) => {
    if (id) {
      e.preventDefault();
      const element = document.getElementById(id);
      if (element) {
        const offset = element.offsetTop - navigationHeight; // Adjust the offset as needed
        window.scrollTo({
          top: offset,
          behavior: 'smooth',
        });
      }
    }
  };
  return (
    <>
      {fields?.data?.storeLandingSection?.sectionList?.jsonValue &&
        fields?.data?.storeLandingSection?.sectionList?.jsonValue?.length > 0 && (
          <div className={base()} id="section-selector">
            <div className={tabsWrapper()}>
              {fields?.data?.storeLandingSection?.sectionList?.jsonValue?.map(
                (link: linkList, index: number) => {
                  return (
                    <div id={link?.fields?.link?.value?.href?.replace('#', 'store-')} key={index}>
                      {link?.fields?.link && (
                        <LinkHelper
                          onClick={(e) =>
                            handleClick(e, link?.fields?.link?.value?.href?.replace('#', ''))
                          }
                          field={link?.fields?.link}
                          className={tabLink()}
                        />
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
    </>
  );
};
export default StoreNavigation;
