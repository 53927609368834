import { tv } from 'tailwind-variants';

export const accountManagementSplitterTailwindVariant = tv(
  {
    slots: {
      base: [''],
      rowClasses: ['flex', 'w-full', 'gap-desk-margin-loose-right'],
      leftColumn: [''],
      rightColumn: [''],
    },
    variants: {
      device: {
        mobile: {
          rowClasses: ['flex-col'],
          leftColumn: [''],
          rightColumn: ['px-4'],
        },
        desktop: {
          rowClasses: ['container', 'flex-row'],
          base: ['py-desk-padding-tight-y', 'px-desk-global-grid-margin'],
          leftColumn: ['w-1/3', 'max-w-[334px]'],
          rightColumn: ['w-2/3', 'flex-1', 'px-0'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
