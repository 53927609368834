import {
  Field,
  ImageField,
  LinkField,
  NextImage,
  RichText,
  Text,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import { ComponentProps } from 'lib/component-props';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { favouriteFriendsArticleTailwindVariant } from 'tailwindVariants/components/favouriteFriendsArticleTailwindVariant';
import { favouriteFriendsTailwindVariant } from 'tailwindVariants/components/favouriteFriendsTailwindVariant';

const Article = ({ isReverse, article }: { isReverse?: boolean; article: FeaturedItem }) => {
  const {
    articleBase,
    articleContentWrapper,
    articleContent,
    articleDescription,
    articleTitle,
    articleLink,
    articleImage,
  } = favouriteFriendsArticleTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
    isReverse: isReverse,
  });
  return (
    <div className={articleBase()}>
      <div className={articleContentWrapper() + ' lg:w-[calc(100%-500px)] xl:w-[calc(100%-650px)]'}>
        <div className={articleContent()}>
          <RichText className={articleTitle()} field={article?.fields?.title} tag="p" />
          <RichText className={articleDescription()} field={article?.fields?.description} tag="p" />
        </div>
        {article?.fields?.link && (
          <LinkHelper className={articleLink()} field={article?.fields?.link} />
        )}
      </div>
      {article?.fields?.image?.value?.src && (
        <NextImage className={articleImage()} field={article?.fields?.image} />
      )}
    </div>
  );
};
export type FavouriteFriendsProps = ComponentProps & {
  fields: {
    friendsList: Friend[];
    heading: {
      value: string;
    };
  };
};
interface Friend {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    image: ImageField;
    isDefaultFriend: {
      value: boolean;
    };
    name: Field<string>;
    featuredList: FeaturedItem[];
    link: LinkField;
  };
}
interface FeaturedItem {
  id: string;
  url: string;
  name: string;
  displayName: string;
  fields: {
    image: ImageField;
    subHead?: Field<string>;
    textAligment?: Field<string>;
    description?: Field<string>;
    title?: Field<string>;
    link?: LinkField;
  };
}
const FavouriteFriends = ({ fields, params }: FavouriteFriendsProps) => {
  const { base, sliderContainer, slider, container, sliderTitle, slideContainer } =
    favouriteFriendsTailwindVariant({
      size: {
        initial: 'mobile',
        lg: 'desktop',
      },
    });
  const getInitialIndex = () => {
    const selectedData = fields?.friendsList?.find(
      (slide: Friend) => slide?.fields?.isDefaultFriend?.value
    );
    if (selectedData) {
      return fields?.friendsList?.indexOf(selectedData);
    }
    return 0;
  };
  const splideRef = useRef<Splide | null>(null); // Define the ref type
  const router = useRouter();
  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(getInitialIndex());

  function onFriendsSlideChange(index: number) {
    splideRef?.current?.splide?.Components.Controller?.setIndex(index);
    splideRef?.current?.splide?.Components?.Move?.move(index, index, 0);
  }
  useEffect(() => {
    onFriendsSlideChange(activeSlideIndex);
  }, [activeSlideIndex]);
  useEffect(() => {
    const hash = router.asPath.split('#')[1];
    if (hash) {
      const selectedSlide = fields?.friendsList.find(
        (slide) => slide?.fields?.name?.value?.toLowerCase() === hash?.toLowerCase()
      );
      if (selectedSlide) {
        setActiveSlideIndex(fields?.friendsList.indexOf(selectedSlide));
        splideRef?.current?.splide?.Components.Controller?.setIndex(
          fields?.friendsList.indexOf(selectedSlide)
        );
        const currentPath = `${router.asPath.split('#')[0]}#${selectedSlide?.fields?.name?.value}`;
        window.history.replaceState({}, '', currentPath); // Update the URL without reloading the page
      }
    }
  }, [router.asPath]);

  if (fields === null || fields === undefined) return <></>;

  return (
    <>
      <div className={`${params?.style ?? ''}  ${base()}`}>
        <div className={container()}>
          <div className={sliderContainer()}>
            <RichText className={sliderTitle()} field={fields?.heading} tag="p" />
            <div className={slider()}>
              <Splide
                ref={splideRef}
                options={{
                  // type: isMobile ? 'loop' : 'slide',
                  arrows: false,
                  pagination: false,
                  perMove: 1,
                  gap: '20px',
                  perPage: 6,
                  breakpoints: {
                    1024: {
                      perPage: 6,
                      type: 'slide',
                    },
                    1023: {
                      arrows: true,
                      perPage: 2,
                      gap: '8px',
                      type: 'loop',
                    },
                  },
                  classes: {
                    prev: 'splide__arrow--prev slider-arrow slider-prev',
                    next: 'splide__arrow--next slider-arrow slider-next',
                  },
                }}
                onMoved={(_slide, currentIndex) => {
                  setActiveSlideIndex(currentIndex);
                  const currentPath = `${router.asPath.split('#')[0]}#${
                    fields?.friendsList[currentIndex]?.fields?.name?.value
                  }`;
                  window.history.replaceState({}, '', currentPath); // Update the URL without reloading the page
                }}
                onClick={(_slide, e) => {
                  onFriendsSlideChange(e.index);
                }}
              >
                {fields?.friendsList?.map((slide, index) => {
                  return (
                    <SplideSlide key={index}>
                      <div className={slideContainer()}>
                        <NextImage field={slide?.fields?.image} />
                        <Text tag="p" field={slide?.fields?.name} />
                      </div>
                    </SplideSlide>
                  );
                })}
              </Splide>
            </div>
          </div>
        </div>
      </div>
      <div className={container()}>
        {fields?.friendsList[activeSlideIndex]?.fields?.featuredList?.map((article, index) => {
          return (
            <Article
              key={index}
              isReverse={article?.fields?.textAligment?.value?.toLowerCase() === 'right'}
              article={article}
            />
          );
        })}
      </div>
    </>
  );
};

export default FavouriteFriends;
