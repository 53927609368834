import { Field, LinkField, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import React, { useEffect, useState } from 'react';
import { useTheme } from 'lib/context/ThemeContext';
import { Form as FormikForm, Formik, FormikValues, FormikHelpers } from 'formik';
import TextField from 'src/helpers/Form/TextField';
import { FormFieldsProps, transformData } from 'src/utils/formUtils';
import clsx from 'clsx';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import { apiRequest } from 'src/utils/apiWrapper';
import { useRouter } from 'next/router';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { ErrorCodes, formErrorFlags, getErrorKeyFromErrorCodes } from 'src/helpers/Constants';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { resetPasswordTailwindVariant } from 'tailwindVariants/components/resetPasswordTailwindVariant';
import { loginAPI } from 'src/utils/nextApiConfig';

export type ResetPasswordFormProps = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      data: {
        data: {
          title: Field<string>;
          submitButtonText: Field<string>;
          successRedirectUrl: { jsonValue: LinkField };
          successMessage: Field<string>;
          cancelButtonUrl?: { jsonValue: LinkField };
          errors: {
            values: { name: string; value: string }[];
          };
          failureMessage: Field<string>;
          disclaimer?: Field<string>;
          passwordCta: Field<string>;
          formFields: {
            targetItems: Array<FormFieldsProps>;
          };
        };
      };
    };
  };

interface FormValuesTypes {
  Password?: string;
  ConfirmPassword?: string;
}
export interface FieldValues {
  [key: string]: string;
}

//main component
const ResetPasswordForm = ({ fields, params }: ResetPasswordFormProps): JSX.Element => {
  const { themeName, themeNameUpper } = useTheme();
  const [fieldValues, setFieldValues] = useState<FieldValues>();
  const [showPassword, setShowPassword] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isResetLinkInValid, setIsResetLinkInValid] = useState<false | number>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const token = useAuthToken();
  const router = useRouter();
  const checkIsURLIsValid = async () => {
    const params = router?.query;
    delete params?.path;
    const headersData = {
      site: themeNameUpper, // for now, only PSP would work, WNW integration is left from OC Marketplace
      requesturl: window.location?.origin,
      Authorization: token,
      dataToSend: new URLSearchParams(params as { [key: string]: string }).toString(),
    };
    const options = { method: 'GET', headers: headersData };
    const endPoint = loginAPI?.validateresetpasswordlink;

    if (params && Object.keys(params).length > 0) {
      try {
        const response: number = await apiRequest(endPoint, options);
        if (response && response === ErrorCodes?.Success) {
          setIsResetLinkInValid(false);
        } else {
          setIsResetLinkInValid(response);
        }
        setIsLoading(false);
      } catch (e) {
        console.error('Error in fetching validation data', e);
      }
    }
  };
  useEffect(() => {
    checkIsURLIsValid();
  }, [router?.query]);
  //geting custome error message
  const customErrorMessage = fields?.data?.data?.errors?.values?.flatMap((error) => error);
  const matchPasswordErrorMessage = customErrorMessage?.filter(
    (err) => err?.name === formErrorFlags?.passwordMatch
  );
  const linkExpiredErrorMessage = customErrorMessage?.filter(
    (err) => err?.name === formErrorFlags?.linkExpired
  );
  //form field initial values
  const initialValues = {
    Password: '',
    ConfirmPassword: '',
  };
  //importing variants
  const {
    base,
    formWrapper,
    successMsg,
    form,
    button,
    title,
    fieldWrap,
    position,
    linkErrorWrapper,
    linkError,
  } = resetPasswordTailwindVariant({
    site: themeName,
    size: {
      initial: 'mobile',
      md: 'desktop',
    },
  });

  //geting individual field from the formFields array
  const transFormFields = transformData(fields.data?.data?.formFields);
  //sending Reset password request on form submission.
  const changePassword = async (
    values: FormikValues
    // formikHelpers: FormikHelpers<FormikValues>
  ) => {
    // 1. Payload:
    const passwordObj = {
      Password: values.Password,
    };
    const params = router?.query;
    delete params?.path;
    const headersData = {
      site: themeNameUpper, // for now, only PSP would work, WNW integration is left from OC Marketplace
      requesturl: window.location.origin,
      Authorization: token,
      dataToSend: new URLSearchParams(params as { [key: string]: string }).toString(),
    };

    //options for API Request
    const options = { method: 'POST', headers: headersData, data: passwordObj };
    const endPoint = loginAPI?.resetpassword;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const response: any = await apiRequest(endPoint, options);
    if (response && response.Errors) {
      setShowError(true);
    }
    //on success redirecting on login page or dynamic page
    if (response === ErrorCodes?.Success) {
      setShowError(false);
      setSuccess(true);
    } else if (response === ErrorCodes?.LinkExpired) {
      setShowError(true);
      setErrorMessage(decodeURI(linkExpiredErrorMessage[0]?.value));
    } else {
      setShowError(true);
      setErrorMessage(fields?.data?.data?.failureMessage?.value);
    }
  };

  //setting up values on change of the individual field
  const setValues = (values: FieldValues) => {
    setFieldValues({
      ...values,
    });
  };
  //handling show button functionality
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // check into URL if has query param - ReturnUrl.
  // If yes, append that into Go to Login CTA.
  const returnUrl = router?.query?.ReturnUrl || router?.query?.returnurl;

  const [appendedUrl, setAppendedUrl] = useState(
    fields.data.data.successRedirectUrl.jsonValue.value.href
  );

  useEffect(() => {
    if (returnUrl) {
      const { favoritesku, clickFrom } = router.query;
      const url = new URL(
        fields.data.data.successRedirectUrl.jsonValue.value.href + '?ReturnUrl=' + returnUrl,
        window.location.origin
      );
      returnUrl && url.searchParams.set('ReturnUrl', returnUrl?.toString());
      favoritesku && url.searchParams.set('favoritesku', favoritesku?.toString());
      clickFrom && url.searchParams.set('clickFrom', clickFrom?.toString());
      setAppendedUrl(url.toString());
    }
  }, [returnUrl]);

  //return empty fregment if there is no data
  if (fields === undefined || fields === null) return <></>;

  return (
    <>
      {!isLoading && (
        <>
          {!isResetLinkInValid ? (
            <div className={base({ className: params?.Styles ?? '' })}>
              {success ? (
                <div
                  className={successMsg({ className: 'flex flex-col gap-[12px] md:gap-[24px]' })}
                >
                  <RichTextHelper field={fields?.data?.data?.successMessage} />
                  {/* Earlier above RTE field used to contain Login CTA. Now, we will required to separate into abnother field  */}
                  {/* For example, we can use successRedirectUrl to act as CTA*/}
                  {fields?.data?.data?.successRedirectUrl?.jsonValue?.value?.text && (
                    <LinkHelper
                      className={button()}
                      field={{
                        value: {
                          ...fields?.data?.data?.successRedirectUrl?.jsonValue?.value,
                          href: appendedUrl,
                        },
                      }}
                    />
                  )}
                </div>
              ) : (
                <>
                  <TextHelper tag="p" className={title()} field={fields?.data?.data.title} />
                  <div className={formWrapper()}>
                    <Formik
                      initialValues={initialValues}
                      onSubmit={(
                        values: FormValuesTypes,
                        { setSubmitting }: FormikHelpers<FormValuesTypes>
                      ) => {
                        changePassword(values);
                        setSubmitting(false);
                      }}
                    >
                      <FormikForm className={form()}>
                        {showError && <div className="text-system-red">{errorMessage}</div>}
                        <div className={clsx(fieldWrap(), position())}>
                          <TextField
                            {...transFormFields.Password}
                            fieldtype={showPassword ? 'text' : 'password'}
                            className={''}
                            setFieldValue={setValues}
                            togglePasswordVisibility={togglePasswordVisibility}
                            showPassword={showPassword}
                          />
                        </div>

                        <div className={clsx(fieldWrap(), position())}>
                          <TextField
                            {...transFormFields.ConfirmPassword}
                            fieldtype={showConfirmPassword ? 'text' : 'password'}
                            className={''}
                            matchValue={fieldValues && fieldValues['Password']}
                            togglePasswordVisibility={toggleConfirmPasswordVisibility}
                            showPassword={showConfirmPassword}
                            customErrorMessage={decodeURI(matchPasswordErrorMessage?.[0]?.value)}
                          />
                        </div>
                        <button aria-label="submit" type="submit" className={button()}>
                          {
                            /* <TextHelper tag="p" field= */ fields?.data?.data?.submitButtonText
                              ?.value
                          }{' '}
                        </button>
                      </FormikForm>
                    </Formik>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className={linkErrorWrapper()}>
              <div className={linkError()}>
                {decodeURI(
                  fields?.data?.data?.errors?.values?.find(
                    (error) => error?.name === getErrorKeyFromErrorCodes(isResetLinkInValid)
                  )?.value || ''
                )}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default withDatasourceCheck()<ResetPasswordFormProps>(ResetPasswordForm);
