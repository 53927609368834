import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useEffect, useState } from 'react';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { useRouter } from 'next/router';

export type PlainHTMLProps = {
  params?: { styles?: string };
  fields?: {
    plainHTML?: Field<string>;
  };
};

// Set to cache loaded external scripts
const loadedScripts = new Set<string>();

/**
 * A component that renders a plain HTML field, executing any scripts it may contain
 * only if not in Experience Editor mode.
 *
 * @param {PlainHTMLProps} props The component props.
 * @returns {JSX.Element} The component.
 */
const PlainHTML = (props: PlainHTMLProps): JSX.Element => {
  const isEE = useExperienceEditor();
  const router = useRouter();
  const [scriptData, setScriptData] = useState('');

  /**
   * Execute any scripts within the given HTML content.
   *
   * Handles both inline and external scripts, and will re-inject any scripts that
   * have been removed by a previous call to this function.
   *
   * @param {string} htmlContent The HTML content to search for scripts.
   */
  const executeScripts = (htmlContent: string) => {
    const scriptTags = document.createElement('div');
    scriptTags.innerHTML = htmlContent;

    const scripts = scriptTags.getElementsByTagName('script');

    // Remove previously added inline and external scripts
    const existingScripts = document.querySelectorAll('.injected-script');
    existingScripts.forEach((script) => script.remove());

    for (let i = 0; i < scripts.length; i++) {
      const newScript = document.createElement('script');
      newScript.classList.add('injected-script'); // Mark the script for easier removal

      if (scripts[i].src) {
        // External script - handle caching
        if (!loadedScripts.has(scripts[i].src)) {
          loadedScripts.add(scripts[i].src);
        } else {
          const existingScript = document.querySelector(`script[src="${scripts[i].src}"]`);
          if (existingScript) {
            document.body.removeChild(existingScript);
          }
        }
        // Re-inject external script
        newScript.src = scripts[i].src;
        newScript.async = true;
        newScript.onload = scripts[i].onload;
        document.body.appendChild(newScript);
      } else {
        // Inline script - always re-inject
        newScript.text = scripts[i].innerHTML;
        document.body.appendChild(newScript);
      }
    }
  };

  useEffect(() => {
    if (!isEE && props?.fields?.plainHTML?.value && typeof window !== 'undefined') {
      setScriptData(props.fields.plainHTML.value);
      executeScripts(props.fields.plainHTML.value);
    }
  }, [isEE, props?.fields?.plainHTML?.value]);

  // Re-run scripts after every client-side navigation
  useEffect(() => {
    const handleRouteChange = () => {
      if (!isEE && props?.fields?.plainHTML?.value && typeof window !== 'undefined') {
        setScriptData(props.fields.plainHTML.value);
        executeScripts(props.fields.plainHTML.value);
      }
    };

    // Add event listener for route changes
    router.events.on('routeChangeComplete', handleRouteChange);

    // Clean up the event listener on component unmount
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [isEE, props?.fields?.plainHTML?.value, router.events]);

  if (isEE) {
    return <Text field={props?.fields?.plainHTML} />;
  }

  return props?.fields?.plainHTML && !isEE ? (
    <div
      className={`component rich-text rte container ${props?.params?.styles?.trimEnd()}`}
      dangerouslySetInnerHTML={{ __html: scriptData }}
    />
  ) : (
    <></>
  );
};

export default PlainHTML;
