import { tv } from 'tailwind-variants';

export const loginFormTailwindVariant = tv(
  {
    slots: {
      loginFormWrapper: ['loginForm', 'flex', 'flex-col', 'gap-y-[24px]'],
      form: ['flex', 'flex-col', 'gap-y-[24px]', 'items-start'],
      fieldWrap: ['flex', 'justify-between', 'w-full'],
      position: 'relative',
      ctaLink: [
        'text-color-brand-primary-1-base',
        'font-body-large-bold_underline',
        'text-body-large-bold_underline',
        'leading-body-large-bold_underline',
        'underline cursor-pointer',
      ],
      button: [
        'flex',
        'content-center',
        'items-center',
        'rounded-desk-global-radius-small',
        'text-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'outline-none',
        'justify-center',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
      ],
      consentMsg: [
        'text-color-text-black',
        'text-body-small-bold',
        'font-body-small-bold',
        'leading-body-small-bold',
      ],
      userFields: ['flex', 'flex-col', 'w-full'],
      loaderWrapper: ['flex', 'items-center'],
      formTitle: ['pb-[12px]'],
      buttonWrapper: ['flex'],
      migratedUserMessage: ['rte', 'migratedUserMessage', '[&>strong]:cursor-pointer'],
    },
    compoundSlots: [],
    variants: {
      site: {
        psp: {},
        wnw: {},
      },
      size: {
        mobile: {
          fieldWrap: [
            'gap-x-mob-space-between-loose-horizontal',
            'flex-col',
            'gap-mob-space-between-base-vertical',
          ],
          button: [
            'gap-mob-component-button-full-space-between',
            'py-mob-component-button-full-padding-y',
            'px-mob-component-button-full-padding-x',
            'w-full',
          ],
          userFields: ['gap-mob-space-between-tight-vertical'],
          loaderWrapper: [
            'gap-mob-component-button-full-space-between',
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          formTitle: [
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
            'text-heading-mob-large-bold',
          ],
          buttonWrapper: ['w-full', 'justify-center'],
        },
        desktop: {
          fieldWrap: [
            'gap-x-desk-space-between-loose-horizontal',
            'flex-row',
            'gap-none',
            'flex',
            'flex-col',
            'items-end',
          ],
          button: [
            'gap-desk-component-button-full-space-between',
            'py-desk-component-button-full-padding-y',
            'px-component-button-full-padding-x',
          ],
          userFields: ['gap-desk-space-between-tight-vertical'],
          loaderWrapper: [
            'gap-desk-component-button-full-space-between',
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          formTitle: [
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
            'text-heading-desk-large-bold',
          ],
          buttonWrapper: ['basic-1/2', 'w-fit'],
        },
      },
      outlineCta: {
        true: {
          button: [
            'bg-color-background-white',
            'text-color-text-brand-1',
            'border-2 border-solid border-color-brand-primary-1-dark',
            'hover:bg-color-brand-primary-1-dark hover:text-color-text-white',
            'focus:bg-color-brand-primary-1-dark focus:text-color-text-white',
          ],
        },
        false: {
          button: [
            'bg-color-brand-primary-1-base',
            'text-color-text-white',
            'hover:bg-color-brand-primary-1-dark',
            'focus:bg-color-brand-primary-1-dark',
          ],
        },
      },
      isDisabled: {
        true: {
          button: ['cursor-not-allowed'],
        },
        false: {
          button: ['pointer'],
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'], // `true` to apply to all screen sizes
  }
);
