import { Tokens } from 'ordercloud-javascript-sdk';
import { useEffect, useState } from 'react';

export function useIsSoftLoginEnabled() {
  const localUserToken = Tokens.GetAccessToken();

  const [isSoftLoginEnabled, setIsSoftLoginEnabled] = useState<boolean>(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (sessionStorage.getItem('isSoftLoginEnabled') == 'true') {
        setIsSoftLoginEnabled(true);
      } else {
        setIsSoftLoginEnabled(false);
      }
    }
  }, [localUserToken]);

  return isSoftLoginEnabled;
}
