import { tv } from 'tailwind-variants';

export const storeLocatorGlobalPopupTailwindVariant = tv(
  {
    slots: {
      base: ['storeLocatorGlobalPopup'],
      loaderWrapper: 'flex items-center',
      loadingText: 'font-primary',
      noSearchResultsFoundText:
        'text-body-small-regular font-body-small-regular leading-body-small-regular text-system-red mt-mob-margin-micro-bottom lg:mt-desk-margin-micro-bottom',
      loadMoreButton: 'w-full flex justify-center',
      modalWrapper: '',
      loadMore:
        'flex group underline h-fit w-fit font-primary text-color-text-brand-1 hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
      headingWrapper: 'flex flex-col',
      input:
        'w-full h-[56px] text-color-text-dark placeholder-color-text-dark px-desk-padding-micro-x py-desk-padding-micro-y font-primary text-body-large-regular leading-body-large-regular font-body-large-regular focus:outline-none border-[1px] border-color-border-mid mt-mob-margin-micro-bottom lg:mt-desk-margin-micro-bottom',
      heading: 'font-primary',
      subHeading:
        'font-primary text-body-large-regular leading-body-large-regular font-body-large-regular',
      formHeading: 'font-primary text-body-large-bold leading-body-large-bold font-body-large-bold',
      searchIcon: 'w-[40px] h-[40px] [&>svg]:w-[40px] [&>svg]:h-[40px]',
      storePageArrow:
        'ml-1 [&>svg>*]:stroke-color-text-brand-1 [&>svg>path]:fill-color-text-brand-1 h-[16px] w-[16px] [&_svg]:max-w-[16px] [&_svg]:max-h-[16px] group-hover:[&>svg>*]:stroke-color-brand-tertiary-3-base group-focus:[&>svg>path]:fill-color-brand-tertiary-3-base group-focus:[&>svg>*]:stroke-color-brand-tertiary-3-base group-hover:[&>svg>path]:fill-color-brand-tertiary-3-base',
      searchButton:
        'bg-color-background-brand-1 flex justify-center items-center w-[72px] h-[56px] mt-mob-margin-micro-bottom lg:mt-desk-margin-micro-bottom absolute right-0 top-0 hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        desktop: {
          loadMoreButton: 'pb-0',
          loadingText:
            'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
          loaderWrapper:
            'gap-desk-component-button-full-space-between py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x mt-desk-margin-base-top',
          headingWrapper: 'gap-desk-space-between-tight-vertical',
          modalWrapper: 'py-desk-padding-tight-y px-desk-padding-base-x',
          loadMore:
            'my-desk-margin-tight-top text-heading-desk-medium-bold leading-heading-desk-medium-bold font-heading-desk-medium-bold',
          heading:
            'font-heading-desk-xLarge-bold text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold',
        },
        mobile: {
          loadMoreButton: 'pb-6',
          loadingText:
            'text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold',
          loaderWrapper:
            'py-mob-component-button-full-padding-y mt-mob-margin-base-top gap-mob-component-button-full-space-between',

          modalWrapper: 'py-mob-padding-tight-y px-mob-padding-base-x',
          headingWrapper: 'gap-mob-space-between-tight-vertical',
          loadMore:
            'my-mob-margin-tight-top text-heading-mob-medium-bold leading-heading-mob-medium-bold font-heading-mob-medium-bold',
          heading:
            'font-heading-mob-xLarge-bold text-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold',
        },
      },
      isError: {
        true: {
          input: 'border-system-red',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
