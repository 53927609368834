import { tv } from 'tailwind-variants';

export const loyaltyTabSectionTailwindVariant = tv(
  {
    slots: {
      base: ['relative', 'my-desk-margin-base-bottom'],
      headingWrapper: [
        'py-desk-component-button-condensed-padding-y',
        'px-desk-padding-micro-x',
        'bg-color-background-brand-1',
      ],
      heading: ['text-white', 'font-heading-desk-medium-bold'],
      subHeadingWrapper: ['my-desk-margin-base-bottom'],
      loyaltyWidgets: ['loyalty-widgets'],
      loadMoreButton: [
        'py-desk-component-button-full-padding-y',
        'px-desk-component-button-full-padding-x',
        'gap-desk-component-button-full-space-between',
        'bg-color-color-background-white',
        'border-2',
        'rounded',
        'border-color-brand-primary-1-base',
        'text-color-text-brand-1',
        'text-heading-mob-large-bold',
        'font-heading-mob-large-bold',
        'leading-heading-mob-large-bold',
        'hover:text-white',
        'hover:border-color-brand-primary_1-base',
        'hover:bg-color-brand-primary-1-base',
      ],
      loadMoreButtonWrapper: ['relative', 'flex', 'justify-center'],
    },
    variants: {
      size: {
        mobile: {
          loadMoreButtonWrapper: ['mt-8'],
        },
        desktop: {
          loadMoreButtonWrapper: ['mt-0'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
