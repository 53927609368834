import { tv } from 'tailwind-variants';

//component variants
export const brandsListingTailwindVariant = tv(
  {
    slots: {
      base: ['brandsListing', 'container', 'mx-auto', 'flex', 'flex-col'],
      titleWrapper: ['w-full', 'text-center', 'mb-mob-padding-base-y'],
      brandWrapper: ['grid'],
      linkWraper: [
        'px-mob-padding-micro-y',
        'max-w-[180px]',
        'flex',
        'h-fit',
        'justify-center',
        'flex-col',
        'flex-col-reverce',
      ],
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        mobile: {
          base: ['py-mob-padding-tight-y'],
          titleWrapper: [
            'text-heading-mob-xLarge-bold ',
            'font-heading-mob-xLarge-bold ',
            'leading-heading-mob-xLarge-bold',
          ],
          brandWrapper: ['grid-cols-3', 'gap-y-mob-margin-loose-bottom'],
        },
        desktop: {
          base: ['py-desk-padding-tight-y'],
          titleWrapper: [
            'text-heading-desk-xLarge-bold ',
            'font-heading-desk-xLarge-bold ',
            'leading-heading-desk-xLarge-bold',
          ],
          brandWrapper: ['grid-cols-6', 'gap-y-desk-margin-loose-bottom'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
