import { tv } from 'tailwind-variants';

export const featuredTilesTailwindVariant = tv(
  {
    slots: {
      base: 'w-full featuredTiles ',
      fullWrapper: '',
      wrapper: 'container mx-auto',
      contentAndImage: 'flex-wrap	flex',
      image:
        'order-1 lg:order-none flex relative z-[0] [&>.sc-image-wrapper]:flex [&>.sc-image-wrapper]:h-full',
      contentBlockWrapper: 'order-2 lg:order-none w-full',
      contentBlock: ' grid gap-y-3 w-full px-desk-padding-micro-x',
      heading: 'font-heading-desk-xLarge-bol text-center',
      title:
        'text-heading-desk-large-bold leading-heading-desk-large-bold font-heading-desk-large-bold',
      closeIcon: '',
      content: '',
      link: 'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold hover:no-underline',
      playIcon: ' [&>svg>*]:fill-color-brand-primary-1-base inline-block',
      playButton: 'absolute top-1/2 left-1/2 -translate-x-1/2	-translate-y-1/2',
      playerWrapperModel: 'relative pt-[48%]',
      reactPlayer: 'absolute top-0 left-0',
    },
    variants: {
      size: {
        mobile: {
          base: '',
          heading:
            'py-mob-padding-tight-y text-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold',
          fullWrapper: 'py-mob-padding-tight-y',
          title: '',
          image: 'w-full',
          contentBlockWrapper: '',
          contentBlock: '',
          playIcon: '[&>svg]:w-[50px]',
          link: 'text-color-text-brand-1 hover:text-color-brand-tertiary-2-base',
        },
        desktop: {
          base: '',
          heading:
            'py-desk-padding-tight-y text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold',
          fullWrapper: 'py-desk-padding-tight-y',
          title: '',
          contentAndImage: 'w-full',
          image: '',
          contentBlockWrapper: ' ',
          contentBlock: '',
          content: 'text-heading-mob-small-regular mb-desk-margin-micro-bottom',
          link: 'py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x text-color-text-white hover:text-color-text-white bg-color-brand-primary-1-base hover:bg-color-brand-primary-1-dark rounded-desk-global-radius-small',
          playIcon: '[&>svg]:w-[81px]',
        },
      },

      backgroundTheme: {
        Dark: {
          contentAndImage: '',
          contentBlockWrapper:
            'w-full lg:w-[630px] lg:bg-color-scale-8-dark text-color-text-dark lg:text-color-text-white',
          image: 'w-1/2',
        },
        White: {
          fullWrapper: 'bg-color-background-white text-color-text-dark',
          contentBlockWrapper: 'w-full lg:w-[58.6%]',
          image: 'w-[41.4%] ',
        },
        Gray: {
          fullWrapper: 'text-color-text-dark ',
          contentAndImage:
            'bg-color-background-light lg:p-desk-padding-tight-x py-mob-padding-micro-y px-mob-padding-none',
          contentBlockWrapper:
            'lg:w-[646px] items-center	flex lg:py-desk-padding-tight-x py-mob-padding-tight-x',
          image: 'w-[44.2%] ',
        },
      },
    },
  },
  {
    responsiveVariants: ['sm', 'lg'],
  }
);
