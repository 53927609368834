import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import useExperienceEditor from 'src/hooks/useExperienceEditor';
import MobileBackToMenuWrapper from './MobileBackToMenuWrapper';
import MobileBackToMenuEE from './MobileBackToMenuEE';

export type MobileBackToMenuProps = {
  fields: {
    mobileBackToMenuDictionaryKey: Field<string>;
  };
};

const MobileBackToMenu = ({ fields }: MobileBackToMenuProps) => {
  const isExperienceEditor = useExperienceEditor();

  if (!isExperienceEditor) {
    return <MobileBackToMenuWrapper fields={fields} />;
  } else {
    return <MobileBackToMenuEE />;
  }
};

export default MobileBackToMenu;
