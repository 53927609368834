import { tv } from 'tailwind-variants';

export const jumpMenuTailwindVariant = tv(
  {
    slots: {
      navigationMenu: ['flex flex-row items-center gap-2 my-4'],
      navigationName: ['font-bold'],
      navigationDropdown: [
        'w-[200px]',
        'border-2',
        'border-color-border-mid',
        'bg-white',
        'py-[3px]',
        'px-desk-global-spacing-spacing-3',
        'rounded',
        'h-[34px]',
        'hover: cursor-pointer',
      ],
    },
    variants: {
      size: {
        mobile: {},
        desktop: {},
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
