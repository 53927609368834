import { tv } from 'tailwind-variants';

export const textBannerTailwindVariant = tv(
  {
    slots: {
      base: ['textBanner', 'relative', 'flex', 'justify-center', 'items-center'],
      desktopImage: [''],
      mobileImage: [''],
      img: [''],
      heading: [
        'text-center',
        'text-color-text-white',
        'text-heading-desk-xLarge-bold',
        'font-heading-desk-xLarge-bold',
        'leading-heading-desk-xLarge-bold',
      ],
    },
    compoundSlots: [{ slots: ['img', 'mobileImage'], class: ['h-full', 'w-full'] }],
    variants: {
      device: {
        mobile: {
          base: ['px-mob-padding-loose-x', 'py-mob-padding-loose-y'],
          mobileImage: ['absolute', 'left-0', 'top-0'],
          desktopImage: ['hidden'],
        },
        desktop: {
          base: ['px-0', 'py-desk-padding-micro-y'],
          mobileImage: ['hidden'],
          desktopImage: ['flex'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
