import clientFactory from 'lib/graphql-client-factory';

export async function edgeSafeGraphQLQuery<TReturn>(
  query: string,
  variables: Record<string, unknown>
) {
  const graphQlClient = clientFactory({ fetch: fetch });

  return await graphQlClient.request<TReturn>(query, variables);
}
