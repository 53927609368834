import { tv } from 'tailwind-variants';

export const storeNavigationStoreDetailTailwindVariant = tv(
  {
    slots: {
      base: 'sticky top-0 z-[2] bg-color-background-white section-selector',
      tabsWrapper: 'container mx-auto flex justify-center items-center flex-wrap',
      tabLink:
        'p-2 text-color-text-brand-1 underline text-body-large-bold font-body-large-bold leading-body-large-bold hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
    },
    variants: {
      size: {
        desktop: {
          base: 'block',
          tabsWrapper: 'px-0 py-desk-padding-micro-y gap-desk-space-between-base-horizontal',
        },
        mobile: {
          base: 'hidden',
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
