//global
import React, { useEffect, useState } from 'react';
// import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
//hooks
import useDictionary from 'src/hooks/useDictionary';
import BVRatingSummary from 'components/BazarVoice/BVRatingSummary';
import { useTheme } from 'lib/context/ThemeContext';
import { storeDetailTabTailwindVariant } from 'tailwindVariants/components/storeDetailTabTailwindVariant';
//type
export interface StoreDetailTabProps {
  bvProductId?: string;
  bullets?: string[];
  ingredientsComposition?: string;
  guaranteedAnalysisNutrientName?: string;
  GuaranteedAnalysisPDP?: string;
}
//component variants

//main component
const StoreDetailTab: React.FC<StoreDetailTabProps> = ({
  bvProductId,
  guaranteedAnalysisNutrientName,
  bullets,
  ingredientsComposition,
  GuaranteedAnalysisPDP,
}) => {
  //variants for StoreDetailTab
  const {
    base,
    contentWrapper,
    tabWrapper,
    tabs,
    tabTitle,
    description,
    whiteText,
    block,
    hidden,
    ul,
    guaranteedAnalysisPDPLabel,
  } = storeDetailTabTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const { themeName } = useTheme();

  //getting disctionary data for tab labels
  const { getDictionaryValue } = useDictionary();
  const [currentTab, setCurrentTab] = useState(getDictionaryValue('TabDeailsPDP'));
  //getting tab label from the dictionary and created array for individual tab
  const tabArray = [
    getDictionaryValue('TabDeailsPDP'),
    getDictionaryValue('TabIngredientsAndCompositionPDP'),
    getDictionaryValue('TabReviewsPDP'),
  ];
  useEffect(() => {
    document.body.classList.add(themeName);

    return function cleanup() {
      document.body.classList.remove(themeName);
    };
  }, []);
  return (
    <div className={base()} data-component={'components/StoreDetailTab/StoreDetailTab'}>
      <div className={contentWrapper()}>
        <div className={tabs()}>
          {tabArray?.map((tab, index) => {
            return (
              <div
                key={index}
                className={tabTitle({
                  className: tab === currentTab ? whiteText() : '',
                })}
                id={tab === getDictionaryValue('TabReviewsPDP') ? 'el-pReviews' : tab}
                onClick={() => setCurrentTab(tab)}
              >
                <p id={tab === getDictionaryValue('TabReviewsPDP') ? 'reviewSection' : ''}>{tab}</p>
              </div>
            );
          })}
        </div>
        <div className={description()}>
          <div className={currentTab === getDictionaryValue('TabDeailsPDP') ? block() : hidden()}>
            <ul className={ul()}>
              {bullets &&
                bullets?.map((detail) => {
                  return <li key={detail}>{detail}</li>;
                })}
            </ul>
          </div>
          <div
            className={
              currentTab === getDictionaryValue('TabIngredientsAndCompositionPDP')
                ? tabWrapper()
                : hidden()
            }
          >
            <div>{ingredientsComposition}</div>
            <div>
              <span className={guaranteedAnalysisPDPLabel()}>{GuaranteedAnalysisPDP}</span>
              <p>{guaranteedAnalysisNutrientName}</p>
            </div>
          </div>
          <div
            id="pReview"
            className={`bazaar-voice-pdp ${
              currentTab === getDictionaryValue('TabReviewsPDP') ? block() : hidden()
            }`}
          >
            {bvProductId && <BVRatingSummary bvProductId={bvProductId} />}
          </div>
        </div>
      </div>
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default StoreDetailTab;
