import { tv } from 'tailwind-variants';

export const oldLoyaltyWidgetTailwindVariant = tv(
  {
    slots: {
      base: ['w-full'],
      productRewardsBorder: ['inline-block'],
      productRewardsLink: ['product-rewards-link'],
      productRewardsImage: [''],
    },
    variants: {
      device: {
        mobile: {
          base: ['inline-block', 'overflow-x-auto', 'whitespace-nowrap'],
          productRewardsBorder: ['mr-mob-space-between-tight-horizontal'],
          productRewardsImage: ['w-[158px]'],
        },
        desktop: {
          base: ['overflow-x-auto', 'whitespace-normal'],
          productRewardsBorder: ['mr-5'],
          productRewardsImage: ['w-[230px]'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
