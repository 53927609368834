import { rfkidOf } from 'src/helpers/Constants';
import React from 'react';
import OriginalPreviewSearch from 'src/helpers/search/PreviewSearch';
import { previewSearchTailwindVariant } from 'tailwindVariants/components/previewSearchTailwindVariant';

const PreviewSearch: React.FC = () => {
  const { baseWrapper } = previewSearchTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  return (
    <div className={baseWrapper()}>
      <OriginalPreviewSearch rfkId={rfkidOf?.search} />
    </div>
  );
};

export default PreviewSearch;
