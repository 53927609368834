/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, unwrapResult } from '@reduxjs/toolkit';

import {
  BuyerAddress,
  Address,
  LineItems,
  Me,
  Orders,
  IntegrationEvents,
  RequiredDeep,
  Payment,
  Payments,
  SearchType,
  OrderWorksheet,
  OrderPromotion,
} from 'ordercloud-javascript-sdk';
import { createOcAsyncThunk } from '../ocReduxHelpers';
import { apiRequest } from 'src/utils/apiWrapper';
import { DiscreteLineItem, FulfillmentType } from 'src/helpers/Constants';
import {
  LineItemWithXp,
  OrderWithXp,
  PaymentWithXp,
  OrderWorksheetWithXP,
  OrderCalculateResponseWithXp,
} from '../xp';
import { checkoutAPI, orderPromotionApi } from 'src/utils/nextApiConfig';

export interface RecentOrder {
  order: RequiredDeep<OrderWithXp>;
  lineItems?: LineItemWithXp[];
  payments?: PaymentWithXp[];
}

export interface OcCurrentOrderState {
  initialized: null | boolean;
  order?: OrderWithXp;
  lineItems?: LineItemWithXp[];
  orderPromotions?: OrderPromotion[];
  orderCalculateResponse?: OrderCalculateResponseWithXp;
  payments?: PaymentWithXp[];
  shipEstimateResponse?: RequiredDeep<any>;
  recentOrders: RecentOrder[];
  calculating: boolean;

  anonDiscountOrder?: OrderWithXp;
  anonDiscountLineItems?: LineItemWithXp[];
  anonDiscountPromotions?: OrderPromotion[];
}

const initialState: OcCurrentOrderState = {
  initialized: null,
  calculating: false,
  recentOrders: [],
  payments: [],
};

export const removeAllPayments = createOcAsyncThunk<undefined, undefined>(
  'ocCurrentOrder/removeAllPayments',
  async (_paymentId, ThunkAPI) => {
    const { ocCurrentOrder } = ThunkAPI.getState();
    const queue: Promise<any>[] = [];
    const orderID = ocCurrentOrder.order?.ID;
    if (ocCurrentOrder.payments && orderID) {
      ocCurrentOrder.payments.forEach((p) => {
        if (p.ID) {
          queue.push(Payments.Delete('Outgoing', orderID, p.ID));
        }
      });
    }
    await Promise.all(queue);
    return undefined;
  }
);

export const retrievePayments = createOcAsyncThunk<RequiredDeep<any>[], string>(
  'ocCurrentOrder/retrievePayments',
  async (orderId, _ThunkAPI) => {
    const response = await Payments.List('Outgoing', orderId, { pageSize: 100 });
    return response.Items;
  }
);

export const reapplyPromotions = createOcAsyncThunk<void, void>(
  'ocCurrentOrder/reapplyPromotions',
  async (_, _ThunkAPI) => {
    _ThunkAPI.dispatch(setCalculating());
    const { ocCurrentOrder } = _ThunkAPI.getState();
    const orderId = ocCurrentOrder.order?.ID ?? '';

    // Reapply all auto-apply
    // Kick off the task, but we'll await it later
    const autoApplyPromise = Orders.ApplyPromotions('All', orderId);

    // Get current promos
    const promoResults = await Orders.ListPromotions('All', orderId);

    // Reapply all non-auto-apply promotions
    const nonAutoApplyPromos = promoResults.Items.filter((x) => !x.AutoApply);

    for (let i = 0; i < nonAutoApplyPromos.length; i++) {
      const promo = nonAutoApplyPromos[i];

      try {
        await Orders.RemovePromotion('All', orderId, promo.Code);
      } catch (err) {
        console.error(err);
      }
      try {
        await Orders.AddPromotion('All', orderId, promo.Code);
      } catch (err) {
        console.error(err);
      }
    }
    await autoApplyPromise;
  }
);

export const applyPromo = createOcAsyncThunk<RequiredDeep<any>, any>(
  'ocCurrentOrder/addPromotion',
  async (input, _ThunkAPI) => {
    _ThunkAPI.dispatch(setCalculating());
    const { ocCurrentOrder } = _ThunkAPI.getState();
    const orderId = ocCurrentOrder.order ? ocCurrentOrder.order.ID : undefined;
    const { promoCode, dataareaId } = input;
    const postData = {
      Code: promoCode,
      StoreId: ocCurrentOrder?.order?.ToCompanyID,
      OrderId: orderId,
      DataAreaId: dataareaId,
    };
    const options = { method: 'POST', data: postData };
    const response: any = await apiRequest(orderPromotionApi.addPromotion, options);
    if (response.Success) {
      //return response.Worksheet;
    }
    //await Orders.AddPromotion('All', orderId as string, promoCode);
    // return IntegrationEvents.GetWorksheet('Outgoing', orderId as string);
    //const updateOrder = await Orders.Get('All', orderId as string);
    return response;
  }
);

export const removePromotion = createOcAsyncThunk<RequiredDeep<any>, string>(
  'ocCurrentOrder/removePromotion',
  async (promoCode, ThunkAPI) => {
    const { ocCurrentOrder } = ThunkAPI.getState();
    const orderId = ocCurrentOrder.order?.ID ?? '';

    const patchPromise = Orders.Patch('All', orderId, { xp: { CouponCode: '' } });
    const removePromise = Orders.RemovePromotion('All', orderId as string, promoCode);

    // Run in parallel
    await Promise.all([patchPromise, removePromise]);

    return unwrapResult(
      await ThunkAPI.dispatch(calculateOrder({ skipCalculateAnonDiscountOrder: true }))
    );
  }
);

export const retrieveOrder = createOcAsyncThunk<OrderWorksheetWithXP | undefined, void>(
  'ocCurrentOrder/retrieveOrder',
  async (_, ThunkAPI) => {
    try {
      ThunkAPI.dispatch(setCalculating());

      const { storeReducer } = ThunkAPI.getState();
      const storeId = storeReducer?.selectedStore?.storeId;
      if (!storeId) return undefined;
      const response = await Me.ListOrders({
        sortBy: ['!DateCreated'],
        filters: { Status: 'Unsubmitted', SubscriptionID: '!*' },
        search: `${storeId.trim()}-`,
        searchType: 'ExactPhrasePrefix' as SearchType,
      });

      const firstOrder = response.Items[0];

      if (firstOrder) {
        const worksheet = await IntegrationEvents.GetWorksheet<OrderWorksheetWithXP>(
          'Outgoing',
          firstOrder.ID
        );
        if (worksheet.Order.BillingAddress) {
          ThunkAPI.dispatch(retrievePayments(firstOrder.ID));
        }
        // No need to await because we'll update it separate when it's fulfilled
        // However we do want to ensure that the order is created first
        ThunkAPI.dispatch(getAnonDiscount());
        return worksheet;
      }
      const orderResponse = await ThunkAPI.dispatch(createOrder());
      // No need to await because we'll update it separate when it's fulfilled
      // However we do want to ensure that the order is created first
      ThunkAPI.dispatch(getAnonDiscount());
      const worksheet = unwrapResult(orderResponse);
      return worksheet;
    } catch (error) {
      throw new Error('Failed to list orders');
    }
  }
);

export const deleteCurrentOrder = createOcAsyncThunk<void, void>(
  'ocCurrentOrder/delete',
  async (_, ThunkAPI) => {
    const { ocCurrentOrder } = ThunkAPI.getState();
    if (ocCurrentOrder.order) {
      await Orders.Delete('Outgoing', ocCurrentOrder.order.ID || '');
    }
    // eslint-disable-next-line no-use-before-define
    ThunkAPI.dispatch(clearCurrentOrder());
    ThunkAPI.dispatch(retrieveOrder());
  }
);

export const transferAnonOrder = createOcAsyncThunk<void, string>(
  'ocCurrentOrder/transfer',
  async (anonUserToken, ThunkAPI) => {
    await Me.TransferAnonUserOrder({ anonUserToken });
    ThunkAPI.dispatch(retrieveOrder());
  }
);

export const createLineItem = createOcAsyncThunk<
  RequiredDeep<OrderWorksheet> | undefined,
  {
    request: LineItemWithXp;
    isDFS?: boolean;
  }
>('ocCurrentOrder/createLineItem', async ({ request, isDFS }, ThunkAPI) => {
  ThunkAPI.dispatch(setCalculating());
  const { ocCurrentOrder, storeReducer } = ThunkAPI.getState();
  let orderId = ocCurrentOrder.order ? ocCurrentOrder.order.ID : undefined;
  const selectedStore = storeReducer?.selectedStore;
  const storeId = selectedStore?.storeId.trim();
  const isTip = request?.ProductID === DiscreteLineItem.TIP;
  // initialize the order if it doesn't exist already
  if (!orderId && !isTip) {
    const orderResponse = await ThunkAPI.dispatch(createOrder(isDFS));
    const worksheet = unwrapResult(orderResponse);
    orderId = worksheet?.Order?.ID;
  }

  // create the new line item
  await LineItems.Create('Outgoing', orderId ?? '', {
    ...request,
    ShipFromAddressID: `A-${storeId}`,
  });

  // Kick this off before we reapply promos here because it can be done in parallel.
  // No need to await because we'll update it separate when it's fulfilled
  ThunkAPI.dispatch(calculateAnonDiscount());

  await ThunkAPI.dispatch(reapplyPromotions());

  return unwrapResult(
    await ThunkAPI.dispatch(calculateOrder({ skipCalculateAnonDiscountOrder: true }))
  );
});

export const updateLineItem = createOcAsyncThunk<
  RequiredDeep<OrderWorksheetWithXP> | undefined,
  LineItemWithXp
>('ocCurrentOrder/updateLineItem', async (request, ThunkAPI) => {
  ThunkAPI.dispatch(setCalculating());
  const { ocCurrentOrder } = ThunkAPI.getState();
  const orderId = ocCurrentOrder.order ? ocCurrentOrder.order.ID : undefined;
  //const isTip = request?.ProductID === DiscreteLineItem.TIP;
  // what to do when order doesn't exist? shouldn't happen.. but it could!
  // if (!orderId) {
  // }

  // save the line item
  await LineItems.Save('Outgoing', orderId as string, request.ID as string, request);

  // Kick this off before we reapply promos here because it can be done in parallel.
  // No need to await because we'll update it separate when it's fulfilled
  ThunkAPI.dispatch(calculateAnonDiscount());

  await ThunkAPI.dispatch(reapplyPromotions());
  return unwrapResult(
    await ThunkAPI.dispatch(calculateOrder({ skipCalculateAnonDiscountOrder: true }))
  );
  /*
  return isTip
    ? IntegrationEvents.Calculate('Outgoing', orderId ?? '')
    : IntegrationEvents.GetWorksheet('Outgoing', orderId ?? '');
    */
});

export const removeLineItem = createOcAsyncThunk<
  RequiredDeep<OrderWorksheetWithXP> | undefined,
  string
>('ocCurrentOrder/removeLineItem', async (lineItemId, ThunkAPI) => {
  ThunkAPI.dispatch(setCalculating());
  const { ocCurrentOrder } = ThunkAPI.getState();
  const orderId = ocCurrentOrder.order ? ocCurrentOrder.order.ID : undefined;

  // what to do when order doesn't exist? shouldn't happen.. but it could!
  // if (!orderId) {
  // }

  // save the line item
  await LineItems.Delete('Outgoing', orderId as string, lineItemId);

  // Kick this off before we reapply promos here because it can be done in parallel.
  // No need to await because we'll update it separate when it's fulfilled
  ThunkAPI.dispatch(calculateAnonDiscount());
  await ThunkAPI.dispatch(reapplyPromotions());

  return unwrapResult(
    await ThunkAPI.dispatch(calculateOrder({ skipCalculateAnonDiscountOrder: true }))
  );
  // return IntegrationEvents.GetWorksheet('Outgoing', orderId as string);
});

export const saveShippingAddress = createOcAsyncThunk<
  RequiredDeep<OrderWorksheetWithXP> | undefined,
  Partial<BuyerAddress>
>('ocCurrentOrder/saveShippingAddress', async (request, ThunkAPI) => {
  ThunkAPI.dispatch(setCalculating());
  const { ocCurrentOrder } = ThunkAPI.getState();
  const orderId = ocCurrentOrder.order ? ocCurrentOrder.order.ID : undefined;
  // Need to check if ShippingAddress already exists on currentOrder or not.
  const lineItem = ocCurrentOrder?.lineItems;
  let hasShippingAddress;
  if (lineItem) {
    hasShippingAddress = lineItem[0]?.ShippingAddress ?? null;
  }
  //We are adding a lineitem called CORDF for Colorado retail delivery free//We no longer need this. PSP-2731
  /*
  if (
    request.State == 'CO' &&
    !ocCurrentOrder?.lineItems?.some((x) => x.ProductID == DiscreteLineItem.CORDF)
  ) {
    const line: LineItemWithXp = { ProductID: DiscreteLineItem.CORDF };
    await ThunkAPI.dispatch(createLineItem({ request: line }));
  }
  */
  if (request) {
    if (request.ID) {
      await Orders.Patch('Outgoing', orderId as string, { ShippingAddressID: request.ID });
    } else {
      if (hasShippingAddress) {
        await Orders.PatchShippingAddress('Outgoing', orderId as string, request as Address);
      } else {
        await Orders.SetShippingAddress('Outgoing', orderId as string, request as Address);
      }
    }
  } else {
    await Orders.Patch('Outgoing', orderId as string, { ShippingAddressID: '' });
  }
  return unwrapResult(
    await ThunkAPI.dispatch(calculateOrder({ skipCalculateAnonDiscountOrder: true }))
  );
});

export const patchOrder = createOcAsyncThunk<
  RequiredDeep<OrderWithXp>,
  {
    request: Partial<OrderWithXp>;
    /** If true, will not await the calculate call.
     * Set to true if we don't expect it to change the calculate result */
    deferCalculate?: boolean;
    /** If true, will not call calculate at all.
     * Only set this is we are expecting to call calculate separately afterwards */
    skipCalculate?: boolean;
  }
>('ocCurrentCart/patch', async ({ request, deferCalculate, skipCalculate }, ThunkAPI) => {
  const { ocCurrentOrder } = ThunkAPI.getState();
  if (!ocCurrentOrder.order?.ID) {
    throw new Error('No current order to patch');
  }
  const orderID = ocCurrentOrder.order.ID;
  let patchedOrder;
  try {
    patchedOrder = await Orders.Patch('All', orderID || '', request);
  } catch (err) {
    console.error(err);

    // Get current promos
    const promoResults = await Orders.ListPromotions('All', orderID);

    const nonAutoApplyPromos = promoResults.Items.filter((x) => !x.AutoApply);

    // Remove all promos before patching order
    // Note on remove, we remove all, regardless of autoapply status
    for (let i = 0; i < promoResults.Items.length; i++) {
      const promo = promoResults.Items[i];

      try {
        await Orders.RemovePromotion('All', orderID, promo.Code);
      } catch (err) {
        console.error(err);
      }
    }

    // Now we Patch the order
    patchedOrder = await Orders.Patch('All', orderID, request);

    // Reapply all non-autoapply promos after patching order
    for (let i = 0; i < nonAutoApplyPromos.length; i++) {
      const promo = nonAutoApplyPromos[i];
      try {
        await Orders.AddPromotion('All', orderID, promo.Code);
      } catch (err) {
        console.error(err);
      }
    }

    // Reapply all auto-apply
    await Orders.ApplyPromotions('All', orderID);
  }
  if (!skipCalculate) {
    const calculatePromise = ThunkAPI.dispatch(calculateOrder());

    // If we don't care about the calculate result, we don't need to await it
    // But we still need to call it because OC requires it be called any time order changes
    if (!deferCalculate) {
      await calculatePromise;
    }
  }
  return patchedOrder;
});

export const saveBillingAddress = createOcAsyncThunk<OrderWithXp, Partial<BuyerAddress>>(
  'ocCurrentOrder/saveBillingAddress',
  async (request, ThunkAPI) => {
    const { ocCurrentOrder } = ThunkAPI.getState();
    const orderId = ocCurrentOrder.order ? ocCurrentOrder.order.ID : undefined;

    // what to do when order doesn't exist? shouldn't happen.. but it could!
    if (!orderId) {
      throw Error('Cannot call saveBillingAddress when there is no current orderId');
    }
    if (request.ID) {
      return await Orders.Patch('Outgoing', orderId as string, { BillingAddressID: request.ID });
    } else {
      return await Orders.SetBillingAddress('Outgoing', orderId as string, request as Address);
    }
  }
);

export const removeBillingAddress = createOcAsyncThunk<
  RequiredDeep<OrderWorksheetWithXP> | undefined,
  undefined
>('ocCurrentOrder/removeBillingAddress', async (_request, ThunkAPI) => {
  const { ocCurrentOrder } = ThunkAPI.getState();
  const { order } = ocCurrentOrder;

  // what to do when order doesn't exist? shouldn't happen.. but it could!
  // if (!orderId) {
  // }
  if (!order) {
    throw new Error('No current order to patch');
  }
  await Orders.Patch('Outgoing', order.ID || '', { BillingAddressID: '' });
  return unwrapResult(
    await ThunkAPI.dispatch(calculateOrder({ skipCalculateAnonDiscountOrder: true }))
  );
});

export const estimateShipping = createOcAsyncThunk<OrderWorksheetWithXP, string>(
  'ocCurrentOrder/estimateShipping',
  async (orderId, _ThunkAPI) => {
    _ThunkAPI.dispatch(setCalculating());
    const response = await IntegrationEvents.EstimateShipping('Outgoing', orderId);
    return response;
  }
);

export const selectShipMethods = createOcAsyncThunk<RequiredDeep<any>, RequiredDeep<any>[]>(
  'ocCurrentOrder/selectShipMethods',
  async (selection, ThunkAPI) => {
    ThunkAPI.dispatch(setCalculating());
    const { ocCurrentOrder } = ThunkAPI.getState();
    if (!ocCurrentOrder.order) {
      throw new Error('No current order to update');
    }
    const response = await IntegrationEvents.SelectShipmethods(
      'Outgoing',
      ocCurrentOrder.order.ID || '',
      {
        ShipMethodSelections: selection,
      }
    );
    ThunkAPI.dispatch(removeAllPayments());
    if (ocCurrentOrder.order.BillingAddress) {
      return unwrapResult(
        await ThunkAPI.dispatch(calculateOrder({ skipCalculateAnonDiscountOrder: true }))
      );
    }
    return response;
  }
);

export const addPayment = createOcAsyncThunk<RequiredDeep<Payment>, Payment>(
  'ocCurrentOrder/addPayment',
  async (payment, ThunkAPI) => {
    const { ocCurrentOrder } = ThunkAPI.getState();
    if (!ocCurrentOrder.order) {
      throw new Error('No current order to update');
    }
    return Payments.Create('Outgoing', ocCurrentOrder.order.ID || '', payment);
  }
);

export const removePayment = createOcAsyncThunk<string, string>(
  'ocCurrentOrder/removePayment',
  async (paymentId, ThunkAPI) => {
    const { ocCurrentOrder } = ThunkAPI.getState();
    if (!ocCurrentOrder.order) {
      throw new Error('No current order to update');
    }
    await Payments.Delete('Outgoing', ocCurrentOrder.order.ID || '', paymentId);
    return paymentId;
  }
);

export const submitOrder = createOcAsyncThunk<RecentOrder, any>(
  'ocCurrentOrder/submit',
  async (_onSubmitted, ThunkAPI) => {
    const { ocCurrentOrder } = ThunkAPI.getState();
    if (!ocCurrentOrder.order) {
      throw new Error('No current order to submit');
    }
    const submitResponse = await Orders.Submit('Outgoing', ocCurrentOrder.order.ID || '');
    /*
    const patchedResponse = await Orders.Patch('Outgoing', submitResponse.ID, {
      ID: 'psp-{orderid}',
    }); //incrementors must be configured in OC
    */
    // eslint-disable-next-line no-use-before-define
    ThunkAPI.dispatch(clearCurrentOrder());
    const order: RecentOrder = {
      order: submitResponse,
      lineItems: ocCurrentOrder.lineItems,
      payments: ocCurrentOrder.payments,
    };
    return order;
  }
);

export const createOrder = createOcAsyncThunk<
  RequiredDeep<OrderWorksheet> | undefined,
  boolean | undefined
>('ocCurrentOrder/createOrder', async (isDFS, ThunkAPI) => {
  const { storeReducer } = ThunkAPI.getState();
  const selectedStore = storeReducer?.selectedStore;
  const storeId = selectedStore?.storeId;
  let orderId = '';
  const id = `${storeId.trim()}-${Math.random().toString(36).substring(2, 14)}`;
  const newOrder: OrderWithXp = {
    ID: id,
    ToCompanyID: storeId,
    xp: {
      OrderId: '',
      OrderToSOF: false,
      OrderToD365: false,
      Emails: [],
      Fulfillment: isDFS ? FulfillmentType.DFS : FulfillmentType.BOPIS,
      //DataAreaId: selectedStore.dataareaid,
    },
  };
  const orderResponse = await Orders.Create('Outgoing', newOrder);
  orderId = orderResponse.ID;

  return await IntegrationEvents.GetWorksheet<OrderWorksheetWithXP>('Outgoing', orderId);
});

export type CalculateOrderProps = {
  skipCalculateAnonDiscountOrder?: boolean;
};
export const calculateOrder = createOcAsyncThunk<
  RequiredDeep<OrderWorksheetWithXP> | undefined,
  CalculateOrderProps | void
>('ocCurrentOrder/calculateOrder', async (props, ThunkAPI) => {
  ThunkAPI.dispatch(setCalculating());
  const { ocCurrentOrder } = ThunkAPI.getState();
  if (ocCurrentOrder?.order?.ID) {
    const promise = props?.skipCalculateAnonDiscountOrder
      ? Promise.resolve()
      : ThunkAPI.dispatch(calculateAnonDiscount());
    const calculatePromise = IntegrationEvents.Calculate('Outgoing', ocCurrentOrder.order.ID);
    const [_, calculate] = await Promise.all([promise, calculatePromise]);
    return calculate;
  }
  return undefined;
});

export const getAnonDiscount = createOcAsyncThunk<
  RequiredDeep<OrderWorksheetWithXP> | undefined,
  void
>('ocCurrentOrder/getAnonDiscount', async (_, ThunkAPI) => {
  const { ocCurrentOrder, ocAuth } = ThunkAPI.getState();

  if (ocAuth.isAnonymous && ocCurrentOrder?.order?.ID) {
    const url = checkoutAPI.getAnonDiscount;
    return await apiRequest<RequiredDeep<OrderWorksheetWithXP>>(url, {
      method: 'POST',
      data: { orderId: ocCurrentOrder.order?.ID },
    });
  }
  return undefined;
});
export const calculateAnonDiscount = createOcAsyncThunk<
  RequiredDeep<OrderWorksheetWithXP> | undefined,
  void
>('ocCurrentOrder/calculateAnonDiscount', async (_, ThunkAPI) => {
  const { ocCurrentOrder, ocAuth } = ThunkAPI.getState();

  if (ocAuth.isAnonymous && ocCurrentOrder?.order?.ID) {
    const url = checkoutAPI.calculateAnonDiscount;
    return await apiRequest<RequiredDeep<OrderWorksheetWithXP>>(url, {
      method: 'POST',
      data: { orderId: ocCurrentOrder.order?.ID },
    });
  }
  return undefined;
});

const ocCurrentOrderSlice = createSlice({
  name: 'ocCurrentOrder',
  initialState,
  reducers: {
    setCalculating: (state) => {
      state.calculating = true;
    },
    clearCurrentOrder: (state) => {
      state.order = undefined;
      state.lineItems = undefined;
      state.shipEstimateResponse = undefined;
      state.payments = undefined;
      state.recentOrders = [];
      state.initialized = null;
    },
    clearCurrentOrderWithoutInitialized: (state) => {
      state.order = undefined;
      state.lineItems = undefined;
      state.shipEstimateResponse = undefined;
      state.payments = undefined;
      state.recentOrders = [];
    },
    setRecentPlacedOrder: (state, action) => {
      if (action.payload) {
        state.order = undefined;
        state.lineItems = undefined;
        state.shipEstimateResponse = undefined;
        state.payments = undefined;
        const recent: RecentOrder = {
          order: action.payload.Order,
          lineItems: action.payload.LineItems,
        };
        state.recentOrders.unshift(recent);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveOrder.pending, (state) => {
      state.initialized = false;
      state.order = undefined;
    });
    builder.addCase(retrieveOrder.fulfilled, (state, action) => {
      state.calculating = false;
      if (action.payload) {
        state.order = action.payload.Order;
        state.lineItems = action.payload.LineItems;
        state.shipEstimateResponse = action.payload.ShipEstimateResponse;
        state.orderPromotions = action.payload.OrderPromotions;
        state.orderCalculateResponse =
          action.payload.OrderCalculateResponse ?? state.orderCalculateResponse;
        state.initialized = true;
      }
      // for handling case when user is there but no order has been placed/.
      if (!action.payload?.Order) {
        state.order = {};
      }
      state.initialized = true;
    });
    builder.addCase(retrieveOrder.rejected, (state) => {
      state.initialized = false;
    });

    builder.addCase(patchOrder.fulfilled, (state, action) => {
      if (action.payload) {
        state.order = action.payload;
      }
    });
    builder.addCase(createLineItem.fulfilled, (state, action) => {
      state.order = action.payload?.Order;
      state.lineItems = action.payload?.LineItems;
      state.shipEstimateResponse = action.payload?.ShipEstimateResponse;
      state.orderPromotions = action.payload?.OrderPromotions;
      state.orderCalculateResponse =
        action.payload?.OrderCalculateResponse ?? state.orderCalculateResponse;
      state.calculating = false;
    });
    builder.addCase(updateLineItem.fulfilled, (state, action) => {
      state.order = action.payload?.Order;
      state.lineItems = action.payload?.LineItems;
      state.shipEstimateResponse = action.payload?.ShipEstimateResponse;
      state.orderPromotions = action.payload?.OrderPromotions;
      state.orderCalculateResponse =
        action.payload?.OrderCalculateResponse ?? state.orderCalculateResponse;
      state.calculating = false;
    });
    builder.addCase(updateLineItem.rejected, (state) => {
      state.calculating = false;
    });
    builder.addCase(removeLineItem.fulfilled, (state, action) => {
      state.order = action.payload?.Order;
      state.lineItems = action.payload?.LineItems;
      state.shipEstimateResponse = action.payload?.ShipEstimateResponse;
      state.orderPromotions = action.payload?.OrderPromotions;
      state.orderCalculateResponse =
        action.payload?.OrderCalculateResponse ?? state.orderCalculateResponse;
      state.calculating = false;
    });
    builder.addCase(saveShippingAddress.fulfilled, (state, action) => {
      state.order = action.payload?.Order;
      state.lineItems = action.payload?.LineItems;
      state.shipEstimateResponse = action.payload?.ShipEstimateResponse;
      state.orderPromotions = action.payload?.OrderPromotions;
      state.orderCalculateResponse =
        action.payload?.OrderCalculateResponse ?? state.orderCalculateResponse;
      state.calculating = false;
    });
    builder.addCase(saveBillingAddress.fulfilled, (state, action) => {
      state.order = action.payload;
    });
    builder.addCase(removeBillingAddress.fulfilled, (state, action) => {
      state.order = action.payload?.Order;
      state.lineItems = action.payload?.LineItems;
      state.shipEstimateResponse = action.payload?.ShipEstimateResponse;
      state.orderPromotions = action.payload?.OrderPromotions;
      state.orderCalculateResponse =
        action.payload?.OrderCalculateResponse ?? state.orderCalculateResponse;
    });
    builder.addCase(estimateShipping.fulfilled, (state, action) => {
      state.order = action.payload.Order;
      state.lineItems = action.payload.LineItems;
      state.shipEstimateResponse = action.payload.ShipEstimateResponse;
      state.orderPromotions = action.payload.OrderPromotions;
      state.orderCalculateResponse =
        action.payload.OrderCalculateResponse ?? state.orderCalculateResponse;
      state.calculating = false;
    });
    builder.addCase(selectShipMethods.fulfilled, (state, action) => {
      state.order = action.payload.Order;
      state.lineItems = action.payload.LineItems;
      state.shipEstimateResponse = action.payload.ShipEstimateResponse;
      state.orderPromotions = action.payload.OrderPromotions;
      state.orderCalculateResponse =
        action.payload.OrderCalculateResponse ?? state.orderCalculateResponse;
      state.calculating = false;
    });
    builder.addCase(retrievePayments.fulfilled, (state, action) => {
      state.payments = action.payload;
    });
    builder.addCase(addPayment.fulfilled, (state, action) => {
      if (!state.payments) {
        state.payments = [action.payload];
      } else {
        state.payments.push(action.payload);
      }
    });
    builder.addCase(removePayment.fulfilled, (state, action) => {
      state.payments = state.payments?.filter((p) => p.ID !== action.payload);
    });
    builder.addCase(removeAllPayments.fulfilled, (state, _action) => {
      state.payments = [];
    });
    builder.addCase(submitOrder.fulfilled, (state, action) => {
      state.recentOrders.unshift(action.payload);
    });
    builder.addCase(applyPromo.fulfilled, (state, action) => {
      const worksheet = action?.payload?.Worksheet;
      if (worksheet) {
        state.order = worksheet.Order;
        state.lineItems = worksheet.LineItems;
        state.shipEstimateResponse = worksheet.ShipEstimateResponse;
        state.orderPromotions = worksheet.OrderPromotions;
        state.orderCalculateResponse =
          worksheet.OrderCalculateResponse ?? state.orderCalculateResponse;
      }
      state.calculating = false;
    });

    builder.addCase(applyPromo.rejected, (state) => {
      state.calculating = false;
    });

    builder.addCase(reapplyPromotions.fulfilled, (state) => {
      state.calculating = false;
    });
    builder.addCase(reapplyPromotions.rejected, (state) => {
      state.calculating = false;
    });
    builder.addCase(removePromotion.fulfilled, (state, action) => {
      if (action.payload) {
        state.order = action.payload.Order;
        state.lineItems = action.payload.LineItems;
        state.shipEstimateResponse = action.payload.ShipEstimateResponse;
        state.orderPromotions = action.payload.OrderPromotions;
        state.orderCalculateResponse =
          action.payload.OrderCalculateResponse ?? state.orderCalculateResponse;
      }
      state.calculating = false;
    });
    builder.addCase(removePromotion.rejected, (state) => {
      state.calculating = false;
    });
    builder.addCase(createOrder.fulfilled, (state, action) => {
      if (action.payload) {
        state.order = action.payload.Order;
        state.lineItems = action.payload.LineItems;
        state.shipEstimateResponse = action.payload.ShipEstimateResponse;
        state.orderPromotions = action.payload.OrderPromotions;
        state.orderCalculateResponse =
          action.payload.OrderCalculateResponse ?? state.orderCalculateResponse;
      }
    });
    builder.addCase(calculateOrder.fulfilled, (state, action) => {
      if (action.payload) {
        state.order = action.payload.Order;
        state.lineItems = action.payload.LineItems;
        state.shipEstimateResponse = action.payload.ShipEstimateResponse;
        state.orderPromotions = action.payload.OrderPromotions;
        state.orderCalculateResponse =
          action.payload.OrderCalculateResponse ?? state.orderCalculateResponse;
      }
      state.calculating = false;
    });
    builder.addCase(calculateAnonDiscount.fulfilled, (state, action) => {
      if (action.payload) {
        state.anonDiscountOrder = action.payload.Order;
        state.anonDiscountLineItems = action.payload.LineItems;
        state.anonDiscountPromotions = action.payload.OrderPromotions;
      }
    });
    builder.addCase(getAnonDiscount.fulfilled, (state, action) => {
      if (action.payload) {
        state.anonDiscountOrder = action.payload.Order;
        state.anonDiscountLineItems = action.payload.LineItems;
        state.anonDiscountPromotions = action.payload.OrderPromotions;
      }
    });
  },
});

export const {
  setCalculating,
  clearCurrentOrder,
  clearCurrentOrderWithoutInitialized,
  setRecentPlacedOrder,
} = ocCurrentOrderSlice.actions;

export default ocCurrentOrderSlice.reducer;
