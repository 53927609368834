import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { Me, LineItems } from 'ordercloud-javascript-sdk';
import ComponentContext from 'lib/context/ComponentContext';
import { useState, useEffect, useContext } from 'react';
import { LineItemWithXp, OrderWithXp } from 'src/redux/xp';
import { useOcSelector } from 'src/redux/ocStore';
import { orderConfirmationSummaryVariant } from 'tailwindVariants/components/orderConfirmationSummaryTailwindVariant';
import { DiscreteLineItem } from 'src/helpers/Constants';
import Loader from 'components/Loader/Loader';

export type OrderConfirmationSummaryProps = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      fulfillmentMethodLabel: Field<string>;
      itemQuantityLabel: Field<string>;
      orderTotalLabel: Field<string>;
      storePhoneLabel: Field<string>;
      orderNumberLabel: Field<string>;
      pickupAddressLabel: Field<string>;
      deliveryAddressLabel: Field<string>;
      estimatedDeliveryDateLabel: Field<string>;
      title: Field<string>;
      // submitButtonText: Field<string>;
      // successMessage: Field<string>;
      // successRedirectUrl: {
      //   jsonValue: LinkField;
      // };
      // failureMessage: Field<string>;
    };
  };
export interface FieldValues {
  [key: string]: string;
}

const OrderConfirmationSummary = ({
  fields,
  params,
}: OrderConfirmationSummaryProps): JSX.Element => {
  const { base, formContainer, orderTitle, listGroup, loaderWrapper } =
    orderConfirmationSummaryVariant({
      device: {
        initial: 'mobile',
        lg: 'desktop',
      },
    });

  const recentOrder = useOcSelector((state) => state?.ocCurrentOrder?.recentOrders);

  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  //const [order, setOrder] = useState<OrderWithXp>();
  const [line, setLine] = useState<LineItemWithXp>();
  const [loader, showLoader] = useState(true);

  const [orderNumber, setOrderNumber] = useState<string>('');
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);

  const order = componentContextData?.order as OrderWithXp | undefined;
  const lineItems = componentContextData?.lineItem as LineItemWithXp[] | undefined;
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setOrderNumber(urlParams.get('id') ?? '');
  }, []);

  useEffect(() => {
    const recentworksheet = recentOrder[0];
    if (recentworksheet && recentworksheet?.order?.ID && recentworksheet.lineItems?.length) {
      setcomponentContextData(() => ({
        ...componentContextData,
        order: recentworksheet?.order,
        lineItem: recentworksheet?.lineItems,
      }));
      setLine(recentworksheet.lineItems[0]);
    } else {
      if (orderNumber.length > 0) {
        const initialize = async () => {
          if (order?.ID != undefined) {
            return;
          }
          let orders = await Me.ListOrders({
            filters: { 'xp.OrderId': orderNumber, Status: 'AwaitingApproval|Canceled|Declined' },
          });
          // to bypass SOF changes and let the OrderConfirmationBlock be seen:
          if (!orders.Items.length) {
            orders = await Me.ListOrders({
              filters: { 'xp.OrderId': orderNumber, Status: 'Completed|Open' },
            });
          }

          if (orders?.Items?.length > 0) {
            const lines = await LineItems.List('All', orders?.Items[0]?.ID);

            setcomponentContextData(() => ({
              ...componentContextData,
              order: orders.Items[0],
              lineItem: lines.Items,
            }));
            showLoader(false);
          }
        };
        initialize();
      }
    }
  }, [recentOrder, orderNumber]);

  // As setLine should be re-run once the lineItems are being fetched.
  useEffect(() => {
    if (lineItems?.[0]) {
      setLine(lineItems?.[0]);
    }
  }, [lineItems]);

  useEffect(() => {
    if (order) {
      showLoader(false);
    } else {
      showLoader(true);
    }
  }, [order]);

  const delivery: boolean = order?.xp?.Fulfillment == 'DFS';
  return (
    <div className={'OrderConfirmationSummary ' + params?.Styles}>
      <div className="w-full">
        <div className={formContainer()}>
          {loader ? (
            <div className={loaderWrapper()}>
              <Loader />
            </div>
          ) : (
            <div className={base()}>
              <Text tag="p" className={orderTitle()} field={fields?.title} />

              <ul className={listGroup()}>
                <li className="flex">
                  <Text tag="p" className={orderTitle()} field={fields?.orderNumberLabel} />
                  <span data-orderid={order?.ID}>: {order?.xp?.OrderId}</span>
                </li>
                <li className="flex">
                  <Text tag="p" className={orderTitle()} field={fields?.fulfillmentMethodLabel} />
                  <span>: {delivery ? 'Delivery' : 'Pickup'}</span>
                </li>
                <li className="flex flex-col">
                  <Text
                    tag="p"
                    className={orderTitle()}
                    field={delivery ? fields?.deliveryAddressLabel : fields?.pickupAddressLabel}
                  />
                  <span>
                    {delivery ? line?.ShippingAddress?.Street1 : line?.ShipFromAddress?.Street1}
                    {delivery ? line?.ShippingAddress?.Street2 : line?.ShipFromAddress?.Street2}
                    <br />
                    {delivery ? line?.ShippingAddress?.City : line?.ShipFromAddress?.City}
                  </span>
                </li>
                <li className="flex">
                  <Text
                    tag="p"
                    className={orderTitle()}
                    field={delivery ? fields?.estimatedDeliveryDateLabel : fields?.storePhoneLabel}
                  />
                  <span>
                    :{' '}
                    {delivery
                      ? new Date(order?.xp?.ExpectedDeliveryDate ?? '').toDateString()
                      : myStoreData?.phone}
                  </span>
                </li>
                <li className="flex">
                  <Text tag="p" className={orderTitle()} field={fields?.itemQuantityLabel} />
                  <span>
                    :{' '}
                    {lineItems
                      ?.filter((x) => x.ProductID !== DiscreteLineItem.TIP)
                      .reduce((sum, item: LineItemWithXp) => sum + (item?.Quantity as number), 0)}
                  </span>
                </li>
                <li className="flex">
                  <Text tag="p" className={orderTitle()} field={fields?.orderTotalLabel} />
                  <span>: ${order?.Total?.toFixed(2)}</span>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default OrderConfirmationSummary;
