import { Field, ImageField, NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import clsx from 'clsx';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import { Fragment, useEffect, useState } from 'react';
import { serviceRewardsWidgetTailwindVariant } from 'tailwindVariants/components/serviceRewardsWidgetTailwindVariant';
import { formatDateFromString } from 'src/utils/formatDate';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';

type ServiceFieldsProps = {
  title: Field<string>;
  description: Field<string>;
  serviceId: Field<string>;
  image1Punched: ImageField;
  image1Unpunched: ImageField;
  image2Punched: ImageField;
  image2Unpunched: ImageField;
  image3Punched: ImageField;
  image3Unpunched: ImageField;
  image4Punched: ImageField;
  image4Unpunched: ImageField;
  image5Punched: ImageField;
  image5Unpunched: ImageField;
  image6Punched: ImageField;
  image6Unpunched: ImageField;
  image7Punched: ImageField;
  image7Unpunched: ImageField;
  image8Punched: ImageField;
  image8Unpunched: ImageField;
  mobileImage1Punched: Field<string>;
  mobileImage1Unpunched: Field<string>;
  mobileImage2Punched: Field<string>;
  mobileImage2Unpunched: Field<string>;
  mobileImage3Punched: Field<string>;
  mobileImage3Unpunched: Field<string>;
  mobileImage4Punched: Field<string>;
  mobileImage4Unpunched: Field<string>;
  mobileImage5Punched: Field<string>;
  mobileImage5Unpunched: Field<string>;
  mobileImage6Punched: Field<string>;
  mobileImage6Unpunched: Field<string>;
  mobileImage7Punched: Field<string>;
  mobileImage7Unpunched: Field<string>;
  mobileImage8Punched: Field<string>;
  mobileImage8Unpunched: Field<string>;
};

type ServicesProps = {
  name: string;
  fields: ServiceFieldsProps;
  isPunched: boolean;
};

type ServiceRewardsProps = {
  props: {
    fields: {
      rewardExpiresLabel: Field<string>;
      rewardLabel: Field<string>;
      rewardStatusLabel: Field<string>;
      viewAllRewardsLabel: Field<string>;
      availableRewardsLabel: Field<string>;
      couponCodeCopiedLabel: Field<string>;
      noRewardsImage: ImageField;
      noRewardsLabel: Field<string>;
      numberOfAwardsToShow: Field<number>;
      rewardCouponLabel: Field<string>;
      rewardEarnedLabel: Field<string>;
      locationLabel: Field<string>;
      punchcardDescriptionLabel: Field<string>;
      punchcardLabel: Field<string>;
      purchasedDateLabel: Field<string>;
      quantityLabel: Field<string>;
      serviceLabel: Field<string>;
      services: ServicesProps[];
      storeLabel: Field<string>;
    };
  };
};

type ProductProps = {
  programid: number;
  programName: string;
  programDescription: string;
  termsAndConditions: string;
  dateRewardEarned: string;
  dateRewardExpires: string;
  programRewardStatus: string;
  poolName: string;
  couponCode: string;
  isExpired: boolean;
  CouponRewardName: string;
};

type UnrewardedProductProps = {
  neighborid: number;
  quantity: number;
  storeId: number;
  storeName: string;
  storeAddress1: string;
  storeAddress2: string;
  storeCity: string;
  storeState: string;
  storeZip: string;
  TransDate: string;
};

type AllUnrewardedProductProps = {
  content: UnrewardedProductProps[];
};

const {
  base,
  punchCardSection,
  punchCard,
  tabContent,
  punchCardService,
  punchCardTab,
  punchCardServiceLink,
  punchCardServiceDescription,
  titleSection,
  punchcardTitle,
  navTabs,
  availableRewardsSection,
  availableRewards,
  availableRewardsTitle,
  yourReward,
  yourRewardInfo,
  yourRewardHeader,
  yourRewardName,
  yourRewardEarned,
  yourRewardCoupon,
  yourRewardExpired,
  yourRewardsNoImage,
  noRewardsLabelText,
  oneServiceOnlyPunchCard,
  oneServiceOnlyTitleSection,
  oneServicePunchcardTitle,
  oneServiceOnlyTabContent,
  serviceTabImage,
  rewardsProgramStyles,
  punchcardDescriptionLabelStyles,
  viewAllRewardsButton,
  viewAllRewardsModal,
  viewAllRewardsModalHeading,
  viewAllRewardsModalBody,
  viewAllRewardsModalHeadingRow,
  viewAllRewardsModalBodyRow,
  viewAllRewardsModalCouponRewardImage,
} = serviceRewardsWidgetTailwindVariant({
  device: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

// todo: remove 'any' and types when they are generated with Leprechaun
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ServiceRewardsWidget = ({ props }: ServiceRewardsProps | any) => {
  const {
    services,
    punchcardLabel,
    availableRewardsLabel,
    rewardEarnedLabel,
    rewardExpiresLabel,
    viewAllRewardsLabel,
    noRewardsImage,
    noRewardsLabel,
    couponCodeCopiedLabel,
    numberOfAwardsToShow,
    rewardLabel,
    rewardCouponLabel,
    rewardStatusLabel,
    punchcardDescriptionLabel,
    quantityLabel,
    locationLabel,
    storeLabel,
    purchasedDateLabel,
  } = props.fields;

  const [isError, setIsError] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [rewardsProducts, setRewardsProducts] = useState<ProductProps[]>([]);
  const [allUnrewardedPurchases, setAllUnrewardedPurchases] = useState<AllUnrewardedProductProps[]>(
    []
  );
  const [isUnrewardedResponseReceived, setIsUnrewardedResponseReceived] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openModalIndex, setOpenModalIndex] = useState<number | null>(null);
  const [unrewardedCardId, setUnrewardedCardId] = useState<number | null>(null);
  const [copiedCoupon, setCopiedCoupon] = useState<string | null>(null);
  const anyServices = services && Array.isArray(services) && services.length > 0;
  const loyaltyService = anyServices ? services[0] : null;
  const size = 12;
  const unrewardedSize = 100;
  const rewardsPage = 1;
  const rewardsProgram = 'Services';
  const [isMobileScreenView, setIsMobileScreenView] = useState(window.innerWidth < 1024);

  const copyToClipboard = async (couponCode: string) => {
    try {
      await navigator.clipboard.writeText(couponCode);
      setCopiedCoupon(couponCode);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };

  const fetchProducts = async (size: number, page: number, rewardsProgram: string) => {
    try {
      const response = await fetch(
        `/api/loyalty/get-earned-rewards?size=${size}&page=${page}&program=${rewardsProgram}`
      );
      if (!response.ok && response.status !== 404) {
        throw new Error('Error. The API response was not ok.');
      }
      const data = await response.json();
      setRewardsProducts(data?.content);
    } catch (error) {
      setIsError(`Error. An error occured in the API response. ${error}`);
    }
  };

  const fetchUnrewardedPurchase = async (unrewardedSize: number) => {
    try {
      const unrewardedData = await Promise.all(
        services?.map(async (obj: ServicesProps) => {
          const { value } = obj?.fields?.serviceId;
          const unrewardedResponse = await fetch(
            `/api/loyalty/get-unrewarded-purchase?id=${value}&size=${unrewardedSize}`
          );
          if (!unrewardedResponse.ok && unrewardedResponse.status !== 404) {
            setIsUnrewardedResponseReceived(true);
            throw new Error('Error. The API response was not ok.');
          }
          return unrewardedResponse.json();
        })
      );
      setAllUnrewardedPurchases(unrewardedData);
      setIsUnrewardedResponseReceived(true);
    } catch (error) {
      setIsError(`Error. An error occured in the API response. ${error}`);
      setIsUnrewardedResponseReceived(true);
    }
  };

  if (isError) {
    console.error('isError: ', isError);
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        await Promise.all([
          fetchProducts(size, rewardsPage, rewardsProgram),
          fetchUnrewardedPurchase(unrewardedSize),
        ]);
      } catch (error) {
        setIsError(`Error loading data: ${error}`);
      }
    };

    loadData();
  }, [size, rewardsPage, rewardsProgram, unrewardedSize]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreenView(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleModalClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleUnrewardedModalClick = (index: number, cardId: number) => {
    setUnrewardedCardId(cardId);
    setOpenModalIndex(index);
  };

  const handleUnrewardedModalClose = () => {
    setOpenModalIndex(null);
  };

  const tabColor = [
    'text-color-brand-secondary-1-base bg-white hover:bg-color-brand-secondary-1-base hover:text-white',
    'text-color-background-brand-3 bg-white hover:bg-color-background-brand-3 hover:text-white',
    'text-color-background-brand-1 bg-white hover:bg-color-background-brand-1 hover:text-white',
  ];

  const activeTabColor = [
    'bg-color-brand-secondary-1-base text-white',
    'bg-color-background-brand-3 text-white',
    'bg-color-background-brand-1 text-white',
  ];

  return (
    <div className={base()}>
      {/* Punchcard Section */}
      <div className={punchCardSection()}>
        <div
          className={clsx(
            punchCard(),
            anyServices && services.length === 1 && oneServiceOnlyPunchCard()
          )}
        >
          <div
            className={clsx(
              titleSection(),
              anyServices && services.length === 1 && oneServiceOnlyTitleSection()
            )}
          >
            {anyServices && services.length === 1 ? (
              <>
                {loyaltyService && loyaltyService?.name && (
                  <h2 className={` ${punchcardTitle()} ${oneServicePunchcardTitle()}`}>
                    {loyaltyService?.name}
                  </h2>
                )}
              </>
            ) : (
              <>
                {punchcardLabel && punchcardLabel?.value && (
                  <h2 className={punchcardTitle()}>{punchcardLabel?.value}</h2>
                )}
              </>
            )}
          </div>
          {anyServices && (
            <>
              {services.length > 1 && (
                <>
                  <ul className={navTabs()}>
                    {services.map((item, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => handleTabChange(index)}
                          className={`w-${12 / services.length}/12 ${
                            index === activeTab ? activeTabColor[activeTab] : tabColor[index]
                          } ${punchCardService()}`}
                        >
                          <a
                            aria-label="toggle tab"
                            data-toggle="tab"
                            className={punchCardServiceLink()}
                          >
                            {item?.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
              <div
                className={clsx(
                  tabContent(),
                  anyServices && services.length === 1 && oneServiceOnlyTabContent()
                )}
              >
                {services && services[activeTab]?.fields?.description && (
                  <div
                    id={`service-id-${activeTab}`}
                    className={`${activeTab === 0 ? 'active' : ''}`}
                  >
                    <RichTextHelper
                      field={services[activeTab]?.fields?.description}
                      className={punchCardServiceDescription()}
                    />
                    <div className={punchCardTab()}>
                      {services?.map((item, index) => {
                        const maxImages = 8;
                        const images = [];

                        for (let i = 0; i < maxImages; i++) {
                          const isActiveTab = activeTab === index;
                          const punchedField = item?.fields[`image${i + 1}Punched`];
                          const unpunchedField = item?.fields[`image${i + 1}Unpunched`];

                          if (i < allUnrewardedPurchases[index]?.content?.length) {
                            images.push(
                              <a
                                aria-label="link"
                                key={`punched-${i}`}
                                onClick={() => handleUnrewardedModalClick(index, i)}
                                role="button"
                                className={isActiveTab ? 'block' : 'hidden'}
                              >
                                <ImageHelper
                                  priority
                                  className={serviceTabImage()}
                                  field={punchedField}
                                />
                              </a>
                            );
                          } else {
                            images.push(
                              <ImageHelper
                                priority
                                className={`${serviceTabImage()} ${
                                  isActiveTab ? 'block' : 'hidden'
                                }`}
                                key={`unpunched-${i}`}
                                field={unpunchedField}
                              />
                            );
                          }
                        }

                        return (
                          <div key={index}>
                            <div className="grid grid-cols-4 sm:grid-cols-2 gap-3">
                              {isUnrewardedResponseReceived && images}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {openModalIndex === activeTab && (
                  <ModalWrapper
                    showCloseButtonInModalHeader={false}
                    closeModalOnOverlayClick={true}
                    onCloseClick={handleUnrewardedModalClose}
                    showModal={true}
                    customPopup={true}
                    popupWidth="max-w-[997px]"
                    popupSpacing="p-[15px]"
                  >
                    <div className={rewardsProgramStyles()}>
                      {punchcardDescriptionLabel && punchcardDescriptionLabel?.value && (
                        <RichTextHelper
                          className={punchcardDescriptionLabelStyles()}
                          field={punchcardDescriptionLabel}
                        />
                      )}
                      {allUnrewardedPurchases[activeTab]?.content &&
                        allUnrewardedPurchases[activeTab]?.content?.map((purchase, index) => (
                          <div key={index}>
                            {unrewardedCardId === index && (
                              <>
                                <div className="flex my-3">
                                  {purchasedDateLabel && purchasedDateLabel?.value && (
                                    <RichTextHelper
                                      className="font-bold"
                                      field={purchasedDateLabel}
                                    />
                                  )}
                                  : {formatDateFromString(new Date(purchase?.TransDate as string))}
                                </div>
                                <div className="flex my-3">
                                  {storeLabel && storeLabel?.value && (
                                    <RichTextHelper className="font-bold" field={storeLabel} />
                                  )}
                                  : {purchase?.storeName}
                                </div>
                                <div className="flex my-3">
                                  {locationLabel && locationLabel?.value && (
                                    <RichTextHelper className="font-bold" field={locationLabel} />
                                  )}
                                  : {purchase?.storeAddress1}
                                  {purchase?.storeAddress2} {purchase?.storeCity}{' '}
                                  {purchase?.storeState} {purchase?.storeZip}
                                </div>
                                <div className="flex my-3">
                                  {quantityLabel && quantityLabel?.value && (
                                    <RichTextHelper className="font-bold" field={quantityLabel} />
                                  )}
                                  : {purchase?.quantity}
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                    </div>
                  </ModalWrapper>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {/* Available Rewards */}
      <div className={availableRewardsSection()}>
        <div className={availableRewards()}>
          <div className={titleSection()}>
            <h2 className={availableRewardsTitle()}>{availableRewardsLabel?.value}</h2>
          </div>

          {rewardsProducts && rewardsProducts?.length && (
            <div
              className={clsx(
                tabContent(),
                'text-center justify-center',
                rewardsProducts && rewardsProducts.length === 0 ? 'hidden' : 'block'
              )}
            >
              {rewardsProducts &&
                rewardsProducts?.map((reward, index) => (
                  <Fragment key={index}>
                    {reward?.isExpired === false &&
                      reward?.programRewardStatus === 'Available' &&
                      index < numberOfAwardsToShow?.value && (
                        <div key={index}>
                          <div className={yourReward()}>
                            <div className={yourRewardHeader()}>
                              <div className="text-right">
                                <span className="text-color-brand-primary-1-base font-bold">
                                  {reward?.programRewardStatus}
                                </span>
                              </div>
                              {reward && reward?.CouponRewardName && (
                                <h3 className={yourRewardName()}>{reward?.CouponRewardName}</h3>
                              )}
                            </div>
                            <div className={yourRewardInfo()}>
                              <p className={yourRewardEarned()}>
                                {rewardEarnedLabel?.value}:{' '}
                                <span className="font-bold">
                                  {formatDateFromString(
                                    new Date(reward?.dateRewardEarned as string)
                                  )}
                                </span>
                              </p>
                              <p className={yourRewardExpired()}>
                                {rewardExpiresLabel?.value}:{' '}
                                <span className="font-bold">
                                  {formatDateFromString(
                                    new Date(reward?.dateRewardExpires as string)
                                  )}
                                </span>
                              </p>
                            </div>
                            <div className={yourRewardCoupon()}>
                              <div className="w-full flex justify-center my-3">
                                <ImageHelper
                                  field={{
                                    value: {
                                      src: `https://petsuppliesplusemail.com/bca/z/image?ct=${reward?.couponCode}&amp;cc=T&amp;bc={0}&amp;st={1}`,
                                      alt: reward?.CouponRewardName,
                                      width: '225',
                                      height: '44',
                                    },
                                  }}
                                />
                              </div>

                              <a
                                aria-label="coupon code"
                                key={reward.couponCode}
                                className="font-bold hover:no-underline hover:cursor-pointer"
                                onClick={() => copyToClipboard(reward.couponCode)}
                              >
                                <div>
                                  {copiedCoupon !== reward.couponCode
                                    ? reward.couponCode
                                    : couponCodeCopiedLabel?.value}
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                  </Fragment>
                ))}
            </div>
          )}

          <div
            className={clsx(
              yourRewardsNoImage(),
              rewardsProducts &&
                rewardsProducts?.some((reward) => reward.programRewardStatus === 'Available')
                ? 'hidden'
                : 'block'
            )}
          >
            {noRewardsImage && (
              <div>
                <div>
                  <ImageHelper field={noRewardsImage} />
                </div>
                <h3 className={noRewardsLabelText()}>{noRewardsLabel?.value}</h3>
              </div>
            )}
          </div>
          {rewardsProducts && rewardsProducts?.length > 0 && (
            <div className="text-center mb-4">
              <button
                aria-label="view all rewards"
                type="button"
                className={viewAllRewardsButton()}
                onClick={() => handleModalClick()}
              >
                {viewAllRewardsLabel?.value}
              </button>
            </div>
          )}

          {isModalOpen && (
            <ModalWrapper
              showCloseButtonInModalHeader={false}
              closeModalOnOverlayClick={true}
              onCloseClick={handleModalClose}
              showModal={true}
              customPopup={true}
              popupWidth="max-w-[997px]"
              popupSpacing="p-[15px]"
              additionalClassForPopUpModal={viewAllRewardsModal()}
            >
              <div className="rewards-program">
                <div>
                  {viewAllRewardsLabel && viewAllRewardsLabel?.value && (
                    <RichTextHelper
                      className="text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold py-4 uppercase"
                      field={viewAllRewardsLabel}
                    />
                  )}
                </div>
                <div className="overflow-y-auto">
                  <div className={viewAllRewardsModalHeading()}>
                    <div className={clsx(viewAllRewardsModalHeadingRow(), 'lg:w-3/12')}>
                      {rewardLabel && rewardLabel?.value && (
                        <RichTextHelper className="font-bold" field={rewardLabel} />
                      )}
                    </div>
                    <div className={clsx(viewAllRewardsModalHeadingRow(), 'lg:w-2/12')}>
                      {rewardEarnedLabel && rewardEarnedLabel?.value && (
                        <RichTextHelper className="font-bold" field={rewardEarnedLabel} />
                      )}
                    </div>
                    <div className={clsx(viewAllRewardsModalHeadingRow(), 'lg:w-2/12')}>
                      {rewardExpiresLabel && rewardExpiresLabel?.value && (
                        <RichTextHelper className="font-bold" field={rewardExpiresLabel} />
                      )}
                    </div>
                    <div className={clsx(viewAllRewardsModalHeadingRow(), 'lg:w-3/12')}>
                      {rewardCouponLabel && rewardCouponLabel?.value && (
                        <RichTextHelper className="font-bold" field={rewardCouponLabel} />
                      )}
                    </div>
                    <div className={clsx(viewAllRewardsModalHeadingRow(), 'lg:w-2/12')}>
                      {rewardStatusLabel && rewardStatusLabel?.value && (
                        <RichTextHelper className="font-bold" field={rewardStatusLabel} />
                      )}
                    </div>
                  </div>
                  {rewardsProducts &&
                    rewardsProducts?.map((reward, index) => {
                      return (
                        <div key={index} className={viewAllRewardsModalBody()}>
                          <div className={clsx(viewAllRewardsModalBodyRow(), 'lg:w-3/12')}>
                            <p className="font-bold">{reward?.CouponRewardName}</p>
                          </div>
                          <div className={clsx(viewAllRewardsModalBodyRow(), 'lg:w-2/12')}>
                            {isMobileScreenView &&
                              rewardEarnedLabel &&
                              rewardEarnedLabel?.value && (
                                <RichTextHelper
                                  className="w-1/3 font-bold"
                                  field={rewardEarnedLabel}
                                />
                              )}
                            <p className="font-bold">
                              {formatDateFromString(new Date(reward?.dateRewardEarned as string))}
                            </p>
                          </div>
                          <div className={clsx(viewAllRewardsModalBodyRow(), 'lg:w-2/12')}>
                            {isMobileScreenView &&
                              rewardExpiresLabel &&
                              rewardExpiresLabel?.value && (
                                <RichTextHelper
                                  className="w-1/3 font-bold"
                                  field={rewardExpiresLabel}
                                />
                              )}
                            <p className="font-bold">
                              {formatDateFromString(new Date(reward?.dateRewardExpires as string))}
                            </p>
                          </div>
                          <div className={clsx(viewAllRewardsModalBodyRow(), 'lg:w-3/12')}>
                            {isMobileScreenView &&
                              rewardCouponLabel &&
                              rewardCouponLabel?.value && (
                                <RichTextHelper
                                  className="w-1/3 font-bold"
                                  field={rewardCouponLabel}
                                />
                              )}
                            <div>
                              <NextImage
                                field={{
                                  value: {
                                    src: `https://petsuppliesplusemail.com/bca/z/image?ct=${reward?.couponCode}&amp;cc=T&amp;bc=${reward?.couponCode}&amp;st=N`,
                                    alt: reward?.CouponRewardName,
                                    width: '225',
                                    height: '44',
                                  },
                                }}
                                className={viewAllRewardsModalCouponRewardImage()}
                              />
                              <p className="font-bold underline">{reward?.couponCode}</p>
                            </div>
                          </div>
                          <div className={clsx(viewAllRewardsModalBodyRow(), 'lg:w-2/12')}>
                            {isMobileScreenView &&
                              rewardStatusLabel &&
                              rewardStatusLabel?.value && (
                                <RichTextHelper
                                  className="w-1/3 font-bold"
                                  field={rewardStatusLabel}
                                />
                              )}
                            {reward?.programRewardStatus === 'Expired' && (
                              <p className="text-color-global-outof-stock font-bold">
                                {reward?.programRewardStatus}
                              </p>
                            )}
                            {reward?.programRewardStatus === 'Redeemed' && (
                              <p className="text-color-global-low-stock font-bold">
                                {reward?.programRewardStatus} <br />
                                {/* {formatDateForGTM(reward?.DateRewardRedeemed)} */}
                              </p>
                            )}
                            {reward?.programRewardStatus === 'Available' && (
                              <p className="text-color-brand-primary-1-base font-bold">
                                {reward?.programRewardStatus}
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </ModalWrapper>
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceRewardsWidget;
