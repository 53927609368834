import { useOcSelector } from 'src/redux/ocStore';
import { ProductSearchResultModel, ProductSearchResultModelWithVariants } from './types';
import { useEffect, useState } from 'react';
import { Me } from 'ordercloud-javascript-sdk';

import groupBy from 'lodash/groupBy';
import { BuyerProductWithXp } from 'src/redux/xp';

export function useProductVariants(products: ProductSearchResultModel[]) {
  const store = useOcSelector((x) => x.storeReducer.selectedStore);

  // We'll start with just casting the objects, and we'll populate the variants after
  const initialState = products.map((x) => x as ProductSearchResultModelWithVariants);

  const [productsWithVariants, setProductsWithVariants] = useState(() => initialState);

  // Pipe separate to indicate "OR" filter
  const parentIds = products.map((x) => x.parentid).join('|');

  /**
   * Fetches the IDs of parent products that have variation attributes.
   *
   * This function queries a list of parent products using the provided `parentIds`
   * and filters them to find products that have defined variation attributes.
   *
   * @returns {Promise<string[]>} A promise that resolves to an array of parent product IDs
   * that have variation attributes.
   */
  const fetchParentIdWithVariationAttributes = async () => {
    const parentProductData = await Me.ListProducts<BuyerProductWithXp>({
      filters: { ID: parentIds },
    });

    return parentProductData?.Items?.reduce(
      (parentIdWithVariationAttributesData: string[], parentProduct: BuyerProductWithXp) => {
        if (
          parentProduct.ID &&
          parentProduct?.xp?.VariationAttributes &&
          Array.isArray(parentProduct?.xp?.VariationAttributes) &&
          parentProduct?.xp?.VariationAttributes?.length > 0
        ) {
          parentIdWithVariationAttributesData.push(parentProduct.ID);
        }
        return parentIdWithVariationAttributesData;
      },
      []
    );
  };

  /**
   * Fetches sibling products for the given parent product IDs.
   *
   * This function queries the list of products that are siblings of the specified
   * parent product IDs, using the `parentIds` variable. It filters out products
   * where the 'xp.WE' attribute is set to true and utilizes the current store's
   * seller ID for the query.
   *
   * @returns {Promise<BuyerProductWithXp[]>} A promise that resolves to an array of
   * sibling products.
   */
  const fetchSiblingProducts = async () => {
    return await Me.ListProducts<BuyerProductWithXp>({
      filters: { ParentID: parentIds, 'xp.WE': false },
      sellerID: store.storeId,
    });
  };

  /**
   * Fetches products with variants from the OC and updates the 'showVariants' and
   * 'variants' properties of the products.
   *
   * This function fetches the list of parent product IDs where the 'xp.VariationAttributes'
   * attribute is set and the list of sibling products with the 'xp.WE' attribute set to
   * false, using the current store's seller ID for the query.
   *
   * It then groups the sibling products by their parent product IDs and updates the
   * 'showVariants' and 'variants' properties of the products accordingly.
   *
   * @returns {Promise<Record<string, BuyerProductWithXp[]>>} A promise that resolves to an
   * object where the keys are the parent product IDs and the values are arrays of sibling
   * products.
   */
  const fetchProductsWithVariants = async () => {
    const [parentIdWithVariationAttributes, siblingProducts] = await Promise.all([
      fetchParentIdWithVariationAttributes(),
      fetchSiblingProducts(),
    ]);
    const grouped = groupBy(siblingProducts.Items, (x) => x.ParentID);
    // Add the variants to the product
    const updated = products.map((x) => {
      const product = x as ProductSearchResultModelWithVariants;
      if (product.parentid) {
        product.showVariants = parentIdWithVariationAttributes.includes(product.parentid);
      }
      product.variants = grouped[product.parentid ?? ''] ?? [];
      return product;
    });

    setProductsWithVariants(updated);
    return grouped;
  };

  useEffect(() => {
    // Wait until after we get results
    if (!parentIds.length || !store.storeId) {
      return;
    }
    fetchProductsWithVariants();
  }, [parentIds, products, store.storeId]);

  return productsWithVariants;
}
