import type { SearchResultsInitialState, SearchResultsStoreState } from '@sitecore-search/react';
import {
  FilterEqual,
  FilterOr,
  WidgetDataType,
  useSearchResults,
  widget,
} from '@sitecore-search/react';
import styles from './styles.module.css';
import { PspFacetSection } from './PspFacetSection';
import { PspSortSelect } from './PspSortSelect';
import { PspQuerySummary } from './PspQuerySummary';
import { PspProductRoot } from './PspProductRoot';
import { ProductSearchResultModel, ProductSearchResultModelWithVariants } from './types';
import {
  MarketingTileData,
  MarketingTileSetting,
  MarketingTile,
  insertMarketingTileInResults,
} from '../MarketingTile';
import { PspPagination } from './PspPagination';
import { urlToFacet, useEnsureFacetUrl } from './use-ensure-facet-url';
import { VisualFilters } from './VisualFilters';
import { useEffect, useState } from 'react';
import { useSetSearchPageUri } from './use-set-search-page-uri';
import { useProductVariants } from './use-product-variants';
import { FacetSettingMap, FacetFilterMap } from 'components/Search/ProductListing';
import { PspSearchLoader } from './PspSearchLoader';
import searchResultsTailwind from './SearchResultsTailwind';
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { PspNoResultsBox } from './PspNoResultsBox';
import router, { useRouter } from 'next/router';
import { sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { GTMLabels, GTM_EVENT } from 'src/helpers/Constants';
import { useOcSelector } from 'src/redux/ocStore';
import PspSkeleton from './PspSkeleton';
import { SitecoreTemplateId } from 'lib/constants';
import { useBreakpoints } from 'src/utils/breakpoints';
import { BazaarVoiceScript } from 'src/helpers/BazaarVoice/BazaarVoiceScript';
type SearchResultsProps = {
  defaultSortType?: SearchResultsStoreState['sortType'];
  defaultPage?: SearchResultsStoreState['page'];
  defaultItemsPerPage?: SearchResultsStoreState['itemsPerPage'];
  defaultKeyphrase?: SearchResultsStoreState['keyphrase'];
  facetSettings: FacetSettingMap;
  visualFiltersData?: FacetFilterMap;
  marketingTileData?: MarketingTileData;
  shopFilter?: string;
  shopFilterValue?: string;
  key?: string;
  templateId?: string;
};

type InitialState = SearchResultsInitialState<
  'itemsPerPage' | 'keyphrase' | 'page' | 'sortType' | 'selectedFacets'
>;
type ResponseContextType = {
  response_context: {
    redirect_url?: string;
  };
};

export type ProductOrMarketingTileResults = (MarketingTileSetting | ProductSearchResultModel)[];

export const SearchResultsComponent = ({
  defaultSortType = 'default', // 'featured_desc',
  defaultPage = 1,
  defaultKeyphrase = '',
  defaultItemsPerPage = 24,
  facetSettings,
  visualFiltersData,
  marketingTileData,
  shopFilter,
  shopFilterValue,
  templateId,
}: SearchResultsProps) => {
  const { query: currentQuery, asPath } = useRouter();

  //set the filters for Shops
  const shopFilters = !shopFilterValue?.includes(',')
    ? new FilterEqual(shopFilter || '', shopFilterValue || '')
    : new FilterOr(
        shopFilterValue?.split(',')?.map((val) => {
          return new FilterEqual(shopFilter || '', val || '');
        })
      );

  const searchResults = useSearchResults<ProductSearchResultModel, InitialState>({
    config: {
      defaultFacetType: 'text',
    },

    query: (query) => {
      //if shops filters are available then we are passing shopFilters otherwise or query
      if (shopFilter) {
        return query?.getRequest()?.setSearchFilter(shopFilters);
      } else {
        if (
          (currentQuery?.q && currentQuery?.q?.includes(' ')) ||
          (currentQuery?.query && currentQuery?.query?.includes(' '))
        ) {
          return query.getRequest().setSearchQueryOperator('and');
        }
        return query.getRequest().setSearchQueryOperator('or');
      }
    },

    state: () => {
      const hasWindow = typeof window !== 'undefined';
      const hash = hasWindow ? window.location.hash.replace(/^#+/, '') : '';
      const facetsFromUrl = urlToFacet(hash);
      return {
        sortType: defaultSortType,
        page: defaultPage,
        itemsPerPage: defaultItemsPerPage,
        keyphrase: defaultKeyphrase,
        selectedFacets: facetsFromUrl,
      };
    },
  });

  const {
    widgetRef,
    actions,
    state,
    queryResult: { isLoading, isFetching, data },
    query,
  } = searchResults;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const myStoreData = useOcSelector((state: any) => state?.storeReducer?.selectedStore);
  const { isMobile } = useBreakpoints();

  const { sortType, page, itemsPerPage, keyphrase } = state;
  const {
    total_item: totalItems = null,
    sort,
    facet: facets = [],
    content: baseProducts = [],
    response_context,
  } = (data as typeof data & ResponseContextType) ?? {};

  // If no results, change to "or" search
  useEffect(() => {
    if (totalItems === 0) {
      query.getRequest().setSearchQueryOperator('or');
    }
  }, [query, totalItems]);

  // To redirect user to redirect url if found from Discover response:
  const foundRedirectUrl = response_context?.redirect_url;
  const checkRedirectUrlFromSearch = () => {
    if (foundRedirectUrl) {
      router.push(foundRedirectUrl);
    }
  };

  const productsWithVariants = useProductVariants(baseProducts);

  useEffect(() => {
    checkRedirectUrlFromSearch();
  }, [foundRedirectUrl]);

  //removing row class from the container.
  useEffect(() => {
    const ele = document?.getElementsByClassName('row');
    Array.from(ele)?.map((el) => {
      el?.classList?.remove('row');
    });
  }, []);

  useEffect(() => {
    checkRedirectUrlFromSearch();
    if (totalItems && totalItems > 0) {
      localStorage.setItem('lastSuccessSearch', keyphrase);
    }
  }, [totalItems, keyphrase]);

  const context = useSitecoreContext();
  const route = context?.sitecoreContext
    .route as PSP.Sitecore.templates.PetSuppliesPlus.Base.Fields.HasTitle;

  // If we have search phrase, use that, otherwise use page title
  const headline = keyphrase ? { value: keyphrase } : route.fields?.title;

  // Set Search Page for Widget
  const isShopPage = templateId === SitecoreTemplateId?.ShopPage;
  const hostName = typeof window !== 'undefined' && (window?.location?.origin as string);
  const searchPageURI = isShopPage ? asPath?.split(hostName as string)?.[0] : ''; //set path after domain name e.g /categories/shops/fall-shop
  useSetSearchPageUri(searchPageURI);

  // Ensure that facets are synced with url
  useEnsureFacetUrl(actions, facets);

  const sortChoices = sort?.choices ?? [];

  const productsOrWidgets: ProductOrMarketingTileResults = [...productsWithVariants];

  //GTM DataLayer-----
  //Sending GTM promotion data
  const currentGMTPage =
    asPath?.split('/')?.[1]?.split('?')?.[0]?.toLowerCase() === 'search'
      ? GTMLabels?.searchPage
      : GTMLabels?.PLP;
  const productsData = productsOrWidgets?.filter(
    (product: MarketingTileSetting) => !product?.rfkid
  );
  const pageItem =
    headline?.value ||
    ((currentQuery?.q || currentQuery?.query) as string) ||
    GTMLabels?.notAvailable;

  useEffect(() => {
    if (window) {
      window?.sessionStorage?.setItem(GTMLabels?.currentPage, currentGMTPage);
      window?.sessionStorage?.setItem(GTMLabels?.pageItem, pageItem);
    }

    const checkGtmLoad = () => {
      const isEventFired =
        window &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)['dataLayer']?.filter((val: any) => val?.event === GTM_EVENT?.viewItemList)
          ?.length > 0;
      const isGTMLoad =
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof window !== 'undefined' && (window as any)['google_tag_manager']?.dataLayer?.gtmLoad;
      if (productsData && productsData?.length > 0 && !isEventFired && isGTMLoad) {
        sendProductsPromotion({
          eventName: GTM_EVENT?.viewItemList,
          data: productsData as ProductSearchResultModelWithVariants[],
          currentPage: currentGMTPage,
          pageItem: pageItem,
          affiliation: myStoreData?.storeId,
          isRatingShow: true,
        });
      } else {
        setTimeout(() => {
          checkGtmLoad();
        }, 1000);
      }
    };
    checkGtmLoad();
  }, [productsData]);
  //----------------------

  const [showSkeleton, setShowSkeleton] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      if (totalItems === undefined) {
        setShowSkeleton(true);
      }
    });

    return () => {
      window.removeEventListener('beforeunload', () => {
        setShowSkeleton(false);
      });
    };
  }, []);

  // Only insert marketing tile if we're on the first page.
  if (page === 1) {
    insertMarketingTileInResults(productsOrWidgets, marketingTileData);
  }

  const clearLastAction = () => {
    const checkLastSuccessfulSearch = localStorage.getItem('lastSuccessSearch');
    //TODO: fetch previous query in a state and perform that:
    if (checkLastSuccessfulSearch) {
      router.push(`/search?q=${checkLastSuccessfulSearch}`);
    }
  };

  if (isLoading) {
    return <PspSkeleton />;
  }

  const {
    plpWrapper,
    sitecoreResultsWrapper,
    sitecoreRightSection,
    resultsWrapper,
    queryAndFilter,
    facetPagination,
    searchTitle,
  } = searchResultsTailwind({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  //use for considering only product index and not widget for GTM data
  let productIndex = -1;
  return (
    <div ref={widgetRef} className={plpWrapper()}>
      <BazaarVoiceScript bvCallback={bvCallback} />
      <div className={styles['sitecore-main-area']}>
        {/* Triggers on Facets */}
        {isFetching && <PspSearchLoader />}
        {/* Triggers Skeleton */}
        {showSkeleton === true && <PspSkeleton />}

        {totalItems && totalItems > 0 ? (
          <div className={sitecoreResultsWrapper({ noResults: totalItems === 0 })}>
            {/* Left Section for Facet */}
            {!isMobile && (
              <PspFacetSection actions={actions} facets={facets} facetSettings={facetSettings} />
            )}

            {/* Right Section for Facet */}
            <section className={sitecoreRightSection()}>
              <Text
                tag="h1"
                field={headline}
                className={searchTitle()}
                role="heading"
                aria-level={1}
              />
              <VisualFilters keyphrase={keyphrase} visualFiltersData={visualFiltersData} />

              {isMobile && (
                <PspFacetSection actions={actions} facets={facets} facetSettings={facetSettings} />
              )}

              {/* Query and Filer */}
              <section className={queryAndFilter({ noResults: totalItems === 0 })}>
                <PspQuerySummary
                  state={state}
                  totalItems={totalItems}
                  productsLength={productsWithVariants?.length}
                />
                {totalItems === 0 && (
                  <PspNoResultsBox keyphrase={keyphrase} clearLastAction={clearLastAction} />
                )}
                <PspSortSelect actions={actions} sortChoices={sortChoices} sortType={sortType} />
              </section>

              {totalItems > 0 && (
                <>
                  {/* Results */}
                  <div className={resultsWrapper({ className: 'productList' })}>
                    {productsOrWidgets.map((x, index) => {
                      const product = x as ProductSearchResultModelWithVariants;
                      const widget = x as MarketingTileSetting;
                      const isWidget = !!widget.rfkid;
                      if (isWidget && marketingTileData) {
                        return (
                          <MarketingTile
                            key={index}
                            isMobile={widget.isMobile}
                            marketingTileData={marketingTileData}
                          />
                        );
                      }
                      const currentProductIndex = productIndex; // Capture the current product index
                      productIndex++;

                      if (!product?.id) {
                        return null;
                      }
                      return (
                        <PspProductRoot
                          key={product.id}
                          product={product}
                          index={currentProductIndex - 1}
                          currentPage={currentGMTPage}
                          actions={actions}
                        />
                      );
                    })}
                  </div>
                  {/* Pagination section */}
                  {/* <div className={styles['sitecore-page-controls']}> */}
                  <div className={facetPagination()}>
                    <PspPagination {...{ actions, totalItems, itemsPerPage, page }} />
                  </div>
                </>
              )}
            </section>
          </div>
        ) : null}

        {/* Fix to include PSP No Results box along with keeping PSP-3496: React185 error */}
        {!(isLoading || isFetching) && totalItems === 0 && (
          <div className={sitecoreResultsWrapper({ noResults: totalItems === 0 })}>
            <PspFacetSection actions={actions} facets={facets} facetSettings={facetSettings} />

            <section className={sitecoreRightSection()}>
              <Text
                tag="h1"
                field={headline}
                className={searchTitle()}
                role="heading"
                aria-level={1}
              />
              {/* Query and Filer */}
              <section className={queryAndFilter({ noResults: totalItems === 0 })}>
                <PspQuerySummary
                  state={state}
                  totalItems={totalItems}
                  productsLength={productsWithVariants?.length}
                />
                {totalItems === 0 && (
                  <>
                    <PspNoResultsBox keyphrase={keyphrase} clearLastAction={clearLastAction} />
                  </>
                )}
                <PspSortSelect actions={actions} sortChoices={sortChoices} sortType={sortType} />
              </section>
            </section>
          </div>
        )}
      </div>
    </div>
  );
};

const SearchResultsWidget = widget(
  SearchResultsComponent,
  WidgetDataType.SEARCH_RESULTS,
  'product'
);

export default SearchResultsWidget;

interface BVObject {
  reviews: {
    on: (event: string, callback: () => void) => void;
  };
}

// Define the bvCallback function
const bvCallback = (BV: BVObject): void => {
  // Register a function to be called when a BV feature needs to display the R&R element, such as Rating Summary's stars
  BV.reviews.on('show', () => {
    // If the R&R container is hidden (such as behind a tab), put code here to make it visible (open the tab)
    const buttons = document.getElementsByClassName('bv_war_button');
    if (buttons && buttons.length > 0) {
      (buttons[0] as HTMLElement).click(); // Using type assertion to avoid compilation errors
    }
  });
};
