//global
import React, { useEffect, useState } from 'react';
//model
import { PSP } from '../../../models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import { bannerCtaTailwindVariant } from 'tailwindVariants/components/bannerCtaTailwindVariant';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';

export type BannerCtaProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.BannerCtaPanel.Fields.BannerCtapanel;

//main component

const BannerCta: React.FC<BannerCtaProps> = ({ fields, params }) => {
  //state
  const isEE = useExperienceEditor();
  const [screen, setScreen] = useState<number>(1023);

  // variant
  const {
    base,
    innerDiv,
    description,
    contentWrapper,
    ctaLink,
    LinkWrapper,
    content,
    imgWrapper,
    title,
  } = bannerCtaTailwindVariant({
    colors: params?.FieldNames === 'BlackText' ? 'black' : 'default',
    device: { initial: 'mobile', lg: 'desktop' },
    bannerSize: params?.showCTALoader === '1' ? 'small' : 'big',
  });

  //handle mobile
  useEffect(() => {
    setScreen(window?.innerWidth);
    const handleResize = () => {
      setScreen(window?.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [screen]);

  //return empty fragment if field value is null or undefined
  if (fields === undefined || fields === null) return <></>;

  return (
    <div className={base({ className: params?.Styles ?? '' })}>
      <div className={innerDiv()}>
        <div className={contentWrapper()}>
          <div className={imgWrapper()}>
            <ImageHelper field={fields?.desktopBackgroundImage} />
          </div>

          <div className={content()}>
            <div className={params?.showCTALoader === '1' ? title() : ''}>
              <RichTextHelper field={fields?.title} />
            </div>
            <RichTextHelper field={fields?.description} className={description()} />
          </div>
          <div className={LinkWrapper()}>
            {isEE
              ? fields?.link && <LinkHelper field={fields?.link} className={ctaLink()} />
              : fields?.link?.value?.href?.length &&
                fields?.link?.value?.href?.length > 0 && (
                  <LinkHelper field={fields?.link} className={ctaLink()} />
                )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const Default = (props: BannerCtaProps): JSX.Element => {
  return <BannerCta {...props} />;
};
export const WhiteText = (props: BannerCtaProps): JSX.Element => {
  return <BannerCta {...props} />;
};
export const BlackText = (props: BannerCtaProps): JSX.Element => {
  return <BannerCta {...props} />;
};
