import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import Loader from 'components/Loader/Loader';
import { useAutoshipContext } from 'lib/context/AutoshipComponentContext';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { useSiteNameForApi } from 'src/hooks/useSiteName';
import { SubscriptionResponse } from 'src/utils/apiConfig';
import { apiRequest, ApiRequestOptions } from 'src/utils/apiWrapper';
import { autoshipAPI } from 'src/utils/nextApiConfig';
import { skipDeliveryModalTailwindVariant } from 'tailwindVariants/components/skipDeliveryModalTailwindVariant';

interface SkipDeliveryProps {
  fields: {
    title?: Field<string>;
    description?: Field<string>;
    warningHeading?: Field<string>;
    skipDeliveryText?: Field<string>;
    loaderText?: Field<string>;
    ctaText?: Field<string>;
    button?: Field<string>;
  };
}
const SkipDelivery = ({ fields }: SkipDeliveryProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    base,
    title,
    description,
    button,
    warningHeading,
    buttonWrapper,
    loadingWrapper,
    buttonWithBg,
  } = skipDeliveryModalTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const { autoshipContextData, setAutoshipContextData } = useAutoshipContext();
  const handleModalClose = () => {
    setIsLoading(false);
    setAutoshipContextData({
      ...autoshipContextData,
      isSkipClicked: false,
      subscriptionData: false,
    });
  };

  const site = useSiteNameForApi();
  const token = useAuthToken();
  if (fields === undefined || fields === null) return <></>;

  const onSkipClicked = async () => {
    setIsLoading(true);

    const oldNextDeliveryDate = autoshipContextData?.subscriptionData?.NextOrderDate
      ? DateTime.fromISO(autoshipContextData.subscriptionData.NextOrderDate)
      : null;

    const options: ApiRequestOptions = {
      method: 'POST',
      data: {
        SubscriptionId: autoshipContextData.subscriptionData.ID,
      },
      headers: {
        Authorization: token,
        site: site,
      },
    };
    const response = await apiRequest<SubscriptionResponse>(autoshipAPI?.skipAutoship, options);

    setIsLoading(false);

    setAutoshipContextData({
      ...autoshipContextData,
      isSkipClicked: false,
      subscriptionData: false,
      isSkippedSuccessfully: response.Success,
      subscriptionId: autoshipContextData.subscriptionData.ID,
      nextDeliveryDate: response.Subscription.NextOrderDate, // updatedNextDeliveryDate.toISO(),
      skippedDeliveryDate: oldNextDeliveryDate?.toFormat('MM/dd/yyyy'),
    });
  };
  return (
    <ModalWrapper
      showCloseButtonInModalHeader={false}
      closeModalOnOverlayClick={true}
      onCloseClick={handleModalClose}
      showModal={autoshipContextData?.isSkipClicked && autoshipContextData?.subscriptionData}
      customPopup={true}
      popupWidth="max-w-[997px]"
      popupSpacing="p-0"
    >
      <div className={base()}>
        <RichText className={title()} field={fields?.title} />
        <div>
          <RichText className={description()} field={fields?.description} />
          <RichText className={warningHeading()} field={fields?.warningHeading} />
        </div>
        <div className={buttonWrapper()}>
          {isLoading ? (
            <div className={loadingWrapper()}>
              <Loader />
              {fields?.loaderText?.value}
            </div>
          ) : (
            <>
              {fields?.skipDeliveryText?.value && (
                <button
                  aria-label="skip delivery"
                  className={buttonWithBg()}
                  onClick={onSkipClicked}
                >
                  {fields?.skipDeliveryText?.value}
                </button>
              )}
            </>
          )}
          {fields?.ctaText?.value && (
            <button
              aria-label="cancel delivery"
              className={button({
                isLoading,
              })}
              onClick={handleModalClose}
            >
              {fields?.ctaText?.value}
            </button>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default SkipDelivery;
