//global
import React from 'react';
import { NextImage, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import { dividerTailwindVariant } from 'tailwindVariants/components/dividerTailwindVariant';

export type DividerProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.Divider.Fields.Divider;

const Divider: React.FC<DividerProps> = ({ fields, params }) => {
  const { base, mobileIcon, desktopIcon, wrapper } = dividerTailwindVariant({
    device: { initial: 'mobile', md: 'desktop' },
  });
  return (
    <div className={base({ className: params?.Styles ?? '' })}>
      <div className={wrapper()}>
        {fields?.desktopIcon && <NextImage field={fields?.desktopIcon} className={desktopIcon()} />}
        {fields?.mobileIcon && <NextImage field={fields?.mobileIcon} className={mobileIcon()} />}
      </div>
    </div>
  );
};

export default withDatasourceCheck()<DividerProps>(Divider);
