export const convertToBase64 = async (
  file: Blob | MediaSource,
  setFileTypeError: (val: boolean) => void
) => {
  // Helper function to check file extension
  const getFileExtension = (filename: string): string => {
    return filename.toLowerCase().split('.').pop() || '';
  };

  // Check both MIME type and file extension
  const isValidFile = (file: Blob): boolean => {
    const validMimeTypes = [
      'image/jpeg',
      'image/png',
      'image/svg+xml',
      'image/jpg',
      'video/mp4',
      'image/heif',
      'image/heic',
    ];

    const validExtensions = ['jpg', 'jpeg', 'png', 'svg', 'mp4', 'heif', 'heic'];

    // Get the file name from the Blob
    const fileName = (file as File).name || '';
    const fileExtension = getFileExtension(fileName);
    const mimeType = file.type;

    // Check if either MIME type is valid or file extension is valid
    return validMimeTypes.includes(mimeType) || validExtensions.includes(fileExtension);
  };

  if (!isValidFile(file as Blob)) {
    console.error('Invalid file type');
    setFileTypeError(true);
    return;
  }

  let convertedBlob: Blob;

  // Check both MIME type and extension for HEIC/HEIF files
  const isHeicHeif = (file: Blob): boolean => {
    const fileName = (file as File).name || '';
    const fileExtension = getFileExtension(fileName);
    return (
      file.type === 'image/heic' ||
      file.type === 'image/heif' ||
      fileExtension === 'heic' ||
      fileExtension === 'heif'
    );
  };

  if (isHeicHeif(file as Blob) && typeof window !== 'undefined') {
    try {
      const heic2any = (await import('heic2any')).default;
      convertedBlob = (await heic2any({
        blob: file as Blob,
        toType: 'image/jpeg',
      })) as Blob;
    } catch (error) {
      console.error('Error converting HEIC/HEIF:', error);
      setFileTypeError(true);
      throw error;
    }
  } else {
    convertedBlob = file as Blob;
  }

  return new Promise((resolve, reject) => {
    setFileTypeError(false);
    const fileReader = new FileReader();
    fileReader?.readAsDataURL(convertedBlob as Blob);

    fileReader.onload = () => {
      setFileTypeError(false);
      resolve(fileReader.result);
    };

    fileReader.onerror = (error) => {
      setFileTypeError(true);
      reject(error);
    };
  });
};
