import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import { CrowdTwistFieldsProps } from '../LoyaltyTabSection';
import Link from 'next/link';
import { oldLoyaltyWidgetTailwindVariant } from 'tailwindVariants/components/oldLoyaltyWidgetTailwindVariant';

type OldLoyaltyWidgetProps = {
  props: {
    fields: CrowdTwistFieldsProps;
  };
};

const { base, productRewardsBorder, productRewardsLink, productRewardsImage } =
  oldLoyaltyWidgetTailwindVariant({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

const OldLoyaltyWidget = ({ props }: OldLoyaltyWidgetProps) => {
  const {
    availableOffersTileImage,
    inProgressTileImage,
    earnedOffersTileImage,
    oldLoyaltyPageLink,
    availableOffersTileAnchorLink,
    earnedOffersTileAnchorLink,
    inProgressTileAnchorLink,
  } = props.fields;
  return (
    <div className={base()}>
      <div className={productRewardsBorder()}>
        <Link
          aria-label="rewards link"
          href={`${oldLoyaltyPageLink?.value?.href}${availableOffersTileAnchorLink?.value}`}
          className={productRewardsLink()}
        >
          <ImageHelper className={productRewardsImage()} field={availableOffersTileImage} />
        </Link>
      </div>
      <div className={productRewardsBorder()}>
        <Link
          aria-label="rewards link"
          href={`${oldLoyaltyPageLink?.value?.href}${inProgressTileAnchorLink?.value}`}
          className={productRewardsLink()}
        >
          <ImageHelper className={productRewardsImage()} field={inProgressTileImage} />
        </Link>
      </div>
      <div className={productRewardsBorder()}>
        <Link
          aria-label="rewards link"
          href={`${oldLoyaltyPageLink?.value?.href}${earnedOffersTileAnchorLink?.value}`}
          className={productRewardsLink()}
        >
          <ImageHelper className={productRewardsImage()} field={earnedOffersTileImage} />
        </Link>
      </div>
    </div>
  );
};

export default OldLoyaltyWidget;
