import {
  RecommendationWidgetQuery,
  WidgetDataType,
  useRecommendation,
  widget,
} from '@sitecore-search/react';
import SplideSlider from 'src/helpers/commonComponents/SplideSlider';
import { ProductSearchResultModelWithVariants } from '../SearchResults/types';
import { ProductCard } from './ProductCard';
import { tv } from 'tailwind-variants';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect, useState } from 'react';
import { sendProductsPromotion } from 'src/utils/sendGTMEvent';
import { GTM_EVENT, GTMLabels } from 'src/helpers/Constants';
import { useOcSelector } from 'src/redux/ocStore';
import { useProductVariants } from '../SearchResults/use-product-variants';
import { ProductOrMarketingTileResults } from '../SearchResults';
import { useRouter } from 'next/router';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';

//component variant
const featuredProductVariant = tv(
  {
    slots: {
      base: [
        'bg-color-scale-2-light',
        'flex',
        'flex-col',
        '[&>*>*>button]:border-color-border-brand',
        '[&>*>*>button]:border-[2px]',
        '[&>*>*>button]:border-solid',
        'lg:[&>*>*>button]:top-[-40px]',
        '[&>*>*>button]:top-[-25px]',
        '[&>*>*>button]:w-6',
        '[&>*>*>button]:h-6',
        '[&>*>*>button]:!opacity-100',
        '[&>*>*>button>svg]:fill-color-brand-primary-1-base',
        '[&_.slider-next]:right-0',
        'xl:[&_.slider-prev]:left-[95%]',
        'lg:[&_.slider-prev]:left-[93%]',
        'md:[&_.slider-prev]:left-[92%]',
        '[&_.slider-prev]:left-[83%]',
        '[&>*>*>button]:bg-white',
        '[&>*>*>button>svg]:h-3',
      ],
      heading: [],
      container: ['container mx-auto'],
      skeleton: ['w-full h-[586px] bg-gray-300 rounded mb-2'],
    },
    variants: {
      size: {
        mobile: {
          base: ['px-mob-padding-micro-x', 'py-mob-padding-tight-y', 'gap-mob-margin-base-bottom'],
          heading: [
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          container: ['p-0'],
        },
        desktop: {
          base: [
            'px-desk-padding-micro-x',
            'py-desk-padding-tight-y',
            'gap-desk-margin-base-bottom',
          ],
          heading: [
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          container: ['px-4'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
//types
interface FeaturedProductProps {
  title: Field<string> | undefined;
  pageLimit: string | undefined;
  brandName?: string;
}
// interface Filter {
//   filter: {
//     type: string;
//     name: string;
//     value: string;
//   };
// }
//main component
const FeaturedProduct: React.FC<FeaturedProductProps> = ({
  title,
  pageLimit,
  brandName,
}): JSX.Element => {
  //destructuring the variants
  const { container, base, heading, skeleton } = featuredProductVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const myStoreData = useOcSelector((state: any) => state?.storeReducer?.selectedStore);
  const router = useRouter();
  //BrandName state
  const [brand, setBrand] = useState(brandName);

  //calling an useRecommendation for getting the discoverData
  const {
    widgetRef,
    actions,
    query,
    queryResult: { isLoading, data: { content: products = [] } = {} },
  } = useRecommendation({
    query: (query: RecommendationWidgetQuery) =>
      query?.getRequest()?.setRecommendationsFilter({
        type: 'eq',
        name: 'xp_brand',
        value: brandName,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any),
    state: {
      itemsPerPage: Number(pageLimit),
    },
  });

  const productsWithVariants = useProductVariants(products);

  const productsData: ProductOrMarketingTileResults = [...productsWithVariants];

  //sending a product data on GTM, on change of product data
  useEffect(() => {
    if (window) {
      window?.sessionStorage?.setItem(GTMLabels?.currentPage, GTMLabels?.featuredItems);
      window?.sessionStorage?.setItem(GTMLabels?.pageItem, GTMLabels?.featuredItems);
    }
    const checkGtmLoad = () => {
      const isEventFired =
        window &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)['dataLayer']?.filter((val: any) => val?.event === GTM_EVENT?.viewItemList)
          ?.length > 0;
      const isGTMLoad =
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof window !== 'undefined' && (window as any)['google_tag_manager']?.dataLayer?.gtmLoad;
      if (productsData && productsData?.length > 0 && !isEventFired && isGTMLoad) {
        sendProductsPromotion({
          eventName: GTM_EVENT?.viewItemList,
          data: productsData as ProductSearchResultModelWithVariants[],
          currentPage: GTMLabels?.featuredItems,
          pageItem: GTMLabels?.featuredItems,
          affiliation: myStoreData?.storeId,
        });
      } else {
        setTimeout(() => {
          checkGtmLoad();
        }, 1000);
      }
    };
    checkGtmLoad();
  }, [productsData]);

  // setting a brand value on changing the brand page from breadcrumb
  useEffect(() => {
    setBrand(brandName);
    query?.getRequest()?.setRecommendationsFilter({
      type: 'eq',
      name: 'xp_brand',
      value: brand,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any);
  }, [brandName, brand]);

  //----------------------

  return (
    <div ref={widgetRef} className={container()} data-component={'helpers/search/FeaturedProduct'}>
      <div className={base()}>
        {!isLoading && productsData && router?.isReady ? (
          <>
            {title && <RichTextHelper field={title} className={heading()} />}
            <SplideSlider
              onMounted={(splide) => {
                splide.refresh();
              }}
              options={{
                arrows: products?.length > 5 ? true : false,
                perPage: 5,
                gap: 12,
                clones: 10,
                perMove: 1,
                type: 'loop',
                pagination: false,
                breakpoints: {
                  1279: {
                    perPage: 5,
                  },
                  1024: {
                    perPage: 4,
                  },
                  767: {
                    perPage: 1,
                  },
                  640: {
                    perPage: 1,
                  },
                },
                classes: {
                  prev: 'splide__arrow--prev slider-prev',
                  next: 'splide__arrow--next slider-next',
                },
              }}
            >
              {(productsData as ProductSearchResultModelWithVariants[])?.map((product, index) => {
                return (
                  <ProductCard
                    product={product}
                    index={index}
                    actions={actions}
                    key={index}
                    title={title?.value}
                  />
                );
              })}
            </SplideSlider>
          </>
        ) : (
          <div className={base({ className: 'animate-pulse !flex-row gap-3' })}>
            {Array.from(Array(5))?.map((_, index) => {
              return (
                <div
                  className={skeleton({ className: index !== 0 ? 'hidden md:block' : '' })}
                  key={index}
                ></div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

//creating widget
const FeaturedProductWidget = widget(FeaturedProduct, WidgetDataType.RECOMMENDATION, 'product');

export default FeaturedProductWidget;
