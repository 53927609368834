//global
import React, { useEffect, useRef } from 'react';

//lib
import Head from 'next/head';
import { useTheme } from 'lib/context/ThemeContext';
import { useBazarVoiceToken } from 'components/BazarVoice/BazarVoiceToken';
import { useOcIsInitalized } from 'src/redux/ocProvider';

export interface BazaarVoiceScriptProps<TBVObject> {
  bvCallback?: (BV: TBVObject) => void;
}

//main component
export function BazaarVoiceScript<TBVObject>({ bvCallback }: BazaarVoiceScriptProps<TBVObject>) {
  const { themeNameUpper } = useTheme();

  const isInitialized = useOcIsInitalized();

  const bv = useBazarVoiceToken();

  const loading = bv?.loading;
  const token = bv?.bvResponse?.Token ?? '';

  const scriptRef = useRef<HTMLScriptElement>();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).bvCallback = bvCallback;
    }
  }, [bvCallback]);

  // To ensure we re-add script when dependency changes
  const incrementorRef = useRef(0);

  useEffect(() => {
    const existingScript = document.querySelector('#bv-script') as HTMLScriptElement | null;
    if (existingScript) {
      scriptRef.current = existingScript;
    }
    // Only add the script after we're initialized
    // By which time, if user is logged in, we should have a token.
    if (isInitialized && !loading && !scriptRef.current) {
      const scriptTag = document.createElement('script');
      scriptRef.current = scriptTag;
      scriptTag.id = 'bv-script';
      scriptTag.async = true;
      const url =
        themeNameUpper === 'PSP'
          ? process.env.NEXT_PUBLIC_BAZAAR_VOICE_URL || ''
          : process.env.NEXT_PUBLIC_WNW_BAZAAR_VOICE_URL || '';

      // Without cache busting, it seems script was not reloading
      // Even when it was being removed and re-added
      scriptTag.src = url + '?cacheBust=' + incrementorRef.current;
      incrementorRef.current++;

      document.body.appendChild(scriptTag);
    }
    return () => {
      document.querySelector('#bv-script')?.remove();
    };
  }, [isInitialized, themeNameUpper, token, bvCallback, loading]);

  if (!isInitialized || loading) {
    return <></>;
  }
  // Token meta tag will be added before script tag
  return (
    <>
      <Head>
        <meta name="bv:userToken" content={token} />
      </Head>
    </>
  );
}
