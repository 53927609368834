import { tv } from 'tailwind-variants';

export const warningUnsavedChangesTailwindVariant = tv(
  {
    slots: {
      base: ['warningUnsavedChanges'],
      wrapper: [''],
      title: [
        'text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold',
      ],
      btnWrapper: ['flex gap-desk-space-between-base-horizontal mt-desk-margin-base-bottom'],
      btnPrimary: ['bg-color-brand-primary-1-base', 'text-color-text-white'],
      btnSecondary: ['border-2 border-color-brand-primary-1-base', 'text-color-text-brand-1'],
    },
    compoundSlots: [
      {
        slots: ['btnPrimary', 'btnSecondary'],
        class: [
          'py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x',
          'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
          'rounded-desk-global-radius-small',
          'w-full',
        ],
      },
    ],
    variants: {
      size: {
        mobile: { btnWrapper: ['flex-col'] },
        desktop: {
          btnPrimary: ['max-w-[310px]'],
          btnSecondary: [
            'hover:bg-color-brand-primary-1-base',
            'hover:text-color-text-white',
            'max-w-[310px]',
          ],
          btnWrapper: ['flex-row'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
