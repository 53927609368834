import { CheckIcon } from '@radix-ui/react-icons';
import { AccordionFacets, FacetItem } from '@sitecore-search/ui';

import styles from './styles.module.css';
import {
  SearchResponseFacet,
  useSearchResultsSelectedFacets,
  useSearchResultsSelectedFilters,
} from '@sitecore-search/react';
import type { SearchResultsStoreTextSelectedFacet } from '@sitecore-search/widgets/dist/esm/searchResults/types';
import { FacetSetting } from 'components/Search/ProductListing';
import intersection from 'lodash/intersection';
import { SearchResultsWidget } from '@sitecore-search/react/dist/esm/types';
import useDictionary from 'src/hooks/useDictionary';
import { FacetWrapper, FacetWrapperContext } from './PspFacetWrapper';
import searchResultsTailwind from './SearchResultsTailwind';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import clsx from 'clsx';

export interface PspFacetMergedProps {
  facets: SearchResponseFacet[];
  actions: SearchResultsWidget['ActionProps'];
  settings: FacetSetting;
  isFacetOpen?: boolean;
}

const {
  accordionItem,
  facetItemLabel,
  facetCount,
  facetCheckbox,
  accordionItemWrapper,
  facetLabel,
  facetIcon,
  clearLabel,
  facetRoot,
} = searchResultsTailwind({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
});

export const PspFacetMerged = (props: PspFacetMergedProps) => {
  const { facets, settings } = props;

  const validValues = facets.map((x) => x?.value.filter((v) => v.text === 'true')).flat();
  if (!validValues.length) {
    return <></>;
  }
  return (
    <div>
      <AccordionFacets.Facet
        facetId={settings.facetName ?? 'merged-facet'}
        className={clsx(facetRoot({ className: ['pt-[0px]', 'border-color-border-mid'] }))}
      >
        <MergedFacetHeader {...props} />
        <AccordionFacets.Content className="w-full">
          <AccordionFacets.ValueList className="w-full">
            <MergedFacetBody {...props} />
          </AccordionFacets.ValueList>
        </AccordionFacets.Content>
      </AccordionFacets.Facet>
    </div>
  );
};

function MergedFacetHeader({ facets, actions, settings }: PspFacetMergedProps) {
  const selectedFacets = useSearchResultsSelectedFacets();
  const selectedFacetIds = selectedFacets.map((x) => x.id);
  const facetIds = facets.map((x) => x.name);
  const isFacetSelected = intersection(selectedFacetIds, facetIds).length > 0;

  const dictionary = useDictionary();

  return (
    <AccordionFacets.Header className={styles['sitecore-accordion-header']}>
      <AccordionFacets.Trigger className={styles['sitecore-accordion-trigger'] + ' group'}>
        <span className={facetLabel()}>
          {settings.facetLabelOverride ||
            dictionary.getDictionaryValue('NeedItTodayFacetPLP', 'Need it Today?')}
        </span>

        {isFacetSelected ? (
          <span
            className={clearLabel()}
            // This is a span instead of a button because we are already inside a button
            onClick={(e) => {
              e.stopPropagation();
              facets.forEach((facet) => {
                actions.onRemoveFilter({ facetId: facet.name, type: 'range' });
              });
            }}
          >
            {dictionary.getDictionaryValue('ClearFacetPLP', 'Clear')}
          </span>
        ) : null}
        <IconHelper className={facetIcon()} icon={'chevron-down'} />
      </AccordionFacets.Trigger>
    </AccordionFacets.Header>
  );
}

const MergedFacetBody = ({ facets, settings }: PspFacetMergedProps) => {
  const selectedFacetsFromApi = useSearchResultsSelectedFilters();
  const selectedFacetValues = selectedFacetsFromApi
    .filter((x) => x.type === 'text' && facets.some((f) => f.name === x.facetId))
    .map((x) => (x as SearchResultsStoreTextSelectedFacet).facetValueId ?? '');

  return (
    <FacetWrapper
      items={facets}
      selectedFacetValues={selectedFacetValues}
      getValue={(x) => x?.value.find((y) => y.text === 'true')?.id ?? ''}
      getText={(x) => settings.facetValueOverrideMap[x.label] || x.label}
    >
      <FacetWrapperContext.Consumer>
        {(context) => {
          return context.items.map((facet: SearchResponseFacet) => {
            const facetValueText = settings.facetValueOverrideMap[facet.label] || facet.label;
            // We only care about the "true" facet.
            const trueFacetValue = facet?.value.find((x) => x.text === 'true');
            if (!trueFacetValue) {
              return;
            }
            return (
              <AccordionFacets.Facet
                facetId={facet.name}
                key={facet.name}
                className={styles['sitecore-accordion-facets-facet']}
              >
                <AccordionFacets.Content className="w-full">
                  <AccordionFacets.ValueList className={styles['sitecore-accordion-value-list']}>
                    <FacetItem
                      facetValueId={trueFacetValue.id}
                      key={trueFacetValue.id}
                      data-key={trueFacetValue.id}
                      className={accordionItem()}
                    >
                      {/* This fragment is needed because otherwise react complains about a missing key.
                      Maybe this is only intended to have 1 child item, but we aren't getting a warning about it. */}
                      <>
                        <AccordionFacets.ItemCheckbox className={facetCheckbox()}>
                          <AccordionFacets.ItemCheckboxIndicator>
                            <CheckIcon />
                          </AccordionFacets.ItemCheckboxIndicator>
                        </AccordionFacets.ItemCheckbox>
                        {/* Item label */}
                        <div className={accordionItemWrapper()}>
                          <AccordionFacets.ItemLabel className={facetItemLabel()}>
                            {facetValueText}
                          </AccordionFacets.ItemLabel>
                          <AccordionFacets.ItemLabel className={facetCount()}>
                            {trueFacetValue.count && `(${trueFacetValue.count.toLocaleString()})`}
                          </AccordionFacets.ItemLabel>
                        </div>
                      </>
                    </FacetItem>
                  </AccordionFacets.ValueList>
                </AccordionFacets.Content>
              </AccordionFacets.Facet>
            );
          });
        }}
      </FacetWrapperContext.Consumer>
    </FacetWrapper>
  );
};
