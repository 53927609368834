import { tv } from 'tailwind-variants';

export const headingTailwindVariant = tv(
  {
    slots: {
      base: 'heading',
      title: 'font-primary',
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        mobile: {
          title:
            'mb-3 text-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold font-heading-mob-xLarge-bold',
        },
        desktop: {
          title:
            'mb-[23px] text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold font-heading-desk-xLarge-bold',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
