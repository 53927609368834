import { tv } from 'tailwind-variants';

export const myAutoshipAddATipTailwindVariant = tv(
  {
    slots: {
      base: 'MyAutoshipAddAtip flex flex-col gap-6',
      buttonWrapper: 'flex',
      tipLabel: '',
      priceError: ['text-system-red'],
      title:
        'font-primary text-color-text-black font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold',
      subTitle:
        'font-primary text-color-text-black font-body-large-bold text-body-large-bold leading-body-large-bold',
      description:
        'font-primary text-color-text-black font-body-large-regular text-body-large-regular leading-body-large-regular',
      warningHeading:
        'font-primary text-color-text-black font-body-large-regular text-body-large-regular leading-body-large-regular',
      loadingWrapper:
        'font-primary w-full py-desk-component-button-full-padding-y flex text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold px-desk-component-button-full-padding-y gap-desk-component-button-full-space-between',
      buttonWithBg:
        'h-fit w-full rounded-desk-global-radius-small py-desk-component-button-full-padding-y flex justify-center text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold text-color-text-white bg-color-brand-primary-1-base hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
      button:
        'h-fit w-fit px-desk-component-button-full-padding-x rounded-desk-global-radius-small py-desk-component-button-full-padding-y flex justify-center text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold text-color-brand-primary-1-base bg-color-background-white border-2 border-color-brand-primary-1-base hover:text-color-text-white focus:text-color-text-white hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark ',
      customTipWrapper: ['flex w-full gap-desk-global-spacing-spacing-5 '],
      currencyLabel: ['input-group-addon absolute top-[27%] left-[1.5%]'],
      tipField: [
        'outline-none',
        'border-2',
        'text-color-text-black',
        'placeholder:text-color-text-black',
        'focus:placeholder:opacity-0',
        'placeholder:transition-opacity',
        'placeholder:duration-500',
        '!pl-5',
        'disabled:bg-color-scale-3-light-mid',
        'w-full',
        'appearance-none',
        'bg-color-scale-1-white',
      ],
      tipButton: [
        '!w-max !text-body-large-bold !font-body-large-bold !leading-body-large-bold !py-desk-global-spacing-spacing-3',
      ],
      loader: [
        'absolute h-full w-full top-0 left-0 bg-black opacity-25 flex justify-center items-center border-b-[1px]',
      ],
    },
    compoundSlots: [
      {
        slots: ['tipLabel', 'tipField'],
        class: ['text-body-large-regular font-body-large-regular leading-body-large-regular'],
      },
    ],
    variants: {
      size: {
        desktop: {
          base: 'px-desk-padding-tight-x py-desk-padding-tight-y',
          warningHeading: 'mt-desk-margin-micro-bottom',
          buttonWrapper: 'mt-desk-margin-base-top flex-row gap-desk-space-between-base-horizontal',
          tipField: [
            'py-desk-global-spacing-spacing-3',
            'px-desk-global-spacing-spacing-4',
            'rounded-desk-global-radius-small',
          ],
          tipLabel: ['mx-2'],
        },
        mobile: {
          base: 'px-mob-padding-tight-x py-mob-padding-tight-y',
          warningHeading: 'mt-mob-margin-micro-bottom',
          buttonWrapper: 'mt-mob-margin-base-top flex-col gap-mob-space-between-base-horizontal',
          tipField: [
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-4',
            'rounded-mob-global-radius-small',
          ],
          tipLabel: [''],
        },
      },
    },
  },

  { responsiveVariants: ['lg'] }
);
