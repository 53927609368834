import { tv } from 'tailwind-variants';

export const storeDetailTabTailwindVariant = tv(
  {
    slots: {
      base: ['tabbed', 'container', 'mx-auto', 'product-details-section'],
      tabs: ['text-center', 'justify-center', 'items-center', 'bg-color-background-brand-1'],
      tabWrapper: [''],
      tabTitle: ['text-color-scale-3-light-mid'],
      description: [
        'product-details-tabs-section',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      contentWrapper: ['product-details-section-wrapper', 'border-2', 'border-color-border-light'],
      block: ['block'],
      hidden: ['hidden'],
      ul: ['flex-col'],
      whiteText: ['text-color-text-white'],
      guaranteedAnalysisPDPLabel: [
        'block',
        'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
        'mb-2',
      ],
    },
    compoundSlots: [{ slots: ['tabs', 'description', 'ul'], class: ['flex'] }],
    variants: {
      size: {
        mobile: {
          base: ['py-mob-padding-tight-y'],
          tabs: [
            'px-mob-padding-micro-x',
            'py-mob-padding-micro-y',
            'gap-mob-global-spacing-spacing-5',
          ],
          tabTitle: [
            'text-heading-mob-medium-bold',
            'font-heading-mob-medium-bold',
            'leading-heading-mob-medium-bold',
          ],
          description: [
            'px-mob-padding-tight-x',
            'py-mob-padding-tight-y',
            'flex-col',
            'gap-mob-global-spacing-spacing-13',
          ],
          tabWrapper: ['flex', 'flex-col', 'gap-mob-space-between-loose-vertical'],
          ul: ['gap-mob-margin-base-bottom'],
        },
        desktop: {
          base: ['py-desk-padding-tight-y'],
          tabs: ['px-desk-padding-loose-x', 'py-desk-padding-micro-y', 'gap-desk-padding-loose-x'],
          tabTitle: [
            'cursor-pointer',
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
          ],
          description: [
            'px-desk-padding-tight-x',
            'py-desk-padding-tight-y',
            'flex-row',
            'gap-desk-space-between-loose-vertical',
          ],
          tabWrapper: ['grid', 'grid-cols-2', 'gap-desk-space-between-loose-vertical'],
          ul: ['gap-desk-margin-base-bottom'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
