import { tv } from 'tailwind-variants';

export const fulfillmentSwitchTailwindVariant = tv(
  {
    slots: {
      container: ['justify-center'],
      miniCartContainer: [''],
      currentFulfillmentTxt: [],
      switchToLink: [
        'text-color-brand-primary-1-base',
        'font-body-large-bold_underline',
        'text-body-large-bold_underline',
        'leading-body-large-bold_underline',
        'underline cursor-pointer',
      ],
      deliveryPopupSpacing: [],
      promotionLabel: [
        'text-color-text-white',
        'text-body-medium-regular',
        'font-body-medium-regular',
        'leading-body-medium-regular',
      ],
      fulfillmentMessage: ['fulfillmentMessage', 'w-full', 'bg-white'],
      deliveryTopTextSection: ['gap-[12px]'],
      dialogueTitle: [],
      dialogueShortDescription: [],
      FieldsRequiredMessage: [
        'text-body-small-regular',
        'font-body-small-regular',
        'leading-body-small-regular',
      ],
      formContainer: ['flex', 'flex-col'],
      formFieldRow: ['flex', 'self-stretch'],
      formField: ['flex', 'flex-col', 'flex-[1_0_0]'],
      inputField: [
        'outline-none',
        'border-2',
        'text-color-text-black',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
        'placeholder:text-color-text-black',
        'focus:border-color-brand-primary-1-base',
        'focus:placeholder:opacity-0',
        'placeholder:transition-opacity',
        'placeholder:duration-500',
        'focus:border-color-brand-primary-1-base',
        'disabled:bg-color-scale-3-light-mid',
        'w-full',
        'appearance-none',
        'bg-color-scale-1-white',
      ],
      errorStyle: [
        'text-system-red',
        'text-body-small-regular',
        'font-body-small-regular',
        'leading-body-small-regular',
      ],
      fieldLabel: [
        'text-color-text-black',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
      ],
      dropDownField: [
        'outline-none',
        'bg-color-scale-1-white',
        'border-2',
        'text-color-text-black',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
        'placeholder:text-color-text-black',
        'focus:border-color-brand-primary-1-base',
        'appearance-none',
        'w-full',
        "bg-[url('/icons/dropdown.svg')]",
        'bg-no-repeat',
        'bg-[position:calc(100%-10px)_center]',
      ],
      submitBtn: [
        'bg-color-brand-primary-1-base',
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
        'text-color-text-white',
        'rounded-mob-global-radius-small',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
      ],
      formWrapper: ['flex', 'flex-col', 'item-start'],
      formSubmitRow: [],
      loaderWrapper: ['flex', 'items-center'],
      mainLoaderWrapper: ['flex', 'items-center'],
      changeStoreWrapper: [
        'font-body-large-bold',
        'leading-body-large-bold',
        'text-body-large-bold',
        'flex',
        'gap-[4px]',
      ],
      findStoreContainer: ['flex', 'flex-col', 'item-start'],
      hardError: [
        'flex',
        'align-start',
        'text-body-large-bold',
        'leading-body-large-bold',
        'font-body-large-bold',
        'text-color-accent-red',
      ],
      softError: [
        'flex',
        'align-start',
        'text-body-large-bold',
        'leading-body-large-bold',
        'font-body-large-bold',
        'p-[5px]',
        'bg-[#fcf8e3]',
      ],
    },
    compoundSlots: [
      { slots: ['currentFulfillmentTxt', 'dialogueTitle'], class: ['text-color-text-black'] },
      {
        slots: ['container', 'deliveryTopTextSection'],
        class: ['flex', 'flex-col', 'items-start'],
      },
      {
        slots: ['currentFulfillmentTxt', 'dialogueShortDescription'],
        class: ['text-body-large-bold', 'font-body-large-bold', 'leading-body-large-bold'],
      },
    ],
    variants: {
      device: {
        mobile: {
          container: [
            //'p-mob-global-spacing-spacing-3',
            'gap-mob-margin-micro-bottom',
            'max-w-full',
          ],
          promotionLabel: ['p-mob-global-spacing-spacing-3'],
          fulfillmentMessage: ['p-mob-global-spacing-spacing-3'],
          currentFulfillmentTxt: [],
          deliveryPopupSpacing: ['py-mob-padding-micro-y', 'px-mob-padding-micro-x'],
          deliveryTopTextSection: [],
          dialogueTitle: [
            'text-heading-mob-xLarge-bold',
            'font-heading-mob-xLarge-bold',
            'leading-heading-mob-xLarge-bold',
          ],
          formContainer: ['gap-mob-space-between-tight-vertical'],
          formFieldRow: [
            'gap-x-mob-space-between-base-vertical',
            'gap-y-mob-space-between-tight-vertical',
            'flex-col',
          ],
          formField: ['gap-mob-margin-micro-bottom'],
          inputField: [
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-4',
            'rounded-mob-global-radius-small',
          ],
          fieldLabel: ['gap-mob-margin-micro-bottom'],
          dropDownField: [
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-4',
            'rounded-mob-global-radius-small',
          ],
          submitBtn: [
            'text-heading-mob-medium-bold',
            'font-heading-mob-medium-bold',
            'leading-heading-mob-medium-bold',
            'py-mob-component-button-full-padding-y',
            'px-mob-component-button-full-padding-x',
            'rounded-mob-global-radius-small',
          ],
          formWrapper: ['py-mob-padding-micro-y', 'gap-mob-space-between-tight-vertical'],
          loaderWrapper: [
            'gap-mob-component-button-full-space-between',
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          mainLoaderWrapper: ['gap-mob-component-button-full-space-between'],
          changeStoreWrapper: ['flex-col'],
          hardError: ['gap-mob-margin-tight-right'],
          softError: ['gap-mob-margin-tight-right'],
          findStoreContainer: ['mt-mob-margin-tight-bottom', 'gap-mob-margin-tight-bottom'],
        },
        desktop: {
          container: [
            //'p-desk-global-spacing-spacing-3',
            'gap-desk-margin-micro-bottom',
            'max-w-[60%]',
          ],
          promotionLabel: ['p-desk-global-spacing-spacing-3'],
          fulfillmentMessage: ['p-desk-global-spacing-spacing-3'],
          currentFulfillmentTxt: [],
          deliveryPopupSpacing: ['py-desk-padding-micro-y', 'px-desk-padding-micro-x'],
          deliveryTopTextSection: [],
          dialogueTitle: [
            'text-heading-desk-xLarge-bold',
            'font-heading-desk-xLarge-bold',
            'leading-heading-desk-xLarge-bold',
          ],
          formContainer: ['gap-desk-space-between-tight-vertical'],
          formFieldRow: [
            'gap-desk-space-between-base-vertical',
            'gap-y-desk-space-between-tight-vertical',
            'flex-row',
          ],
          formField: ['gap-desk-margin-micro-bottom'],
          inputField: [
            'py-desk-global-spacing-spacing-3',
            'px-desk-global-spacing-spacing-4',
            'rounded-desk-global-radius-small',
          ],
          fieldLabel: ['gap-desk-margin-micro-bottom'],
          dropDownField: [
            'py-desk-global-spacing-spacing-3',
            'px-desk-global-spacing-spacing-4',
            'rounded-desk-global-radius-small',
          ],
          submitBtn: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'hover:bg-color-brand-primary-1-dark',
          ],
          formWrapper: ['py-desk-padding-micro-y'],
          formSubmitRow: ['items-end', 'flex-col'],
          loaderWrapper: [
            'gap-desk-component-button-full-space-between',
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          mainLoaderWrapper: ['gap-desk-component-button-full-space-between'],
          changeStoreWrapper: ['flex-row'],
          hardError: ['gap-desk-margin-tight-right'],
          softError: ['gap-desk-margin-tight-right'],
          findStoreContainer: ['mt-desk-margin-tight-bottom', 'gap-desk-margin-tight-bottom'],
        },
      },
      isSmallPopup: {
        true: {
          formFieldRow: ['lg:flex-col'],
          formSubmitRow: ['lg:items-center items-center justify-center'],
          switchToLink: ['uppercase'],
          formField: ['w-full'],
          formWrapper: ['lg:gap-[0px]'],
          loaderWrapper: ['items-center', 'w-full', 'justify-center'],
          changeStoreWrapper: ['lg:flex-col'],
          fulfillmentMessage: ['p-[0]'],
        },
        false: {
          formFieldRow: ['lg:flex-row'],
          formWrapper: ['gap-desk-global-spacing-spacing-5'],
        },
      },
      isPromotion: {
        true: { container: ['bg-pdp-option-background', 'p-[1px]'] },
        false: {},
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
