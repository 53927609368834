import React, { useEffect } from 'react';
import { Text, RichText, Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  HideLinkFromStoreNavigation,
  ShowLinkFromStoreNavigation,
} from 'src/helpers/StoreLandingHelper';
import { aboutStoreDetailVariants } from 'tailwindVariants/components/aboutStoreDetailTailwindVariant';
interface AboutStoreProps {
  fields?: {
    data?: {
      datasource?: { aboutThisStoreText: Field<string> };
      storeData?: { aboutStore?: Field<string> };
    };
  };
}
const AboutStore = ({ fields }: AboutStoreProps): JSX.Element => {
  const { base, contentWrapper, heading, description } = aboutStoreDetailVariants({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const storeDetails: any = useSitecoreContext()?.sitecoreContext?.route?.fields ?? {};
  useEffect(() => {
    if (!storeDetails?.aboutstore?.value) {
      HideLinkFromStoreNavigation('about-store');
    } else {
      ShowLinkFromStoreNavigation('about-store');
    }
  }, [storeDetails?.aboutstore?.value]);
  return (
    <>
      {storeDetails?.aboutstore?.value && (
        <div className={base()} id="about-store">
          <div className={contentWrapper()}>
            <Text
              className={heading()}
              field={fields?.data?.datasource?.aboutThisStoreText}
              tag="p"
            />
            <RichText className={description()} field={storeDetails?.aboutstore} />
          </div>
        </div>
      )}
    </>
  );
};
export default AboutStore;
