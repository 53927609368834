import { tv } from 'tailwind-variants';

export const quickLinksTailwindVariant = tv(
  {
    slots: {
      base: ['my-desk-margin-base-bottom'],
      headingWrapper: [
        'py-desk-component-button-condensed-padding-y',
        'px-desk-padding-micro-x',
        'bg-color-background-brand-1',
      ],
      heading: ['text-white', 'font-heading-desk-medium-bold'],
      headingLinkWrapper: ['m-desk-global-spacing-spacing-1'],
      headingLink: ['text-color-brand-primary-1-base', 'font-heading-desk-medium-bold'],
    },
    variants: {
      size: {
        mobile: {},
        desktop: {},
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
