//global
import React, { useEffect, useState } from 'react';
import {
  Field,
  ImageField,
  LinkField,
  withDatasourceCheck,
} from '@sitecore-jss/sitecore-jss-nextjs';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import SplideSlider from 'src/helpers/commonComponents/SplideSlider';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import Link from 'next/link';
import {
  formatDateForGTM,
  notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { useBreakpoints } from 'src/utils/breakpoints';
import { useOcSelector } from 'src/redux/ocStore';
import { GTM_EVENT } from 'src/helpers/Constants';
import withPersonalization from 'src/helpers/withPersonalization/withPersonalization';
import { useRouter } from 'next/router';
import { heroCarouselTailwindVariant } from 'tailwindVariants/components/heroCarouselTailwindVariant';
//type

export type HeroCarouselProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.HeroCarousel.Fields.HeroCarouselContainer;
export type HeroCarouselPersionlizedProps = {
  fields: {
    bannerList: {
      ctaLink: LinkField;
      mobileImage: ImageField;
      isAutoPlay: Field<boolean>;
    }[];
  };
};

//main component
const HeroCarousel: React.FC<HeroCarouselProps> = ({ fields, params }) => {
  const { base, card, img, deskImg, mobImg } = heroCarouselTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const [viewedSlideIds, setViewedSlideIds] = useState<number[]>([]);

  const listLength = (fields?.selectCarouselItems && fields?.selectCarouselItems?.length) || 0;
  const isArrow = listLength > 1 ? true : false;
  fields?.selectCarouselItems && fields?.selectCarouselItems?.length > 1 ? true : false;
  const [visibleIndex, setVisibleIndex] = useState(0);
  const { deviceName } = useBreakpoints();
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore?.storeId) as string;
  const { isReady } = useRouter();

  const setPromotionData = (gtmEvent: string, clicked?: boolean) => {
    const gtmPromotion = [];
    const currentIndex = visibleIndex || 0;
    const visibleIndexData = fields?.selectCarouselItems?.find(
      (_, index) => currentIndex === index
    );

    const promotionObject = {
      promotion_id: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.promotionId?.value),
      promotion_name: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.promotionName?.value),
      creative_name: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.componentName?.value),
      creative_slot: Number(visibleIndexData?.fields?.creativeSlotNumber?.value),
      promotion_device: deviceName,
      promotion_copy: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.promotionCopy?.value),
      promotion_dates: notAvailableIfNullOrEmpty(
        formatDateForGTM(
          `${visibleIndexData?.fields?.promotionDateFROM?.value} - ${visibleIndexData?.fields?.promotionDateTO?.value}`
        )
      ),
      promotion_cta: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.cTAButtonCopy?.value),
      promotion_url: notAvailableIfNullOrEmpty(visibleIndexData?.fields?.promotionURL?.value?.href),
    };

    if (
      visibleIndexData &&
      !viewedSlideIds?.includes(visibleIndex) &&
      myStoreId &&
      visibleIndexData?.fields?.componentName?.value !== ''
    ) {
      gtmPromotion.push(promotionObject);
      trackObjectForPromotion(gtmEvent, myStoreId, gtmPromotion);
    }

    if (clicked) {
      const data = [{ ...promotionObject }];
      trackObjectForPromotion(gtmEvent, myStoreId, data);
    }
  };

  useEffect(() => {
    // Start: Promotion GTM data push on load
    isReady && myStoreId && setPromotionData(GTM_EVENT?.viewPromotion);
    isReady &&
      myStoreId &&
      setViewedSlideIds((prev) =>
        !prev?.includes(visibleIndex) ? [...prev, visibleIndex] : [...prev]
      );
    // End: Promotion GTM data push on load
  }, [deviceName, visibleIndex, isReady, myStoreId]);

  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div
      className={base({ className: params?.Style ?? '' })}
      data-component={'components/HeroCarousel'}
    >
      <SplideSlider
        setVisibleIndex={setVisibleIndex}
        isAutoPlay={fields?.isAutoPlay?.value || true}
        options={{
          arrows: isArrow,
          type: isArrow ? 'loop' : '',
          interval: Number(fields?.autoPlayDuration?.value) || 3000,
          autoplay: isArrow ? fields?.isAutoPlay?.value || true : false,
          classes: {
            prev: 'arrow-prev splide-button splide__arrow--prev your-class-prev',
            next: 'arrow-next splide-button splide__arrow--next your-class-next',
          },
        }}
      >
        {fields?.selectCarouselItems?.slice(0, 10)?.map((banner, index) => {
          return (
            <div
              className={card()}
              onClick={() => {
                setPromotionData(GTM_EVENT?.selectPromotion, true);
              }}
              data-creative-id={banner?.fields?.componentName?.value}
              data-promotion-cta={banner?.fields?.cTAButtonCopy?.value}
              data-promo-id={banner?.fields?.promotionId?.value}
              data-promotion-name={banner?.fields?.promotionName?.value}
              data-promotion-copy={banner?.fields?.promotionCopy?.value}
              dta-promotion-url={banner?.fields?.promotionURL?.value?.href}
              key={index}
            >
              <div className={img()}>
                <Link aria-label="image" href={banner?.fields?.link?.value?.href || '#'}>
                  {/* Don't check whether we're in mobile or desktop, handle that with CSS.  
                  Checking mobile with JS causes layout shift */}
                  <ImageHelper
                    field={banner?.fields?.desktopImage}
                    className={deskImg()}
                    // Only priority if it is the first slide
                    priority={index === 0}
                  />
                  <ImageHelper
                    field={banner?.fields?.mobileImage}
                    className={mobImg()}
                    priority={index === 0}
                  />
                </Link>
              </div>
            </div>
          );
        })}
      </SplideSlider>
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<HeroCarouselProps>(withPersonalization(HeroCarousel));
