//global
import React, { useEffect } from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
//local

//lib
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import { WarningUnsavedChangesProps } from 'components/WarningUnsavedChanges/WarningUnsavedChanges';
import { useCheckoutFormContext } from 'lib/context/CheckoutFormContext';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { warningUnsavedChangesTailwindVariant } from 'tailwindVariants/components/warningUnsavedChangesTailwindVariant';
//type
export type WarningProfileUpdateProps = WarningUnsavedChangesProps;
//main component
const WarningProfileUpdate: React.FC<WarningProfileUpdateProps> = ({ fields, params }) => {
  const { base, wrapper, title, btnWrapper, btnPrimary, btnSecondary } =
    warningUnsavedChangesTailwindVariant({
      size: { initial: 'mobile', lg: 'desktop' },
    });
  const { checkoutContextData, updateCheckoutContextData } = useCheckoutFormContext();

  useEffect(() => {
    updateCheckoutContextData && updateCheckoutContextData({ setDeliverySubmitting: undefined });
  }, []);
  return (
    <ModalWrapper
      showModal={checkoutContextData?.warningProfileUpdate}
      onCloseClick={() => {
        updateCheckoutContextData({
          warningProfileUpdate: false,
        });
      }}
      additionalClassForPopUpModal={`${base({
        className: params?.Styles ?? '',
      })} !item-start !h-fit translate-y-0  animate-[topAnimation_0.3s_ease-in-out]`}
      popupWidth="max-w-[995px]"
      showCloseButtonInModalHeader={false}
      closeModalOnOverlayClick
      customPopup
    >
      <div className={wrapper()}>
        <RichTextHelper field={fields?.title} tag="p" className={title()} />
        {fields?.description && (
          <RichTextHelper field={fields?.description} tag="div" className="mt-8" />
        )}
        <div className={btnWrapper()}>
          {fields?.primaryLink && fields?.secondaryLink && (
            <>
              <button
                aria-label="primary link"
                className={btnPrimary()}
                onClick={(e) => {
                  e.preventDefault();
                  checkoutContextData?.isPickup &&
                    updateCheckoutContextData({
                      setSubmitting: true,
                      warningProfileUpdate: false,
                    });
                }}
              >
                <TextHelper field={{ value: fields?.primaryLink?.value?.text || '' }} />
              </button>
              <button
                aria-label="secondary link"
                className={btnSecondary()}
                onClick={(e) => {
                  e.preventDefault();
                  checkoutContextData?.isPickup &&
                    updateCheckoutContextData({
                      setSubmitting: false,
                      warningProfileUpdate: false,
                    });
                }}
              >
                <TextHelper field={{ value: fields?.secondaryLink?.value?.text || '' }} />
              </button>
            </>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<WarningProfileUpdateProps>(WarningProfileUpdate);
