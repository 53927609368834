import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { PageController } from '@sitecore-search/react';
import { PSP } from 'models/PetSuppliesPlus.Model';

export const useSetSearchPageUri = (customPageURI?: string) => {
  const sitecoreContext = useSitecoreContext();
  const sitecorePage = sitecoreContext.sitecoreContext
    .route as PSP.Sitecore.templates.PetSuppliesPlus.PageTypes.Fields.ProductsListingPage;
  if (customPageURI) {
    PageController.getContext().setPageUri(customPageURI);
  } else if (sitecorePage.fields?.discoverSearchUri?.value) {
    PageController.getContext().setPageUri(sitecorePage.fields.discoverSearchUri?.value);
  }
};
