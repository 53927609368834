import { errorMessages } from 'src/helpers/Constants';
import ComponentContext from 'lib/context/ComponentContext';
import React, { useContext } from 'react';
import useDictionary from 'src/hooks/useDictionary';
import { errorLabelTailwindVariant } from 'tailwindVariants/components/errorLabelTailwindVariant';

const ErrorLabel = () => {
  const { title } = errorLabelTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const { componentContextData } = useContext(ComponentContext);
  const { getDictionaryValue } = useDictionary();
  return (
    <>
      <span id="payment-error-label" className={title()}>
        {componentContextData?.unavailableItemsRemoved == true
          ? getDictionaryValue('checkoutpriceexcludecheck')
          : errorMessages?.invalidCVV}
      </span>
    </>
  );
};

export default ErrorLabel;
