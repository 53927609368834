import { tv } from 'tailwind-variants';

export const favouriteFriendsArticleTailwindVariant = tv(
  {
    slots: {
      articleBase: '',
      articleContentWrapper: '',
      articleContent: 'flex flex-col',
      articleTitle: 'font-primary',
      articleDescription:
        'font-body-large-regular text-body-large-regular leading-body-large-regular font-primary',
      articleSubHead: 'font-primary',
      articleImage:
        'w-full lg:max-w-[500px] object-fit xl:max-w-desk-component-image-promo-image-2-max-width',
      articleLink:
        'text-color-text-brand-1 lg:hover:no-underline font-heading-desk-medium-bold text-heading-desk-medium-bold leading-heading-desk-medium-bold',
    },
    variants: {
      size: {
        desktop: {
          articleImage: 'w-auto max-h-desk-component-image-promo-image-2-max-height min-h-[450px]',
          articleContent:
            'px-desk-padding-micro-x gap-desk-margin-tight-bottom pb-desk-margin-micro-bottom',
          articleBase: 'py-desk-padding-tight-y flex-row w-full',
          articleContentWrapper:
            'bg-color-scale-8-dark px-desk-padding-base-x py-desk-padding-tight-y gap-desk-margin-tight-bottom',
          articleSubHead:
            'font-heading-desk-medium-bold text-heading-desk-medium-bold leading-heading-desk-medium-bold text-color-scale-3-light-mid',
          articleDescription: 'text-color-text-white',
          articleLink:
            'mx-desk-padding-micro-x hover:bg-color-brand-primary-1-dark hover:text-color-text-white  text-color-text-white py-desk-component-button-full-padding-y w-fit px-desk-component-button-full-padding-x rounded-[4px] bg-color-brand-primary-1-base',
          articleTitle:
            'text-color-text-white font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold',
        },
        mobile: {
          articleBase: 'py-mob-padding-tight-y flex flex-col-reverse',
          articleLink: 'hover:text-color-brand-tertiary-3-base ',
          articleContentWrapper:
            'py-mob-padding-tight-y px-mob-padding-micro-x flex flex-col gap-mob-margin-tight-bottom',
          articleContent: 'gap-mob-margin-tight-bottom pb-mob-margin-tight-bottom',
          articleTitle:
            'text-color-text-dark font-heading-mob-large-bold text-heading-mob-large-bold leading-heading-mob-large-bold',
          articleSubHead:
            'text-color-scale-7-mid-dark font-heading-desk-medium-bold text-heading-desk-medium-bold leading-heading-desk-medium-bold',
          articleDescription:
            'font-body-large-regular text-body-large-regular leading-body-large-regular text-color-text-dark',
          articleImage: 'w-full min-h-[240px]',
        },
      },
      isReverse: {
        true: {
          articleBase: 'lg:flex-row-reverse',
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
