//global
import React from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import { brandsListingTailwindVariant } from 'tailwindVariants/components/brandsListingTailwindVariant';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
//type
export type BrandsListingProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.BrandsListing.Fields.BrandsListing;

//main component
const BrandsListing: React.FC<BrandsListingProps> = ({ fields, params }) => {
  const { base, titleWrapper, brandWrapper, linkWraper } = brandsListingTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div id={'brandsListing'} className={base({ className: params?.Style ?? '' })}>
      <RichTextHelper field={fields?.heading} className={titleWrapper()} />
      <div className={brandWrapper()}>
        {fields?.selectBrands?.map((brand, index) => {
          return (
            <LinkHelper field={brand?.fields?.link} key={index} className={linkWraper()}>
              <ImageHelper field={brand?.fields?.image} />
            </LinkHelper>
          );
        })}
      </div>
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<BrandsListingProps>(BrandsListing);
