import { tv } from 'tailwind-variants';

export const testimonialCarouselTailwindVariant = tv(
  {
    slots: {
      base: [
        'relative',
        'overflow-hidden',
        'testimonialCarousel',
        'text-primary',
        'text-color-text-white',
        'bg-cover',
        'bg-center',
        '[&_*_*_button]:bg-white',
        '[&_*_*_button]:!opacity-100',
        '[&_*_*_button_svg]:fill-color-brand-primary-1-base',
        '[&_*_*_.your-class-prev]:!-left-[60px] lg:[&_*_*_.your-class-prev]:!left-[0px]',
        '[&_*_*_.your-class-next]:!-right-[60px] lg:[&_*_*_.your-class-next]:!right-[0px]',
        '[&_*_*_.your-class-prev>svg]:ml-7 lg:[&_*_*_.your-class-prev>svg]:ml-0',
        '[&_*_*_.your-class-next>svg]:mr-7 lg:[&_*_*_.your-class-next>svg]:mr-0',
        '[&_*_*_button>svg>path]:!drop-shadow-arrows',
      ],
      backgroundImage: ['absolute top-0 left-0 h-full w-full object-cover'],
      slideWrapper: ['flex', 'justify-center', 'items-center'],
      quouteText: [
        'text-center',
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
      ],
      imageWrapper: [''],
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      device: {
        mobile: {
          base: [
            'px-mob-global-spacing-spacing-4',
            'py-mob-padding-base-y',
            '[&_*_*_button]:top-[66px]',
            '[&_*_*_button_svg]:w-[30px]',
            '[&_*_*_button_svg]:h-[30px]',
            '[&_*_*_first]:-left-[60px]',
            '[&>*>*>button]:h-[100px]',
            '[&>*>*>button]:w-[100px]',
          ],
          slideWrapper: ['flex-col', 'gap-mob-global-grid-gutter'],
          quouteText: ['px-mob-padding-micro-x'],
          imageWrapper: ['w-[225px]', 'h-[142px]'],
        },
        desktop: {
          base: [
            'px-desk-global-spacing-spacing-4',
            'py-0',
            'flex-row',
            '[&_*_*_button]:!top-[50%]',
            '[&_*_*_button]:border-color-brand-primary-1-base',
            '[&_*_*_button]:border-[2px]',
            '[&_*_*_button_svg]:w-[20px]',
            '[&_*_*_button_svg]:h-[20px]',
            '[&_*_*_button]:border-solid',
            '[&>*>*_button]:h-[40px]',
            '[&>*>*_button]:w-[40px]',
          ],
          slideWrapper: ['flex-row', 'gap-desk-global-grid-gutter'],
          quouteText: ['pl-0', 'px-0', 'pr-desk-global-spacing-spacing-13'],
          imageWrapper: ['h-full', 'w-full', 'max-w-[397px]', 'max-h-[250px]', 'object-cover'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
