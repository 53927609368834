import React from 'react';

/**
 * Triggers a custom 'submit' event on the form associated with the given formRef.
 * This is useful for triggering form submission when the user clicks on a button
 * that is not a native HTML form submit button.
 *
 * @param {React.RefObject<HTMLFormElement>} formRef - The React ref object pointing to an HTML form element.
 * @returns {void}
 */
export const triggerSubmitEvent = (
  formRef: React.RefObject<HTMLFormElement>,
  gtmEventName?: string,
  gtmObj?: {
    userDetails?: string;
  }
): void => {
  if (formRef?.current) {
    if (
      typeof window !== 'undefined' &&
      //eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any)['google_tag_manager']?.dataLayer?.gtmLoad &&
      gtmEventName &&
      gtmObj
    ) {
      window &&
        window?.['dataLayer']?.push({
          event: gtmEventName,
          ...gtmObj,
        });
    }
  } else {
    console.error('Form reference is null');
  }
};
