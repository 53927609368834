import { GetStoreDetailsReturn, StoreObjectData } from 'src/pages/api/store/get-store-details';
import { apiRequest } from 'src/utils/apiWrapper';
import axios from 'axios';
import { SupplierAddresses, Tokens } from 'ordercloud-javascript-sdk';
import { publicURLTrailingSlash } from './publicURLTrailingSlash';
import { isTokenExpired } from 'src/utils/requestDecorator';
import { storeApi } from 'src/utils/nextApiConfig';

export const updateMyStore = async (
  storeId: string
): Promise<StoreObjectData | Record<string, never>> => {
  try {
    const response = await apiRequest<GetStoreDetailsReturn>(
      `${storeApi.getStoreDetails}?${new URLSearchParams({
        storeId,
        // If we're switching stores, we want to save this selection in cookie set on server
        save: 'true',
      }).toString()}`,
      {
        method: 'GET',
      }
    );
    if (response?.data) {
      const storeId = response.data.storeId;
      if (!storeId) {
        throw new Error('Error: No store found');
      }
      localStorage.setItem('storeId', storeId);
      dispatchEvent(new Event('storeIdChanged'));

      return await addSupplierData(response.data);
    }
    return {};
  } catch (error) {
    console.error('Error', error);
    return {};
  }
};

export const getGlobalStoreSettings = async () => {
  try {
    const publicURL = publicURLTrailingSlash();
    const response = await axios.get(`${publicURL}api/store/get-global-store-settings`);
    return response.data;
  } catch (error) {
    console.error('Error', error);
    return {};
  }
};

export async function addSupplierData(data: StoreObjectData) {
  const storeId = data.storeId;
  const token = Tokens.GetAccessToken();
  if (token && !isTokenExpired(token)) {
    const supplierAddress = await SupplierAddresses.Get(storeId, `A-${storeId}`);
    const updatedData = {
      ...data,
      BOPIS: supplierAddress?.xp?.BOPIS ?? null,
      DFS: supplierAddress?.xp?.DFS ?? null,
      StoreType: supplierAddress?.xp?.StoreType ?? null,
      OnlineBooking: supplierAddress?.xp?.OnlineBooking ?? null,
      StoreServices: supplierAddress?.xp?.StoreServices ?? null,
    };
    return updatedData;
  }
  return data;
}
