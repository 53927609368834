import { tv } from 'tailwind-variants';

export const autoshipAlertMessageTailwindVariant = tv(
  {
    slots: {
      base: ['autoshipAlertMessage', 'block'],
      autoshipAlertMessageWrapper: '',
      descriptionWrapper: 'block border border-color-brand-autoship bg-color-background-white',
      description:
        'inline font-primary text-body-large-bold font-body-large-bold leading-body-large-bold',
      link: 'inline ml-1 text-color-text-brand-1 font-primary text-body-large-bold font-body-large-bold leading-body-large-bold underline hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
      titleWrapper:
        'bg-color-brand-autoship text-color-text-white font-primary text-body-large-bold font-body-large-bold leading-body-large-bold',
      modalDiv: 'flex flex-col',
      modalTitle:
        'font-primary text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        desktop: {
          base: '-mt-[15px] mb-0',
          autoshipAlertMessageWrapper: 'my-desk-margin-base-bottom',
          titleWrapper: 'py-desk-global-spacing-spacing-2 px-desk-global-spacing-spacing-5',
          descriptionWrapper: 'p-desk-global-spacing-spacing-5',
          modalDiv:
            'gap-desk-space-between-tight-vertical py-desk-padding-tight-y px-desk-padding-base-x',
        },
        mobile: {
          base: 'mt-mob-margin-tight-bottom mb-mob-padding-tight-bottom',
          titleWrapper: 'py-mob-global-spacing-spacing-2 px-mob-global-spacing-spacing-5',
          descriptionWrapper: 'p-desk-global-spacing-spacing-5',
          modalDiv:
            'gap-mob-space-between-tight-vertical py-mob-padding-tight-y px-mob-padding-base-x',
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
