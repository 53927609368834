import { tv } from 'tailwind-variants';

export const goeLocationPopupTailwindVariant = tv(
  {
    slots: {
      base: ['base'],
      container: ['container'],
      modalPanel: [''],
      modalContent: [''],
      logo: ['flex', 'justify-center', 'mb-5'],
      content: ['flex', 'flex-col', 'justify-center', 'text-center'],
      text: [
        'font-primary',
        'text-color-background-dark',
        'text-heading-desk-small-bold',
        'font-heading-desk-small-bold',
        'leading-heading-desk-small-bold',
      ],
      shoppingButtonWrapper: ['lg:mb-desk-padding-tight-y'],
      shoppingButton: [
        'font-primary',
        'text-color-white',
        '!text-heading-desk-medium-bold',
        '!font-heading-desk-medium-bold',
        '!leading-heading-desk-medium-bold',
      ],
      storeButtonWrapper: ['bg-color-background-light'],
      storeButton: [
        'underline',
        'flex',
        'items-center',
        'justify-center',
        'text-color-text-brand-1',
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
        'group',
        'hover:text-color-brand-tertiary-3-base',
        'focus:text-color-brand-tertiary-3-base',
      ],
      missingStoreButtonWrapper: ['lg:mb-desk-padding-tight-y'],
      storeDetailWrapper: ['flex', 'flex-col'],
      linkIcon:
        'w-4 h-4 [&_svg]:max-w-4 [&_svg]:max-h-4 group-hover:[&>svg>*]:fill-color-brand-tertiary-3-base group-focus:[&>svg>*]:fill-color-brand-tertiary-3-base [&>svg>*]:fill-color-text-brand-1',
    },
    variants: {
      size: {
        mobile: {
          base: [''],
          linkIcon: 'ml-mob-space-between-micro-horizontal',
          modalContent: ['pt-mob-component-button-full-padding-x'],
          shoppingButtonWrapper: ['mt-mob-margin-base-bottom mb-mob-padding-tight-y'],
          storeButtonWrapper: ['py-mob-padding-base-y'],
          missingStoreButtonWrapper: ['mt-mob-margin-base-bottom mb-mob-padding-tight-y'],
          storeDetailWrapper: ['gap-mob-space-between-tight-vertical'],
        },
        desktop: {
          base: [''],
          container: [''],
          modalContent: ['pt-desk-component-button-full-padding-x'],
          linkIcon: 'ml-desk-space-between-micro-horizontal',
          shoppingButtonWrapper: ['mt-desk-margin-base-bottom mb-desk-padding-tight-y'],
          storeButtonWrapper: ['py-desk-padding-tight-y'],
          missingStoreButtonWrapper: ['mt-desk-margin-base-bottom mb-desk-padding-tight-y'],
          storeDetailWrapper: ['gap-desk-space-between-tight-vertical'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
