import { tv } from 'tailwind-variants';

export const storeLocatorPageTailwindVariant = tv(
  {
    slots: {
      loadMoreButton: 'w-full flex justify-center',
      base: 'flex storeLocator container bg-color-background-light lg:gap-[20px] xl:justify-between',
      heading: 'font-primary text-color-text-black',
      headingSmall: 'font-primary text-color-text-black',
      noSearchResultsFoundText:
        'text-body-small-regular font-body-small-regular leading-body-small-regular text-system-red',
      headingWrapper: ' flex flex-col',
      searchButton:
        'bg-color-background-brand-1 flex justify-center items-center h-full absolute right-0 top-0 hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark',
      subHeadingWrapper: 'flex flex-col',
      resultText: 'font-primary inline-block max-w-full',
      searchIcon: 'w-6 h-6 [&>svg]:w-6 [&>svg]:h-6',
      loadingTextWrapper: 'flex items-center',
      loadMore:
        'rounded-desk-global-radius-small bg-color-text-brand-1 h-fit w-fit hover:no-underline hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark active:bg-color-brand-primary-1-dark text-body-large-bold leading-body-large-bold font-body-large-bold',
      input:
        'w-full text-color-text-dark placeholder-color-text-dark px-desk-padding-micro-x py-desk-padding-micro-y font-primary text-body-large-bold leading-body-large-bold font-body-large-bold focus:outline-none',
      formLabel:
        'font-primary text-color-text-dark text-body-large-bold leading-body-large-bold font-body-large-bold',
      loadingText: 'font-primary',
    },
    variants: {
      size: {
        desktop: {
          input: 'h-[56px]',
          searchButton: 'w-[72px]',
          loadingText:
            'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
          loadingTextWrapper:
            'gap-desk-component-button-full-space-between py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x mt-desk-margin-base-top',
          noSearchResultsFoundText: 'mb-desk-margin-micro-bottom',
          loadMore:
            'mt-0 rounded-desk-global-radius-small text-color-text-white px-desk-component-button-condensed-padding-x py-desk-component-button-condensed-padding-y mb-0',
          base: 'flex-row py-desk-padding-base-y px-desk-padding-tight-x',
          headingWrapper: 'w-[533px] gap-desk-margin-loose-bottom',
          subHeadingWrapper: 'gap-desk-margin-tight-bottom',
          heading: 'text-[56px] font-[700] leading-[58px]',
          headingSmall:
            'text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
          resultText: 'hidden',
          loadMoreButton: 'mt-desk-margin-base-bottom',
        },
        mobile: {
          input: 'h-[48px]',
          searchButton: 'w-[72px]',
          loadingText:
            'text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold',
          loadingTextWrapper:
            'py-mob-component-button-full-padding-y px-mob-component-button-full-padding-x mt-mob-margin-base-top gap-mob-component-button-full-space-between',
          loadMore:
            'rounded-mob-global-radius-small text-color-text-white block px-mob-component-button-full-padding-x py-mob-component-button-full-padding-y',
          noSearchResultsFoundText: 'mb-mob-margin-micro-bottom',
          base: 'flex-col py-mob-padding-base-y px-mob-padding-tight-x',
          headingWrapper: 'w-full gap-mob-margin-loose-bottom',
          subHeadingWrapper: 'gap-mob-margin-tight-bottom',
          heading: 'text-[40px] font-[700] leading-[48px]',
          headingSmall:
            'text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold',
          resultText:
            'text-heading-mob-large-regular font-heading-mob-large-regular leading-heading-mob-large-regular',
          loadMoreButton: 'mt-mob-margin-loose-bottom',
        },
      },
      isError: {
        true: {
          input: 'focus:outline-none border-[1px] border-system-red',
        },
        false: {},
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
