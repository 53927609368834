import { tv } from 'tailwind-variants';

export const faqTabTailwindVariant = tv(
  {
    slots: {
      tabButton: [
        'flex',
        'items-center',
        'justify-center',
        'p-desk-component-button-condensed-padding-y',
        'rounded-desk-global-radius-x-small',
        'font-primary',
        'cursor-pointer',
        'text-color-text-brand-1',
        'font-heading-desk-xLarge-bold',
        'leading-body-large-regular',
      ],
      activeTabButton: ['!text-color-text-black', 'border', 'border-color-border-brand'],
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
