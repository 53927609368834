import { tv } from 'tailwind-variants';

export const searchMessageTailwindVariant = tv(
  {
    slots: {
      base: ['searchMessage', 'bg-color-background-light'],
      wrapper: ['flex container justify-center', 'pt-[20px]'],
      descriptionWrapper: [
        'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold text-center',
      ],
    },
    variants: {
      device: {
        mobile: {
          base: [''],
        },
        desktop: {
          base: [''],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
