import { tv } from 'tailwind-variants';

export const loyaltyProgramTabsTailwindVariant = tv(
  {
    slots: {
      base: [''],
      breadCrumbsWrapper: ['flex items-center mb-3'],
      linkToLoyaltyProgram: [
        'text-color-background-brand-1 text-heading-mob-small-regular font-heading-mob-small-regular leading-heading-mob-small-regular hover:no-underline',
      ],
      mainHeadingStyles: [
        'text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold',
      ],
      tabsWrapper: ['mt-desk-margin-loose-right border-b border-color-color-border-mid'],
      tabsSection: ['border-b border-color-color-scale-3-light-mid'],
      tabHeading: ['flex gap-desk-space-between-base-horizontal'],
      tabSectionTabs: [
        'text-heading-desk-small-bold font-heading-desk-small-bold  leading-heading-desk-small-bold text-color-scale-7-mid-dark pb-2',
      ],
      tabSectionTabActive: ['text-color-text-black border-b-[3px] border-color-background-brand-1'],
      tabComponents: ['my-desk-padding-micro-bottom'],
    },
    variants: {
      device: {
        mobile: {
          base: [''],
        },
        desktop: {
          base: [''],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
