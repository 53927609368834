//global
import React from 'react';
import { withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import { credentialsTailwindVariants } from 'tailwindVariants/components/credentialsTailwindVariants';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
//type
export type CredentialsProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.Credentials.Fields.Credentials;
//component variants

//main component
const Credentials: React.FC<CredentialsProps> = ({ fields, params }) => {
  //check if pageEditor or not
  const isEE = useExperienceEditor();
  //background color
  const backgroundColor =
    fields?.hexColor && fields?.hexColor?.value !== ''
      ? fields?.hexColor?.value
      : 'bg-color-brand-primary-1-base';
  //variants
  const { base, divWrapper, cardWrapper, rightWrapper, card, contentWrapper, title, description } =
    credentialsTailwindVariants({
      device: { initial: 'mobile', lg: 'desktop' },
    });
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div
      id={'credentials'}
      className={(params?.Styles ?? '') + ' ' + base({ class: backgroundColor })}
      style={{ backgroundColor: backgroundColor }}
    >
      <div className={divWrapper()}>
        <div
          className={cardWrapper({
            class: `${fields?.awardsList && fields.awardsList.length <= 1 ? 'justify-center' : ''}`,
          })}
        >
          {fields?.awardsList?.map((c, index) => {
            return (
              <div key={index} className={card({ class: (index + 1) % 2 !== 0 ? 'right' : '' })}>
                <ImageHelper field={c?.fields?.image} />
              </div>
            );
          })}
        </div>
        <div className={rightWrapper()}>
          <div className={contentWrapper()}>
            <RichTextHelper field={fields?.title} className={title()} />
            <RichTextHelper field={fields?.description} className={description()} />
            {isEE && (
              <>
                Background color :<TextHelper field={fields?.hexColor} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<CredentialsProps>(Credentials);
