import { tv } from 'tailwind-variants';

export const inProgressOffersTailwindVariant = tv(
  {
    slots: {
      base: ['flex', 'flex-col', 'gap-desk-padding-micro-bottom'],
      productGrid: [
        'loyalty-product-grid',
        'grid',
        'pt-desk-padding-micro-bottom',
        'gap-desk-space-between-tight-horizontal',
        'border-t',
        'border-color-color-border-mid',
      ],
      productItem: [
        'lp-inprogress-product',
        'flex',
        'flex-col',
        'justify-between',
        'rounded-lg',
        'bg-white',
      ],
      productHeading: ['relative', 'h-[172px]'],
      productImage: ['flex', 'justify-center', 'px-desk-padding-tight-x'],
      productHeadingProgress: ['flex', 'justify-center', 'items-center', 'w-full', 'h-full'],
      productHeadingImageWrapper: [
        'flex',
        'justify-center',
        'items-center',
        'absolute',
        'z-10',
        'w-[91px]',
        'h-[91px]',
        'm-[15px]',
      ],
      productShortProgramName: [
        'text-color-background-brand-1',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
        'mb-3',
      ],
      productShortProgramNameWrapper: [
        'flex',
        'justify-between',
        'flex-col',
        'bg-color-background-light',
        'py-desk-padding-micro-y',
        'px-desk-global-spacing-spacing-3',
        'min-h-[160px]',
      ],
      productShortDescription: [
        'text-heading-mob-small-regular',
        'font-heading-mob-small-regular',
        'leading-heading-mob-small-regular',
      ],
      amountLabelStyle: [
        'text-heading-desk-xLarge-medium',
        'font-heading-desk-xLarge-medium',
        'leading-heading-desk-xLarge-medium',
      ],
      purchaseToGoLabelStyle: [
        'text-body-xSmall-bold',
        'font-body-xSmall-bold',
        'leading-body-xSmall-bold',
      ],
      modalProgramName: [
        'text-heading-mob-small-bold',
        'font-heading-mob-small-bold',
        'leading-heading-mob-small-bold',
      ],
      modalProgramDescription: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      productNoExpiryDateText: [
        'text-color-text-dark',
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
        'py-3',
      ],
      termsAndConditionWrapperStyle: [
        'border-y',
        'border-color-scale-3-light-mid',
        'border-1',
        'py-desk-padding-micro-y',
        'mb-3',
      ],
      termsAndConditionLabelStyle: [
        'font-bold text-color-scale-5-mid',
        'mb-desk-margin-base-bottom',
      ],
      termsAndConditionTextStyle: ['text-color-scale-5-mid'],
      loadMoreButtonWrapper: ['flex', 'justify-center '],
      loadMoreButton: [
        'flex',
        'w-[188px]',
        'justify-center',
        '!py-desk-component-button-condensed-padding-y',
        'mt-desk-global-spacing-spacing-5',
      ],
      inProgressRewardsEarnThroughLabelStyle: [
        'mt-3',
        'font-body-small-bold',
        'text-body-small-bold',
        'leading-body-small-bold',
      ],
      inProgressRewardsViewPurchasesLinkTextStyle: [
        'flex',
        'items-center',
        'text-color-background-brand-1',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
      ],
      qualifyingPurchaseModalHeadingStyle: [
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
        'mb-6',
      ],
      qualifyingPurchasesTableColumn1TextWrapper: [
        'flex',
        'flex-wrap',
        'p-3',
        'border-b',
        'border-color-color-scale-3-lightMid',
      ],
      qualifyingPurchasesTableColumn1TextStyle: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'heading-heading-desk-medium-bold',
      ],
      qualifyingPurchasesTableColumn2TextStyle: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'heading-heading-desk-medium-bold',
      ],
      qualifyingPurchasesTableColumn3TextStyle: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'heading-heading-desk-medium-bold',
      ],
    },
    variants: {
      device: {
        mobile: {
          base: [''],
          productGrid: ['grid-cols-1'],
        },
        desktop: {
          base: [''],
          productGrid: ['grid-cols-4'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
