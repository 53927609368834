import { tv } from 'tailwind-variants';

export const leftNavigationTailwindVariant = tv(
  {
    slots: {
      sideNav: ['flex', 'flex-col'],
      sideNavItems: ['gap-desk-component-button-full-space-between', 'my-1'],
      sideNavItem: [
        'w-full',
        'hover:bg-color-scale-2-light',
        'hover:no-underline',
        'inline-block',
        'after:hidden',
        'cursor-pointer',
      ],
      sideNavItemActive: [''],
      sideNavItemIcon: [''],
    },
    variants: {
      size: {
        mobile: {
          sideNavItems: ['border-b', 'border-color-border-mid', 'my-0'],
          sideNavItem: [
            'flex',
            'items-center',
            'justify-between',
            'text-color-text-dark',
            'capitalize',
            'text-heading-mob-small-bold',
            'font-heading-mob-small-bold',
            'leading-heading-mob-large-bold',
            'text-[#565a5c]',
            'py-mob-global-spacing-spacing-2',
            'px-mob-global-grid-margin',
          ],
          sideNavItemIcon: ['flex', 'items-center', 'gap-2', 'text-color-brand-primary-1-base'],
          // sideNavItemActive: ['pl-desk-global-spacing-spacing-5'],
        },
        desktop: {
          sideNav: ['py-desk-global-spacing-spacing-5'],
          sideNavItems: 'border-0',
          sideNavItem: [
            'w-full',
            'text-color-brand-primary-1-base',
            'py-desk-global-spacing-spacing-1',
            'px-desk-global-spacing-spacing-2',
            'font-heading-mob-small-bold',
            'text-heading-mob-small-bold',
            'leading-heading-mob-small-bold',
            'inline-block no-underline',
            'border-none',
          ],
          sideNavItemActive: ['pl-desk-global-spacing-spacing-5', 'bg-color-scale-2-light'],
          sideNavItemIcon: ['hidden'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
