import { tv } from 'tailwind-variants';

//component variants

export const localOffersTailwindVariant = tv(
  {
    slots: {
      base: ['localOffers', 'w-full', 'h-[600px]'],
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        mobile: {},
        desktop: {},
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
