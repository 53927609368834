import { useTheme } from 'lib/context/ThemeContext';
import { Field, NextImage, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useEffect } from 'react';
import {
  HideLinkFromStoreNavigation,
  ShowLinkFromStoreNavigation,
} from 'src/helpers/StoreLandingHelper';
import { meetTeamStoreDetailTailwindVariant } from 'tailwindVariants/components/meetTeamStoreDetailTailwindVariant';

const RenderTeamMember = ({ teamData }: { teamData: TeamData[] }) => {
  const { themeName } = useTheme();
  const {
    teamMembersWrapper,
    teamMemberWrapper,
    teamMemberTitle,
    teamMemberDescription,
    teamImageWrapper,
    teamImage,
  } = meetTeamStoreDetailTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
    theme: themeName,
  });
  return (
    <div className={teamMembersWrapper()}>
      {teamData?.map((member: TeamData, index: number) => {
        return (
          <div className={teamMemberWrapper()} key={index}>
            <div className={teamImageWrapper()}>
              <NextImage
                field={{
                  value: {
                    src: member?.photo?.value || '/images/transparent-img.png',
                    alt: member?.name ? 'memberImage' : '',
                    width: 240,
                    height: 240,
                  },
                }}
                className={teamImage()}
              />
            </div>
            {member?.name?.value && (
              <Text tag="p" className={teamMemberTitle()} field={member.name} />
            )}
            {member?.role?.jsonValue?.fields?.title?.value && (
              <Text
                tag="p"
                className={teamMemberDescription()}
                field={{ value: member?.role?.jsonValue?.fields?.title?.value }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
interface TeamData {
  photo?: Field<string>;
  name?: Field<string>;
  role?: {
    jsonValue?: {
      fields: {
        title: {
          value: string;
        };
      };
    };
  };
}
interface MeetTeamProps {
  fields?: {
    data?: {
      datasource?: {
        meetTeamText: Field<string>;
        meetTeamText2: Field<string>;
      };
      storeTeamData?: {
        children?: {
          results?: {
            children?: { results: TeamData[] };
          }[];
        };
      };
    };
  };
}

const MeetTeam = ({ fields }: MeetTeamProps) => {
  const { themeName } = useTheme();
  const { base, teamWrapper, teamMembersContentWrapper, teamWrapperDescription, teamWrapperTitle } =
    meetTeamStoreDetailTailwindVariant({
      size: {
        initial: 'mobile',
        lg: 'desktop',
      },
      theme: themeName,
    });

  useEffect(() => {
    if (
      !(
        fields?.data?.storeTeamData?.children?.results?.[0]?.children?.results &&
        fields?.data?.storeTeamData?.children?.results?.[0]?.children?.results?.length > 0
      )
    ) {
      HideLinkFromStoreNavigation('meet-team');
    } else {
      ShowLinkFromStoreNavigation('meet-team');
    }
  }, [fields?.data?.storeTeamData?.children?.results?.[0]?.children?.results]);
  return (
    <>
      {fields?.data?.storeTeamData?.children?.results?.[0]?.children?.results &&
        fields?.data?.storeTeamData?.children?.results?.[0]?.children?.results?.length > 0 && (
          <div className={base()} id="meet-team">
            <div className={teamWrapper()}>
              {fields?.data?.datasource?.meetTeamText && (
                <Text
                  tag="p"
                  className={teamWrapperTitle()}
                  field={fields?.data?.datasource?.meetTeamText}
                />
              )}
              {fields?.data?.datasource?.meetTeamText2 && (
                <RichText
                  tag="p"
                  className={teamWrapperDescription()}
                  field={fields?.data?.datasource?.meetTeamText2}
                />
              )}
            </div>
            <div className={teamMembersContentWrapper()}>
              <RenderTeamMember
                teamData={fields?.data?.storeTeamData?.children?.results?.[0]?.children?.results}
              />
            </div>
          </div>
        )}
    </>
  );
};

export default MeetTeam;
