import { tv } from 'tailwind-variants';

export const serviceRewardsWidgetTailwindVariant = tv(
  {
    slots: {
      base: ['service-rewards', 'flex', 'gap-desk-global-spacing-spacing-7'],
      punchCardSection: ['punchcard-section'],
      tabContent: ['py-desk-padding-micro-y', 'px-desk-padding-micro-x'],
      punchCard: [
        'w-full',
        'border',
        'border-solid',
        'border-color-border-light',
        'rounded-lg',
        'p-0',
      ],
      punchCardService: [
        'punchcard-service',
        'w-full',
        'flex',
        'items-center',
        'py-desk-padding-micro-top',
        'px-desk-global-spacing-spacing-3',
        'cursor-pointer',
        'rounded-t',
      ],
      punchCardTab: ['p-desk-padding-micro-y'],
      punchCardServiceLink: ['text-center', 'hover:no-underline', 'w-full'],
      punchCardServiceDescription: ['punchcard-service-description', 'text-center'],
      punchCardServiceImages: ['grid'],
      titleSection: [
        'flex',
        'justify-center',
        'align-center',
        'bg-color-scale-2-light',
        'py-desk-padding-micro-x',
      ],
      punchcardTitle: [
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
      ],
      navTabs: ['flex', 'justify-around', 'mb-desk-margin-base-bottom'],
      availableRewardsSection: ['flex', 'available-rewards-section'],
      availableRewards: [
        'w-full',
        'border',
        'border-solid',
        'border-color-border-light',
        'rounded-lg',
        'p-0',
        'h-full',
      ],
      availableRewardsTitle: [
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
      ],
      yourReward: [
        'border',
        'border-solid',
        'border-color-border-light',
        'rounded-lg',
        'p-8',
        'my-5',
      ],
      yourRewardInfo: ['your-reward-info'],
      yourRewardHeader: ['your-reward-header'],
      yourRewardName: ['font-bold'],
      yourRewardEarned: ['your-reward-earned'],
      yourRewardExpired: ['your-reward-expired'],
      yourRewardCoupon: ['mt-5'],
      yourRewardsNoImage: [
        'flex',
        'items-center',
        'justify-center',
        'h-[75%]',
        'text-center',
        'py-desk-padding-micro-y',
        'px-desk-padding-micro-x',
        'your-rewards-section',
      ],
      serviceImages: ['flex justify-center'],
      noRewardsLabelText: ['no-rewards-label'],
      oneServiceOnlyPunchCard: ['one-service-only'],
      oneServiceOnlyTitleSection: ['!bg-color-brand-primary-1-base'],
      oneServicePunchcardTitle: ['!text-white'],
      oneServiceOnlyTabContent: ['one-service-only'],
      serviceTabImage: ['w-56'],
      rewardsProgramStyles: ['rewards-program'],
      punchcardDescriptionLabelStyles: [
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
        'py-4',
      ],
      viewAllRewardsButton: [
        'py-desk-component-button-full-padding-y',
        'px-desk-component-button-full-padding-x',
        'gap-desk-component-button-full-space-between',
        'bg-color-color-background-white',
        'border-2',
        'rounded',
        'border-color-brand-primary-1-base',
        'text-color-text-brand-1',
        'text-heading-mob-large-bold',
        'font-heading-mob-large-bold',
        'leading-heading-mob-large-bold',
        'hover:text-white',
        'hover:border-color-brand-primary_1-base',
        'hover:bg-color-brand-primary-1-base',
        'view-history-button',
      ],
      viewAllRewardsModal: ['viewAllRewardsModal'],
      viewAllRewardsModalHeading: ['mb-4 py-3 border-b-2 border-color-border-dark'],
      viewAllRewardsModalBody: ['viewAllRewardsModalBody', 'mb-4'],
      viewAllRewardsModalHeadingRow: [''],
      viewAllRewardsModalBodyRow: [''],
      viewAllRewardsModalCouponRewardImage: ['block'],
    },
    variants: {
      device: {
        mobile: {
          base: ['flex-col'],
          punchCardSection: ['w-full'],
          punchCardServiceLink: [
            'text-body-small-bold',
            'font-body-small-bold',
            'leading-body-small-bold',
          ],
          punchCardServiceDescription: [
            'text-heading-mob-small-bold',
            'font-heading-mob-small-bold',
            'leading-heading-mob-small-bold',
          ],
          punchCardServiceImages: ['grid-cols-4', 'gap-mob-space-between-base-horizontal'],
          availableRewardsSection: ['flex'],
          noRewardsLabelText: [
            'no-rewards-label',
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          viewAllRewardsModal: ['!items-start', 'absolute', 'left-0', 'top-0'],
          viewAllRewardsModalHeading: ['hidden'],
          viewAllRewardsModalBodyRow: ['flex', 'mb-4'],
          viewAllRewardsModalCouponRewardImage: ['-ml-2.5'],
        },
        desktop: {
          base: ['flex-row'],
          punchCardSection: ['w-5/12'],
          punchCardServiceLink: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
          ],
          punchCardServiceDescription: [
            'text-heading-desk-small-bold',
            'font-heading-desk-small-bold',
            'leading-heading-desk-small-bold',
          ],
          punchCardServiceImages: ['grid-cols-2', 'gap-4'],
          availableRewardsSection: ['w-7/12'],
          noRewardsLabelText: [
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          viewAllRewardsModal: ['!items-center'],
          viewAllRewardsModalHeading: ['flex', 'flex-wrap'],
          viewAllRewardsModalBody: ['flex flex-wrap'],
          viewAllRewardsModalHeadingRow: ['px-2'],
          viewAllRewardsModalBodyRow: ['block', 'px-2'],
          viewAllRewardsModalCouponRewardImage: ['ml-0'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
