import { ImageField, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import React from 'react';

const PoweredByGoogleImage = () => {
  const { sitecoreContext } = useSitecoreContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const googleImage = (sitecoreContext as any)?.route?.fields?.Configuration?.[0]?.fields
    ?.image as ImageField;
  return <ImageHelper field={googleImage} />;
};

export default PoweredByGoogleImage;
