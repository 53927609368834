//global
import React, { useEffect, useState } from 'react';
import { LinkField, RichText, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { Text, Field } from '@sitecore-jss/sitecore-jss-nextjs';

//local

//lib
import { ComponentProps } from 'lib/component-props';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import { useOcSelector } from 'src/redux/ocStore';
import { currentPath, redirectTo } from 'src/helpers/Constants';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import { loginContentTailwindVariant } from 'tailwindVariants/components/loginContentTailwindVariant';

//type
export type LoginContentProps = ComponentProps & {
  fields?: {
    data?: {
      data?: {
        title?: Field<string>;
        shortDescription?: Field<string>;
        successRedirectUrl?: { jsonValue: LinkField };
        cancelButtonUrl?: { jsonValue: LinkField };
        submitButtonText?: Field<string>;
        cancelButtonText?: Field<string>;
      };
    };
  };
};

//main component
const LoginContent: React.FC<LoginContentProps> = ({ fields, params }) => {
  const { base, headingWrapper, title, button, buttonWithOutline } = loginContentTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const selectedStore = useOcSelector((state) => state?.storeReducer?.selectedStore);
  const isRegistrationEnable = useOcSelector(
    (state) => state?.otherSettingSlice?.data?.enableAccountCreation?.jsonValue?.value
  );
  const [appendCheckoutUrl, setAppendCheckoutUrl] = useState(false);

  const pathName = useRealPathName();

  useEffect(() => {
    // Check if current page is on '/logincheckout' page.
    if (pathName == currentPath?.isLoginCheckout) {
      setAppendCheckoutUrl(true);
    }
  }, []);

  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div className={(params?.Styles ?? '') + base()}>
      <div className={headingWrapper()}>
        {fields?.data?.data?.title && (
          <Text tag="p" className={title()} field={fields?.data?.data?.title} />
        )}
        {fields?.data?.data?.shortDescription && (
          <RichText tag="p" field={fields?.data?.data?.shortDescription} />
        )}
      </div>
      {/* Registration CTA */}
      {fields?.data?.data?.successRedirectUrl?.jsonValue?.value && (
        <LinkHelper
          className={button({ isDisabled: !(isRegistrationEnable === 'true') })}
          field={{
            value: {
              ...fields?.data?.data?.successRedirectUrl?.jsonValue?.value,
              text: fields?.data?.data?.submitButtonText?.value,
              querystring: appendCheckoutUrl ? redirectTo?.checkout : '',
              href:
                isRegistrationEnable === 'true'
                  ? fields?.data?.data?.successRedirectUrl?.jsonValue?.value?.href
                  : '#',
            },
          }}
        />
      )}
      {/* Checkout as Guest CTA */}
      {selectedStore?.checkoutDisable == 0 &&
        fields?.data?.data?.cancelButtonUrl?.jsonValue?.value && (
          <LinkHelper
            className={buttonWithOutline()}
            field={{
              value: {
                ...fields?.data?.data?.cancelButtonUrl?.jsonValue?.value,
                text: fields?.data?.data?.cancelButtonText?.value,
              },
            }}
          />
        )}
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<LoginContentProps>(LoginContent);
