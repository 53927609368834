import { tv } from 'tailwind-variants';

export const alertPromoTailwindVariant = tv(
  {
    slots: {
      base: ['alertPromo', 'flex w-full', 'gap-y-2', 'min-h-16', 'relative'],
      container: ['w-full'],
      heading: [
        'flex',
        'justify-center',
        'items-center',
        'h-full',
        'hover:no-underline',
        'duration-300',
        'hover:opacity-85',
        'py-desk-padding-micro-y',
        'px-desk-global-grid-margin',
      ],
      title: ['text-color-text-white', 'font-bold', 'leading-tight'],
      closePanel: ['flex'],
      closeButton: [
        'flex-shrink-0',
        'inline-flex',
        'justify-center',
        'w-10',
        'h-10',
        'items-center',
        'text-sm',
        'duration-300',
        'hover:opacity-85',
        'absolute',
        'top-1/2',
        '-translate-y-1/2',
        'right-0',
        'mr-4',
      ],
      screenReaderOnly: ['sr-only'],
    },
    variants: {
      size: {
        mobile: {
          heading: ['py-mob-global-grid-margin', 'pl-mob-global-grid-margin'],
          title: [
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          closePanel: ['py-mob-global-grid-margin'],
        },
        desktop: {
          title: [
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          closePanel: ['pr-0', 'items-center'],
          closeButton: ['absolute', 'mr-4'],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
