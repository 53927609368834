import { tv } from 'tailwind-variants';

//component variants
export const heroCarouselTailwindVariant = tv(
  {
    slots: {
      base: [
        'heroCarousel',
        'relative',
        '[&_.splide_.arrow-prev]:left-4',
        '[&_.splide_.arrow-prev]:right-4',
        '[&_.splide_.splide-button]:bg-transparent',
        '[&_.splide_.splide-button]:h-[25px]',
        '[&_.splide_.splide-button]:w-[25px]',
        '[&_.splide_.splide-button>svg>path]:!drop-shadow-arrows',
        '[&_.splide_.splide-button]:opacity-100',
        '[&_.splide_.splide-button>svg]:fill-color-brand-primary-1-base',
      ],
      deskImg: [''],
      mobImg: [''],
      card: [''],
      img: ['h-auto w-full'],
    },
    compoundSlots: [{ slots: ['deskImg', 'mobImg'], class: ['h-full', 'w-full'] }],
    variants: {
      size: {
        mobile: { deskImg: ['hidden'], mobImg: ['block'] },
        desktop: { deskImg: ['block'], mobImg: ['hidden'] },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
