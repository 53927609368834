import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { PSP } from 'models/PetSuppliesPlus.Model';
import { useContext, useEffect } from 'react';
import { changeStoreVariants } from 'tailwindVariants/components/changeStoreTailwindVariant';
import ComponentContext from 'lib/context/ComponentContext';
import GetStoresWidget from './GetStoresWidget';
import { useOcSelector } from 'src/redux/ocStore';
import { Cookies } from 'react-cookie';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';

export type ChangeStoreProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.ChangeStoreWidget.Fields.ChangeStore;

const ChangeStore = ({ fields }: ChangeStoreProps): JSX.Element => {
  const cookies = new Cookies();
  const { container, dialogueTitle, popupSpacing } = changeStoreVariants({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);
  useEffect(() => {
    setcomponentContextData({ ...componentContextData, showChangeStorePopup: false });
  }, []);
  const handleModalClose = () => {
    setcomponentContextData({ ...componentContextData, showChangeStorePopup: false });
  };
  const myStoreData = useOcSelector((state) => state?.storeReducer?.selectedStore);
  return (
    <div
      className={container()}
      data-component="components/ProductDetail/ChangeStorePopup/ChangeStore"
    >
      <ModalWrapper
        showCloseButtonInModalHeader={false}
        closeModalOnOverlayClick={true}
        onCloseClick={handleModalClose}
        showModal={componentContextData?.showChangeStorePopup}
        customPopup={true}
        popupSpacing={popupSpacing()}
        popupWidth={'max-w-[1060px]'}
      >
        <>
          <Text field={fields?.title} tag="h4" className={dialogueTitle()} />
          {/* 1st Call Discover to get product list */}
          {componentContextData?.showChangeStorePopup && (
            <GetStoresWidget
              rfkId="rfk_Store_Locator_Geo_Location"
              fields={fields}
              coordinates={{
                lat: Number(cookies.get('MyLatitude')) || Number(myStoreData.latitude),
                lng: Number(cookies.get('MyLongitude')) || Number(myStoreData.longitude),
              }}
              productId={componentContextData?.productId}
            />
          )}
        </>
      </ModalWrapper>
    </div>
  );
};

export default ChangeStore;
