import { tv } from 'tailwind-variants';

export const fullWidthVideoTileTailwindVariant = tv(
  {
    slots: {
      base: 'w-full fullWidthVideoTile',
      wrapper: 'container mx-auto',
      closeIcon: '',
      title: '',
      subtitle: '',
      playIcon: ' [&>svg>*]:fill-color-brand-primary-1-base inline-block',
      playButton: 'absolute top-0 left-0 w-full h-full',
      playerWrapper: 'relative pt-[56.25%] ',
      playerWrapperModel: 'relative pt-[48%]',
      reactPlayer: 'absolute top-0 left-0',
      reactPlayerAutoPlay:
        'video-aspect-ratio absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 aspect-w-16 aspect-h-9',

      link: 'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold hover:no-underline py-desk-component-button-full-padding-y px-desk-component-button-full-padding-x text-color-text-white hover:text-color-text-white bg-color-brand-primary-1-base hover:bg-color-brand-primary-1-dark rounded-desk-global-radius-small',
      linkWrapper: 'flex w-full  justify-center',
      contentBlock:
        'contentBlock absolute w-full  left-0 top-1/2 text-center -translate-y-1/2 flex flex-col justify-center text-color-text-white',
    },
    variants: {
      size: {
        mobile: {
          base: 'py-mob-padding-tight-y',
          image: 'w-full',
          playIcon: '[&>svg]:w-[50px]',
          link: '',
          title:
            'text-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold font-heading-mob-xLarge-bold mb-mob-margin-loose-bottom',
          subtitle: 'text-heading-mob-small-regular mb-mob-margin-micro-bottom',
          linkWrapper: 'flex-col md:flex-row gap-mob-space-between-base-horizontal',
          contentBlock: 'px-mob-padding-base-x py-mob-padding-tight-y',
        },
        desktop: {
          base: 'py-desk-padding-tight-y',
          image: '',
          playIcon: '[&>svg]:w-[81px]',
          title:
            'text-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold font-heading-desk-xLarge-bold mb-desk-margin-loose-bottom',
          subtitle: 'text-heading-desk-small-regular mb-desk-margin-micro-bottom',
          linkWrapper: 'flex-row gap-desk-space-between-base-horizontal',
          contentBlock: 'p-desk-padding-tight-y',
        },
      },
    },
  },
  {
    responsiveVariants: ['sm', 'lg'],
  }
);
