import { tv } from 'tailwind-variants';

export const promotionCouponTailwindVariant = tv(
  {
    slots: {
      base: [
        'promotionCoupon',
        'container',
        'mx-auto',
        'flex flex-col justify-center items-center',
      ],
      errorBlock: [
        'w-full border border-system-red-default text-system-red-dark bg-system-red-light p-mob-padding-tight-y mb-5 rounded-md',
        'text-body-large-regular',
        'font-normal',
        'leading-body-large-regular',
      ],
      printBtn: [
        'printBtn',
        'w-full',
        'max-w-[310px]',
        'flex',
        'justify-center',
        'items-center',
        'my-5',
        'gap-desk-global-spacing-spacing-3',
        'mb-desk-global-spacing-spacing-10',
        'py-desk-component-button-full-padding-y',
        'rounded-sm',
        'bg-color-brand-primary-1-base hover:bg-color-brand-primary-1-dark text-color-text-white',
      ],
      heading: ['text-uppercase', 'text-center'],
      printIcon: ['w-[26px] h-[26px] [&>svg>*]:fill-color-background-white'],
      couponImg: ['pb-desk-global-spacing-spacing-7'],
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        mobile: {
          printBtn: [
            'px-mob-component-button-full-padding-x',
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
          heading: [
            'mb-[10px]',
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
        },
        desktop: {
          printBtn: [
            'px-desk-component-button-full-padding-x',
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
          heading: [
            'text-heading-desk-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-desk-large-bold',
          ],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
