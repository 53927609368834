import { tv } from 'tailwind-variants';

export const registerFormTailwindVariant = tv(
  {
    slots: {
      formWrapper: ['flex', 'flex-col', 'items-center', 'gap-[8px]'],
      formContainer: ['container', 'flex', 'contact-us', 'bg-color-background-light'],
      form: [
        'bg-color-background-light',
        'flex',
        'flex-col',
        'gap-desk-space-between-base-vertical',
        // '[&>*:last-child]:bg-red-500 gap-0',
      ],
      fieldWrap: ['flex', 'justify-between', 'w-full'],
      contentTitleSection: ['flex', 'flex-col', 'gap-[12px]', 'w-full'],
      title: ['text-center'],
      titleOrderConfirmation: ['text-color-text-black'],
      shortDescription: [
        'text-center',
        'text-color-text-dark',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      disclaimer: 'w-full self-stretch',
      submitBtn: [
        'bg-color-brand-primary-1-base',
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
        'text-color-text-white',
        'rounded-mob-global-radius-small',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
      ],
      position: 'relative',
      extra: 'justify-center',
      radioButtonWrapper: 'flex w-fit relative cursor-pointer',
      radioButtonsWrapper: 'flex',
      radioIcon:
        'invisible w-[20px] h-[20px] outline-color-border-mid border-2 rounded-full peer-checked:visible absolute bg-color-brand-primary-1-base',
      radioField:
        'w-[20px] h-[20px] rounded-full cursor-pointer outline-color-border-mid border-2 appearance-none bg-color-background-white rounded-4 peer',
      successBase: 'flex text-center justify-center w-full',
      loaderWrapper: ['flex', 'items-center'],
    },
    variants: {
      device: {
        mobile: {
          formWrapper: ['pt-mob-padding-none', 'pb-mob-margin-loose-bottom'],
          formContainer: ['px-0'],
          form: ['py-mob-padding-tight-y', 'px-mob-padding-tight-x', 'max-w-[627px]'],
          extra: ['px-mob-padding-tight-x', 'pb-mob-margin-loose-bottom', 'w-full'],
          fieldWrap: ['gap-mob-space-between-loose-horizontal', 'flex-col'],
          contentTitleSection: ['items-center', 'pt-mob-padding-tight-y', 'pb-mob-padding-micro-y'],
          title: [
            'text-heading-mob-xLarge-bold',
            'font-heading-mob-xLarge-bold',
            'leading-heading-mob-xLarge-bold',
          ],
          titleOrderConfirmation: [
            'text-heading-mob-medium-bold',
            'font-heading-mob-medium-bold',
            'leading-heading-mob-medium-bold',
          ],
          submitBtn: [
            'w-full',
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'rounded-desk-global-radius-small',
          ],
          disclaimer: 'text-body-large-regular leading-body-large-regular font-body-large-regular',
          radioButtonsWrapper: 'gap-mob-margin-tight-right',
          radioButtonWrapper: 'gap-mob-space-between-micro-horizontal',
          successBase:
            'mb-mob-margin-loose-bottom py-mob-padding-tight-y px-mob-global-grid-margin ',
          loaderWrapper: [
            'gap-mob-component-button-full-space-between',
            'text-heading-mob-large-bold',
            'font-heading-mob-large-bold',
            'leading-heading-mob-large-bold',
          ],
        },
        desktop: {
          formWrapper: [
            'pt-desk-padding-none',
            'px-desk-global-grid-margin',
            'pb-desk-margin-loose-bottom',
          ],
          formContainer: ['px-inherit'],
          form: ['py-desk-padding-tight-y', 'px-desk-padding-tight-x', 'max-w-[627px]'],
          extra: ['px-desk-padding-tight-x', 'pb-desk-margin-loose-bottom', 'max-w-[627px]'],
          fieldWrap: ['gap-x-desk-space-between-loose-horizontal', 'flex-row', 'gap-none'],
          contentTitleSection: [
            'items-start',
            'pt-desk-padding-tight-y',
            'pb-desk-padding-micro-y',
            'px-desk-global-grid-margin',
          ],
          title: [
            'text-heading-desk-xLarge-bold',
            'font-heading-desk-xLarge-bold',
            'leading-heading-desk-xLarge-bold',
          ],
          titleOrderConfirmation: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
          ],
          submitBtn: [
            'max-w-[280px]',
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'hover:bg-color-brand-primary-1-dark',
          ],
          disclaimer:
            'text-body-small-regular leading-body-small-regular font-body-small-regular max-w-[627px]',
          radioButtonWrapper: 'gap-desk-space-between-micro-horizontal',
          radioButtonsWrapper: 'gap-desk-margin-tight-right',
          successBase:
            'mb-desk-margin-loose-bottom y-desk-padding-tight-y px-desk-global-grid-margin',
          loaderWrapper: [
            'gap-desk-component-button-full-space-between',
            'text-heading-desk-large-bold',
            'font-heading-desk-large-bold',
            'leading-heading-desk-large-bold',
          ],
        },
      },
      isOrderConfirmationVariant: {
        true: {
          formWrapper: ['!pb-0', 'lg:!pb-desk-margin-base-bottom'],
        },
        false: {
          formWrapper: [],
        },
      },
      isDisabled: {
        true: {
          submitBtn: ['cursor-not-allowed'],
        },
        false: {
          submitBtn: ['pointer'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
