import { tv } from 'tailwind-variants';

export const stickyNavigationTailwindVariant = tv(
  {
    slots: {
      base: 'StickyNavigation sticky top-0 z-[5] bg-color-brand-primary-1-dark w-full',
      container: 'container flex overflow-x-auto items-center',
      link: ' hover:no-underline hover:text-component-sticky-nav-hover flex-none w-fit focus:text-component-sticky-nav-hover text-color-text-white text-heading-mob-medium-regular font-heading-mob-medium-regular leading-heading-mob-medium-regular',
    },
    variants: {
      size: {
        desktop: {
          container: 'justify-center gap-desk-global-spacing-spacing-9 py-desk-padding-micro-y',
        },
        mobile: {
          container: 'gap-mob-global-spacing-spacing-8 px-0 py-mob-padding-micro-y',
          base: 'px-mob-global-grid-margin',
        },
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
