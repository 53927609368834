//global
import React from 'react';
import { NextImage, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import apiConfig from 'src/utils/apiConfig';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
//lib
import { ComponentProps } from 'lib/component-props';
import useSWR from 'swr';
import TextHelper from 'src/helpers/commonComponents/TextHelper';
import { useRouter } from 'next/router';
import { loaderGIF } from 'src/helpers/Constants';
import Image from 'next/image';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { useTheme } from 'lib/context/ThemeContext';
import { promotionCouponTailwindVariant } from 'tailwindVariants/components/promotionCouponTailwindVariant';
import { orderPromotionApi } from 'src/utils/nextApiConfig';
//type
export type PromotionCouponProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.PromotionCoupon.Fields.Coupon;

//feting the coupon image url
const fetchImage = async (referenceKey: string, token: string, site: string) => {
  const headersData = {
    Authorization: token,
    site: site,
  };
  const res = await fetch(orderPromotionApi?.promotionCoupon, {
    method: 'POST',
    body: JSON.stringify([{ ReferenceKey: referenceKey }]),
    headers: headersData,
  });
  return await res?.json();
};

//main component
const PromotionCoupon: React.FC<PromotionCouponProps> = ({ fields, params }) => {
  const { base, errorBlock, printBtn, heading, printIcon, couponImg } =
    promotionCouponTailwindVariant({
      size: { initial: 'mobile', lg: 'desktop' },
    });
  const token = useAuthToken();
  const { themeNameUpper } = useTheme();
  const { query } = useRouter();
  const { data, isLoading } = useSWR(query?.r as string, () =>
    fetchImage((query?.r as string) ?? '', token, themeNameUpper)
  );
  const handlePrint = () => {
    window.print();
  };

  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div className={base({ className: params?.Style ?? '' })}>
      {!isLoading && data !== undefined ? (
        data !== null ? (
          <>
            <button aria-label="print" className={printBtn()} onClick={handlePrint}>
              <IconHelper icon="print" className={printIcon({ className: 'printIcon' })} />
              <TextHelper field={fields?.printCouponText} />
            </button>
            <div id="printCoupon">
              <h2 className={heading()}>{data?.[0]?.CouponDescription}</h2>
              <Image
                src={apiConfig?.couponImage?.imageUrl + data?.[0]?.ImagePath}
                alt={data?.[0]?.CouponName}
                width={720}
                height={620}
                className={couponImg()}
                title={data?.[0]?.CouponName}
              />
            </div>
          </>
        ) : (
          <div className={errorBlock()}>
            <TextHelper field={fields?.couponExpiredText} />
          </div>
        )
      ) : (
        <div className={''}>
          <NextImage field={loaderGIF} height={75} width={75} />
        </div>
      )}
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<PromotionCouponProps>(PromotionCoupon);
