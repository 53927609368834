import { tv } from 'tailwind-variants';

export const faqAccordionTailwindVariant = tv(
  {
    slots: {
      tabPanel: ['border-t', 'border-color-border-mid'],
      tabItem: ['border-b', 'border-color-border-mid'],
      tabItemGroup: [''],
      tabButton: [
        'flex',
        'items-center',
        'justify-center',
        'p-desk-component-button-condensed-padding-y',
        'rounded-desk-global-radius-x-small',
        'font-primary',
        'cursor-pointer',
        'text-color-text-brand-1',
        'font-heading-desk-xLarge-bold',
        'leading-body-large-regular',
      ],
      tabContent: ['px-4 py-3'],
      tabItemSummary: [
        'my-1',
        'py-4',
        'gap-3',
        'font-medium',
        'marker:content-none',
        'hover:cursor-pointer',
      ],
      tabItemQuestion: ['flex', 'items-center'],
      tabItemTitle: [
        'text-heading-desk-small-bold',
        'font-heading-desk-small-bold',
        'leading-heading-desk-small-bold',
        'pl-4',
      ],
      tabItemDescription: ['ml-desk-margin-loose-left', 'font-normal'],
      tabItemArticle: ['px-4', 'pb-4'],
      tabList: [''],
      activeTabButton: ['!text-color-text-black', 'border', 'border-color-border-brand'],
      activeTabSlideDown: ['pt-2', 'mb-1'],
      activeTabSlideIn: ['h-0', 'invisible'],
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
