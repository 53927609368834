import { NextRequest, NextResponse } from 'next/server';
import { MiddlewarePlugin } from '..';

const STORE_ID_PREFIX = '_store_id_';

export interface StoreIdData {
  storeId: string;
}

export function getStoreIdFromRewrite(pathname: string): StoreIdData | null {
  const path = pathname.endsWith('/') ? pathname : pathname + '/';
  const result = path.match(`${STORE_ID_PREFIX}(.*?)\\/`);

  if (
    result &&
    result[1] !== '' //&& result[2] !== ''
  ) {
    const [, storeId] = result;
    return { storeId };
  }
  // Cannot serialize undefined, so make sure it's null instead of undefined.
  return null;
}

/**
 * Normalize a store rewrite path (remove product data)
 * @param {string} pathname the pathname
 * @returns {string} the pathname with product data removed
 */
export function normalizeStoreIdRewrite(pathname: string): string {
  let newPathName = pathname;
  const idResult = pathname.match(`${STORE_ID_PREFIX}.*?(?:\\/|$)`);
  if (idResult) {
    newPathName = newPathName.replace(idResult[0], '');
  }
  return newPathName;
}

class StoreIdPlugin implements MiddlewarePlugin {
  // Needs to be after multisite middleware, but before others
  order = -0.5;

  excludeRoute = (pathname: string) => {
    return (
      pathname.includes('.') || // Ignore files
      pathname.startsWith('/api/') || // Ignore Next.js API calls
      pathname.startsWith('/sitecore/') || // Ignore Sitecore API calls
      pathname.startsWith('/_next') || // Ignore next service calls
      pathname.startsWith('/feaas-render') || // Ignore feaas-render (FEaaS render) calls
      pathname.startsWith('/healthz') || // Ignore Health check calls
      pathname.startsWith('/-') // Ignore Sitecore media.
    );
  };
  /**
   * exec async method - to find coincidence in url.pathname and redirects of site
   * @param req<NextRequest>
   * @returns Promise<NextResponse>
   */
  async exec(req: NextRequest, res?: NextResponse): Promise<NextResponse> {
    // Response will be provided if other middleware is run before us
    let response = res || NextResponse.next();

    const pathname = req.nextUrl.pathname;

    if (this.excludeRoute(pathname)) {
      return response;
    }

    const storeId = req.nextUrl.searchParams.get('storeId') || req.cookies.get('storeId')?.value;

    // If no store id in cookie, let it grab default in page props factory
    if (!storeId) {
      return response;
    }

    const rewritePath = this.getStoreIdRewritePath(pathname, { storeId }); // Make sure to also manually pass query string because it's not part of pathname.

    // If no path found then no need to do anything further
    if (!rewritePath) {
      return response;
    }

    // Path can be rewritten by previously executed middleware
    const basePath = res?.headers.get('x-sc-rewrite') || pathname;

    // Get any existing rewrite so we can include it in the final rewrite.
    const existingRewrite =
      pathname === '/'
        ? basePath.substring(0, basePath.length - 1)
        : basePath.replace(pathname, '');

    const rewriteUrl = req.nextUrl.clone();

    // Add the existing rewrite and our new rewrite
    rewriteUrl.pathname = existingRewrite + rewritePath;

    response = NextResponse.rewrite(rewriteUrl, res);

    // Share rewrite path with following executed middlewares
    response.headers.set('x-sc-rewrite', rewriteUrl.pathname);

    response.headers.set('x-psp-storeid', storeId);

    response.cookies.set('storeId', storeId);

    return response;
  }

  getStoreIdRewritePath(pathname: string, data: StoreIdData) {
    const path = pathname.startsWith('/') ? pathname : '/' + pathname;

    return `/${STORE_ID_PREFIX}${data.storeId}${path}`;
  }
}

export const storeIdPlugin = new StoreIdPlugin();
