//global
import React, { useContext, useEffect, useRef, useState } from 'react';

//lib
import { ComponentProps } from 'lib/component-props';
//helper
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
//local
import { PSP } from 'models/PetSuppliesPlus.Model';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import withPersonalization from 'src/helpers/withPersonalization/withPersonalization';
import { useBreakpoints } from 'src/utils/breakpoints';
import { useOcSelector } from 'src/redux/ocStore';
import {
  formatDateForGTM,
  Items,
  notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'src/helpers/Constants';
import { GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { getAppRoot } from 'lib/edge-runtime/graphql/get-app-root';
import { searchLandingPageBannerVariants } from 'tailwindVariants/components/searchLandingPageBannerTailwindVariant';
import { useSearchParams } from 'next/navigation';
import { findMatchingItem, getMatchData } from 'lib/utils/string-utils';
import ComponentContext from 'lib/context/ComponentContext';
import graphqlClientFactory from 'lib/graphql-client-factory';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';

//type
type gtmType = { [key: string]: string | number }[];

export type Fields =
  PSP.Sitecore.templates.PetSuppliesPlus.LandingPageBanner.Fields.LandingPageBanner &
    Pick<ComponentProps, 'rendering'> & {
      myStoreId?: string;
      gtmPromotion?: gtmType;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params?: any;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      landingPageBannerSetting?: any;
    };
export type SearchLandingPageBannerProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.LandingPageBanner.Fields.LandingPageBanner & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    landingPageBannerSetting?: any;
  };

//Default Banner
const DefaultBanner: React.FC<Fields> = ({
  // fields,
  params,
  rendering,
  // myStoreId,
  // gtmPromotion,
  landingPageBannerSetting,
}) => {
  const isEE = useExperienceEditor();

  const { base } = searchLandingPageBannerVariants({
    device: { initial: 'mobile', lg: 'desktop' },
    padding: params?.FieldNames == 'NoTopPadding' ? 'noPaddingTop' : 'default',
  });

  const { bannerBase, contentWrapper, title, linkWrapper, deskImg, mobImg, icon } =
    searchLandingPageBannerVariants({
      device: { initial: 'mobile', lg: 'desktop' },
    });

  const searchParams = useSearchParams();
  const query = searchParams.get('q') || searchParams.get('query') || '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [newSearchLandingPageBanner, setNewSearchLandingPageBanner] = useState<any>();

  useEffect(() => {
    const findMatch = async () => {
      if (query) {
        const matchedItem = await findMatchingItem(
          query,
          landingPageBannerSetting?.field?.targetItems || [],
          getMatchData
        );

        if (matchedItem) {
          setNewSearchLandingPageBanner(matchedItem?.landingPageDatasource?.targetItem);
        } else {
          setNewSearchLandingPageBanner(null);
        }
      }
    };
    findMatch();
  }, [query]);

  const { deviceName }: { deviceName: string } = useBreakpoints();
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const gtmPromotion: gtmType = [
    {
      promotion_id: notAvailableIfNullOrEmpty(newSearchLandingPageBanner?.promotionId?.value),
      promotion_name: notAvailableIfNullOrEmpty(newSearchLandingPageBanner?.promotionName?.value),
      creative_name: notAvailableIfNullOrEmpty(newSearchLandingPageBanner?.componentName?.value),
      creative_slot: Number(newSearchLandingPageBanner?.creativeSlotNumber?.value),
      promotion_device: deviceName,
      promotion_copy: notAvailableIfNullOrEmpty(newSearchLandingPageBanner?.promotionCopy?.value),
      promotion_dates: notAvailableIfNullOrEmpty(
        formatDateForGTM(
          `${newSearchLandingPageBanner?.promotionDateFROM?.value} - ${newSearchLandingPageBanner?.promotionDateTO?.value}`
        )
      ),
      promotion_cta: notAvailableIfNullOrEmpty(newSearchLandingPageBanner?.cTAButtonCopy?.value),
      promotion_url: notAvailableIfNullOrEmpty(
        newSearchLandingPageBanner?.promotionURL?.value?.href
      ),
    },
  ];

  const componentRef = useRef(null);
  const { componentContextData, setcomponentContextData } = useContext(ComponentContext);

  useEffect(() => {
    // Add this instance's data to the context
    setcomponentContextData((prevData) => ({
      ...prevData,
      ...(newSearchLandingPageBanner && {
        SearchLandingPageBanner: [
          ...(prevData?.SearchLandingPageBanner || []),
          { uid: rendering?.uid, items: newSearchLandingPageBanner && newSearchLandingPageBanner },
        ],
      }),
    }));

    // Cleanup function to remove this instance when unmounting
    return () => {
      setcomponentContextData((prevData) => ({
        ...prevData,
        SearchLandingPageBanner: prevData?.SearchLandingPageBanner?.filter(
          (item: Fields) => item?.rendering?.uid !== prevData?.SearchLandingPageBanner?.uid
        ),
      }));
    };
  }, [newSearchLandingPageBanner]);

  useEffect(() => {
    const searchLandingPageBannernstances = componentContextData?.SearchLandingPageBanner || [];

    // Check if this is the last rendered instance

    if (componentRef.current && searchLandingPageBannernstances.length >= 1) {
      const allInstances = document.querySelectorAll('.SearchLandingPageBanner');
      if (componentRef.current === allInstances[allInstances.length - 1]) {
        // This is the last instance, combine data here
        const combinedData = searchLandingPageBannernstances?.flatMap(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (instance: any) => instance.items && instance.items
        );
        combinedData && sendGTMData(combinedData, deviceName, myStoreId, GTM_EVENT?.viewPromotion);
      }
    }
  }, [componentContextData.SearchLandingPageBanner]);

  const { isMobile } = useBreakpoints();

  const isAllFieldsEmpty = () => {
    if (isMobile) {
      if (
        newSearchLandingPageBanner?.heading?.jsonValue?.value ||
        (newSearchLandingPageBanner?.headingLink?.jsonValue?.value?.href &&
          newSearchLandingPageBanner?.headingLink?.jsonValue?.value?.text) ||
        newSearchLandingPageBanner?.contentLink?.jsonValue?.value?.href ||
        newSearchLandingPageBanner?.mobileImage?.jsonValue?.value?.src
      ) {
        return false;
      }
    } else {
      if (
        newSearchLandingPageBanner?.heading?.jsonValue?.value ||
        (newSearchLandingPageBanner?.headingLink?.jsonValue?.value?.href &&
          newSearchLandingPageBanner?.headingLink?.jsonValue?.value?.text) ||
        newSearchLandingPageBanner?.contentLink?.jsonValue?.value?.href ||
        newSearchLandingPageBanner?.desktopImage?.jsonValue?.value?.src
      ) {
        return false;
      }
    }
    return true;
  };

  //if no data then returned empty fragment
  if (
    newSearchLandingPageBanner === undefined ||
    newSearchLandingPageBanner === null ||
    isAllFieldsEmpty()
  )
    return <></>;
  return (
    <div
      ref={componentRef}
      //   //   // {/* //TODO: Add personalization here Krushna */}
      data-component={'components/SearchLandingPageBanner/SearchLandingPageBanner'}
      data-creative-id={newSearchLandingPageBanner?.componentName?.value}
      data-promotion-cta={newSearchLandingPageBanner?.cTAButtonCopy?.value}
      data-promo-id={newSearchLandingPageBanner?.promotionId?.value}
      data-promotion-name={newSearchLandingPageBanner?.promotionName?.value}
      data-promotion-copy={newSearchLandingPageBanner?.promotionCopy?.value}
      dta-promotion-url={newSearchLandingPageBanner?.promotionURL?.value?.href}
      className={
        ' ' +
        base({
          className: `${
            landingPageBannerSetting?.isFullWidth?.jsonValue?.value ? '' : 'container mx-auto'
          } ${newSearchLandingPageBanner ? '' : '!p-0'}`,
        })
      }
    >
      <div className={bannerBase()}>
        {isEE ? (
          <div className={contentWrapper()}>
            {newSearchLandingPageBanner?.heading?.jsonValue && (
              <RichTextHelper
                field={newSearchLandingPageBanner?.heading?.jsonValue}
                className={title()}
              />
            )}
            {newSearchLandingPageBanner?.headingLink?.jsonValue && (
              <LinkHelper
                field={newSearchLandingPageBanner?.headingLink?.jsonValue}
                className={linkWrapper()}
              >
                <>
                  <IconHelper icon="chevron-right" className={icon()} />
                </>
              </LinkHelper>
            )}
          </div>
        ) : (newSearchLandingPageBanner?.heading?.jsonValue?.value &&
            newSearchLandingPageBanner?.heading?.jsonValue?.value?.length > 0) ||
          (newSearchLandingPageBanner?.headingLink?.jsonValue?.value?.href &&
            newSearchLandingPageBanner?.headingLink?.jsonValue?.value?.href?.length > 0) ? (
          <div className={contentWrapper()}>
            {newSearchLandingPageBanner?.heading?.jsonValue?.value && (
              <RichTextHelper
                field={newSearchLandingPageBanner?.heading?.jsonValue}
                className={title()}
              />
            )}
            {(newSearchLandingPageBanner?.headingLink?.jsonValue?.value?.href ||
              newSearchLandingPageBanner?.headingLink?.jsonValue?.value?.text) && (
              <LinkHelper
                field={newSearchLandingPageBanner?.headingLink?.jsonValue}
                className={linkWrapper()}
              >
                <>
                  {!isEE && newSearchLandingPageBanner?.headingLink?.jsonValue?.value?.text && (
                    <span>{newSearchLandingPageBanner?.headingLink?.jsonValue?.value?.text}</span>
                  )}
                  {newSearchLandingPageBanner?.headingLink?.jsonValue?.value?.text && (
                    <IconHelper icon="chevron-right" className={icon()} />
                  )}
                </>
              </LinkHelper>
            )}
          </div>
        ) : (
          ''
        )}

        {newSearchLandingPageBanner?.contentLink?.jsonValue && (
          <LinkHelper
            field={newSearchLandingPageBanner?.contentLink?.jsonValue}
            aria-label="offer-link"
            onClick={() =>
              myStoreId &&
              newSearchLandingPageBanner?.componentName?.value !== '' &&
              trackObjectForPromotion(GTM_EVENT?.selectPromotion, myStoreId, gtmPromotion)
            }
          >
            <>
              {/* {renderImage()} */}
              {isMobile ? (
                <ImageHelper
                  field={newSearchLandingPageBanner?.mobileImage?.jsonValue}
                  className={mobImg()}
                />
              ) : (
                <ImageHelper
                  field={newSearchLandingPageBanner?.desktopImage?.jsonValue}
                  className={deskImg()}
                />
              )}
            </>
          </LinkHelper>
        )}
      </div>
    </div>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sendGTMData = (data: any, deviceName: string, storeId: string, event: string) => {
  const gtmPromotion: Items[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?.map((d: any) => {
    d?.promotionName?.value !== '' &&
      gtmPromotion.push({
        promotion_id: notAvailableIfNullOrEmpty(d?.promotionId?.value),
        promotion_name: notAvailableIfNullOrEmpty(d?.promotionName?.value),
        creative_name: notAvailableIfNullOrEmpty(d?.componentName?.value),
        creative_slot: Number(d?.creativeSlotNumber?.value),
        promotion_device: deviceName,
        promotion_copy: notAvailableIfNullOrEmpty(d?.promotionCopy?.value),
        promotion_dates: notAvailableIfNullOrEmpty(
          formatDateForGTM(`${d?.promotionDateFROM?.value} - ${d?.promotionDateTO?.value}`)
        ),
        promotion_cta: notAvailableIfNullOrEmpty(d?.cTAButtonCopy?.value),
        promotion_url: notAvailableIfNullOrEmpty(d?.promotionURL?.value?.href),
      });
  });
  gtmPromotion?.length > 0 && trackObjectForPromotion(event, storeId, gtmPromotion);
};

//main component
const SearchLandingPageBanner: React.FC<SearchLandingPageBannerProps> = ({
  rendering,
  params,
  landingPageBannerSetting,
}): JSX.Element => {
  return (
    <DefaultBanner
      rendering={rendering}
      params={params}
      landingPageBannerSetting={landingPageBannerSetting}
    />
  );
};

export const Default = withPersonalization((props: SearchLandingPageBannerProps): JSX.Element => {
  return <SearchLandingPageBanner {...props} />;
});
export const TopPadding = withPersonalization(
  (props: SearchLandingPageBannerProps): JSX.Element => {
    return <SearchLandingPageBanner {...props} />;
  }
);
export const NoTopPadding = withPersonalization(
  (props: SearchLandingPageBannerProps): JSX.Element => {
    return <SearchLandingPageBanner {...props} />;
  }
);

// For Search Query: ------

const getSiteWisePathNameForQuery = (siteName: string) => {
  return siteName?.toUpperCase() === 'PSP'
    ? '{60EB345F-EEFA-4476-99C1-D37ED2180FE6}'
    : '{253D1CC2-9C2C-4E4A-91D7-986822C9A0D0}';
};

export const getStaticProps: GetStaticComponentProps = async (_rendering, layoutData) => {
  const graphQLClient = graphqlClientFactory({});
  const siteName = layoutData.sitecore.context.site?.name ?? 'psp';
  if (!siteName) {
    console.error('Site name not found');
    return {
      facetSettings: {},
    };
  }
  const language = layoutData.sitecore.context.language ?? 'en';
  if (!language) {
    console.error('language not found');
    return {
      facetSettings: {},
    };
  }
  const appRootId = await getAppRoot(siteName, language);
  if (!appRootId) {
    console.error(`appRootId not found for site: "${siteName}" in language: "${language}"`);
    return {
      facetSettings: {},
    };
  }
  const landingPageBannerSetting = (await searchLandingPageBannerSetting(siteName)) || {};

  return {
    landingPageBannerSetting: landingPageBannerSetting,
  };
  // Function to call GQL visual filter for search query:
  async function searchLandingPageBannerSetting(siteName: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const LandingPageBanner = await graphQLClient.request<any>(
      searchLandingPageBannerQuery?.replace('{SITENAME}', getSiteWisePathNameForQuery(siteName)),
      {
        language: layoutData?.sitecore?.context?.language,
        appRootId,
      }
    );
    const LandingPageBannerResponse = LandingPageBanner?.search?.results[0];
    return LandingPageBannerResponse;
  }
};

const searchLandingPageBannerQuery = `{
  search(
    where: {
      AND: [
        { name: "_path", value: "{SITENAME}" }
        { name: "_templates", value: "{83B36151-F9B9-46AA-90FF-D542C775A544}" }
      ]
    }
  ) {
    results {
      path
      field(name: "searchLandingPageBannerList") {
        ... on MultilistField {
          targetItems {
          ... on LandingPageBannerMapItem {
            exclusionMatch{
              value
            }
            exclusions{
              value
            }
            searchPhrase{
              value
            }
            wordMatch{
              value
            }
            landingPageDatasource {
              value
           
              targetItem {
                  desktopImage:  field(name:"desktopImage")
                {
                  jsonValue
                }
                heading: field(name:"heading")
                {
                  jsonValue
                }
               headingLink :  field(name:"headingLink")
                {
                  jsonValue
                }
                 mobileImage :  field(name:"mobileImage")
                {
                  jsonValue
                }
                  isFullWidth :  field(name:"isFullWidth")
                {
                  jsonValue
                }
              contentLink:  field(name:"contentLink")
                {
                  jsonValue
                }
                promotionId:  field(name:"promotionId")
                {
                  value
                }
                componentName:  field(name:"componentName")
                {
                  value
                }
                promotionName:  field(name:"promotionName")
                {
                  value
                }
                promotionCopy:  field(name:"promotionCopy")
                {
                  value
                }
                promotionDateFROM:  field(name:"promotionDateFROM")
                {
                  value
                }
                promotionDateTO:  field(name:"promotionDateTO")
                {
                  value
                }
                cTAButtonCopy:  field(name:"cTAButtonCopy")
                {
                  value
                }
                promotionURL:  field(name:"promotionURL")
                {
                  value
                }
                creativeSlotNumber:  field(name:"creativeSlotNumber")
                {
                  value
                }
              }
            }
          }
        }
      }
    }
  }
  }
}`;
