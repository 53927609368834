import { tv } from 'tailwind-variants';

export const servicesStoreDetailTailwindVariants = tv(
  {
    slots: {
      base: 'container',
      linkWrapper: 'w-fit',
      link: 'w-fit font-primary text-body-large-bold font-body-large-bold leading-body-large-bold text-color-text-brand-1 hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base',
      headingWrapper: '',
      serviceTitle:
        'font-primary text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold',
      serviceWrapper: 'flex flex-col',
      servicesWrapper: 'bg-color-background-light',
      serviceDetailWrapper: 'flex flex-col',
      serviceDescription:
        'font-primary text-body-large-regular leading-body-large-regular font-body-large-regular',
      heading:
        'text-heading-desk-xLarge-bold font-heading-desk-xLarge-bold leading-heading-desk-xLarge-bold font-primary',
    },
    variants: {
      size: {
        desktop: {
          serviceDetailWrapper: 'gap-desk-margin-tight-bottom pb-mob-margin-micro-bottom',
          serviceWrapper: 'px-desk-padding-micro-x gap-desk-margin-tight-bottom',
          servicesWrapper:
            'grid grid-cols-3  gap-x-6 py-desk-padding-tight-y px-desk-padding-tight-x gap-y-desk-margin-loose-bottom gap-x-6',
          headingWrapper:
            'bg-color-background-light pt-desk-padding-tight-y px-desk-global-grid-margin',
          heading: 'px-0',
        },
        mobile: {
          serviceWrapper: 'pl-mob-padding-micro-x w-full gap-mob-margin-tight-bottom',
          serviceDetailWrapper: 'gap-mob-margin-tight-bottom pb-mob-margin-micro-bottom',
          servicesWrapper:
            'flex flex-col py-mob-padding-tight-y px-mob-global-grid-margin gap-mob-margin-loose-bottom',
          headingWrapper: 'pt-mob-padding-tight-y',
          heading: 'px-mob-global-grid-margin',
        },
      },
      theme: {
        psp: {
          servicesWrapper:
            '[&_img]:rounded-mob-global-radius-pill lg:[&_img]:rounded-desk-global-radius-pill',
        },
        wnw: {},
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
