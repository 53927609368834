import { OrderWithXp, LineItemWithXp } from 'src/redux/xp';

export function hasValidPickupAddress(cart?: OrderWithXp) {
  const pickupInformation = cart?.xp?.PickupInfo;
  const hasPickupInformation = !!(
    pickupInformation &&
    pickupInformation?.FirstName &&
    pickupInformation?.LastName &&
    pickupInformation?.Email &&
    pickupInformation?.StoreId
  );
  return hasPickupInformation;
}

export function hasValidBillingAddress(cart?: OrderWithXp) {
  const billingAdd = cart?.BillingAddress;
  return (
    billingAdd?.FirstName &&
    billingAdd?.LastName &&
    billingAdd?.Street1 &&
    billingAdd?.City &&
    billingAdd?.State
  );
}

export function hasValidDeliveryAddress(productlineitems?: LineItemWithXp[]) {
  const billingAdd = productlineitems?.[0]?.ShippingAddress;
  return (
    billingAdd?.FirstName &&
    billingAdd?.LastName &&
    billingAdd?.Street1 &&
    billingAdd?.City &&
    billingAdd?.State
  );
}
