import { tv } from 'tailwind-variants';

export const newsletterTailwindVariant = tv(
  {
    slots: {
      base: 'NewsLetter flex flex-col items-center text-color-text-white justify-items-center	center',
      title: 'font-primary text-white text-center self-stretch font-bold',
      wrapper: 'flex flex-row w-full justify-center',
      input:
        'flex items-center gap-0.5 flex-grow-1 flex-shrink-0 flex-basis-0 text-color-text-dark text-left border-2 border-transparent placeholder:text-color-text-dark placeholder:font-bold focus:placeholder:opacity-0',
      button: 'bg-color-brand-primary-1-base font-bold',
      loaderButton: '',
    },
    compoundSlots: [
      {
        slots: ['button', 'title', 'input'],
        class: ['font-primary'],
      },
      {
        slots: ['button'],
        class: ['hover:bg-color-brand-primary-1-dark focus:bg-color-brand-primary-1-dark'],
      },
      {
        slots: ['input'],
        class: ['focus:border-2 focus:border-solid focus:border-color-border-brand'],
      },
      {
        slots: ['input', 'button'],
        class: ['outline-none'],
      },
      {
        slots: ['button', 'loaderButton'],
        class: ['inline-flex justify-center items-center'],
      },
    ],

    variants: {
      color: {
        true: {
          base: 'bg-color-background-brand-4',
          loaderButton: 'bg-color-background-brand-4',
        },
        false: {
          base: 'bg-color-scale-8-dark',
          loaderButton: 'bg-color-scale-8-dark',
        },
      },
      size: {
        small: {
          base: 'px-mob-global-grid-margin py-mob-padding-base-y w-full font-xl [&>form]:w-full gap-mob-margin-base-bottom',
          title: 'text-heading-mob-large-bold leading-heading-mob-large-bold w-[100%]',
          input: 'py-mob-padding-tight-y px-mob-padding-micro-x w-4/5 w-full',
          button: 'py-mob-padding-tight-y px-mob-padding-micro-x w-20 w-1/5 w-full',
          wrapper: ['flex-col', 'gap-mob-margin-base-bottom'],
        },
        large: {
          base: 'px-desk-global-grid-margin py-desk-padding-tight-y w-full font-2xl justify-items-center [&>form]:w-fit gap-desk-margin-base-bottom',
          title: 'text-heading-desk-large-bold leading-heading-desk-large-bold',
          input: 'w-[320px] py-desk-padding-micro-y px-mob-padding-micro-x',
          button: 'py-desk-padding-micro-y px-desk-padding-micro-x w-fit',
          wrapper: ['flex-row', 'gap-[0px]'],
          loaderButton: ['pl-4', 'w-[72px]'],
        },
      },
      activeLoader: {
        true: {
          button: [],
        },
        false: {
          button: [],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'], // `true` to apply to all screen sizes
  }
);
