import { tv } from 'tailwind-variants';

export const checkoutHeaderTailwindVariant = tv(
  {
    slots: {
      base: ['bg-color-brand-primary-1-base'],
      linkwrapper: [
        'flex items-center gap-desk-space-between-micro-horizontal hover:no-underline text-heading-desk-medium-bold font-heading-desk-medium-bold',
      ],
      mobimagewrapper: ['lg:hidden w-full'],
      deskimagewrapper: ['lg:flex hidden w-full'],
      iconWrapper: ['w-4'],
      divWrapper: ['gap-desk-space-between-micro-horizontal items-center w-max'],
    },
    compoundSlots: [
      {
        slots: ['mobimagewrapper', 'deskimagewrapper', 'base'],
        class: ['flex justify-center items-center text-color-text-white col-span-4'],
      },
      {
        slots: ['divWrapper', 'base', 'iconWrapper'],
        class: ['flex'],
      },
    ],
    variants: {
      device: {
        mobile: {
          base: [
            'flex-wrap-reverse',
            'flex',
            'py-mob-padding-micro-y',
            'px-mob-global-grid-margin',
            'gap-mob-space-between-base-vertical',
          ],
        },
        desktop: {
          base: [
            'flex-nowrap',
            'grid',
            'grid-cols-6',
            'px-desk-global-grid-margin',
            'py-desk-padding-micro-y',
          ],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
