import { tv } from 'tailwind-variants';
export const bVPLPRatingTailwindVariant = tv(
  {
    slots: {
      base: ['w-full', 'flex'],
    },

    variants: {
      device: {
        mobile: {
          base: [''],
        },
        desktop: {
          base: [''],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
