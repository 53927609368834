import { tv } from 'tailwind-variants';

export const availableOffersTailwindVariant = tv(
  {
    slots: {
      base: ['flex flex-col gap-desk-padding-micro-bottom'],
      productGrid: [
        'loyalty-product-grid grid pt-desk-padding-micro-bottom gap-desk-space-between-tight-horizontal border-t border-color-color-border-mid',
      ],
      productItem: [
        'lp-available-product flex flex-col justify-between py-desk-padding-micro-y px-desk-global-spacing-spacing-3 rounded-lg bg-white hover:cursor-pointer',
      ],
      productImage: [
        'flex justify-center px-desk-padding-tight-x mb-desk-global-spacing-spacing-5',
      ],
      productShortProgramName: [
        'text-color-background-brand-1 text-heading-mob-small-bold font-heading-mob-small-bold leading-heading-mob-small-bold',
      ],
      productShortProgramNameWrapper: ['mb-desk-padding-micro-bottom'],
      productShortDescription: [
        'text-heading-mob-small-regular font-heading-mob-small-regular leading-heading-mob-small-regular',
      ],
      productExpiryDataWrapper: ['mb-desk-padding-micro-bottom'],
      shopNowButton: [
        'flex w-full justify-center !py-desk-component-button-condensed-padding-y mt-desk-global-spacing-spacing-5',
      ],
      loadMoreButtonWrapper: ['flex justify-center '],
      loadMoreButton: [
        'flex w-[188px] justify-center !py-desk-component-button-condensed-padding-y mt-desk-global-spacing-spacing-5',
      ],
      modalProgramStyles: ['my-1'],
      modalProgramName: [
        'text-heading-mob-small-bold font-heading-mob-small-bold leading-heading-mob-small-bold',
      ],
      modalProgramDescription: [
        'text-body-large-regular font-body-large-regular leading-body-large-regular',
      ],
      productNoExpiryDateText: [
        'text-color-text-dark text-heading-desk-large-bold font-heading-desk-large-bold leading-heading-desk-large-bold py-3',
      ],
      termsAndConditionLabelStyle: [
        'font-bold text-color-scale-5-mid',
        'mb-desk-margin-base-bottom',
      ],
      termConditionStyles: [
        'border-y border-color-scale-3-light-mid border-1 py-desk-padding-micro-y mb-3',
      ],
      termsAndConditionTextStyle: ['text-color-scale-5-mid'],
      loadMoreButtonLoadingMore: ['flex items-center gap-4 mt-4'],
    },
    variants: {
      device: {
        mobile: {
          base: [''],
          productGrid: ['grid-cols-1'],
        },
        desktop: {
          base: [''],
          productGrid: ['grid-cols-4'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
