import { tv } from 'tailwind-variants';

export const positionGridAndGridItemTailwindVariant = tv(
  {
    slots: {
      base: ['positionsGrid', 'container', 'mx-auto'],
      contentWrapper: [''],
      contentGap: [''],
      title: [''],
      description: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      ctaWrapper: [
        'flex',
        'w-full',
        'justify-center',
        'items-center',
        '[&>a]:hover:text-color-brand-tertiary-3-base',
        '[&>span]:hover:text-color-brand-tertiary-3-base',
        '[&>span>svg>*]:hover:fill-color-brand-tertiary-3-base',
      ],
      icon: [
        '[&>svg>*]:fill-color-text-brand-1',
        '[&>svg]:h-4',
        '[&>svg]:w-4',
        'cursor-pointer',
        '[&>*>*]:fill-color-text-brand-1]',
        'ml-mob-space-between-micro-horizontal',
      ],
      cardImage: ['h-full', 'w-full', 'object-cover', 'absolute', 'top-0', 'left-0'],
      cardTitle: [
        'text-heading-desk-medium-bold',
        'font-heading-desk-medium-bold',
        'leading-heading-desk-medium-bold',
      ],
      learnMoreCTA: [
        '!text-heading-desk-large-bold',
        '!font-heading-desk-large-bold',
        '!leading-heading-desk-large-bold',
      ],
      cardWrapper: [],
      darkBg: [
        'transition-all',
        'after:duration-300',
        'after:hover:opacity-80',
        'after:absolute',
        'after:bg-black',
        'after:top-0',
        'after:left-0',
        'after:opacity-50',
        'after:w-full',
        'after:h-full',
      ],
      card: ['group', 'flex', 'flex-col', 'justify-center', 'items-center', 'relative'],
      cardContentWrapper: [
        'z-[1]',
        'text-white',
        'flex',
        'flex-col',
        'items-center',
        'gap-desk-global-spacing-spacing-2',
      ],
      closeIcon: [
        'iconWrapper [&>svg>*>*]:fill-color-text-brand-1 [&>*>*>*]:fill-color-text-brand-1 [&>svg]:w-8 [&>svg]:flex [&>svg]:h-8',
      ],
      cardButton: ['w-fit'],
      modalTitle: ['px-2', 'flex', 'justify-center'],
      modalWrapper: ['flex'],
      modalImg: ['w-full'],
      modalImgWrapper: ['w-full', 'bg-color-scale-6-mid'],
      modalContentWrapper: [''],
      modalTopContent: ['px-mob-padding-micro-x', 'bg-color-background-light'],
      modalBottomContent: ['px-mob-padding-micro-x'],
      topContent: ['gap-desk-padding-micro-bottom'],
      modalBtn: ['text-center'],
      colSpan: ['col-span-2'],
      rowSpan: [''],
    },
    compoundSlots: [
      {
        slots: ['modalBottomContent', 'modalContentWrapper', 'modalTopContent', 'topContent'],
        class: ['flex', 'flex-col'],
      },
      {
        slots: ['contentWrapper', 'contentGap'],
        class: ['flex', 'flex-col', 'text-center'],
      },
    ],
    variants: {
      variant: {
        threeInARow: { cardWrapper: ['flex flex-col lg:grid lg:grid-cols-3'] },
        sixGrid: { cardWrapper: ['flex flex-col lg:grid lg:grid-cols-6'] },
        twelveGrid: { cardWrapper: ['flex flex-col lg:grid lg:grid-cols-6'] },
      },
      size: {
        mobile: {
          base: [
            'pb-mob-padding-tight-bottom',
            'px-mob-padding-micro-x',
            'gap-mob-global-spacing-spacing-2',
          ],
          title: [
            'text-heading-mob-xLarge-bold',
            'font-heading-mob-xLarge-bold',
            'leading-heading-mob-xLarge-bold',
          ],
          card: [
            'min-h-[200px]',
            'py-mob-padding-micro-y',
            'px-mob-padding-micro-x',
            'gap-mob-global-spacing-spacing-2',
          ],
          cardWrapper: ['gap-mob-global-spacing-spacing-3', 'mt-mob-global-spacing-spacing-4'],
          contentWrapper: [
            'gap-mob-margin-base-bottom',
            'py-mob-padding-tight-y',
            'px-mob-global-grid-margin',
          ],
          contentGap: ['gap-mob-global-spacing-spacing-2'],
          modalTitle: ['py-mob-padding-micro-y'],
          modalWrapper: ['flex-col'],
          modalContentWrapper: ['gap-mob-margin-base-bottom'],
          modalTopContent: ['gap-mob-margin-base-bottom', 'py-mob-padding-micro-y'],
          modalBottomContent: ['gap-mob-margin-base-bottom', 'py-mob-padding-micro-y'],
        },
        desktop: {
          base: [
            'pb-desk-padding-tight-bottom',
            'px-0',
            'gap-desk-global-spacing-spacing-4',
            'px-desk-padding-micro-x',
          ],
          title: [
            'text-heading-desk-xLarge-bold',
            'font-heading-desk-xLarge-bold',
            'leading-heading-desk-xLarge-bold',
          ],
          card: [
            'min-h-[160px]',
            'py-desk-padding-micro-y',
            'px-desk-padding-micro-x',
            'gap-desk-global-spacing-spacing-2',
          ],
          cardWrapper: ['gap-mob-global-spacing-spacing-4', 'mt-desk-global-spacing-spacing-4'],
          contentWrapper: [
            'gap-desk-margin-base-bottom',
            'py-desk-padding-tight-y',
            'px-desk-global-grid-margin',
          ],
          contentGap: ['gap-desk-global-spacing-spacing-3'],
          cardButton: [
            'hidden',
            'opacity-0',
            'group-hover:block',
            'group-hover:opacity-100',
            'transition-all',
            'duraiton-700',
            'ease-in-out',
          ],
          modalTitle: ['py-desk-padding-micro-y'],
          modalWrapper: ['flex-row'],
          modalImg: ['h-full', 'object-cover'],
          modalImgWrapper: ['max-w-[348px]'],
          modalContentWrapper: ['gap-desk-margin-base-bottom'],
          modalBottomContent: ['gap-desk-margin-base-bottom', 'pt-desk-padding-micro-y'],
          modalTopContent: ['gap-desk-margin-base-bottom', 'pb-desk-padding-micro-y'],
          modalBtn: ['w-fit'],
          colSpan: ['max-w-[408px] max-h-[160px] '],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
