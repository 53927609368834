import { tv } from 'tailwind-variants';

//component variants
export const multiColumnContainerTailwindVariant = tv(
  {
    slots: {
      base: ['multiColumnContainer'],
      wrapper: ['container mx-auto'],
      wrapperSub: ['grid gap-desk-global-grid-gutter'],
    },
    compoundSlots: [{ slots: [], class: [] }],
    variants: {
      size: {
        mobile: {
          base: ['py-mob-padding-tight-y'],
        },
        desktop: {
          base: ['py-desk-padding-tight-y'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
