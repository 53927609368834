import { tv } from 'tailwind-variants';

export const contactUsFormTailwindVariant = tv(
  {
    slots: {
      formWrapper: ['contactUsForm', 'flex', 'flex-col', 'items-center', 'gap-[8px]'],
      formContainer: ['container', 'max-w-[800px]', 'contact-us'],
      form: [
        'bg-color-background-light',
        'flex',
        'flex-col',
        'gap-desk-space-between-base-vertical',
      ],
      fieldWrap: ['flex', 'justify-between', 'w-full'],
      cta: [
        'absolute bg-color-scale-1-white',
        'p-[15px]',
        'border-[2px]',
        'border-color-scale-3-light-mid',
        'flex',
        'align-center',
        'justify-center',
        'flex-col',
        'rounded-r-[4px]',
        'hover:no-underline',
        'hover:bg-color-scale-3-light-mid',
      ],
      contentTitleSection: ['flex', 'flex-col', 'items-center', 'gap-[12px]', 'w-full'],
      title: ['text-center'],
      shortDescription: [
        'text-center',
        'text-color-text-dark',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      submitBtn: [
        'bg-color-brand-primary-1-base',
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
        'text-color-text-white',
        'rounded-mob-global-radius-small',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
      ],
    },
    variants: {
      device: {
        mobile: {
          formWrapper: [
            'pt-mob-padding-none',
            'px-mob-global-grid-margin',
            'pb-mob-margin-loose-bottom',
          ],
          formContainer: ['px-0'],
          form: ['py-mob-padding-tight-y', 'px-mob-padding-tight-x'],
          fieldWrap: ['gap-mob-space-between-loose-horizontal', 'flex-col'],
          cta: [
            'bottom-0 right-0',
            'text-body-xSmall-bold font-body-xSmall-bold leading-body-xSmall-bold',
          ],
          contentTitleSection: ['pt-mob-padding-tight-y', 'pb-mob-padding-micro-y'],
          title: [
            'text-heading-mob-xLarge-bold',
            'font-heading-mob-xLarge-bold',
            'leading-heading-mob-xLarge-bold',
          ],
          submitBtn: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'rounded-desk-global-radius-small',
          ],
        },
        desktop: {
          formWrapper: [
            'pt-desk-padding-none',
            'px-desk-global-grid-margin',
            'pb-desk-margin-loose-bottom',
          ],
          formContainer: ['px-inherit'],
          form: ['py-desk-padding-tight-y', 'px-desk-padding-tight-x'],
          fieldWrap: ['gap-x-desk-space-between-loose-horizontal', 'flex-row', 'gap-none'],
          cta: ['text-body-small-bold font-body-small-bold leading-body-small-bold'],
          contentTitleSection: ['pt-desk-padding-tight-y', 'pb-desk-padding-micro-y'],
          title: [
            'text-heading-desk-xLarge-bold',
            'font-heading-desk-xLarge-bold',
            'leading-heading-desk-xLarge-bold',
          ],
          submitBtn: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'hover:bg-color-brand-primary-1-dark',
          ],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
