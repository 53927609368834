import { tv } from 'tailwind-variants';

export const anchorNavigationTailwindVariant = tv(
  {
    slots: {
      base: 'w-full ',
      wrapper: 'container mx-auto flex flex-col gap-desk-space-between-tight-horizontal',
      linkWrapper: ' justify-center flex flex-wrap',
      linkLi: 'flex justify-center hover:underline decoration-color-text-brand-1',
    },
    variants: {
      size: {
        mobile: {
          base: 'py-mob-padding-micro-y',
          linkWrapper: 'gap-mob-space-between-base-horizontal',
          linkLi: '',
        },
        desktop: {
          base: 'py-desk-padding-micro-y ',
          linkWrapper: 'gap-desk-space-between-base-horizontal',
          linkLi: '',
        },
      },
    },
  },
  {
    responsiveVariants: ['sm', 'lg'],
  }
);
