import clsx from 'clsx';
import { ProductOrMarketingTileResults } from '../SearchResults';
import searchResultsTailwind from '../SearchResults/SearchResultsTailwind';
import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import { useEffect } from 'react';
import {
  formatDateForGTM,
  notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'src/helpers/Constants';
import { useOcSelector } from 'src/redux/ocStore';
import { useBreakpoints } from 'src/utils/breakpoints';
import Link from 'next/link';

export interface MarketingTileSetting {
  rfkid: string;
  columnSpan: number;
  isMobile: boolean;
}

export interface MarketingTileData {
  columnPosition?: Field<number>;
  columnSpan?: Field<number>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  link?: any;
  rowReplacement?: Field<number>;
  text?: Field<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tileImage?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mobileTileImage?: any;
  mobileRowReplacement?: Field<number>;
}

interface MarketingTileProps {
  isMobile: boolean;
  marketingTileData?: MarketingTileData & {
    componentName?: Field<string>;
    creativeSlotNumber?: Field<string>;
    cTAButtonCopy?: Field<string>;
    promotionCopy?: Field<string>;
    promotionDateFROM?: Field<string>;
    promotionDateTO?: Field<string>;
    promotionId?: Field<string>;
    promotionName?: Field<string>;
    promotionURL?: LinkField;
  };
}
export const MarketingTile = ({ marketingTileData, isMobile }: MarketingTileProps): JSX.Element => {
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const { deviceName }: { deviceName: string } = useBreakpoints();
  const tileData = marketingTileData;
  const columnSpan = Number(tileData?.columnSpan?.value ?? 0);
  const { sitecoreMarketingTile } = searchResultsTailwind({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  const className = clsx(
    {
      'block sm:hidden col-span-full': isMobile,
      'hidden sm:block': !isMobile,
    },
    {
      'col-span-4': columnSpan === 4,
      'col-span-3': columnSpan === 3,
      'col-span-2': columnSpan === 2,
      'col-span-1': columnSpan === 1,
      hidden: columnSpan === 0,
    },
    sitecoreMarketingTile()
  );

  const gtmPromotion = [
    {
      promotion_id: notAvailableIfNullOrEmpty(marketingTileData?.promotionId?.value),
      promotion_name: notAvailableIfNullOrEmpty(marketingTileData?.promotionName?.value),
      creative_name: notAvailableIfNullOrEmpty(marketingTileData?.componentName?.value),
      creative_slot: Number(marketingTileData?.creativeSlotNumber?.value),
      promotion_device: deviceName,
      promotion_copy: notAvailableIfNullOrEmpty(marketingTileData?.promotionCopy?.value),
      promotion_dates: notAvailableIfNullOrEmpty(
        formatDateForGTM(
          `${marketingTileData?.promotionDateFROM?.value} - ${marketingTileData?.promotionDateTO?.value}`
        )
      ),
      promotion_cta: notAvailableIfNullOrEmpty(marketingTileData?.cTAButtonCopy?.value),
      promotion_url: notAvailableIfNullOrEmpty(marketingTileData?.promotionURL?.value?.href),
    },
  ];
  useEffect(() => {
    const checkGtmLoad = () => {
      const isEventFired =
        window &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)['dataLayer']?.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (val: any) =>
            val?.ecommerce?.items?.[0]?.creative_name === marketingTileData?.componentName?.value
        )?.length < 1;

      const isGTMLoad =
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof window !== 'undefined' && (window as any)['google_tag_manager']?.dataLayer?.gtmLoad;
      if (myStoreId && isGTMLoad && marketingTileData?.componentName?.value !== '') {
        isEventFired && trackObjectForPromotion(GTM_EVENT?.viewPromotion, myStoreId, gtmPromotion);
      } else {
        setTimeout(() => {
          checkGtmLoad();
        }, 1000);
      }
    };
    checkGtmLoad();
  }, [myStoreId]);

  // Don't render anything if we don't have data
  if (!tileData) {
    return <></>;
  }

  const mobileImageUrl = tileData.mobileTileImage;
  // If we're in mobile, use mobile url, with a fallback to the normal one.  Otherwise use normal url.
  const imageUrl = isMobile && mobileImageUrl ? mobileImageUrl : tileData.tileImage;
  const renderImage = () => (
    <>{imageUrl && <ImageHelper field={imageUrl?.jsonValue ? imageUrl?.jsonValue : imageUrl} />}</>
  );

  const decodedURI = decodeURIComponent(
    tileData?.link?.jsonValue?.value?.href?.toString() || tileData?.link?.value?.href
  );

  return (
    <div
      data-component={'components/PLPMarketingTile/PLPMarketingTile'}
      data-creative-id={marketingTileData?.componentName?.value}
      data-promotion-cta={marketingTileData?.cTAButtonCopy?.value}
      data-promo-id={marketingTileData?.promotionId?.value}
      data-promotion-name={marketingTileData?.promotionName?.value}
      data-promotion-copy={marketingTileData?.promotionCopy?.value}
      dta-promotion-url={marketingTileData?.promotionURL?.value?.href}
      className={className}
    >
      {tileData?.link?.jsonValue || tileData?.link ? (
        <Link
          href={decodedURI}
          onClick={() => {
            myStoreId &&
              marketingTileData?.componentName?.value !== '' &&
              trackObjectForPromotion(GTM_EVENT?.selectPromotion, myStoreId, gtmPromotion);
          }}
        >
          {renderImage()}
        </Link>
      ) : (
        <>{renderImage()}</>
      )}
    </div>
  );
};

const GRID_SIZE = 4;

export function insertMarketingTileInResults(
  productsOrWidgets: ProductOrMarketingTileResults,
  marketingTileData: MarketingTileData | undefined
) {
  // Ensure row is >=1
  const row = Math.max(1, Number(marketingTileData?.rowReplacement?.value ?? 0));
  const rowIndex = row - 1;

  // Ensure col >= 1 and <= GRID_SIZE
  const column = Math.min(
    GRID_SIZE,
    Math.max(1, Number(marketingTileData?.columnPosition?.value ?? 0))
  );
  const colIndex = column - 1;

  // Ensure col >= 1 and <= GRID_SIZE
  const columnSpan = Math.min(
    GRID_SIZE,
    Math.max(1, Number(marketingTileData?.columnSpan?.value ?? 0))
  );

  // If the colspan makes it not fit, adjust column index to fit.
  const realColIndex = Math.min(colIndex, GRID_SIZE - columnSpan);

  // Ensure it's not greater than the result size
  const desktopPosition = Math.min(productsOrWidgets.length, rowIndex * GRID_SIZE + realColIndex);

  // Ensure it's not greater than the result size
  const mobilePosition = Math.min(
    productsOrWidgets.length,
    Number(marketingTileData?.mobileRowReplacement?.value ?? 0)
  );
  if (
    marketingTileData?.tileImage?.jsonValue?.value?.src ||
    marketingTileData?.tileImage?.value?.src
  ) {
    productsOrWidgets.splice(desktopPosition, 0, {
      rfkid: 'rfk_PLPMarketingTile',
      columnSpan,
      isMobile: false,
    });
  }
  if (
    marketingTileData?.mobileTileImage?.jsonValue?.value?.src ||
    marketingTileData?.mobileTileImage?.value?.src
  ) {
    productsOrWidgets.splice(mobilePosition, 0, {
      rfkid: 'rfk_PLPMarketingTile',
      columnSpan,
      isMobile: true,
    });
  }
}
