import { tv } from 'tailwind-variants';

export const previewSearchTailwindVariant = tv(
  {
    slots: {
      baseWrapper: ['flex', 'justify-center', 'bg-color-background-light'],
    },
    variants: {
      size: {
        mobile: {
          baseWrapper: [
            'pt-mob-padding-tight-y',
            'pb-mob-padding-micro-y',
            'px-mob-global-grid-margin',
          ],
        },
        desktop: {
          baseWrapper: [
            'pt-desk-padding-tight-y',
            'pb-desk-padding-micro-y',
            'px-desk-global-grid-margin',
          ],
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
