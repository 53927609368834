import { tv } from 'tailwind-variants';

export const cookiePopupTailwindVariant = tv(
  {
    slots: {
      base: 'cookiePopup z-[951] fixed bg-color-scale-1-white flex flex-col justify-center items-center gap-2 rounded-desk-global-radius-medium shadow-privacyShadow',
      heading: 'w-full h-auto flex flex-row justify-between align-center',
      title: 'font-primary text-color-text-dark',
      closeIcon:
        'cursor-pointer [&_svg]:w-[24px] [&_svg]:h-[24px] [&>svg>*>*]:fill-color-brand-primary-1-base',
      content:
        '[&>*>a]:text-color-brand-primary-1-base [&>a]:text-color-brand-primary-1-base font-primary text-body-small-regular font-body-small-regular leading-body-small-regular',
    },
    variants: {
      size: {
        mobile: {
          base: 'bottom-[88px] left-[8px] right-[8px] w-auto py-mob-padding-micro-y px-mob-padding-micro-x',
          title:
            'text-heading-mob-medium-bold font-heading-mob-medium-bold leading-heading-mob-medium-bold',
        },
        desktop: {
          base: 'bottom-[25px] left-auto right-[25px] w-[350px] py-desk-padding-micro-y px-desk-padding-micro-x',
          title:
            'text-heading-desk-medium-bold font-heading-desk-medium-bold leading-heading-desk-medium-bold',
        },
      },
    },
  },
  {
    responsiveVariants: ['lg'],
  }
);
