import { Field, LinkField, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { useTheme } from 'lib/context/ThemeContext';
import React, { useEffect, useState } from 'react';
import { apiRequest } from 'src/utils/apiWrapper';
import { ComponentProps } from 'lib/component-props';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import clsx from 'clsx';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { useRouter } from 'next/router';
import { ErrorCodes, statusMessage } from 'src/helpers/Constants';
import Loader from 'components/Loader/Loader';
import { accountVerificationTailwindVariant } from 'tailwindVariants/components/accountVerificationTailwindVariant';
import { loginAPI } from 'src/utils/nextApiConfig';

export type AccountVerificationProps = React.InputHTMLAttributes<HTMLInputElement> &
  ComponentProps & {
    fields: {
      success: Field<string>;
      genericError: Field<string>;
      linkExpire: Field<string>;
      ctaText: Field<string>;
      cta: {
        jsonValue: LinkField;
      };
    };
  };

const AccountVerification = ({ fields, params }: AccountVerificationProps): JSX.Element => {
  const { successBase, loaderWrapper, button } = accountVerificationTailwindVariant({
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });
  const { themeNameUpper } = useTheme();
  const [successResponse, setSuccessResponse] = useState(false);
  const token = useAuthToken();
  const [showLoader, setShowLoader] = useState(true);
  const [messageCode, setMessageCode] = useState<string>();
  const router = useRouter();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    // Set the isClient flag to true after the component has mounted
    setIsClient(true);
  }, []);

  //   Function call to verify the user:
  const activateUser = async (queryParams: unknown) => {
    const headersData = {
      site: themeNameUpper,
      requesturl: window.location.origin,
      Authorization: token,
      dataToSend: new URLSearchParams(queryParams as { [key: string]: string }).toString(),
    };

    const options = {
      method: 'GET',
      headers: headersData,
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await apiRequest(loginAPI?.userActivate, options);
      if (response && response === ErrorCodes?.Success) {
        setShowLoader(false);
        setSuccessResponse(true);
        setMessageCode(fields?.success?.value);
      } else if (response && response === ErrorCodes?.LinkExpired) {
        setMessageCode(fields?.linkExpire?.value);
        setShowLoader(false);
        setSuccessResponse(false);
      } else {
        setMessageCode(fields?.genericError?.value);
        setShowLoader(false);
        setSuccessResponse(false);
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  useEffect(() => {
    if (!isClient) return;

    try {
      const dataToSend = router?.query;
      delete dataToSend?.path;
      const queryParams = dataToSend;
      if (dataToSend && dataToSend.e) activateUser(queryParams);
    } catch (error) {
      console.error(error);
    }
  }, [router?.query, isClient]);

  return (
    <div className={'AccountVerification ' + params?.Styles}>
      {showLoader && (
        <div className={loaderWrapper()}>
          <Loader />
          <p>{statusMessage?.inProgress}</p>
        </div>
      )}
      {!showLoader && messageCode && (
        <div className={clsx(successBase(), 'flex-col', 'gap-[12px]')}>
          <RichText className="rte" tag="p" field={{ value: messageCode }} />
          {successResponse && (
            <LinkHelper
              className={button({ className: 'hover:no-underline' })}
              field={{
                value: {
                  href: fields?.cta?.jsonValue?.value.href ?? '/login',
                  text: fields?.ctaText?.value,
                },
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AccountVerification;
