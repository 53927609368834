import { normalizeStoreIdRewrite } from 'lib/middleware/plugins/store-id';
import { Plugin } from '..';

class StoreIdPlugin implements Plugin {
  exec(path: string) {
    // Remove personalize rewrite segment from the path
    return normalizeStoreIdRewrite(path);
  }
}

export const storeIdPlugin = new StoreIdPlugin();
