import config from 'temp/config';
type Site = {
  name: string;
  hostName: string;
  language: string;
};

export const publicURLTrailingSlash = () => {
  const url = process.env.PUBLIC_URL;
  if (url) {
    if (url.endsWith('/')) {
      return url;
    } else {
      return url + '/';
    }
  }

  if (typeof window !== 'undefined') {
    // In browser, don't need public url, can return '/'
    return '/';
  } else {
    if (process.env.VERCEL) {
      return `https://${process.env.VERCEL_URL}/`;
    } else {
      // For build server in case variable isn't set
      return 'http://localhost:3000/';
    }
  }
};

export const extractDomainUrlFromConfig = (siteName: string) => {
  // Parse the JSON string into an array of Site objects
  const parsedSites: Site[] = JSON.parse(config.sites);

  // Find the site object with the matching name
  const site = parsedSites.find((s: Site) => s.name === siteName);

  // If the site is found, split the hostName and return the first domain name
  if (site) {
    return `https://${site.hostName.split('|')[0]}`;
  }

  // If the site is not found, return null
  return null;
};
