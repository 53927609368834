import { TabProps } from './types';
import { faqTabTailwindVariant } from 'tailwindVariants/components/faqTabTailwindVariant';

const Tab = ({ tab, isActive, onClick }: TabProps) => {
  const { tabButton, activeTabButton } = faqTabTailwindVariant({
    size: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  return (
    <div
      className={`${tabButton()} ${isActive ? activeTabButton() : ''}`}
      onClick={() => onClick(tab.id)}
    >
      {tab.title}
    </div>
  );
};

export default Tab;
