// React imports and component dependencies
import React, { useEffect, useState } from 'react';
import { Text, RichText, Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import { PSP } from '../../../models/PetSuppliesPlus.Model';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { useTheme } from 'lib/context/ThemeContext';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { useOcSelector } from 'src/redux/ocStore';
import { getPromotionObject, trackObjectForPromotion } from 'src/utils/sendGTMEvent';
import { GTM_EVENT } from 'src/helpers/Constants';
import { useBreakpoints } from 'src/utils/breakpoints';
import { doubleCalloutBlockTailwindVariant } from 'tailwindVariants/components/doubleCalloutBlockTailwindVariant';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
// Define the properties of a Card in the list
export type CardListProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.DoubleCallout.Fields.DoubleCallout;
export type DoubleCalloutProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.DoubleCallout.Fields.DoubleCalloutList;
export type DoubleCalloutData = DoubleCalloutProps & {
  isPrimary?: boolean;
};
// Define the properties of the CalloutRow component
interface CalloutRowProps {
  fields: {
    heading?: Field<string>;
    subHeading?: Field<string>;
    headingLink?: LinkField;
    cardList?: CardListProps[];
  };
  isPrimary?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promotionObject?: any;
  storeId?: string;
  componentName?: string;
}
// Define the properties of the CalloutBlock component
interface CalloutBlockProps {
  data: CardListProps;
  isPrimary?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promotionObject?: any;
  storeId?: string;
  componentName?: string;
}
interface WrapperContent {
  isPrimary: boolean;
  linkField?: LinkField;
  content: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  promotionObject?: any;
  storeId?: string;
  componentName?: string;
}
// Define tailwind CSS variants for the components

// Component for rendering each row in the Callout component
const CalloutRow: React.FC<CalloutRowProps> = ({
  fields,
  isPrimary = false,
  promotionObject,
  storeId,
  componentName,
}) => {
  // Extract relevant variants
  const { heading, headingLink, colWrapper, description, headingWrapper } =
    doubleCalloutBlockTailwindVariant({
      isPrimary,
    });
  const isEE = useExperienceEditor();
  return (
    <div className={colWrapper()}>
      {isPrimary && (
        <div
          className={headingWrapper({
            showHeading: !!(fields?.heading?.value || fields?.subHeading?.value),
            className: 'self-center',
          })}
        >
          <>
            {isEE && (
              <>
                <Text field={fields.heading} tag="p" className={heading()} />
                {fields.headingLink && (
                  <LinkHelper field={fields.headingLink} className={headingLink()} />
                )}
              </>
            )}
            {!isEE && (
              <>
                {fields?.heading?.value && fields?.heading?.value != '' ? (
                  <Text field={fields.heading} tag="p" className={heading()} />
                ) : (
                  <>
                    {fields.headingLink && (
                      <LinkHelper field={fields.headingLink} className={headingLink()} />
                    )}
                  </>
                )}
              </>
            )}
          </>
          {fields?.subHeading && (
            <RichText tag="p" field={fields.subHeading} className={description()} />
          )}
        </div>
      )}
      {fields?.cardList &&
        fields?.cardList?.length > 0 &&
        fields.cardList.map((card, index) => (
          <CalloutBlock
            key={index}
            data={card}
            isPrimary={isPrimary}
            promotionObject={promotionObject}
            storeId={storeId}
            componentName={componentName}
          />
        ))}
    </div>
  );
};
// Utility component to wrap content with a link if available
const WrapContentWithLink: React.FC<WrapperContent> = ({
  isPrimary,
  linkField,
  content,
  storeId,
  componentName,
  promotionObject,
}) => {
  const { buttonWrapper, noButtonWrapper } = doubleCalloutBlockTailwindVariant({
    isPrimary,
  });

  if (linkField?.value?.href) {
    return (
      <LinkHelper
        field={linkField}
        className={buttonWrapper()}
        hideLinkInEE={true}
        onClick={() => {
          if (componentName !== '') {
            trackObjectForPromotion(GTM_EVENT?.selectPromotion, storeId as string, promotionObject);
          }
        }}
      >
        <>{content}</>
      </LinkHelper>
    );
  }
  return <div className={noButtonWrapper()}>{content}</div>;
};
// Component for rendering each block in the Callout component
const CalloutBlock: React.FC<CalloutBlockProps> = ({
  data,
  isPrimary = false,
  promotionObject,
  storeId,
  componentName,
}) => {
  //Experience Editor
  const isEE = useExperienceEditor();
  // Extract relevant variants
  const { contentWrapper, icon, colHeading, colDescription, link, headingWrapperForCol, linkIcon } =
    doubleCalloutBlockTailwindVariant({
      isPrimary,
    });
  const [linkDetails, setLinkDetails] = useState(data?.fields?.link);
  const storeDetails = useOcSelector((state) => state?.storeReducer?.selectedStore);
  useEffect(() => {
    setLinkDetails(
      data.fields?.link?.value?.text === '{store-phoneNumber}'
        ? {
            value: {
              ...data?.fields?.link?.value,
              text: storeDetails?.phone,
              href: storeDetails?.phone ? `tel:${storeDetails?.phone}` : '',
            },
          }
        : data?.fields?.link
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeDetails]);
  return (
    <div className={headingWrapperForCol()}>
      {data?.fields?.icon?.value?.src &&
        WrapContentWithLink({
          isPrimary,
          promotionObject,
          storeId,
          componentName,
          linkField: linkDetails,
          content: <ImageHelper field={data.fields.icon} className={icon()} />,
        })}
      <div>
        <div className={contentWrapper()}>
          {data?.fields?.heading?.value &&
            WrapContentWithLink({
              isPrimary,
              promotionObject,
              storeId,
              componentName,
              linkField: linkDetails,
              content: <Text tag="p" field={data.fields.heading} className={colHeading()} />,
            })}
          {data?.fields?.subHeading && (
            <RichTextHelper
              tag="p"
              field={data.fields.subHeading}
              {...(data.fields.subHeading?.value?.includes('{opening-hours}') && {
                updatedField: {
                  value: data.fields.subHeading?.value?.replace(
                    '{opening-hours}',
                    storeDetails?.OpeningHours || ''
                  ),
                },
              })}
              className={colDescription()}
            />
          )}
        </div>
        <div>
          {linkDetails &&
            linkDetails?.value?.href &&
            linkDetails?.value?.text &&
            data?.fields?.link && (
              <LinkHelper
                field={isEE ? data?.fields?.link : linkDetails}
                className={link()}
                onClick={() => {
                  if (componentName !== '')
                    trackObjectForPromotion(
                      GTM_EVENT?.selectPromotion,
                      storeId as string,
                      promotionObject
                    );
                }}
              >
                <>
                  {!isEE && <span>{linkDetails?.value?.text}</span>}
                  {data.fields?.link?.value?.text != '{store-phoneNumber}' &&
                    data?.fields?.link?.value?.href &&
                    data?.fields?.link?.value?.href?.length > 0 && (
                      <IconHelper className={linkIcon()} icon={'chevron-right'} />
                    )}
                </>
              </LinkHelper>
            )}
        </div>
      </div>
    </div>
  );
};
// Main DoubleCallout component
const DoubleCallout: React.FC<DoubleCalloutData> = ({ fields, params, isPrimary }) => {
  // Extract relevant variants
  const { themeName } = useTheme();
  const { base, wrapper } = doubleCalloutBlockTailwindVariant({
    isPrimary: isPrimary,
  });
  //Getting current Store ID
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const { deviceName }: { deviceName: string } = useBreakpoints();
  // Promotion GTM data Object
  const gtmPromotion = getPromotionObject(fields, deviceName);
  //Promotion GTM data push on load
  useEffect(() => {
    if (myStoreId && fields?.componentName?.value !== '') {
      trackObjectForPromotion(GTM_EVENT?.viewPromotion, myStoreId, gtmPromotion);
    }
  }, [myStoreId]);
  if (fields === null || fields === undefined) return <></>;
  return (
    <div
      className={`${params?.Styles ?? ''} ${base({
        showBackgroundColor: isPrimary && themeName === 'psp',
      })}`}
      data-component={'components/DoubleCallout/DoubleCallout'}
      data-creative-id={fields?.componentName?.value}
      data-promotion-cta={fields?.cTAButtonCopy?.value}
      data-promo-id={fields?.promotionId?.value}
      data-promotion-name={fields?.promotionName?.value}
      data-promotion-copy={fields?.promotionCopy?.value}
      dta-promotion-url={fields?.promotionURL?.value?.href}
    >
      <div className={wrapper()}>
        <CalloutRow
          fields={fields}
          isPrimary={isPrimary}
          componentName={fields?.componentName?.value}
          promotionObject={gtmPromotion}
          storeId={myStoreId}
        />
      </div>
    </div>
  );
};

export const Default = (props: DoubleCalloutProps): JSX.Element => {
  return <DoubleCallout {...props} isPrimary={true} />;
};

export const PreFooterwithCTA = (props: DoubleCalloutProps): JSX.Element => {
  return <DoubleCallout {...props} isPrimary={true} />;
};

export const ContactCards3Up = (props: DoubleCalloutProps): JSX.Element => {
  return <DoubleCallout {...props} isPrimary={false} />;
};
