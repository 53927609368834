import ReactPlayer from 'react-player';
import { PSP } from '../../../models/PetSuppliesPlus.Model';
import { ComponentProps } from 'lib/component-props';
import React, { useRef, useState, useEffect } from 'react';
import IconHelper from 'src/helpers/commonComponents/IconHelper';
import { NextImage, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import { sites } from 'src/helpers/Constants';
import { fullWidthVideoTileTailwindVariant } from 'tailwindVariants/components/fullWidthVideoTileTailwindVariant';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';

export type FullWidthVideoTileProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.FullWidthVideoTile.Fields.FullWidthVideoTile & {
    children?: React.ReactElement<React.HTMLProps<HTMLElement>> | null;
  };

const {
  base,
  wrapper,
  playerWrapper,
  playerWrapperModel,
  reactPlayer,
  reactPlayerAutoPlay,
  playIcon,
  linkWrapper,
  contentBlock,
  link,
  title,
  subtitle,
} = fullWidthVideoTileTailwindVariant({
  size: {
    initial: 'mobile',
    lg: 'desktop',
  },
});
// Main FullWidthVideoTile component

const FullWidthVideoTile: React.FC<FullWidthVideoTileProps> = ({ fields, children, params }) => {
  if (fields === null || fields === undefined) return <></>;
  const VideoVariant = params?.FieldNames == 'PopUpVideo';

  return (
    <div className={VideoVariant ? 'w-full' : base({ className: params?.Styles ?? '' })}>
      <div className={VideoVariant ? 'w-full' : wrapper()}>{children}</div>
    </div>
  );
};
const FullWidthVideoPlayer = (props: FullWidthVideoTileProps): JSX.Element => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);

  const videoUrl = props?.fields?.videoEmbed?.value;

  return (
    <FullWidthVideoTile {...props}>
      <>
        <div className={playerWrapper()}>
          {isClient && (
            <ReactPlayer
              className={reactPlayer()}
              width="100%"
              height="100%"
              url={videoUrl}
              controls
            />
          )}
        </div>
      </>
    </FullWidthVideoTile>
  );
};
export const Default = (props: FullWidthVideoTileProps): JSX.Element => {
  return <FullWidthVideoPlayer {...props} />;
};

export const InlineVideo = (props: FullWidthVideoTileProps): JSX.Element => {
  return <FullWidthVideoPlayer {...props} />;
};

export const PopUpVideo = (props: FullWidthVideoTileProps): JSX.Element => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const reactPlayerRef = useRef<ReactPlayer>(null);

  const handleModalClose = () => {
    setShowModal(false);
    document.body.classList.remove('overflow-hidden');
    if (reactPlayerRef.current) {
      const internalPlayer = reactPlayerRef.current.getInternalPlayer();
      if (internalPlayer && typeof internalPlayer.pauseVideo === 'function') {
        internalPlayer.pauseVideo();
      }
    }
  };

  const handleModalOpen = () => {
    setShowModal(true);
    document.body.classList.add('overflow-hidden');
  };
  return (
    <FullWidthVideoTile {...props}>
      <>
        <div className="relative flex justify-center">
          <NextImage field={props.fields?.backgroundImage} />
          <button
            aria-label="play"
            className="absolute flex left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2"
            onClick={handleModalOpen}
          >
            <IconHelper className={playIcon()} icon={'icon-play'} />
          </button>
          <ModalWrapper
            showCloseButtonInModalHeader={true}
            closeModalOnOverlayClick={true}
            onCloseClick={handleModalClose}
            showModal={showModal}
          >
            <div className={playerWrapperModel()}>
              <ReactPlayer
                className={reactPlayer()}
                width="100%"
                height="100%"
                url={props?.fields?.videoEmbed?.value}
                ref={reactPlayerRef}
                playing={showModal}
                controls={true}
              />
            </div>
          </ModalWrapper>
        </div>
      </>
    </FullWidthVideoTile>
  );
};
export const AutoPlayVideo = (props: FullWidthVideoTileProps): JSX.Element => {
  const [isClient, setIsClient] = useState(false);

  const context = useSitecoreContext();
  const sitecoreContext = context.sitecoreContext;
  const siteName = sitecoreContext?.site?.name?.toUpperCase() ?? 'unknown';

  useEffect(() => {
    setIsClient(true);
  }, []);
  return (
    <FullWidthVideoTile {...props}>
      <div className="relative h-[75vh] overflow-hidden">
        {isClient && (
          <ReactPlayer
            className={reactPlayerAutoPlay()}
            width="100%"
            height="100%"
            url={props?.fields?.videoEmbed?.value}
            controls={true}
            playing={true} // Set playing to true for automatic playback
            muted={true} // Mute the video to ensure autoplay works in some browsers
            loop
          />
        )}
        <div className={contentBlock()}>
          {props.fields?.title && (
            <RichTextHelper
              tag={
                props?.fields?.titleTag?.fields?.Value?.value?.length > 0
                  ? props?.fields?.titleTag?.fields?.Value?.value
                  : 'h1'
              }
              className={title()}
              field={props.fields?.title}
            />
          )}
          {props.fields?.subtitle && (
            <RichTextHelper className={subtitle()} field={props.fields?.subtitle} />
          )}
          <div className={linkWrapper()}>
            {props?.fields?.cta1Link && props?.fields?.cta1Link?.value?.text && (
              <LinkHelper field={props?.fields?.cta1Link} className={link()} />
            )}
            {props?.fields?.cta2Link && props?.fields?.cta2Link?.value?.text && (
              <LinkHelper field={props?.fields?.cta2Link} className={link()} />
            )}
          </div>
        </div>
        {siteName !== sites?.psp ? (
          <svg
            aria-label="video"
            className="absolute -bottom-[1px] -left-[1px] w-full h-auto [&>*]:w-full"
            xmlns="http://www.w3.org/2000/svg"
            width="1284"
            height="52"
            viewBox="0 0 1284 52"
            fill="none"
          >
            <path
              d="M433.566 32.58C259.302 47.4027 0.101562 13.8263 0.101562 13.8263V51.9584H1283.9C1179.44 26.9538 994.922 1.48718 862.149 0.0740694C729.376 -1.33904 607.83 17.7573 433.566 32.58Z"
              fill="white"
            />
          </svg>
        ) : (
          <></>
        )}
      </div>
    </FullWidthVideoTile>
  );
};
