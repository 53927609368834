//global
import React from 'react';
import { NextImage, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';

//local
import { PSP } from 'models/PetSuppliesPlus.Model';

//lib
import { ComponentProps } from 'lib/component-props';
import SplideSlider from 'src/helpers/commonComponents/SplideSlider';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { testimonialCarouselTailwindVariant } from 'tailwindVariants/components/testimonialCarouselTailwindVariant';

//type
// export type TestimonialCarouselProps = ComponentProps &
//   PSP.Sitecore.templates.PetSuppliesPlus.TestimonialCarousel.Fields.TestimonialCarousel;

// todo: Check later if this change is not breaking the component
export type TestimonialCarouselProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.TestimonialCarouselItem.Fields.TestimonialCarousel;
//main component
const TestimonialCarousel: React.FC<TestimonialCarouselProps> = ({ fields, params }) => {
  //variaints
  const { base, backgroundImage, slideWrapper, quouteText, imageWrapper } =
    testimonialCarouselTailwindVariant({
      device: { initial: 'mobile', lg: 'desktop' },
    });

  const isAutoPlay = fields?.autoPlay?.value;
  const duration =
    fields?.duration?.value === undefined || fields?.duration?.value?.length <= 0
      ? 3000
      : Number(fields?.duration?.value);
  const isArrow = fields?.testimonialList && fields?.testimonialList?.length > 1 ? true : false;
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div className={base({ className: params?.Styles ?? '' })}>
      <ImageHelper field={fields?.backgroundImage} className={backgroundImage()} />
      <SplideSlider
        options={{
          type: 'loop',
          pagination: false,
          speed: 1000,
          arrows: isArrow,
          autoplay: isAutoPlay,
          interval: duration,
          classes: {
            prev: 'arrow-prev splide__arrow--prev your-class-prev',
            next: 'arrow-next splide__arrow--next your-class-next',
          },
        }}
        isAutoPlay={fields?.autoPlay?.value}
      >
        {fields?.testimonialList?.map((item, index) => {
          return (
            <div className={slideWrapper()} key={index}>
              {item?.fields?.image && (
                <div className={imageWrapper()}>
                  <NextImage field={item?.fields?.image} className={imageWrapper()} />
                </div>
              )}
              {item?.fields?.quotes && (
                <RichTextHelper field={item?.fields?.quotes} className={quouteText()} />
              )}
            </div>
          );
        })}
      </SplideSlider>
    </div>
  );
};

//check withDataSourceCheck If it is not then show blank instead of error.
export default withDatasourceCheck()<TestimonialCarouselProps>(TestimonialCarousel);
