import { useTheme } from 'lib/context/ThemeContext';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuthToken } from 'src/helpers/MiddlewareToken';
import { useOcIsInitalized } from 'src/redux/ocProvider';
import { useOcSelector } from 'src/redux/ocStore';
import apiConfig from 'src/utils/apiConfig';
import { apiRequest } from 'src/utils/apiWrapper';
export type tokenAPIResponse = {
  Token?: string;
  Url?: string;
} | null;

export type BazarVoiceTokenResponse = {
  bvResponse?: tokenAPIResponse;
  loading?: boolean;
} | null;

export async function getBVData(data: unknown, themeName: string, token: string) {
  try {
    return apiRequest(`${apiConfig?.bazarVoiceReviewAPI?.bazarVoiceReviewAPIEndpoint}`, {
      method: 'POST',
      data: data,
      headers: { site: themeName, Authorization: token },
    })
      .then((response: tokenAPIResponse) => {
        return response;
      })
      .catch((err) => {
        console.error('err', err);
        return null;
      });
  } catch (err) {
    console.error('In getBVData err', err);
    return null;
  }
}
export function useBazarVoiceToken(): BazarVoiceTokenResponse {
  const [bvResponse, setBVResponse] = useState<tokenAPIResponse>(null);

  const isInitialized = useOcIsInitalized();
  const [loading, setLoading] = useState(true);
  const isAnonymous = useOcSelector((s) => s?.ocAuth?.isAnonymous);
  const userId = useOcSelector((s) => s?.ocUser?.user?.ID);
  const email = useOcSelector((s) => s?.ocUser?.user?.Email);
  const { themeName } = useTheme();
  const token = useAuthToken();
  const getToken = useCallback(async () => {
    if (!isInitialized) {
      setLoading(true);
    } else {
      if (userId && email) {
        setLoading(true);
        getBVData({}, themeName, token)
          .then((response: tokenAPIResponse) => {
            setBVResponse(response);
            setLoading(false);
          })
          .catch((err) => {
            console.error('error:', err);
            setBVResponse(null);
            setLoading(false);
          });
      } else {
        // setLoading(false);
      }
    }
  }, [email, isInitialized, themeName, token, userId]);

  useEffect(() => {
    if (!isAnonymous) {
      getToken();
    } else {
      setBVResponse(null);

      setLoading(false);
    }
  }, [isAnonymous, getToken]);

  return { bvResponse, loading };
}

// We don't actually need this component anymore, as dependent components will use the hook, but
// keeping this here so it doesn't cause error due to component not found
const BazarVoiceToken = () => {
  return <></>;
};

export default BazarVoiceToken;
