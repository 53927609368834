//global
import React from 'react';
import { Text, RichText, NextImage, Link } from '@sitecore-jss/sitecore-jss-nextjs';
import { PSP } from '../../../models/PetSuppliesPlus.Model';
//local

//lib
import { ComponentProps } from 'lib/component-props';
import ButtonHelper from 'src/helpers/commonComponents/Button';
import clsx from 'clsx';
import LinkHelper from 'src/helpers/commonComponents/LinkHelper';
import { backgroundTheme, GTM_EVENT } from 'src/helpers/Constants';
import withPersonalization from 'src/helpers/withPersonalization/withPersonalization';
import ImageHelper from 'src/helpers/commonComponents/ImageHelper';
import {
  formatDateForGTM,
  notAvailableIfNullOrEmpty,
  trackObjectForPromotion,
} from 'src/utils/sendGTMEvent';
import { useBreakpoints } from 'src/utils/breakpoints';
import { useOcSelector } from 'src/redux/ocStore';
import useExperienceEditor from 'src/hooks/useExperienceEditor';
import { multiColumnItemTailwindVariant } from 'tailwindVariants/components/multiColumnItemTailwindVariant';

//type
export type MultiColumnItemProps = ComponentProps &
  PSP.Sitecore.templates.PetSuppliesPlus.MultiColumnItem.Fields.MultiColumnItem & {
    children?: React.ReactElement<React.HTMLProps<HTMLElement>> | null;
  };

export type itemdata = {
  expanded?: boolean;
  required?: boolean;
  value?: string;
  valueType?: string;
};
export type itemdataProps = {
  title?: itemdata;
  subtitle?: itemdata;
  description?: itemdata;
  image_url?: itemdata;
  primaryLinkText?: itemdata;
  primaryLinkUrl?: {
    expanded?: boolean;
    required?: boolean;
    value?: string;
    valueType?: string;
  };
  secondaryLinkText?: itemdata;
  secondaryLinkUrl?: itemdata;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sendGTMData = (data: any, deviceName: string, storeId: string, event: string) => {
  const gtmPromotion = [
    {
      promotion_id: notAvailableIfNullOrEmpty(data?.fields?.promotionId?.value),
      promotion_name: notAvailableIfNullOrEmpty(data?.fields?.promotionName?.value),
      creative_name: notAvailableIfNullOrEmpty(data?.fields?.componentName?.value),
      creative_slot: Number(data?.fields?.creativeSlotNumber?.value),
      promotion_device: deviceName,
      promotion_copy: notAvailableIfNullOrEmpty(data?.fields?.promotionCopy?.value),
      promotion_dates: notAvailableIfNullOrEmpty(
        formatDateForGTM(
          `${data?.fields?.promotionDateFROM?.value} - ${data?.fields?.promotionDateTO?.value}`
        )
      ),
      promotion_cta: notAvailableIfNullOrEmpty(data?.fields?.cTAButtonCopy?.value),
      promotion_url: notAvailableIfNullOrEmpty(data?.fields?.promotionURL?.value?.href),
    },
  ];
  gtmPromotion && trackObjectForPromotion(event, storeId, gtmPromotion);
};

//main component
const MultiColumnItem: React.FC<MultiColumnItemProps> = ({ fields, children, params }) => {
  const { base, baseWithAndWithoutCTA } = multiColumnItemTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  //if no data then returned empty fragment
  if (fields === undefined || fields === null) return <></>;
  return (
    <div
      className={clsx(base(), {
        [baseWithAndWithoutCTA()]:
          params?.FieldNames !== 'DoubleCTA' && params?.FieldNames !== 'OnlyImage',
      })}
    >
      <>{children}</>
    </div>
  );
};
//check withDataSourceCheck If it is not then show blank instead of error.
export const Default = withPersonalization((props: MultiColumnItemProps): JSX.Element => {
  const {
    imageWrapper,
    imageWrapperWithCTA,
    image,
    content,
    title,
    subHeading,
    description,
    buttonWrapperWithCTA,
    ButtonHelperWithCTA,
  } = multiColumnItemTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });
  const { deviceName }: { deviceName: string } = useBreakpoints();
  const isEE = useExperienceEditor();
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  const isLink =
    props?.fields?.primaryLink?.value?.href?.length &&
    props?.fields?.primaryLink?.value?.href?.length > 0;
  return (
    <>
      <MultiColumnItem {...props}>
        <>
          <div
            className={imageWrapper()}
            data-creative-id={props?.fields?.componentName?.value}
            data-promotion-cta={props?.fields?.cTAButtonCopy?.value}
            data-promo-id={props?.fields?.promotionId?.value}
            data-promotion-name={props?.fields?.promotionName?.value}
            data-promotion-copy={props?.fields?.promotionCopy?.value}
            dta-promotion-url={props?.fields?.promotionURL?.value?.href}
          >
            <NextImage
              field={props?.fields?.image}
              className={clsx(image(), {
                [imageWrapperWithCTA()]: props?.fields?.roundBorderWithShadow?.value,
              })}
            />
          </div>
          <div className={content()}>
            <RichText field={props?.fields?.title} className={title()} />
            <Text tag="div" field={props?.fields?.subHeading} className={subHeading()} />
            <RichText field={props?.fields?.description} className={description()} />
            {isLink ? (
              <div className={buttonWrapperWithCTA()}>
                <ButtonHelper
                  isLinkField={true}
                  variant="link"
                  size="small"
                  onClickHandler={() => {
                    sendGTMData(props, deviceName, myStoreId, GTM_EVENT?.selectPromotion);
                  }}
                  className={ButtonHelperWithCTA()}
                  outline={false}
                  field={props?.fields?.primaryLink}
                />
              </div>
            ) : isEE ? (
              props?.fields?.primaryLink && <Link field={props?.fields?.primaryLink} />
            ) : (
              ''
            )}
          </div>
        </>
      </MultiColumnItem>
    </>
  );
});
export const WithCTA = withPersonalization((props: MultiColumnItemProps): JSX.Element => {
  return <Default {...props} />;
});
export const WithoutCTA = withPersonalization((props: MultiColumnItemProps): JSX.Element => {
  const { imageWrapper, image, content, titleWithoutCTA, subHeading, description } =
    multiColumnItemTailwindVariant({
      size: { initial: 'mobile', lg: 'desktop' },
    });
  const SelectedTheme = props?.params?.backgroundTheme as backgroundTheme;
  const { deviceName }: { deviceName: string } = useBreakpoints();
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;
  return (
    <MultiColumnItem {...props}>
      <>
        <div
          className={imageWrapper()}
          data-creative-id={props?.fields?.componentName?.value}
          data-promotion-cta={props?.fields?.cTAButtonCopy?.value}
          data-promo-id={props?.fields?.promotionId?.value}
          data-promotion-name={props?.fields?.promotionName?.value}
          data-promotion-copy={props?.fields?.promotionCopy?.value}
          dta-promotion-url={props?.fields?.promotionURL?.value?.href}
          onClick={() => {
            sendGTMData(props, deviceName, myStoreId, GTM_EVENT?.selectPromotion);
          }}
        >
          <NextImage field={props?.fields?.image} className={image()} />
        </div>
        <div className={content()}>
          <RichText
            field={props?.fields?.title}
            className={titleWithoutCTA({ backgroundTheme: SelectedTheme })}
          />
          <Text tag="div" field={props?.fields?.subHeading} className={subHeading()} />
          <RichText field={props?.fields?.description} className={description()} />
        </div>
      </>
    </MultiColumnItem>
  );
});
export const DoubleCTA = withPersonalization((props: MultiColumnItemProps): JSX.Element => {
  const {
    ImageContentWrapper,
    imageWrapper,
    imageWrapperDoubleCTA,
    contentDoubleCTA,
    titleDoubleCTA,
    subHeading,
    description,
    buttonWrapperDoubleCTA,
    primaryCta,
    secondaryCta,
  } = multiColumnItemTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const SelectedTheme = props?.params?.backgroundTheme as backgroundTheme;
  const { deviceName }: { deviceName: string } = useBreakpoints();
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;

  // useEffect(() => {
  //   if (myStoreId) {
  //     sendGTMData(props, deviceName, myStoreId, GTM_EVENT?.viewPromotion);
  //   }
  // }, [myStoreId]);

  return (
    <MultiColumnItem {...props}>
      <div
        className={ImageContentWrapper()}
        data-creative-id={props?.fields?.componentName?.value}
        data-promotion-cta={props?.fields?.cTAButtonCopy?.value}
        data-promo-id={props?.fields?.promotionId?.value}
        data-promotion-name={props?.fields?.promotionName?.value}
        data-promotion-copy={props?.fields?.promotionCopy?.value}
        dta-promotion-url={props?.fields?.promotionURL?.value?.href}
      >
        <div className={(imageWrapper(), imageWrapperDoubleCTA())}>
          {props?.fields?.primaryLink ? (
            <LinkHelper editable={false} hideLinkInEE field={props?.fields?.primaryLink}>
              <ImageHelper field={props?.fields?.image} className="w-full" />
            </LinkHelper>
          ) : (
            <ImageHelper field={props?.fields?.image} className="w-full" />
          )}
        </div>
        <div className={contentDoubleCTA()}>
          <RichText
            field={props?.fields?.title}
            className={titleDoubleCTA({ backgroundTheme: SelectedTheme })}
          />
          <Text tag="div" field={props?.fields?.subHeading} className={subHeading()} />
          <RichText field={props?.fields?.description} className={description()} />
          {props?.fields?.primaryLink?.value?.href && props?.fields?.primaryLink?.value?.text && (
            <div className={buttonWrapperDoubleCTA()}>
              <ButtonHelper
                isLinkField={true}
                variant="primary"
                size="default"
                outline={false}
                field={props?.fields?.primaryLink}
                className={primaryCta({ backgroundTheme: SelectedTheme })}
                onClickHandler={() => {
                  sendGTMData(props, deviceName, myStoreId, GTM_EVENT?.selectPromotion);
                }}
              />
              {props?.fields?.secondaryLink?.value?.href &&
                props?.fields?.secondaryLink?.value?.text && (
                  <ButtonHelper
                    isLinkField={true}
                    variant="secondary"
                    size="default"
                    outline={false}
                    className={secondaryCta({ backgroundTheme: SelectedTheme })}
                    field={props?.fields?.secondaryLink}
                    onClickHandler={() => {
                      sendGTMData(props, deviceName, myStoreId, GTM_EVENT?.selectPromotion);
                    }}
                  />
                )}
            </div>
          )}
        </div>
      </div>
    </MultiColumnItem>
  );
});
export const OnlyImage = withPersonalization((props: MultiColumnItemProps): JSX.Element => {
  const { imageWrapper, image } = multiColumnItemTailwindVariant({
    size: { initial: 'mobile', lg: 'desktop' },
  });

  const { deviceName }: { deviceName: string } = useBreakpoints();
  const myStoreId = useOcSelector((state) => state?.storeReducer?.selectedStore.storeId) as string;

  const isHover =
    props?.fields?.primaryLink?.value?.href && props?.fields?.primaryLink?.value?.href?.length > 0
      ? 'hover:scale-[1.01] hover:shadow-multiColumnItem'
      : '';

  return (
    <MultiColumnItem {...props}>
      <div
        className={imageWrapper({ className: isHover })}
        data-creative-id={props?.fields?.componentName?.value}
        data-promotion-cta={props?.fields?.cTAButtonCopy?.value}
        data-promo-id={props?.fields?.promotionId?.value}
        data-promotion-name={props?.fields?.promotionName?.value}
        data-promotion-copy={props?.fields?.promotionCopy?.value}
        dta-promotion-url={props?.fields?.promotionURL?.value?.href}
      >
        {props?.fields?.primaryLink ? (
          <LinkHelper
            editable={false}
            hideLinkInEE
            field={props?.fields?.primaryLink}
            onClick={() => sendGTMData(props, deviceName, myStoreId, GTM_EVENT?.selectPromotion)}
          >
            <NextImage field={props?.fields?.image} className={image()} />
          </LinkHelper>
        ) : (
          <NextImage field={props?.fields?.image} className={image()} />
        )}
      </div>
    </MultiColumnItem>
  );
});
