import { tv } from 'tailwind-variants';

export const googleMapTailwindVariant = tv(
  {
    slots: {
      map: `w-full h-[350px] lg:h-[516px] lg:min-w-[300px] lg:max-w-[380px] xl:h-[616px] xl:max-w-[568px]`,
      markerClass: 'mt-[14px] mr-[7px]',
    },
    variants: {
      size: {
        desktop: {},
        mobile: {},
      },
    },
  },
  {
    responsiveVariants: ['md', 'lg'],
  }
);
