import { tv } from 'tailwind-variants';

export const credentialsTailwindVariants = tv(
  {
    slots: {
      base: ['credentials'],
      divWrapper: ['flex', 'container', 'mx-auto'],
      cardWrapper: ['lg:[&_.right]:ml-auto'],
      card: [
        'bg-color-background-white',
        'rounded-[12px]',
        'w-[85%]',
        'min-w-[334px]',
        'lg:min-w-[0px]',
        'lg:max-w-[415px]',
        'lg:w-[415px]',
      ],
      rightWrapper: [''],
      contentWrapper: ['flex-col', 'text-color-text-white', 'max-w-[954px]'],
      title: [
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
      ],
      description: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
    },
    compoundSlots: [
      { slots: ['contentWrapper', 'cardWrapper', 'card'], class: ['flex'] },
      { slots: ['divWrapper', 'card'], class: ['justify-center', 'items-center'] },
    ],
    variants: {
      device: {
        mobile: {
          divWrapper: [
            'pt-mob-padding-tight-y',
            'pl-mob-global-grid-margin',
            'pb-mob-padding-loose-bottom',
            'pr-0',
            'flex-col',
            'flex-col-reverse',
          ],
          cardWrapper: [
            'px-mob-padding-tight-y',
            'gap-[19px]',
            'overflow-x-scroll',
            'flex-row',
            'w-full ',
          ],
          card: [
            'py-mob-padding-micro-y',
            'px-mob-padding-tight-x',
            'rounded-global-radius-large',
            'h-[145px]',
          ],
          rightWrapper: ['py-mob-padding-tight-y', 'px-mob-padding-tight-y'],
          contentWrapper: [
            'px-mob-padding-micro-x',
            'gap-mob-margin-tight-bottom',
            'text-center',
            'pb-2',
          ],
        },
        desktop: {
          divWrapper: ['pt-desk-padding-tight-y', 'pb-desk-padding-tight-y', 'flex-row'],
          cardWrapper: [
            'px-desk-margin-loose-right',
            'gap-[24px]',
            'flex-col',
            'overflow-visible',
            'w-full',
            'max-w-[570px]',
          ],
          card: ['py-desk-padding-micro-y', 'px-desk-padding-tight-x', 'h-full'],
          rightWrapper: ['py-desk-padding-tight-y', 'px-desk-padding-base-x', 'order-1'],
          contentWrapper: [
            'px-desk-padding-micro-x',
            'gap-desk-margin-tight-bottom',
            'text-left',
            'pb-0',
          ],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
