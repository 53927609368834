import { Field, FormikValues, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import FieldWrapper from './FieldWrapper';
import { tv } from 'tailwind-variants';
import { FieldValues } from 'components/Registration/RegistrationForm/RegistrationForm';

type TextAreaFieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  subLabel?: string;
  name?: string;
  className?: string;
  requiredvalidationmessage?: string;
  validationpattern?: string;
  hasFullWidth?: boolean;
  placeholdertext?: string;
  characterlimit?: string | number;
  fieldtype?: string;
  validationerrormessage?: string;
  mincharacterlimit?: string;
  mincharacterlimitvalidationmessage?: string;
  maxcharacterlimitvalidationmessage?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setFieldValue?: (val: FieldValues | any) => void;
};

const FieldsVariant = tv(
  {
    slots: {
      fields: [
        'outline-none',
        'border-2',
        'text-color-text-black',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
        'placeholder:text-color-text-black',
        'focus:placeholder:opacity-0',
        'placeholder:transition-opacity',
        'placeholder:duration-500',
        'focus:border-color-brand-primary-1-base',
        'disabled:bg-color-scale-3-light-mid',
        'w-full',
      ],
    },
    variants: {
      device: {
        mobile: {
          fields: [
            'py-mob-global-spacing-spacing-3',
            'px-mob-global-spacing-spacing-4',
            'rounded-mob-global-radius-small',
          ],
        },
        desktop: {
          fields: [
            'py-desk-global-spacing-spacing-3',
            'px-desk-global-spacing-spacing-4',
            'rounded-desk-global-radius-small',
          ],
        },
      },
      hasErrorStyle: {
        true: { fields: ['border-system-red'] },
        false: { fields: ['border-color-border-mid'] },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);

const TextAreaField: React.FC<TextAreaFieldProps> = (props: TextAreaFieldProps) => {
  const {
    id,
    name,
    className,
    placeholdertext,
    requiredvalidationmessage,
    required,
    characterlimit,
    validationpattern,
    validationerrormessage,
    disabled,
    mincharacterlimitvalidationmessage,
    maxcharacterlimitvalidationmessage,
    mincharacterlimit,
    setFieldValue,
  } = props;
  const fieldValidate = (value: string) => {
    let error;
    if (required && !value) {
      error = requiredvalidationmessage || 'This field is required.'; // TODO: - Static message callback can be take from dictionary item.
    } else if (mincharacterlimit && value.length < Number(mincharacterlimit)) {
      error = mincharacterlimitvalidationmessage;
    } else if (validationpattern) {
      const regex = new RegExp(validationpattern);
      if (!regex.test(value)) {
        error = validationerrormessage;
      }
    } else if (
      (Number(characterlimit) && value.length > Number(characterlimit)) ||
      messageOrigLen > Number(characterlimit)
    ) {
      error =
        maxcharacterlimitvalidationmessage ??
        'Message allows a maximum of ' + Number(characterlimit) + ' characters';
      setMessageOrigLen(value.length);
    }

    return error;
  };

  const [messageOrigLen, setMessageOrigLen] = useState<number>(0);

  const { errors, touched, values } = useFormikContext<FormikValues>();
  const hasError = name && touched[name] && errors[name] ? true : false;
  const { fields } = FieldsVariant({
    hasErrorStyle: hasError,
    device: {
      initial: 'mobile',
      lg: 'desktop',
    },
  });

  useEffect(() => {
    if (Number(characterlimit)) {
      setFieldValue && setFieldValue(values);
      values.Message = values?.Message?.substring(0, Number(characterlimit));
      setMessageOrigLen(values?.Message?.length);
    }
  }, [values]);

  return (
    <>
      <FieldWrapper hasError={hasError} {...props} className={className}>
        <Field
          as="textarea"
          className={fields()}
          id={id}
          name={name}
          aria-label={name}
          placeholder={placeholdertext}
          validate={fieldValidate}
          data-characterlimit={characterlimit}
          rows={10}
          disabled={disabled}
        />
      </FieldWrapper>
    </>
  );
};

export default TextAreaField;
