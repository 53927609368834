import { Plugin } from '..';
import { normalizeProductIdSkuRewrite } from 'lib/middleware/plugins/pdp-sku';

class PdpSkuPlugin implements Plugin {
  exec(path: string) {
    // Remove personalize rewrite segment from the path
    return normalizeProductIdSkuRewrite(path);
  }
}

export const pdpSkuPlugin = new PdpSkuPlugin();
