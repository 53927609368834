import { tv } from 'tailwind-variants';

export const ctaBannerTailwindVariant = tv(
  {
    slots: {
      base: ['cta-banner-variant', 'bg-color-background-light'],
      ctaBannerWrapper: ['container', 'justify-between', 'w-full'],
      contentWrapper: ['flex-col', 'w-full', 'max-w-[980px]'],
      title: [
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
      ],
      description: [
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
      ],
      link: [
        'w-fit',
        'items-center',
        'text-color-text-brand-1',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
        'gap-mob-space-between-micro-horizontal',
      ],
      img: [''],
      icon: [
        '[&>svg>*>*]:fill-color-brand-primary-1-base',
        '[&>svg>*]:fill-color-brand-primary-1-base',
        'h-4',
        'w-4',
        '[&_svg]:max-w-4 ',
        '[&_svg]:max-h-4',
      ],
    },
    compoundSlots: [
      { slots: ['ctaBannerWrapper', 'contentWrapper', 'icon', 'link'], class: ['flex'] },
      {
        slots: ['link', 'description'],
        class: [],
      },
    ],
    variants: {
      size: {
        mobile: {
          base: [],
          ctaBannerWrapper: [
            'flex-col',
            'flex-col-reverse',
            'gap-mob-margin-loose-bottom',
            'py-mob-padding-tight-y',
            'px-mob-global-grid-margin',
          ],
          contentWrapper: ['gap-mob-margin-tight-bottom'],
          link: ['mt-mob-margin-base-top'],
          img: ['hidden'],
        },
        desktop: {
          base: [],
          ctaBannerWrapper: [
            'flex-row',
            'gap-desk-global-spacing-spacing-13',
            'py-desk-padding-tight-y',
          ],
          link: ['mt-3'],
          contentWrapper: ['gap-desk-margin-tight-bottom', 'justify-center'],
          img: ['block'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
