import { tv } from 'tailwind-variants';

export const warningDeliveryThresholdTailwindVariant = tv(
  {
    slots: {
      popUpPad: '',
      titleLargeBold:
        'font-heading-desk-large-bold text-heading-desk-large-bold leading-heading-desk-large-bold text-color-background-black mb-desk-margin-base-bottom',
      bodyCopy:
        'font-body-large-regular text-body-large-regular leading-body-large-regular text-color-background-black mb-desk-margin-base-bottom',
      buttonStyles: [
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
        'py-desk-component-button-condensed-padding-y',
        'px-desk-component-button-condensed-padding-x',
        'hover:no-underline',
        'flex',
        'justify-center',
        'items-center',
        'text-center',
        'duration-300',
        'rounded',
        'lg:mb-desk-margin-base-bottom',
        'lg:my-[12px]',
        'my-[6px]',
      ],
      buttonPrimaryStyle: [
        'bg-color-brand-primary-1-base',
        'hover:bg-color-brand-primary-1-dark',
        'border-color-brand-primary-1-base',
        'text-white',
      ],
      buttonSecondaryStyle: [
        'text-color-brand-primary-1-base',
        'border-2 border-color-brand-primary-1-base',
        'rounded bg-white',
        'hover:bg-color-brand-primary-1-base',
        'hover:text-white',
      ],
    },
    variants: {
      size: {
        mobile: {
          popUpPad: 'py-mob-padding-tight-y px-mob-padding-base-x',
        },
        desktop: {
          popUpPad: 'py-desk-padding-tight-y px-desk-padding-base-x',
        },
      },
    },
  },
  {
    responsiveVariants: ['sm', 'lg'],
  }
);
