/* eslint-disable @typescript-eslint/no-explicit-any */
import { transferAnonOrder } from 'src/redux/ocCurrentOrder';
import {
  Me,
  Order,
  SearchType,
  LineItems,
  LineItem,
  RequestOptions,
  Orders,
} from 'ordercloud-javascript-sdk';

export const MergeCart = async (
  dispatch: any,
  anonCartId: string,
  anonProducts: string[],
  anonToken: string
) => {
  try {
    const storeId: string = anonCartId.substring(0, 4);
    //Get cart for this store
    //We avoid using retrieveOrder redux, because it call worksheet and will update redux, before merge cart
    const response = await Me.ListOrders({
      search: `${storeId}-`,
      sortBy: ['!DateCreated'],
      filters: { Status: 'Unsubmitted', SubscriptionID: '!*' },
      searchOn: ['ID'],
      searchType: 'ExactPhrasePrefix' as SearchType,
    });
    if (response?.Items?.length > 0) {
      const order: Order = response.Items[0];
      const oid: string = order.ID ?? '';
      const lines = await LineItems.List('All', oid);
      if (lines && lines?.Meta?.TotalCount > 0) {
        const newArr = lines?.Items?.filter((l) => !anonProducts.includes(l.ProductID)).map(
          function (val) {
            return {
              ProductID: val.ProductID,
              Quantity: val.Quantity,
              ShipFromAddressID: val.ShipFromAddressID,
            } as LineItem;
          }
        );

        await Promise.all(
          newArr?.map(async (l) => {
            await LineItems.Create('Outgoing', anonCartId, l, {
              accessToken: anonToken,
            } as RequestOptions);
          })
        );

        Promise.all(
          response?.Items?.map(async (o) => {
            await Orders.Delete('Outgoing', o.ID);
          })
        );
      }
    }
    await dispatch(transferAnonOrder(anonToken));
  } catch (err) {
    console.error('MergeCart: ', err);
  }
};
