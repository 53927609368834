import { tv } from 'tailwind-variants';

export const findStoreTailwindVariant = tv(
  {
    slots: {
      storeListing: ['flex', 'flex-col', 'border-t', 'border-t-color-border-mid'],
      storeItem: ['flex', 'gap-[12px]', 'border-b', 'border-b-color-border-mid'],
      storeDetailsWrapper: ['flex', 'gap-[12px]'],
      storeDetail: ['flex flex-col gap-[12px]'],
      storeName: ['text-color-text-dark'],
      myStoreWrapper: ['flex', 'gap-[12px]'],
      myStoreTxt: [
        'text-color-text-brand-1',
        'font-body-large-bold',
        'leading-body-large-bold',
        'text-body-large-bold',
      ],
      selectStoreWrapper: ['flex', 'flex-col'],
      selectStoreCTA: [
        'bg-color-brand-primary-1-base',
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
        'text-color-text-white',
        'rounded-mob-global-radius-small',
        'disabled:bg-color-brand-primary-1-disabled',
        'hover:disabled:bg-color-brand-primary-1-disabled',
        'text-center',
      ],
      storeThumbnailImage: ['w-auto', 'max-h-[124px]', 'self-start', 'flex'],
    },
    variants: {
      device: {
        mobile: {
          selectStoreWrapper: ['w-full'],
          storeName: ['font-body-large-bold', 'leading-body-large-bold', 'text-body-large-bold'],
          storeListing: ['py-mob-margin-tight-top', 'gap-mob-space-between-tight-vertical'],
          storeItem: ['pb-desk-margin-tight-bottom'],
          storeDetailsWrapper: ['flex-col', 'justify-between'],
          selectStoreCTA: [
            'font-body-large-bold',
            'leading-body-large-bold',
            'text-body-large-bold',
            'px-mob-component-button-condensed-padding-x',
            'py-mob-component-button-condensed-padding-y',
          ],
        },
        desktop: {
          selectStoreWrapper: ['w-auto'],
          storeName: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
          ],
          storeListing: ['py-desk-margin-tight-top', 'gap-desk-space-between-tight-vertical'],
          storeDetailsWrapper: ['flex-row', 'justify-between', 'flex-[1_0_0]'],
          storeItem: ['pb-desk-margin-tight-bottom'],
          myStoreWrapper: ['order-2'],
          selectStoreCTA: [
            'text-heading-desk-medium-bold',
            'font-heading-desk-medium-bold',
            'leading-heading-desk-medium-bold',
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'hover:bg-color-brand-primary-1-dark',
          ],
        },
      },
      inminicart: {
        true: {
          selectStoreWrapper: ['w-full', 'self-auto'],
          storeName: ['font-body-large-bold', 'leading-body-large-bold', 'text-body-large-bold'],
          storeListing: ['py-mob-margin-tight-top', 'gap-mob-space-between-tight-vertical'],
          storeItem: ['pb-desk-margin-tight-bottom'],
          storeDetailsWrapper: ['lg:flex-col', 'justify-between'],
          selectStoreCTA: [
            'font-body-large-bold',
            'leading-body-large-bold',
            'text-body-large-bold',
            'px-mob-component-button-condensed-padding-x',
            'py-mob-component-button-condensed-padding-y',
          ],
          storeDetail: ['lg:order-2'],
          storeThumbnailImage: [],
        },
        false: {
          selectStoreWrapper: ['self-end'],
        },
      },
    },
  },
  { responsiveVariants: ['sm', 'lg'] }
);
