import { tv } from 'tailwind-variants';

//component variants
export const bannerCtaTailwindVariant = tv(
  {
    slots: {
      base: ['relative', 'bannerCta', 'w-full'],
      innerDiv: [
        'container',
        'mx-auto',
        'bg-cover',
        'bg-center',
        'text-center',
        'text-color-text-white',
        'text-primary',
      ],
      imageWrapper: [],
      imgWrapper: [
        '*:object-cover',
        '*:absolute',
        '*:h-full',
        '*:w-full',
        '*:left-0',
        '*:top-0',
        '*:max-h-[400px]',
      ],
      title: [
        'w-full',
        'text-heading-desk-xLarge-bold',
        'font-heading-desk-xLarge-bold',
        'leading-heading-desk-xLarge-bold',
      ],
      description: [
        'w-full',
        'text-body-large-bold',
        'font-body-large-bold',
        'leading-body-large-bold',
      ],
      contentWrapper: [],
      ctaLink: [
        'z-10',
        'bg-color-brand-primary-1-base',
        'text-heading-desk-large-bold',
        'font-heading-desk-large-bold',
        'leading-heading-desk-large-bold',
        'rounded-[0.25rem]',
        'hover:no-underline',
        'transition-all',
        'ease-in-out',
      ],
      LinkWrapper: ['flex'],
      content: ['z-[1]', 'w-full'],
    },
    compoundSlots: [
      {
        slots: ['contentWrapper', 'innerDiv', 'content'],
        class: ['flex', 'flex-col', 'justify-center', 'items-center'],
      },
    ],
    variants: {
      colors: {
        black: { description: ['text-color-scale-9-black'], title: ['text-color-scale-9-black'] },
        default: { description: ['text-white'], title: ['text-white'] },
      },
      device: {
        mobile: {
          innerDiv: ['py-mob-padding-tight-y'],
          contentWrapper: ['gap-2'],
          content: ['gap-mob-margin-tight-bottom'],
          ctaLink: [
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
          ],
        },
        desktop: {
          innerDiv: ['py-desk-padding-tight-y'],
          contentWrapper: ['gap-desk-margin-base-bottom'],
          content: ['gap-desk-margin-tight-bottom'],
          ctaLink: [
            'py-desk-component-button-full-padding-y',
            'px-desk-component-button-full-padding-x',
            'hover:bg-color-brand-primary-1-dark',
          ],
        },
      },
      bannerSize: {
        small: {
          innerDiv: ['h-auto'],
        },
        big: {
          innerDiv: ['h-[400px]'],
        },
      },
    },
  },
  { responsiveVariants: ['lg'] }
);
