// Global
import { convertHtmlToReact } from '@hedgedoc/html-to-react';
import React, { ReactElement } from 'react';
import { publicURLTrailingSlash } from 'src/helpers/publicURLTrailingSlash';
import useSWR from 'swr';

import Caret from 'public/icons/Caret.svg';
import BreadcrumbDivider from 'public/icons/breadcrumb-divider.svg';
import Menu from 'public/icons/menu.svg';
import Close from 'public/icons/close.svg';
import Search from 'public/icons/search.svg';
import ShoppingCart from 'public/icons/shopping-cart.svg';
import ChevronRight from 'public/icons/chevron-right.svg';
import ChevronDown from 'public/icons/chevron-down.svg';
import ChevronUp from 'public/icons/chevron-up.svg';

// Import common icons directly.  Don't need to worry about bundle size since they're always on the page anyway
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const commonIcons: Record<string, any> = {
  Caret,
  'breadcrumb-divider': BreadcrumbDivider,
  menu: Menu,
  close: Close,
  search: Search,
  'shopping-cart': ShoppingCart,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  'chevron-down': ChevronDown,
};

export type Variant = 'outlined' | 'rounded' | 'sharp';

export type IconHelperProps = {
  className?: string;
  fill?: boolean;
  icon: string;
  variant?: Variant;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onKeyDown?: (event?: React.KeyboardEvent<HTMLElement>) => void;
  tabIndex?: number;
};

const IconHelper = ({
  className,
  fill,
  icon,
  onClick,
  onMouseEnter,
  onMouseLeave,
  tabIndex,
  onKeyDown,
}: //   variant = 'sharp' as Variant,
IconHelperProps): ReactElement => {
  // Handle onClick event
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const iconName = `${icon}${fill ? '-fill' : ''}`;
  const Icon = commonIcons[iconName];
  return (
    <span
      tabIndex={tabIndex}
      className={`${className}`}
      onClick={handleClick}
      onKeyDown={onKeyDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {Icon ? <Icon /> : <SwrIcon icon={icon} fill={fill} />}
    </span>
  );
};

function SwrIcon({
  fill,
  icon,
}: //   variant = 'sharp' as Variant,
IconHelperProps): ReactElement | null {
  const publicURL = publicURLTrailingSlash();
  const iconUri = `${publicURL}icons/${icon}${fill ? '-fill' : ''}.svg`;
  const fetcher = async (url: string) => {
    const res = await fetch(url);

    if (!res.ok) {
      throw new Error(`Failed to fetch icon: ${res.statusText}`);
    }

    return res.text();
  };
  const { data } = useSWR(iconUri, fetcher, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return data ? <>{convertHtmlToReact(data)}</> : null;
}

export default IconHelper;
