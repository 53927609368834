import { Field, RichText } from '@sitecore-jss/sitecore-jss-nextjs';
import { useAutoshipContext } from 'lib/context/AutoshipComponentContext';
import React from 'react';
import ModalWrapper from 'src/helpers/commonComponents/ModalWrapper';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { cancelledAutoshipModalTailwindVariant } from 'tailwindVariants/components/cancelledAutoshipModalTailwindVariant';

interface CancelledAutoshipProps {
  fields: {
    title?: Field<string>;
    description?: Field<string>;
    closeText?: Field<string>;
  };
}
const CancelledAutoship = ({ fields }: CancelledAutoshipProps) => {
  const { base, title, description, button, buttonWrapper } = cancelledAutoshipModalTailwindVariant(
    {
      size: { initial: 'mobile', lg: 'desktop' },
    }
  );
  const { autoshipContextData, setAutoshipContextData } = useAutoshipContext();
  const handleModalClose = () => {
    setAutoshipContextData({
      ...autoshipContextData,
      isCancelledSuccessfully: false,
    });
  };
  if (fields === undefined || fields === null) return <></>;

  return (
    <ModalWrapper
      showCloseButtonInModalHeader={false}
      closeModalOnOverlayClick={true}
      onCloseClick={handleModalClose}
      showModal={autoshipContextData?.isCancelledSuccessfully}
      customPopup={true}
      popupWidth="max-w-[997px]"
      popupSpacing="p-0"
    >
      <div className={base()}>
        <RichText className={title()} field={fields?.title} />
        <div>
          <RichTextHelper className={description()} field={fields?.description} />
        </div>
        <div className={buttonWrapper()}>
          {fields?.closeText?.value && (
            <button aria-label="close button" className={button()} onClick={handleModalClose}>
              {fields?.closeText?.value}
            </button>
          )}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default CancelledAutoship;
