import { ComponentRendering, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { WidgetDataType, useContentBlock, widget } from '@sitecore-search/react';
import { useRouter } from 'next/router';
import { RerenderOnRouteChange } from 'src/helpers/utility/RerenderOnRouteChange';
import { useEffect, useState } from 'react';
import RichTextHelper from 'src/helpers/commonComponents/RichTextHelper';
import { searchMessageTailwindVariant } from 'tailwindVariants/components/searchMessageTailwindVariant';

export interface SearchMessageProps {
  params: {
    discoverRFKId: string;
  };
  rendering: ComponentRendering;
  setSearchMessageData?: string;
}

export type RichTextData = {
  title: Field<string>;
  richText: Field<string>;
  content?: Field<string>;
  detailedDescription?: Field<string>;
};
export type PersonalizedSearchMessageProps = SearchMessageProps;

export type PersionalizedRichTextProps = Omit<SearchMessageProps, 'rendering'>;

const { base, wrapper, descriptionWrapper } = searchMessageTailwindVariant({
  device: { initial: 'mobile', lg: 'desktop' },
});

const PersonalizedSearchMessage: React.FC<PersonalizedSearchMessageProps> = ({
  setSearchMessageData,
}): JSX.Element => {
  const queryPhrase = setSearchMessageData;

  const {
    query,
    queryResult: { data: { appearance: { variables: data = [] } = {} } = {} },
  } = useContentBlock({
    query: (query) => {
      query.getRequest().setSearchQueryKeyphrase(queryPhrase as string);
    },
  });

  useEffect(() => {
    query.getRequest().setSearchQueryKeyphrase(queryPhrase as string);
  }, [queryPhrase]);

  const richTextData: RichTextData = data as unknown as RichTextData;

  if (!richTextData?.title?.value && !richTextData?.detailedDescription?.value) {
    return <></>;
  }

  return (
    <div className={wrapper()}>
      {richTextData?.title && (
        <RichTextHelper field={richTextData?.title} className={descriptionWrapper()} />
      )}
      {richTextData?.content && (
        <RichTextHelper field={richTextData?.content} className={descriptionWrapper()} />
      )}
    </div>
  );
};

//creating widget
export const PersonalizedSearchMessageWidget = widget(
  PersonalizedSearchMessage,
  WidgetDataType.CONTENT_BLOCK,
  'PersonalizedSearchMessage'
);

const SearchMessage: React.FC<SearchMessageProps> = ({ params, rendering }): JSX.Element => {
  const { query } = useRouter();
  const [searchQuery, setSearchQuery] = useState<string>();

  useEffect(() => {
    setSearchQuery((query?.q || query?.query) as string);
  }, [query?.q, query?.query]);

  return (
    <div className={base()}>
      <div>
        <RerenderOnRouteChange rerenderOnHashChange={false}>
          <PersonalizedSearchMessageWidget
            rfkId={params?.discoverRFKId}
            params={params}
            rendering={rendering}
            setSearchMessageData={searchQuery as string}
          />
        </RerenderOnRouteChange>
      </div>
    </div>
  );
};
export default SearchMessage;
