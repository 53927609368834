export const SitecoreTemplateId = {
  // /sitecore/templates/Foundation/JavaScript Services/App
  JssApp: '061cba1554744b918a0617903b102b82',

  // /sitecore/templates/Foundation/JSS Experience Accelerator/Multisite/JSS Settings
  JssSettings: '{EC848505-D30C-4BDC-A0AA-7CC9D320085E}',

  // /sitecore/templates/Foundation/JSS Experience Accelerator/Multisite/JSS Data
  JssData: '{A29D272E-9D48-453C-9E9D-B47585FA7F20}',

  // /sitecore/templates/System/Dictionary/Dictionary entry
  DictionaryEntry: '6d1cd89719364a3aa511289a94c2a7b1',

  // /sitecore/templates/Feature/PetSuppliesPlus/Search/Facets/Facet Item
  FacetItem: 'FD4A6A96-A615-4E23-AD5B-4B5B4ADEAC7B',

  //shops page template id /sitecore/templates/Project/PetSuppliesPlus/Page Types/Products Shop Page
  ShopPage: '1aae551e-b8c4-47fb-962a-9cb7c3a36393',
};

export const SitecoreItemId = {
  WordLookups: {
    AllWords: '{5D3BB308-7964-44A9-B97F-A4105BD2BBDC}',
    AnyWords: '{394D0AA1-8F30-4C2D-96EF-FD6190699038}',
    ContainsPhrase: '{A83BCF17-B4D8-4027-A00F-AA96FB2E35D3}',
    ExactMatch: '{8CA44E56-D1D9-4DB0-AF9E-64E7A2D89362}',
  },
};
